<template>
  <div class="h-680px">
    <Loading
      v-if="loading"
      class="h-full"
    />

    <UnitReview
      v-else
      :cards="reviewCards"
      @back="emit('done')"
    />
  </div>
  <Icon
    name="close"
    class="cursor-pointer absolute right-2 top-2 w-20px"
    @click="emit('done')"
  ></Icon>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import UnitReview from '@/mobile/components/UnitReview.vue'
import Icon from '@/components/Icon.vue'
import { fetchAtlasStageCards, type AtlasStageCard } from '@/api/atlas'

const loading = ref(true)

const props = defineProps<{
  stageId: number
}>()

const emit = defineEmits<{
  done: []
}>()

const reviewCards = ref<AtlasStageCard[]>([])

fetchAtlasStageCards(props.stageId)
  .then(res => {
    reviewCards.value = res.cards
  })
  .finally(() => {
    loading.value = false
  })
</script>
<style scoped></style>
