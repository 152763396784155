<template>
  <div class="text-center px-16px flex flex-col bg-ld-background">
    <RatioSpacedContainer class="flex-1 g-body-width">
      <div class="flex flex-col justify-center items-center w-full">
        <div
          class="h-124px w-full flex items-end justify-center m-b--12px scale-70"
        >
          <div class="relative h-460px w-150px flex items-end justify-center">
            <LottieBox
              class="w-126px h-250px absolute z--1"
              :autoplay="true"
              :loop="true"
              name="flame0"
              :start="35"
            />
          </div>
        </div>
        <NumberImage
          :number="checkData.checkInCtRes.continuousDays"
          class="z-1"
        />
        <div class="text-24px text-orange-400 font-bold m-t-12px">连续签到</div>
      </div>

      <CheckInWeekCalendar
        class="m-t-24px w-full"
        :checkInDates="checkData.stats"
      ></CheckInWeekCalendar>
    </RatioSpacedContainer>
    <div class="g-footer-width mb-40px">
      <Button
        class="w-full"
        label="继续"
        @click="onBack"
      ></Button>
    </div>
  </div>
</template>

<script setup lang="ts">
import NumberImage from './NumberImage.vue'
import type { CheckInCtRes, CheckInStatsItem } from '@/api/user'
import LottieBox from './LottieBox.vue'
import CheckInWeekCalendar from './CheckInWeekCalendar.vue'

defineProps<{
  checkData: {
    checkInCtRes: CheckInCtRes
    stats: CheckInStatsItem[]
  }
}>()

const emit = defineEmits<{
  done: []
}>()

function onBack() {
  emit('done')
}
</script>

<style scoped></style>
