import { Mark, mergeAttributes } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    cloze: {
      setCloze: () => ReturnType
      toggleCloze: () => ReturnType
      unsetCloze: () => ReturnType
    }
  }
}
const Cloze = Mark.create({
  name: 'cloze',

  parseHTML() {
    return [
      {
        tag: 'span[data-cloze]',
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return ['span', mergeAttributes({ 'data-cloze': true }, HTMLAttributes), 0]
  },

  addAttributes: () => {
    return {
      group: {
        default: undefined,
      },
      distrators: {
        default: [],
      },
      giveAwayDistrators: {
        default: [],
      },
    }
  },

  addCommands() {
    return {
      setCloze:
        () =>
        ({ commands }) => {
          return commands.setMark(this.name)
        },
      toggleCloze:
        () =>
        ({ commands }) => {
          return commands.toggleMark(this.name)
        },
      unsetCloze:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name)
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-d': () => this.editor.commands.toggleCloze(),
      'Mod-D': () => this.editor.commands.toggleCloze(),
      'Ctrl-d': () => this.editor.commands.toggleCloze(),
      'Ctrl-D': () => this.editor.commands.toggleCloze(),
    }
  },
})

export default Cloze
