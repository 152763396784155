// 落库的数据结构

export type Card = ClozeCard | EnWordCard

export enum CardType {
  // 知识点
  CLOZE = 'cloze.card',

  // 英语单词
  EN_WORD = 'enWord.card',
}

//
// 知识点卡片
//
export type Content = BlockNode[]

export type ClozeCard = {
  type: CardType.CLOZE
  content: Content
  altContents?: Content[]
  analysis?: Content

  // 配图 assetId
  illustration?: UserAssetId
}

//
// 单词卡片
//

export type EnWordCard = {
  type: CardType.EN_WORD
  // 单词
  word: NonEmptyString
  // 释义
  definition: NonEmptyString
  // 配图 assetId
  illustration?: UserAssetId
  // 发音
  prons: {
    label: NonEmptyString
    language: PronunciationLanguage
  }[]
  examples: {
    source: NonEmptyString
    translation: NonEmptyString
  }[]
  tabs: {
    title: NonEmptyString
    content: Content
  }[]
  // 干扰项
  distrators: {
    word: NonEmptyString
    definition: NonEmptyString
  }[]
}
// 标准使用 BCP 47 https://www.techonthenet.com/js/language_tags.php
export type PronunciationLanguage = 'en-US' | 'en-GB'

//
// Block Nodes
//
export type BlockNode = P

export interface P {
  type: 'p'
  content: InlineNode[]
}

//
// Inline Nodes
//
export type InlineNode = Text | Cloze

// 文本
export interface Text {
  type: 'text'
  text: string
  style?: TextStyle
}

export type TextStyle = {
  // 加点字
  dot?: boolean
}

// 挖空
export interface Cloze {
  type: 'cloze'
  text: string
  group?: NonEmptyString
  distrators?: NonEmptyString[]
  giveAwayDistrators?: NonEmptyString[]
}

export type UserAssetId = string

type NonEmptyString = string
