<template>
  <div class="flex items-center justify-center">
    <Img
      v-if="props.style.icon.type === 'img'"
      :key="props.style.icon.content"
      :name="props.style.icon.content"
      class="w-full"
      height="100%"
      width="100%"
      preserveAspectRatio="xMidYMid slice"
    />

    <span v-else>
      {{ props.style.icon.content }}
    </span>
  </div>
</template>
<script setup lang="ts">
import type { PackageStyle } from '@/api/package-source'

const props = defineProps<{
  style: PackageStyle
}>()
</script>
<style scoped></style>
