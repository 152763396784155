<template>
  <div
    :class="[
      'flex items-center text-ld-brand-500',
      {
        'text-ld-grayText cursor-pointer': props.showHelp,
      },
    ]"
    @click="showExpLessTip = true"
  >
    <Icon
      name="exp"
      class="w-24px"
    />

    <span class="text-19px font-semibold ml-1">
      {{ _t(`经验+${props.exp}`) }}
    </span>

    <NTooltip
      v-if="props.showHelp"
      :show="showExpLessTip"
      placement="top"
      trigger="click"
      class="max-w-260px"
      @update:show="showExpLessTip = $event"
    >
      <div class="text-17px leading-[1.4]">
        {{ props.helpText }}
      </div>

      <template #trigger>
        <Icon
          name="action-help"
          class="w-24px ml-3 text-ld-brand-500"
        />
      </template>
    </NTooltip>
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import { NTooltip } from 'naive-ui'

const props = defineProps<{
  exp: number
  showHelp: boolean
  helpText: string
}>()

const showExpLessTip = ref(false)
</script>

<style scoped></style>
