<template>
  <div class="relative flex items-center justify-center">
    <Icon
      :name="props.name"
      class="absolute inset-0 m-auto max-h-full -z-1"
    />
    <slot />
  </div>
</template>

<script lang="ts" setup>
// Icon 默认居中显示
const props = defineProps({
  name: {
    type: String,
    required: true,
  },
})
</script>

<style scoped></style>
