<template>
  <div class="word whitespace-pre-line">
    {{ props.card.word }}
  </div>
  <div class="definition whitespace-pre-line">
    {{ card.definition }}
  </div>
</template>
<script setup lang="ts">
import type { EnWordCard } from '@/types/core'

const props = defineProps<{
  card: EnWordCard
}>()
</script>
<style scoped>
.word {
  font-size: 17px;
  font-weight: 500;
}
.definition {
  font-size: 14px;
  font-weight: 400;
  margin-top: 8px;
}
</style>
