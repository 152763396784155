<template>
  <div class="h-full flex flex-col flex-1">
    <ExplainChoice
      v-if="finalFace.type === EnWordCardFaceType.ExplainChoice"
      :face="finalFace"
    />

    <ExampleChoice
      v-else-if="finalFace.type === EnWordCardFaceType.ExampleChoice"
      :face="finalFace"
    />

    <WordChoice
      v-else-if="finalFace.type === EnWordCardFaceType.WordChoice"
      :face="finalFace"
    />

    <PickImage
      v-else-if="finalFace.type === EnWordCardFaceType.PickImage"
      :face="finalFace"
    />
  </div>
</template>

<script lang="ts" setup>
import ExplainChoice from './faces/ExplainChoice.vue'
import ExampleChoice from './faces/ExampleChoice.vue'
import WordChoice from './faces/WordChoice.vue'
import PickImage from './faces/PickImage.vue'

import { EnWordCardFaceType, type EnWordCardFace } from '@/types/core'
import { processEnWordFace } from '@/utils/card'
import EnWordCardLayout from '../layout/EnWordCardLayout.vue'

const props = defineProps<{
  face: EnWordCardFace
}>()

const finalFace = processEnWordFace(props.face)
</script>

<style scoped></style>
