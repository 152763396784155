<template>
  <div
    v-for="(node, index) in props.content"
    :key="index"
  >
    <template v-for="n in node.content">
      <slot
        v-if="n.type === 'cloze'"
        :node="n"
        name="cloze"
      >
        <Cloze :node="n"></Cloze>
      </slot>

      <slot
        v-if="n.type === 'text'"
        :node="n"
        name="text"
      >
        <Text :node="n"></Text>
      </slot>
    </template>

    <br v-if="node.content.length === 0" />
  </div>
</template>
<script setup lang="ts">
import type { Content } from '@/types/core'
import Text from '@/components/card-render/Text.vue'
import Cloze from '@/components/CardPreview/render/Cloze.vue'

const props = defineProps<{
  content: Content
}>()
</script>
<style scoped></style>
