<template>
  <div class="hstack items-stretch card-edit h-full w-full">
    <div class="flex flex-col items-center overflow-hidden w-385px">
      <div class="phone-container border-box h-full">
        <CardPreview
          v-if="data.cardFace === PREVIEW_CARD"
          :key="cardJson"
          :card="data.card"
        ></CardPreview>

        <ConcreteCard
          v-else-if="face"
          :key="cardJson + data.cardFace + data.interaction"
          class="flex-1"
          :face="face"
        ></ConcreteCard>
      </div>

      <SelectButton
        :model-value="data.cardFace"
        :options="cardFaceOptions"
        optionLabel="label"
        optionValue="type"
        :allowEmpty="false"
        class="mt-2"
        @update:model-value="onCardFaceChange"
      ></SelectButton>

      <SelectButton
        v-model="data.interaction"
        :options="interactionOptions"
        optionLabel="label"
        optionValue="value"
        :allowEmpty="false"
        class="mt-2"
      ></SelectButton>
    </div>

    <div class="flex-1 flex flex-col overflow-scroll">
      <TabView
        class="h-full"
        :activeIndex="store.cardEditTab"
        @update:active-index="store.setCardEditTab($event)"
      >
        <TabPanel :header="_t('修改内容')">
          <ClozeCardForm
            v-if="data.card.type === CardType.CLOZE"
            min-cols="30"
            :card="data.card"
            @change="onCardChange"
          ></ClozeCardForm>

          <WordCardForm
            v-if="data.card.type === CardType.EN_WORD"
            min-cols="30"
            :card="data.card"
            @change="onCardChange"
          ></WordCardForm>
          <div
            v-if="cardInvalidMsg"
            class="text-red mt-4"
          >
            {{ cardInvalidMsg }}
          </div>
        </TabPanel>

        <TabPanel :header="_t('主题样式')">
          {{ _t('主题样式') }}
        </TabPanel>
        <TabPanel :header="_t('卡片信息')">
          <div class="vstack items-start h-full overflow-hidden">
            <div class="overflow-y-scroll flex-1">
              <CardSource
                :key="data.cardInfo.content"
                :card="data.cardInfo"
              />
            </div>

            <Button
              label="删除卡片"
              class="self-center mt-auto"
              scene="danger"
              @click="emit('delete')"
            ></Button>
          </div>
        </TabPanel>
      </TabView>
    </div>
  </div>
</template>
<script setup lang="ts">
import ConcreteCard from '@/components/ConcreteCard/ConcreteCard.vue'
import CardPreview from '@/components/CardPreview/CardPreview.vue'
import ClozeCardForm from './ClozeCardForm.vue'
import WordCardForm from './WordCardForm.vue'
import SelectButton from 'primevue/selectbutton'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import {
  Interaction,
  CardType,
  ClozeCardFaceType,
  EnWordCardFaceType,
} from '@/types/core'
import { computed, reactive } from 'vue'
import { randomPick, tryJSONParse } from '@/utils'
import { cloneDeep } from 'lodash-es'
import { useCommonStore } from '@/stores'
import CardSource from './CardSource.vue'

import type { CardResponse as CardResponse } from '@/api/package-source'
import type { ConcreteCardFace, Card } from '@/types/core'
import { parseCard, validateCard } from '@/utils/card'

const PREVIEW_CARD = 'PREVIEW'

const interactionOptionNameMap = {
  [Interaction.Practice]: _t('练习'),
  [Interaction.Check]: _t('检验'),
  [Interaction.Flash]: _t('连刷'),
}

const store = useCommonStore()

const props = defineProps<{
  card: CardResponse
  cards: CardResponse[]
}>()

const emit = defineEmits<{
  update: [CardResponse]
  delete: []
}>()

const data = reactive({
  cardFace: PREVIEW_CARD as ConcreteCardFace['type'] | typeof PREVIEW_CARD,
  interaction: Interaction.Practice,
  cardInfo: props.card,
  card: tryJSONParse(props.card.content, {
    type: CardType.CLOZE,
    content: '解析失败',
  }) as Card,
})
const cardJson = computed(() => JSON.stringify(data.card))

const cardInvalidMsg = computed(() => {
  if (data.card == null) return ''

  return validateCard(data.card)
})

const cardFaceOptions = computed(() => {
  const preivewOption = {
    label: '预览',
    type: PREVIEW_CARD,
  }

  switch (data.card.type) {
    case CardType.CLOZE:
      return [
        preivewOption,
        {
          label: '选择题',
          type: ClozeCardFaceType.Choice,
        },
        {
          label: '极简选择题',
          type: ClozeCardFaceType.MinimalChoice,
        },
        {
          label: '送分选择题',
          type: ClozeCardFaceType.GiveAwayChoice,
        },
        {
          label: '判断题',
          type: ClozeCardFaceType.Judgement,
        },
        {
          label: '送分判断题',
          type: ClozeCardFaceType.GiveAwayJudgement,
        },
      ]
    case CardType.EN_WORD:
      return [
        preivewOption,
        {
          label: '单词选释义',
          type: EnWordCardFaceType.WordChoice,
        },
        {
          label: '例句选释义',
          type: EnWordCardFaceType.ExampleChoice,
        },
        {
          label: '释义选单词',
          type: EnWordCardFaceType.ExplainChoice,
        },
        {
          label: '选图',
          type: EnWordCardFaceType.PickImage,
        },
      ]
    default:
      return []
  }
})

function getInteractions(
  type: CardType,
  face: ConcreteCardFace['type'] | typeof PREVIEW_CARD
): Interaction[] {
  if (face === PREVIEW_CARD) return []

  switch (type) {
    case CardType.CLOZE: {
      return [Interaction.Practice, Interaction.Check, Interaction.Flash]
    }

    case CardType.EN_WORD: {
      return [Interaction.Practice, Interaction.Check, Interaction.Flash]
    }

    default:
      return []
  }
}

const interactionOptions = computed(() => {
  if (data.cardFace === PREVIEW_CARD) {
    return []
  }

  const interactions = getInteractions(data.card.type, data.cardFace)

  return interactions.map(interaction => ({
    label: interactionOptionNameMap[interaction],
    value: interaction,
  }))
})

const face = computed<ConcreteCardFace | null>(() => {
  const altCards = props.cards
    .filter(item => item.id !== data.cardInfo.id)
    .map(item => JSON.parse(item.content) as Card)
    .filter(item => item.type === data.card.type)
    .map(card => parseCard(card))
    .filter(result => result.error == null)
    .map(result => result.card)

  const parsedResult = parseCard(data.card)

  if (parsedResult.error != null) return null

  return {
    type: data.cardFace,
    cardId: data.cardInfo.id,
    card: parsedResult.card,
    interaction: data.interaction,
    altCards,
    style: {
      questionLayout: randomPick(['horizontal', 'vertical']),
      operationLayout: 'horizontal',
    },
  } as ConcreteCardFace
})

function onCardFaceChange(cardFace: any) {
  data.cardFace = cardFace
  const interactions = getInteractions(data.card.type, cardFace)

  if (!interactions.includes(data.interaction)) {
    data.interaction = interactions[0]
  }
}

function onCardChange(card: Card) {
  data.card = card

  const res = cloneDeep(props.card)
  res.content = JSON.stringify(card)
  emit('update', res)
}
</script>
<style scoped>
:deep(.phone-container) {
  min-height: 600px;
  height: 100%;
}

:deep(.p-tabview) {
  display: flex;
  flex-direction: column;
}

:deep(.p-tabview-panels) {
  flex: 1;
  overflow-y: hidden;
}

:deep(.p-tabview-panel) {
  height: 100%;
  overflow-y: scroll;
}
</style>
