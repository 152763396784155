<template>
  <div ref="mainRef">
    <div>
      <label
        data-required
        class="block text-900 text-sm font-medium"
      >
        {{ _t('邮箱') }}
      </label>

      <InputText
        v-model="email"
        tabindex="1"
        class="mt-2 w-full"
        :pt="{ root: { 'data-focus': 'true' } }"
      />
    </div>

    <div class="mt-5">
      <div class="flex items-center">
        <label
          data-required
          class="block text-900 text-sm font-medium"
        >
          {{ _t('密码') }}
        </label>

        <TextButton
          :label="_t('忘记密码')"
          class="ml-auto reset-pwd-btn"
          tabindex="0"
          @click="emit('resetPwd')"
        />
      </div>

      <Password
        v-model="password"
        :feedback="false"
        class="mt-2 w-full"
        inputClass="w-full"
        :inputProps="{
          tabindex: '1',
        }"
        @keydown.enter="onSignIn"
      />
    </div>

    <Button
      class="mt-8 w-full"
      label="登录"
      :disabled="!isValid"
      :loading="loading"
      @click="onSignIn"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, onMounted } from 'vue'
import { isEmail } from '@/utils'
import { useCommonStore } from '@/stores'
import { login } from '@/api/auth'

const emit = defineEmits<{
  resetPwd: []
  done: []
}>()

const email = ref('')
const password = ref('')

const isValid = computed(() => {
  return isEmail(email.value) && password.value !== ''
})

const loading = ref(false)
const store = useCommonStore()
function onSignIn() {
  if (!isValid.value) return

  loading.value = true

  login({
    email: email.value,
    password: password.value,
  })
    .then(res => {
      if (res.code === 0) {
        store.login(res.data.token, res.data.user)
        emit('done')
      } else {
        _message.info(res.message)
      }
    })
    .finally(() => {
      loading.value = false
    })
}

// auto focus
const mainRef = ref()
onMounted(() => {
  setTimeout(() => {
    mainRef.value.querySelector('input[data-focus]').focus()
  }, 0)
})
</script>

<style scoped>
.reset-pwd-btn :deep(button) {
  padding: 0px;
  font-size: 14px;
}
</style>
