<template>
  <div class="p-4">
    <div class="rounded-2xl border border-gray-200 bg-white">
      <div class="flex justify-center items-center p-x-4 p-y-2">
        <div class="text-2xl font-bold flex-1">
          {{ currentYear }} {{ _t('年') }} {{ currentMonth + 1 }} {{ _t('月') }}
        </div>
        <div class="flex items-center">
          <i
            class="pi pi-chevron-left cursor-pointer w-8"
            @click="onPrevMonth"
          ></i>
          <i
            class="pi pi-chevron-right w-8"
            :class="{
              'text-gray-400': !nextMonthEnable,
              'cursor-pointer': nextMonthEnable,
            }"
            @click="onNextMonth"
          ></i>
        </div>
      </div>
      <div class="grid grid-cols-7">
        <div
          v-for="(week, i) in weeks"
          :key="week"
          class="week flex justify-center items-center"
          :class="{ 'week-r': i >= 5 }"
        >
          {{ week }}
        </div>
      </div>
      <div class="border-b border-gray-200"></div>
      <div class="grid grid-cols-7">
        <div
          v-for="(day, i) in days"
          :key="i"
          class="day flex justify-center items-center"
          :class="getDayClass(day)"
        >
          <template v-if="!checkIn(day)">
            {{ day }}
          </template>
          <Icon
            v-else
            name="icon_check_in_border"
            skipsvgo
            class="icon w-31px"
          />
        </div>
      </div>
      <div class="border-b border-gray-200"></div>
      <div class="flex p-y-4">
        <div
          v-for="item in dataInMonth"
          :key="item.desc"
          class="flex-1 flex flex-col items-center justify-center"
        >
          <div class="text-orange-400">
            <span class="text-2xl font-bold p-r-1">{{ item.count }}</span>
            <span class="text-sm">{{ item.unit }}</span>
          </div>
          <div class="text-sm text-[var(--text-color-secondary)]">
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { type CheckInStatsItem } from '@/api/user'
import { computed } from 'vue'

const emit = defineEmits<{
  done: []
  onPrevMonth: []
  onNextMonth: []
}>()

const props = defineProps<{
  currentYear: number
  currentMonth: number
  // 签到数据
  checkInDates: CheckInStatsItem[]
  // 本月签到天数
  count: number
  // 本月签到时长
  duration: number
  // 本月卡片数
  card: number
  // 下个月是否可用
  nextMonthEnable: boolean
}>()

const today = new Date()

const weeks = ['一', '二', '三', '四', '五', '六', '日']

const days = computed(() => {
  const firstDay = new Date(props.currentYear, props.currentMonth, 1)
  const lastDay = new Date(props.currentYear, props.currentMonth + 1, 0)
  const firstDayWeek = firstDay.getDay()
  const lastDayDate = lastDay.getDate()
  const result = []
  for (let i = 1; i < firstDayWeek; i++) {
    result.push(null)
  }
  for (let i = 1; i <= lastDayDate; i++) {
    result.push(i)
  }

  return result
})

const dataInMonth = computed(() => {
  // 获取当月的数据

  return [
    {
      count: props.count || 0,
      unit: _t('天'),
      desc: _t('本月签到'),
    },
    {
      count: props.duration || 0,
      unit: _t('分钟'),
      desc: _t('本月时长'),
    },
    {
      count: props.card || 0,
      unit: _t('卡片'),
      desc: _t('本月卡片'),
    },
  ]
})

// 某一天是否签到了
function checkIn(d: number | null) {
  if (d === null) {
    return false
  }
  const index = d - 1
  const item = props.checkInDates[index]
  if (!item) {
    return false
  }
  return item.type !== null
}

function getDayClass(d: number | null) {
  if (d === null) {
    return {}
  }
  // 表示是否已经签到
  const checked = checkIn(d)
  // now 表示是否是今天
  const now = today.getDate() === d && today.getMonth() === props.currentMonth
  const after = today < new Date(props.currentYear, props.currentMonth, d)
  const dayDate = new Date(props.currentYear, props.currentMonth, d)

  // 前一天是否已经签到
  const prevDayChecked = checkIn(d - 1)
  // 后一天是否已经签到
  const nextDayChecked = checkIn(d + 1)
  const weekFirst = dayDate.getDay() === 1
  const weekLast = dayDate.getDay() === 0
  const monthFirst = d === 1
  const monthLast =
    d === new Date(props.currentYear, props.currentMonth + 1, 0).getDate()
  return {
    now: now,
    after: after,
    checked: checked,
    'checked-l': !prevDayChecked || weekFirst || monthFirst,
    'checked-r': !nextDayChecked || weekLast || monthLast,
  }
}

function onPrevMonth() {
  emit('onPrevMonth')
}
function onNextMonth() {
  if (!props.nextMonthEnable) {
    return
  }
  emit('onNextMonth')
}
</script>
<style scoped>
.week {
  font-size: 18px;
  aspect-ratio: 1;
  color: var(--text-color);
  font-weight: 600;
}
.week-r {
  color: var(--red-500);
}
.day {
  font-size: 18px;
  aspect-ratio: 1;
  color: var(--text-color);
}
.day.now {
  text-decoration: underline;
  text-decoration-thickness: 4px;
  color: var(--orange-200);
}

.day.after {
  color: var(--gray-400);
}
.day.checked {
  background-color: var(--orange-200);
  color: white;
}
.day.checked-l {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}
.day.checked-r {
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
}
</style>
