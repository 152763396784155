<template>
  <div class="px-3 pb-3">
    <div class="header">
      <div>{{ _t('试学体验') }}</div>

      <Icon
        name="close-circle"
        class="cursor-pointer absolute right-0 w-25px"
        @click="onClose"
      ></Icon>
    </div>

    <div class="mx-auto px-4 flex flex-col items-center mt-6 mb-8 gap-10px">
      <div
        class="w-99px h-99px rounded-4 text-60px flex justify-center items-center"
        :style="{
          backgroundColor: `var(--${pkg.style.themeColor}-200)`,
        }"
      >
        <PkgIcon
          :style="pkg.style"
          class="w-66px h-66px"
        />
      </div>

      <div
        class="text-19px leading-[1.2] font-semibold line-clamp-2"
        :style="{
          color: `var(--${pkg.style.themeColor}-800)`,
        }"
      >
        {{ pkg.name }}
      </div>
    </div>

    <div
      class="trail-box flex flex-col"
      @click="onPackageUnlock"
    >
      <template v-if="packageAccess == null">
        <div class="text-19px font-semibold">
          {{ _t('这个卡包正在试学中') }}
        </div>
        <div class="text-17px">
          {{
            _t(
              `你可以免费体验 ${leftTrailCount} 次闯关；解锁完整版后，可无限次闯关。`
            )
          }}
        </div>

        <TextButton
          label="解锁完整版"
          class="unlock-btn ml-auto"
        ></TextButton>
      </template>

      <div
        v-else-if="packageAccess === PackageAccess.Author"
        class="flex items-center justify-center"
      >
        {{ _t('这是你自己的卡包') }}
      </div>

      <div
        v-else
        class="flex items-center justify-center"
      >
        <div class="w-40px h-40px relative items-center">
          <LottieBox
            name="check-mark"
            :loop="false"
            :autoplay="true"
            class="absolute absolute-center w-80px h-80px"
          />
        </div>

        <span class="text-19px ml-2 font-semibold text-ld-brand-500">
          {{ _t('卡包已解锁、尽情闯关吧') }}
        </span>
      </div>
    </div>

    <Button
      class="w-full h-56px mt-4"
      :disabled="!props.learnable"
      @click="emit('done', true)"
    >
      <div
        class="flex justify-center w-full font-semibold items-center text-21px"
      >
        <span>
          {{ packageAccess == null ? _t('开始体验闯关') : _t('开始闯关') }}
        </span>

        <EnergyCount
          :count="-props.requiredEnergy"
          class="ml-4"
        />
      </div>
    </Button>
  </div>
</template>
<script setup lang="ts">
import { PackageAccess, type PackageBasic } from '@/api/package-source'
import PackageUnlock from '@/components/PackageUnlock.vue'
import EnergyCount from '@/components/EnergyCount.vue'
import PkgIcon from './PkgIcon.vue'
import LottieBox from '@/components/LottieBox.vue'
import { ref, computed } from 'vue'

const props = defineProps<{
  pkg: PackageBasic
  learnable: boolean
  requiredEnergy: number
}>()

const emit = defineEmits<{
  done: [start: boolean]
  unlock: [PackageAccess]
}>()

const packageAccess = ref(props.pkg.packageAccess)

const leftTrailCount = computed(() => {
  if (props.pkg.trailStatus == null) {
    return 0
  }
  return props.pkg.trailStatus.trailLimit - props.pkg.trailStatus.trailCount
})

function onClose() {
  emit('done', false)
}

async function onPackageUnlock() {
  if (packageAccess.value != null) return

  await _presentContent(PackageUnlock, {
    rootClass: 'min-w-400px max-w-600px',
    props: {
      package: props.pkg,
      exitAfterUnlock: true,
      onUnlock(access: PackageAccess) {
        emit('unlock', access)

        setTimeout(() => {
          packageAccess.value = PackageAccess.Vip
        }, 200)
      },
    },
  })
}
</script>

<style scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  font-weight: 600;
  line-height: 24px;
  position: relative;
  height: 44px;
}

.trail-box {
  border-radius: 12px;
  background-color: var(--bluegray-200);
  padding: 12px;
  line-height: 1.4;
  cursor: pointer;
}

.unlock-btn {
  padding: 0px;
  font-size: 18px;
  text-decoration: underline;
  text-underline-offset: 3px;
}
</style>
