import is from 'is_js'
import dayjs from 'dayjs'
import { floor } from 'lodash-es'

export function isEmail(input: string): boolean {
  // 如果包含中文，返回false
  if (/[^\x00-\xff]/.test(input)) {
    return false
  }
  return is.email(input)
}

// TODO: prod 关闭消息打印
export function warn(...args: any[]) {
  console.warn(...args)
}

export function randomString(length: number): string {
  const characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  let result = ''
  const charactersLength = characters.length

  // 生成随机字符串
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

// format date in local timezone
export function formatDate(
  input: string | Date | number,
  format: string = 'YYYY-MM-DD HH:mm:ss'
) {
  return dayjs(input).format(format)
}

// e.g. 1 -> A, 2 -> B
// i: starts with 0
export function number2ChoiceOptionText(i: number) {
  // 'A' char code is 65
  return String.fromCharCode(65 + i)
}

export function tryJSONParse(str: string, dft: any = null) {
  try {
    return JSON.parse(str)
  } catch (_err) {
    return dft
  }
}

export function formatDateRelative(input: string | Date | number) {
  const today = dayjs(new Date().setHours(0, 0, 0, 0))

  const diffDays = today.diff(input, 'day', true)
  const diffWeeks = today.diff(input, 'week')
  const diffMonths = today.diff(input, 'month')
  const diffYears = today.diff(input, 'year')

  if (diffYears > 0) {
    return `${diffYears} 年前`
  } else if (diffMonths > 0) {
    return `${diffYears} 月前`
  } else if (diffWeeks > 0) {
    return `${diffYears} 周前`
  } else if (diffDays > 0) {
    return '昨天'
  } else {
    return '今天'
  }
}

export const KB = 1024
export const MB = 1024 * 1024

export function formatSize(bytes: number) {
  if (bytes < MB) {
    const kb = (bytes / KB).toFixed(2)

    return `${kb} KB`
  }

  const mb = (bytes / MB).toFixed(2)

  return `${mb} MB`
}

async function getImageFromFile(file: File): Promise<HTMLImageElement> {
  const url = URL.createObjectURL(file)

  return new Promise((resolve, reject) => {
    const img = new Image()

    img.onload = function () {
      resolve(img)
      URL.revokeObjectURL(url)
    }

    img.onerror = reject

    img.src = url
  })
}

export async function readImageSize(
  file: File
): Promise<{ height: number; width: number }> {
  const img = await getImageFromFile(file)

  return {
    height: img.height,
    width: img.width,
  }
}

function renameFileExt(filename: string, newExt: string): string {
  const parts = filename.split('.')

  if (parts.length === 1) return filename

  const fileNameWithoutExt = parts.slice(0, -1).join('.')

  return `${fileNameWithoutExt}.${newExt}`
}

const MIN_COMPRESS_SIZE = 500 * KB

export async function resizeAndCompressImage(
  file: File,
  maxWidth: number,
  maxHeight: number,
  quality: number = 0.8
): Promise<File> {
  if (file.size < MIN_COMPRESS_SIZE) return file

  if (file.name.endsWith('.gif')) return file

  const image = await getImageFromFile(file)

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')!

  // 计算压缩比例
  let width = image.width
  let height = image.height
  const aspectRatio = width / height

  if (width > maxWidth || height > maxHeight) {
    if (aspectRatio > 1) {
      // 宽度较大
      width = maxWidth
      height = Math.floor(maxWidth / aspectRatio)
    } else {
      // 高度较大
      height = maxHeight
      width = Math.floor(maxHeight * aspectRatio)
    }
  }

  // 设置canvas的大小
  canvas.width = width
  canvas.height = height

  // 绘制调整大小后的图片
  ctx.drawImage(image, 0, 0, width, height)

  return canvasToImageFile(canvas, file.name, 'image/jpeg', quality)
}

export function canvasToImageFile(
  canvas: HTMLCanvasElement,
  filename: string,
  type: 'image/jpeg' | 'image/png',
  quality: number
): Promise<File> {
  return new Promise((resolve, reject) => {
    canvas.toBlob(
      blob => {
        if (blob) {
          resolve(new File([blob], renameFileExt(filename, 'jpg')))
        } else {
          reject(new Error('failed to compress image'))
        }
      },
      type,
      quality
    )
  })
}

export function fenToYuan(fen: number) {
  return floor(fen / 100, 2)
}

export function randomPick<T>(list: T[]): T {
  const index = Math.floor(Math.random() * list.length)
  return list[index]
}

export function setViewportHeight() {
  document.documentElement.style.setProperty(
    '--ld-viewport-height',
    window.innerHeight + 'px'
  )
}

export function wait(ms: number) {
  return new Promise(resolve => {
    setTimeout(resolve, ms)
  })
}

export interface HighlightSpan {
  text: string
  highlight: boolean
}

export function getHighlightSpans(
  text: string,
  highlightText: string
): HighlightSpan[] {
  const spans: HighlightSpan[] = []
  const unstyledSpans: HighlightSpan[] = text
    .split(highlightText)
    .map(text => ({
      text,
      highlight: false,
    }))

  let pushQuery = false
  while (unstyledSpans.length > 0) {
    if (pushQuery) {
      spans.push({ text: highlightText, highlight: true })
    } else {
      spans.push(unstyledSpans.shift()!)
    }

    pushQuery = !pushQuery
  }

  return spans
}

export function pickFile(accept = '*'): Promise<File> {
  return new Promise(resolve => {
    const input = document.createElement('input') as HTMLInputElement
    input.setAttribute('accept', accept)
    input.type = 'file'
    input.style.display = 'none'
    document.body.appendChild(input)
    input.click()
    input.addEventListener('change', evt => {
      const file = (evt.target as HTMLInputElement).files?.[0]

      if (file != null) {
        resolve(file)
      }
    })
  })
}

const lottieCache = new Map<string, any>()
export async function loadLottie(name: string): Promise<any> {
  if (lottieCache.has(name)) {
    return lottieCache.get(name)
  }

  const data = (await import(`@/assets/lottie/${name}.json`)).default

  lottieCache.set(name, data)

  return data
}

// TODO(buding): 如果用户手动关闭弹窗，该方法不会返回，永远 pending
export async function loadAliyunCaptcha(
  button: HTMLButtonElement,
  elementId: string,
  onCaptchaVerify: (param: string) => {
    captchaResult: boolean
    bizResult: boolean
  }
) {
  document.getElementById('aliyunCaptcha-mask')?.remove()
  document.getElementById('aliyunCaptcha-window-popup')?.remove()
  const width = Math.min(window.innerWidth * 0.8, 360)

  initAliyunCaptcha({
    SceneId: _global.aliyunCaptchaSceneId,
    prefix: _global.aliyunCaptchaPrefix,
    mode: 'popup',
    button: `#${button.id}`,
    element: `#${elementId}`,
    captchaVerifyCallback: onCaptchaVerify,
    slideStyle: {
      width,
    },
    language: 'cn',
  })
}
// 获取文字行数
export function getTextLineCount(textElement: HTMLElement): number {
  const computedStyle = window.getComputedStyle(textElement)
  const lineHeight = parseInt(computedStyle.lineHeight)
  const height = textElement.clientHeight
  return Math.floor(height / lineHeight)
}

// 传入 seconds
// 转成 mm:ss
// 如果超过 60 分钟，则显示 hh:mm 最多显示到 “99:59”
export function formatLearnDuration(seconds: number): string {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor(seconds / 60) % 60
  const sec = seconds % 60

  const secStr = String(sec).padStart(2, '0')
  const minStr = String(minutes).padStart(2, '0')
  const hourStr = String(hours).padStart(2, '0')

  if (hours >= 100) {
    return '99:59'
  }

  if (hours > 0) {
    return `${hourStr}:${minStr}`
  }
  return `${minStr}:${secStr}`
}

export function displayCurrency(currency: 'CNY') {
  const map = {
    CNY: '¥',
  }

  return map[currency] ?? map['CNY']
}

export function clamp(value: number, min: number, max: number) {
  if (value < min) return min
  if (value > max) return max
  return value
}

// Boss 数量 12 个 从 1001 - 1012
const bossCount = 12

export function bossList(): string[] {
  // boss 形象从 1001 开始
  return Array.from({ length: bossCount })
    .map((_, i) => 1001 + i)
    .map(name => `${name}`)
}

// 随意挑选一个 boss 形象
export function randomPickBoss(): string {
  return randomPick(bossList())
}
