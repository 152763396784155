<template>
  <div class="header mx-2">
    <div>{{ _t(`第 ${props.stageLevel} 关`) }}</div>

    <Icon
      name="close-circle"
      class="cursor-pointer absolute right-0 top-0 w-22px"
      @click="onClose"
    ></Icon>
  </div>

  <div class="h-162px my-6">
    <Loading
      v-if="loading"
      class="h-full"
    />

    <Swiper
      v-else
      effect="coverflow"
      slidesPerView="auto"
      :centeredSlides="true"
      :grabCursor="true"
      :pagination="true"
      :coverflowEffect="{
        rotate: 0,
        stretch: 0,
        depth: 0,
        scale: 0.8,
        slideShadows: false,
      }"
      :modules="[EffectCoverflow]"
      @slideChange="onSlideChange"
      @swiper="onSetSwiper"
    >
      <SwiperSlide
        v-for="(item, index) in preStageInfo.packages"
        :key="item.id"
        class="w-130px h-165px"
      >
        <PkgCard
          :package="item"
          :selected="activeIndex === index"
          class="w-full h-full"
          @click="onPkgClick(index)"
        />
      </SwiperSlide>

      <SwiperSlide class="w-130px h-165px">
        <div
          class="pkg-add"
          @click="onPkgAddClick"
        >
          <Icon
            name="pkg-add"
            class="w-72px"
          />
          <span class="text-sm mt-3 h-36px flex items-center">
            {{ _t('添加卡包') }}
          </span>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>

  <DebugLabel class="absolute top-0 left-0">
    <DebugButton
      v-if="pkg"
      label="完成关卡"
      size="small"
      @click="onDebugFinishStage"
    ></DebugButton>
  </DebugLabel>

  <div class="px-3">
    <div class="h-28px mb-2 flex justify-center items-center">
      <div
        v-if="pkg && isPkgTrailAllUsed(pkg)"
        class="text-15px font-semibold"
      >
        {{ _t('该卡包体验次数已用完，') }}

        <TextButton
          class="text-18px ml--1"
          @click="onPackageUnlock(pkg)"
        >
          <span class="text-15px font-semibold underline underline-offset-3">
            {{ _t('解锁完整版') }}
          </span>
        </TextButton>
      </div>

      <ExpInfo
        v-else-if="pkg != null"
        :key="pkg.id"
        :exp="getPkgExp(pkg)"
        :showHelp="isPkgExpLess(pkg)"
        :helpText="_t('这个卡包你已经很熟练了，建议学习其他卡包')"
      />
    </div>

    <div class="h-56px">
      <Button
        v-if="pkg != null"
        class="w-full h-56px"
        :disabled="!isPkgLearnable(pkg)"
        :disabled-msg="_t('该卡包体验次数已用完，请先请解锁')"
        :loading="stageLoading"
        @click="onStartClick(pkg)"
      >
        <div
          class="flex justify-center w-full font-semibold items-center text-21px"
        >
          <span>{{ _t('开始闯关') }}</span>
          <EnergyCount
            :count="-preStageInfo.requiredEnergy"
            class="ml-4"
          />
        </div>
      </Button>

      <Button
        v-else-if="isAddPackage"
        class="w-full h-56px"
        @click="onPkgSearch"
      >
        <div
          class="flex justify-center w-full font-semibold items-center text-21px"
        >
          <span>{{ _t('去社区搜索') }}</span>
        </div>
      </Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, computed } from 'vue'
import PkgCard from './PkgCard.vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { EffectCoverflow } from 'swiper/modules'
import type { Swiper as TSwiper } from 'swiper'
import 'swiper/css'
import { Code } from '@/api/code'
import { preStartAtlasStage } from '@/api/atlas'
import { useCommonStore } from '@/stores'
import { useRouter } from 'vue-router'
import type DebugLabel from '@/components/DebugLabel.vue'
import { startAtlasStage } from '@/api/atlas'
import { getPackageShowDate } from '@/utils/package'
import db from '@/db'
import { debugFinishStage } from './debug'
import EnergyCount from '@/components/EnergyCount.vue'
import PackageUnlock from '@/components/PackageUnlock.vue'
import { PackageAccess, type PackageBasic } from '@/api/package-source'
import PkgTrail from './PkgTrail.vue'
import ExpInfo from '@/components/ExpInfo.vue'
import Button from '@/components/Button.vue'
import SearchPanel from '@/components/SearchPanel/SearchPanel.vue'

const store = useCommonStore()
const router = useRouter()

const props = defineProps<{
  stageLevel: number
  lastPkgId?: number
}>()

const emit = defineEmits<{
  stageCompleted: []
  done: [pkgId?: number]
}>()

const preStageInfo = ref<{
  packages: PackageBasic[]
  packageExpMap: Record<
    number,
    {
      exp: number
      lessExp: boolean
    }
  >
  requiredEnergy: number
}>({
  packages: [],
  requiredEnergy: 0,
  packageExpMap: {},
})

const activeIndex = ref(0)
const loading = ref(true)
const stageLoading = ref(false)
const swiperController = ref<TSwiper>()

const pkg = computed<PackageBasic | undefined>(
  () => preStageInfo.value.packages[activeIndex.value]
)

const isAddPackage = computed(
  () =>
    activeIndex.value === preStageInfo.value.packages.length && !loading.value
)

async function initSwiperIndex() {
  const index = preStageInfo.value.packages.findIndex(
    pkg => pkg.id === props.lastPkgId
  )

  activeIndex.value = index > -1 ? index : 0
  swiperController.value?.slideTo(activeIndex.value)
}

function isPkgLearnable(pkg: PackageBasic) {
  if (pkg.packageAccess == null && pkg.trailStatus != null) {
    return pkg.trailStatus.trailCount < pkg.trailStatus.trailLimit
  }

  return true
}

function isPkgTrailAllUsed(pkg: PackageBasic) {
  if (pkg.packageAccess == null && pkg.trailStatus != null) {
    return pkg.trailStatus.trailCount >= pkg.trailStatus.trailLimit
  }

  return false
}

function getPkgExp(pkg: PackageBasic) {
  return preStageInfo.value.packageExpMap[pkg.id]?.exp ?? 0
}

function isPkgExpLess(pkg: PackageBasic) {
  return preStageInfo.value.packageExpMap[pkg.id]?.lessExp
}

function onSetSwiper(val: TSwiper) {
  swiperController.value = val
  initSwiperIndex()
}

function onSlideChange(swiper: TSwiper) {
  activeIndex.value = swiper.activeIndex
}

async function onDebugFinishStage() {
  if (!pkg.value) return

  const result = await debugFinishStage(pkg.value.id)

  if (result) {
    store.fetchStatsOverview()
    _message.success('关卡已完成')
    emit('stageCompleted')
    emit('done', pkg.value.id)
  }
}

async function onStartClick(pkg: PackageBasic) {
  // 试学
  if (pkg.packageAccess == null) {
    const start = await _presentContent(PkgTrail, {
      rootClass: 'g-dialog',
      props: {
        pkg,
        learnable: isPkgLearnable(pkg),
        requiredEnergy: preStageInfo.value.requiredEnergy,
        onUnlock(access: PackageAccess) {
          pkg.packageAccess = access
        },
      },
    })

    if (start) {
      onStageStart(pkg)
    }
    return
  }

  onStageStart(pkg)
}

function onStageStart(pkg: PackageBasic) {
  stageLoading.value = true

  startAtlasStage(pkg.id)
    .then(async res => {
      if (res.code === Code.NotEnoughEnergy) {
        _message.info('面包不足')
        _openEnergyBuy()
        return
      } else if (res.code !== Code.Ok) {
        _message.info(res.message)
        return
      }

      store.increaseEnergy(-preStageInfo.value.requiredEnergy)

      store.setStageUnit(
        {
          unitId: res.data.unitId,
          atlasStageId: res.data.stageId,
          schedules: res.data.schedules,
        },
        db.debug.queueType
      )

      emit('done', pkg.id)
      router.push({
        name: 'unit/learn',
      })
    })
    .finally(() => {
      stageLoading.value = false
    })
}

function onClose() {
  emit('done')
}

function onPkgAddClick() {
  if (isAddPackage.value) {
    onPkgSearch()
  } else {
    onPkgClick(preStageInfo.value.packages.length)
  }
}

function onPkgClick(index: number) {
  activeIndex.value = index
  swiperController.value?.slideTo(activeIndex.value)
}

function onPkgSearch() {
  _presentContent(SearchPanel, {
    rootClass: 'h-680px w-480px p-0',
    bottomSheetClass: 'h-85vh',
    props: {
      isPage: false,
      navigator: _global.isPcMode ? 'modal' : 'bottomSheet',
      onPackagesUpdate() {
        fetchUserPackages().then(() => {
          swiperController.value?.slideTo(preStageInfo.value.packages.length)
        })
      },
      onPackageChallenge(pkgId: number) {
        _closeActiveDialog()

        const index = preStageInfo.value.packages.findIndex(
          pkg => pkg.id === pkgId
        )

        activeIndex.value = index > -1 ? index : 0
        swiperController.value?.slideTo(activeIndex.value)
      },
    },
  })
}

function onPackageUnlock(pkg: PackageBasic) {
  _presentContent(PackageUnlock, {
    rootClass: 'min-w-400px max-w-600px',
    props: {
      package: pkg,
      exitAfterUnlock: true,
      onUnlock(access: PackageAccess) {
        pkg.packageAccess = access
      },
    },
  })
}

async function fetchUserPackages() {
  loading.value = true
  try {
    const res = await preStartAtlasStage(props.stageLevel)

    preStageInfo.value.requiredEnergy = res.requiredEnergy
    preStageInfo.value.packages = res.packageList.packages.sort(
      (a, b) => getPackageShowDate(b) - getPackageShowDate(a)
    )
    preStageInfo.value.packageExpMap = res.packageExp

    initSwiperIndex()
  } finally {
    loading.value = false
  }
}

onMounted(async () => {
  fetchUserPackages()
})
</script>

<style scoped>
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 17px;
  font-weight: 600;
  line-height: 24px;
  position: relative;
  height: 24px;
  margin-top: 12px;
}

.pkg-add {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, var(--gray-100) 0%, var(--gray-300) 100%);
  border: 3px solid var(--gray-700);
  border-style: dashed;
  border-radius: 16px;
  width: 130px;
  height: 165px;
}
</style>
