<template>
  <div class="w-lg">
    <iframe
      ref="iframeRef"
      class="w-full h-600px"
      src=""
      frameborder="0"
    ></iframe>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted } from 'vue'

const props = defineProps<{
  form: string
}>()

const iframeRef = ref()
onMounted(() => {
  const iframe = iframeRef.value!
  iframe.contentWindow.document.open()
  iframe.contentWindow.document.write(props.form)
  iframe.contentWindow.document.close()
})
</script>

<style scoped></style>
