<template>
  <div class="flex flex-col text-center">
    <div class="flex-1 flex flex-col justify-center self-center g-body-width">
      <div class="flex-1 relative overflow-hidden">
        <LottieBox
          name="check-mark"
          :loop="false"
          :autoplay="true"
        />
        <LottieBox
          name="scatter-flowers"
          :loop="false"
          :autoplay="true"
          class="position-absolute top-0 left-0 w-full h-full scale-120"
        />
      </div>
      <audio
        autoplay="true"
        style="display: none"
      >
        <source
          src="/audio/lesson_end.mp3"
          type="audio/mpeg"
        />
      </audio>
      <div
        class="text-25px mb-60px flex items-center justify-center color-[var(--yellow-500)] font-bold space-x-4"
      >
        <Icon
          name="wheat-left"
          class="w-16px"
        />
        <span>{{ _t('学习完成') }}</span>
        <Icon
          name="wheat-right"
          class="w-16px"
        />
      </div>
      <div class="flex justify-center text-center gap-4">
        <AnimationLabel
          text="学习时长"
          style="--color-main: var(--yellow-500)"
          :index="0"
          class="flex-1"
        >
          <Icon
            name="clock-circle"
            class="mr-1 w-25px"
          />
          <AnimationNum :value="formatLearnDuration(duration)" />
        </AnimationLabel>
        <AnimationLabel
          text="学习卡片"
          style="--color-main: var(--green-500)"
          :index="1"
          class="flex-1"
        >
          <Icon
            name="document-add"
            class="mr-1 w-24px"
          />
          <AnimationNum :value="count" />
        </AnimationLabel>
        <AnimationLabel
          text="获得经验"
          style="--color-main: var(--ld-brand-500)"
          :index="2"
          class="flex-1"
        >
          <Icon
            name="exp"
            class="mr-1 w-25px"
          />

          <AnimationNum :value="exp" />
        </AnimationLabel>
      </div>
    </div>

    <div class="mt-80px mb-28px w-full flex gap-2 g-footer-width">
      <Button
        :label="_t('学习回顾')"
        class="h-56px basis-1/3 whitespace-nowrap"
        scene="secondary"
        @click="onCardsReview"
      />

      <Button
        :label="_t('继续')"
        class="h-56px basis-2/3 whitespace-nowrap"
        @click="onContinue"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import AnimationNum from '@/components/AnimationNum.vue'
import LottieBox from '@/components/LottieBox.vue'
import AnimationLabel from '@/components/AnimationLabel.vue'
import Icon from './Icon.vue'
import { formatLearnDuration } from '@/utils'
defineProps<{
  duration: number
  count: number
  exp: number
}>()
const emit = defineEmits<{
  review: []
  continue: []
}>()
function onCardsReview() {
  emit('review')
}
function onContinue() {
  emit('continue')
}
</script>

<style scoped></style>
