<template>
  <div
    v-if="!_global.isProd && db.debug.showDebugLabel"
    class="text-yellow"
  >
    <span>{{ props.text }}</span>
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
import db from '@/db'

const _global = window._global

const props = defineProps<{
  text?: string
}>()
</script>
<style scoped></style>
