import type { CommonStore } from '@/stores'
import api from './base'
import type { CommonResponse } from './base'
import { Code } from './code'

export enum TaskType {
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  NEWBIE = 'NEWBIE',
}

export enum GoalType {
  N_STAGE = 'N_STAGE',
  N_EXP = 'N_EXP',
  N_CHECK_IN = 'N_CHECK_IN',
  N_CARD = 'N_CARD',
  N_MINUTE = 'N_MINUTE',
  N_STAR = 'N_STAR',
  N_DAILY_TASK = 'N_DAILY_TASK',
}

export enum RewardType {
  DIAMOND = 'DIAMOND',
}

export interface Reward {
  type: RewardType
  value: number
}

export interface WeeklyTask {
  taskNo: number
  rewards: Reward[]
  dailyTaskRequire: number
  rewardClaimed: boolean
}
export interface DailyTask {
  taskNo: number
  title: string
  emoji: string
  total: number
  goalType: GoalType
  newbie: boolean
  rewardClaimed: boolean
  rewards: Reward[]
}

export interface TaskStats {
  weekly?: {
    deadline: string
    completedDailyTaskCount: number
    tasks: WeeklyTask[]
  }
  daily: {
    deadline: string
    tasks: DailyTask[]
    stats: Record<GoalType, number>
  }
}

export function fetchTasks() {
  return api.get<unknown, TaskStats>('/v1/tasks')
}

export async function receiveTaskReward(
  payload: {
    taskNo: number
    type: TaskType
  },
  store: CommonStore
) {
  return api
    .post<unknown, CommonResponse<{ count: number }>>(
      '/v1/tasks/claim-reward',
      payload
    )
    .then(res => {
      if (res.code === Code.Ok) {
        store.setUnreceivedTaskCount(res.data.count)
      }

      return res
    })
}

export function fetchUnreceivedTaskCount() {
  return api.get<unknown, { count: number }>('/v1/tasks/unclaimed-count')
}
