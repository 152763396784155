<template>
  <ClozeCardLayout
    :has-footer="
      (data.passed && finalCard.interaction !== Interaction.Flash) ||
      finalCard.interaction === Interaction.Check
    "
  >
    <template #content>
      <CardQuestionLayout :is-role-image="isRoleImage">
        <template #image>
          <img
            v-if="face.card.illustration"
            :src="_global.assetUrl(face.card.illustration)"
          />
          <RoleImage v-else-if="!finalCard.style.hideRoleImage" />
        </template>
        <template #text>
          <div :class="data.animationClass">
            <Content :content="displayContent">
              <template #cloze="{ node }">
                <Cloze
                  :node="node"
                  :focused="data.focusOn === node.index && clozeCount > 1"
                  :rectify-mode="getClozeRectifyMode(node)"
                  :input-text="getInputText(node)"
                  @click="onClozeClick(node)"
                ></Cloze>
              </template>
            </Content>
          </div>
        </template>
      </CardQuestionLayout>
    </template>
    <template #tip>
      <Tip
        v-if="!finalCard.style.hideTip"
        :visible="data.showTip"
        :tip-times="data.tipTimes"
      />
    </template>
    <template #options>
      <template
        v-if="!data.passed || finalCard.interaction === Interaction.Flash"
      >
        <div :class="operationLayoutClass">
          <Button
            v-for="(op, i) in data.options"
            :key="i"
            :scene="getOptionScene(op)"
            :class="getOptionClass(op).join(' ')"
            @click="onClickOption(op)"
          >
            <div
              v-if="isLargeScreen"
              class="keyboard-tip"
              :class="op.used ? '' : 'text-gray-400'"
            >
              {{ i + 1 }}
            </div>
            <SparkleText
              :tag="onlyCorrect && op.used"
              class="sparkle-text"
            >
              <AutoFontSizeByText
                v-if="operationLayoutClass === 'operation-grid'"
                :font-size-list="[21, 19, 17, 15]"
              >
                {{ op.text }}
              </AutoFontSizeByText>
              <AutoFontSizeByLine
                v-else
                class="text-start"
                :font-size-list="[21, 19, 17, 15]"
              >
                {{ op.text }}
              </AutoFontSizeByLine>
            </SparkleText>
          </Button>
        </div>
      </template>
    </template>

    <template #footer>
      <BottomFeedback
        v-if="data.passed"
        v-show="finalCard.interaction !== Interaction.Flash"
        :star="data.star"
        :analysis="finalCard.card.analysis"
        @next="onNext?.()"
      >
        <Content :content="finalCard.card.analysis"></Content>
      </BottomFeedback>
      <Button
        v-else-if="finalCard.interaction === Interaction.Check"
        class="check-btn"
        :label="$t('cardview.check')"
        @click="onCheckClick"
      ></Button>
    </template>
  </ClozeCardLayout>
</template>

<script lang="ts" setup>
import { ClozeCardFaceType, Interaction, QuizStage } from '@/types/core'
import { reactive, inject, computed, watch } from 'vue'
import Content from '@/components/card-render/Content.vue'
import Cloze from '@/components/card-render/PickerCloze.vue'
import { t } from '@/i18n'
import { useHotKey } from '@/hooks'
import { UnitEventType } from '@/api/learn'
import { checkClozes, genClozes, genOptions } from './helper'
import { getOperationLayoutClass } from '@/components/ConcreteCard/layout'
import ClozeCardLayout from '@/components/ConcreteCard/layout/ClozeCardLayout.vue'
import RoleImage from '@/components/ConcreteCard/common/RoleImage.vue'
import Tip from '@/components/ConcreteCard/common/Tip.vue'
import BottomFeedback from '@/components/ConcreteCard/common/BottomFeedback.vue'
import { feedbackStar } from '@/components/ConcreteCard/common/feedback'
import { randomPick } from '@/utils'
import {
  getContentClozes,
  parseQAContent,
  processClozeFace,
} from '@/utils/card'

import '@/components/ConcreteCard/layout/style.css'

import type { ComputedRef } from 'vue'
import type { Option, MyCloze } from './helper'
import type { ClozeCardChoiceFace } from '@/types/core'
import SparkleText from '@/components/SparkleText.vue'
import CardQuestionLayout from '@/components/ConcreteCard/layout/CardQuestionLayout.vue'
import AutoFontSizeByText from '@/components/AutoFontSizeByText.vue'
import AutoFontSizeByLine from '@/components/AutoFontSizeByLine.vue'

const props = defineProps<{
  face: ClozeCardChoiceFace
  quizStage?: QuizStage
}>()

const isLargeScreen = inject<ComputedRef<boolean>>('isLargeScreen')
const onEvent = inject<(event: UnitEventType) => void>('onEvent')
const onNext = inject<VoidFunction>('onNext')
const onStar = inject<(star: feedbackStar) => void>('onStar')

// quiz
const changeAnswer = inject<(answered: boolean) => void>('answerChange')
const resultChange = inject<(correct: boolean) => void>('resultChange')

useHotKey('tab,down,right', () => {
  if (data.passed) return

  nextCloze()
})

useHotKey('up,left', () => {
  if (data.passed) return

  prevCloze()
})

useHotKey('del,backspace', () => {
  if (data.passed) return

  if (data.focusOn != null) {
    const cloze = data.clozes[data.focusOn!]
    onClozeClick(cloze)
  }
})

useHotKey('enter,space', () => {
  if (data.passed) {
    onNext?.()
    return
  }

  if (finalCard.value.interaction !== Interaction.Check) {
    return
  }

  onCheckClick()
})

useHotKey('1,2,3,4,5,6,7,8,9,0', (evt: KeyboardEvent) => {
  evt.preventDefault()

  const key = evt.key
  const index = (key === '0' ? 10 : Number(key)) - 1

  if (index >= data.options.length) return

  const op = data.options[index]
  onClickOption(op)
})

const finalCard = computed(
  () => processClozeFace(props.face) as ClozeCardChoiceFace
)

const isRoleImage = computed<boolean>(() => {
  if (props.face.card.illustration) {
    return false
  } else {
    return true
  }
})
const data = reactive({
  star: feedbackStar.One,
  passed: false,
  animationClass: '',

  clozes: genClozes(finalCard.value.card.content),
  options: [] as Option[],
  // 输入的答案
  inputMap: {} as Record<number, Option | null>,
  // 答案的结果
  resultMap: {} as Record<number, 'replace' | 'remove' | 'wrong' | null>,

  tipTimes: 0,
  showTip: false,
  focusOn: 0 as number | null,
})

data.options = genOptions(data.clozes, {
  lessOptions: finalCard.value.type === ClozeCardFaceType.MinimalChoice,
  useGiveAwayDistrators:
    finalCard.value.type === ClozeCardFaceType.GiveAwayChoice,
  altCards: finalCard.value.altCards,
})

const onlyCorrect = computed(() =>
  [Interaction.Practice, Interaction.Flash].includes(
    finalCard.value.interaction
  )
)

const isFlash = computed(
  () => finalCard.value.interaction === Interaction.Flash
)

const reviseMode = computed(
  () =>
    props.quizStage === QuizStage.ViewCorrectAnswer ||
    (finalCard.value.interaction === Interaction.Check && data.passed)
)

const content = computed(() =>
  randomPick([
    finalCard.value.card.content,
    ...finalCard.value.card.altContents,
  ])
)

const clozeCount = computed(() => {
  return getContentClozes(content.value).length
})

const displayContent = computed(() => {
  // 填空题的问答形式处理
  // 详细规则参考 https://qianmo.atlassian.net/browse/LD-989
  if (clozeCount.value === 1) {
    const { isQA, qaContent } = parseQAContent(content.value)

    if (isQA) {
      return qaContent
    }
  }

  return content.value
})

const operationLayoutClass = computed(() => {
  return getOperationLayoutClass(
    finalCard.value.style.operationLayout,
    data.options
  )
})

function getInputText(cloze: MyCloze) {
  const op = data.inputMap[cloze.index]

  return op?.text
}

function getClozeRectifyMode(cloze: MyCloze): boolean {
  if (!reviseMode.value) return false

  return data.resultMap[cloze.index] != null
}

function getOptionClass(op: Option): string[] {
  const classList = ['option']
  if (operationLayoutClass.value === 'operation-grid') {
    if (data.options.length === 2) {
      classList.push('aspect-square')
    } else {
      classList.push('aspect-[3/2]')
    }
  }

  if (op.wrong) {
    classList.push('wrong')
  }
  if (onlyCorrect.value) {
    if (op.used) {
      classList.push('correct')
    }
    if (data.passed && !op.used) {
      classList.push('used')
    }
  } else {
    if (op.used) {
      classList.push('used')
    }
  }

  return classList
}

function getOptionScene(
  op: Option
):
  | 'choice'
  | 'choiceSelected'
  | 'choiceCorrect'
  | 'choiceWrong'
  | 'choiceUsed' {
  let classList = getOptionClass(op)

  if (classList.includes('used')) {
    return 'choiceUsed'
  }
  if (classList.includes('correct')) {
    return 'choiceCorrect'
  }
  if (classList.includes('wrong')) {
    return 'choiceWrong'
  }

  return 'choice'
}

function onCheckClick() {
  data.passed = true
  data.focusOn = null

  const correct = onCheckResult()
  emitResultEvent(correct)

  data.animationClass = correct ? 'passed' : 'unpassed'

  setTimeout(() => {
    data.animationClass = ''
  }, 200)
}

function onCheckResult(): boolean {
  const { resultMap } = checkClozes(data.clozes, data.inputMap)

  data.resultMap = resultMap

  const correct = data.clozes.every(cloze => {
    const op = data.inputMap[cloze.index]
    return op != null && data.resultMap[cloze.index] === null
  })

  resultChange?.(correct)
  return correct
}

function emitResultEvent(correct: boolean) {
  if (correct) {
    data.star = data.tipTimes > 0 ? feedbackStar.Two : feedbackStar.Three
    onEvent?.(UnitEventType.CORRECT)
  } else {
    data.star = feedbackStar.One
    onEvent?.(UnitEventType.WRONG)
  }

  onStar?.(data.star)
}

function animeOptionWrong(op: Option) {
  op.wrong = true
  setTimeout(() => {
    op.wrong = undefined
  }, 400)
}

// 自动切换到下一张，此时需要等待一些事件的完成，比如这里是等动画结束
// TODO(buding): 如何准确获取动画结束，这里先 hardcode
function onAutoNext() {
  onStar?.(data.star)

  setTimeout(() => {
    onNext?.()
  }, 700)
}

function onClickOption(op: Option) {
  if (op.used) return
  if (data.passed) return

  if (data.focusOn == null) {
    _message.info(t('cardview.focus_first'))
    return
  }

  if (onlyCorrect.value) {
    const inputMap = {
      ...data.inputMap,
      [data.focusOn]: op,
    }
    const { resultMap } = checkClozes(data.clozes, inputMap)

    // 做错
    if (resultMap[data.focusOn] != null) {
      data.showTip = true
      data.tipTimes++
      animeOptionWrong(op)
      onEvent?.(UnitEventType.WRONG)
      return
    }

    op.used = true
    data.showTip = false
    data.inputMap[data.focusOn] = op
    data.focusOn++

    if (data.focusOn === data.clozes.length) {
      data.star = data.tipTimes > 0 ? feedbackStar.Two : feedbackStar.Three
      data.passed = true
      onEvent?.(UnitEventType.CORRECT)

      if (isFlash.value) {
        onAutoNext()
      }
      return
    }
    return
  }

  if (data.inputMap[data.focusOn]) {
    data.inputMap[data.focusOn]!.used = false
  }

  data.inputMap[data.focusOn] = op
  op.used = true

  onCheckResult()

  // 如果只有一个挖空，保持该挖空即可，无需跳转其他挖空
  if (data.clozes.length === 1) return

  // 如果所有挖空都填满了，则不需要自动 focus 下一个挖空，且清除 focus
  const choosedOptions = Object.values(data.inputMap)
  if (
    choosedOptions.length === data.clozes.length &&
    choosedOptions.every(item => item != null)
  ) {
    data.focusOn = null
    return
  }

  nextEmptyCloze()
}

function onClozeClick(cloze: MyCloze) {
  if (data.passed || finalCard.value.interaction === Interaction.Practice)
    return

  data.focusOn = cloze.index

  const op = data.inputMap[cloze.index]

  if (op) {
    op.used = false
    data.inputMap[cloze.index] = null
    onCheckResult()
  }
}

function nextEmptyCloze() {
  const allClozesFilled = data.clozes.every(cloze => {
    const op = data.inputMap[cloze.index]

    return op != null
  })

  // 如果全部填写过了，取消挖空即可
  if (allClozesFilled) {
    data.focusOn = null
    return
  }

  nextCloze()

  // 直到聚焦的挖空没有填写过时停止
  while (data.inputMap[data.focusOn!] != null) {
    nextCloze()
  }
}

function nextCloze() {
  if (data.focusOn === data.clozes.length - 1) {
    data.focusOn = 0
  } else if (data.focusOn != null) {
    data.focusOn++
  } else {
    data.focusOn = 0
  }
}

function prevCloze() {
  if (data.focusOn === 0) {
    data.focusOn = data.clozes.length - 1
  } else if (data.focusOn != null) {
    data.focusOn--
  }
}

watch(data.inputMap, () => {
  const selectedOptionsCount = data.options.filter(op => op.used).length

  changeAnswer?.(selectedOptionsCount !== 0)
})
</script>

<style scoped>
.button {
  font-size: 14px;
}

.passed {
  animation: correct 0.2s;
}

.unpassed {
  animation: wrong 0.2s;
}

.option {
  font-size: 19px;
  cursor: pointer;
  position: relative;
  justify-content: center;
}

.used {
  color: transparent;
}

.keyboard-tip {
  margin-right: 6px;
  border-width: 1px;
  border-radius: 4px;
  width: 15px;
  height: 15px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  font-weight: 700;
  border-color: var(--gray-400);
  color: var(--gray-400);
}
/* Grid 显示快捷键时，快捷键是浮在左上角的，不影响文字的布局 */
.operation-grid .keyboard-tip {
  position: absolute;
  top: 6px;
  left: 6px;
}
</style>
