<template>
  <EnWordCardLayout
    :has-footer="data.checked && props.face.interaction !== Interaction.Flash"
    :hide-role-image="props.face.style.hideRoleImage"
  >
    <template #content>
      <div class="text-primary font-semibold text-4xl leading-none mb-2">
        {{ props.face.card.word }}
      </div>

      <div
        v-if="usPron"
        class="text-17px leading-normal text-[var(--text-color-secondary)] flex justify-center items-center cursor-pointer"
        @click="onPronClick"
      >
        {{ usPron.label }}
        <div class="p-4px">
          <Icon
            name="pron"
            class="w-16px h-16px"
          />
        </div>
      </div>
    </template>
    <template #options>
      <div class="flex flex-col gap-3 mt-4">
        <Button
          v-for="(item, i) of options"
          :key="item.word"
          class="option"
          :class="{ wrong: optionsWrongMap[i] }"
          :scene="getOptionScene(item, i)"
          @click="onOptionClick(item, i)"
        >
          <SparkleText
            class="flex flex-col justify-center items-start w-full text-start"
            :tag="data.checked && item.isAnswer"
          >
            <template v-if="optionsWrongMap[i]">
              <div class="text-lg font-semibold leading-26px">
                {{ item.word }}
              </div>
              <div
                class="text-[var(--text-color-secondary)] text-sm leading-22px"
              >
                {{ item.definition }}
              </div>
            </template>
            <div v-else>
              {{ item.definition }}
            </div>
          </SparkleText>
        </Button>
      </div>
    </template>
    <template #footer>
      <BottomFeedback
        v-if="data.checked"
        v-show="props.face.interaction !== Interaction.Flash"
        :star="data.star"
        @next="onNext?.()"
      ></BottomFeedback>
    </template>
  </EnWordCardLayout>
</template>
<script setup lang="ts">
import type Icon from '@/components/Icon.vue'
import type { WordChoiceEnWordFace } from '@/types/core'
import { Interaction } from '@/types/core'
import { getHighlightSpans, randomPick } from '@/utils'
import { reactive, computed, onMounted, inject } from 'vue'
import { playPron, genOptions, type Option } from '../common'
import { UnitEventType } from '@/api/learn'
import { useHotKey } from '@/hooks'
import Tip from '../../common/Tip.vue'
import { feedbackStar } from '../../common/feedback'
import BottomFeedback from '../../common/BottomFeedback.vue'
import SparkleText from '@/components/SparkleText.vue'
import EnWordCardLayout from '../../layout/EnWordCardLayout.vue'

useHotKey('enter,space', () => {
  if (data.checked) {
    onNext?.()
    return
  }
})

useHotKey('1,2,3,4,5,6,7,8,9,0', (evt: KeyboardEvent) => {
  const key = evt.key
  const index = (key === '0' ? 10 : Number(key)) - 1

  if (index >= options.value.length) return

  const op = options.value[index]
  onOptionClick(op, index)
})

const onNext = inject<VoidFunction>('onNext')
const onEvent = inject<(event: UnitEventType) => void>('onEvent')
const onStar = inject<(star: feedbackStar) => void>('onStar')

const props = defineProps<{
  face: WordChoiceEnWordFace
}>()

const data = reactive({
  checked: false,
  tipTimes: 0,
  star: feedbackStar.One,
  selectedWrongOptions: new Set<number>(),
})

const usPron = computed(() => {
  const us = props.face.card.prons.find(item => item.language === 'en-US')
  return us
})

const options = computed(() => genOptions(props.face.card, props.face.altCards))

const optionsWrongMap = computed(() => {
  const map: Record<number, boolean> = {}

  for (let i = 0; i < options.value.length; i++) {
    map[i] = data.selectedWrongOptions.has(i)
  }

  return map
})

const tipExampleSpans = computed(() => {
  const example = randomPick(props.face.card.examples)

  if (!example) return null

  return getHighlightSpans(example.source, props.face.card.word)
})

onMounted(() => {
  playPron(props.face.card.word)
})

async function onOptionClick(option: Option, index: number) {
  if (data.checked) return

  if (option.isAnswer) {
    data.checked = true
    onEvent?.(UnitEventType.CORRECT)
  } else {
    data.tipTimes++
    data.selectedWrongOptions.add(index)
    onEvent?.(UnitEventType.WRONG)
  }

  data.star = data.tipTimes > 0 ? feedbackStar.Two : feedbackStar.Three

  if (data.checked) {
    onStar?.(data.star)
  }

  await playPron(option.word)
  if (props.face.interaction === Interaction.Flash && data.checked) {
    onNext?.()
  }
}

function onPronClick() {
  playPron(props.face.card.word)
}

function getOptionScene(
  op: Option,
  index: number
):
  | 'choice'
  | 'choiceSelected'
  | 'choiceCorrect'
  | 'choiceWrong'
  | 'choiceUsed' {
  let wrong = optionsWrongMap.value[index]
  let correct = op.isAnswer && data.checked

  if (correct) {
    return 'choiceCorrect'
  }
  if (wrong) {
    return 'choiceWrong'
  }

  return 'choice'
}
</script>

<style scoped>
.option {
  min-height: 66px;
  font-size: 19px;
  line-height: 28.5px;
  position: relative;
}

.option.wrong {
  animation: shake 0.2s linear 0.2s;
}
</style>
