import {
  LearnStatus,
  CardType,
  type EnWordCard,
  type Card,
  ClozeCardFaceType,
  type ConcreteCardFace,
  type VirtualCardFace,
  EnWordCardFaceType,
  Interaction,
  type ParsedClozeCard,
} from '@/types/core'
import { randomPick } from '@/utils'
import { shuffle } from 'lodash-es'

import type { CardSchedule, UnitEventType } from '@/api/learn'
import { parseCard } from '@/utils/card'

export enum QueueType {
  // 间隔重复队列
  ThoughtsMemo = 'ThoughtsMemo',

  // 分阶段队列
  Stages = 'Stages',

  // 连刷队列
  Flash = 'Flash',

  // 决斗队列
  Duel = 'Duel',

  // 插入队列（名称暂定）
  Insert = 'Insert',
}

export type Frame = ConcreteFrame | VirtualFrame

export type ConcreteFrame = {
  type: 'concrete'
  face: ConcreteCardFace
  cardId: number
  status?: LearnStatus
}

type VirtualFrame = {
  type: 'virtual'
  face: VirtualCardFace
}

export abstract class Queue<TConfig, TState> {
  _cards: CardSchedule[] = []
  _config: TConfig

  _activeCardEvents: {
    cardId: number
    event: UnitEventType
  }[] = []

  frames: Frame[] = []

  constructor(cards: CardSchedule[], config: TConfig) {
    this._cards = cards
    this._config = config
  }

  // 获取当前队列的状态信息
  abstract getState(): TState

  // 设置当前队列的状态信息，如当前的卡片，进度等
  abstract setState(state: TState): void

  // 记录卡片的学习行为，用于针对卡片的学习情况作处理
  onEvent(cardId: number, event: UnitEventType) {
    this._activeCardEvents.unshift({
      cardId,
      event,
    })
  }

  _getCardInfo(id: number) {
    return this._cards.find(c => c.cardId === id)
  }

  _orderCards() {
    const orderedCards: CardSchedule[] = []

    // 基础阶段，卡片的顺序需要按照复习/新学混合穿插，多余的卡片排在队尾
    // eg: 复习1、新学1、复习2、新学2、复习3、新学3、新学4、新学5
    const reviewCards = this._cards.filter(
      item => item.cardStatus === LearnStatus.REVIEW
    )
    const debutCards = this._cards.filter(
      item => item.cardStatus === LearnStatus.DEBUT
    )

    let insertReviewCard = true
    while (reviewCards.length > 0 && debutCards.length > 0) {
      orderedCards.push(
        insertReviewCard ? reviewCards.shift()! : debutCards.shift()!
      )

      insertReviewCard = !insertReviewCard
    }

    orderedCards.push(...reviewCards)
    orderedCards.push(...debutCards)

    return orderedCards
  }

  // 表示做完一张卡，队列会将当前卡片移除当前队列
  abstract tick(): void

  // 进行下一个阶段（不同的队列需要自己进行处理）
  abstract next(): void
}

// helper functions
export function orderFrames(frames: ConcreteFrame[], cardIds: number[]) {
  const orderedFrames: ConcreteFrame[] = []

  while (frames.length > 0) {
    for (const id of cardIds) {
      const index = frames.findIndex(item => item.cardId === id)

      if (index > -1) {
        orderedFrames.push(frames[index])
        frames.splice(index, 1)
      }
    }
  }

  return orderedFrames
}

export function getAltCards(cards: CardSchedule[], cardId: number, card: Card) {
  return cards
    .filter(item => item.cardId !== cardId)
    .map(item => JSON.parse(item.content) as Card)
    .filter(item => card.type === item.type)
    .map(card => parseCard(card))
    .filter(result => result.error == null)
    .map(result => result.card) as ParsedClozeCard[] | EnWordCard[]
}

export type FrameConfig = {
  cardType: CardType
  cardFace: ClozeCardFaceType | EnWordCardFaceType
  status: LearnStatus
  interaction: Interaction
  style?: ConcreteCardFace['style']
}

export function getCardConfigs(
  configs: FrameConfig[],
  {
    cardType,
    random,
    status,
  }: {
    cardType: CardType
    random: boolean
    status: LearnStatus
  }
) {
  let cardConfigs = configs.filter(item => item.cardType === cardType)
  cardConfigs = random ? shuffle(cardConfigs) : [...cardConfigs]

  if (status === LearnStatus.DEBUT) {
    cardConfigs = cardConfigs.filter(item => item.status === LearnStatus.DEBUT)
  } else {
    cardConfigs = cardConfigs.filter(item => item.status === LearnStatus.REVIEW)
  }

  return cardConfigs.map(item => {
    if (
      item.style == null &&
      (
        [ClozeCardFaceType.Choice, ClozeCardFaceType.Judgement] as (
          | ClozeCardFaceType
          | EnWordCardFaceType
        )[]
      ).includes(item.cardFace)
    ) {
      item.style = {
        questionLayout: randomPick(['vertical', 'horizontal']),
        operationLayout: 'horizontal',
      } as any
    }

    return item
  })
}

// 卡面难度配置参考：https://qianmojiaoyu.feishu.cn/wiki/YuJ7wp6ZSiMvVok24CUcGfhYngh
export const SingleClozeCardDifficulty = {
  1: [ClozeCardFaceType.GiveAwayChoice, ClozeCardFaceType.GiveAwayJudgement],
  2: [ClozeCardFaceType.MinimalChoice],
  3: [ClozeCardFaceType.Choice, ClozeCardFaceType.Judgement],
}

export const MultiClozeCardDifficulty = {
  1: [ClozeCardFaceType.GiveAwayJudgement],
  2: [ClozeCardFaceType.GiveAwayChoice],
  3: [ClozeCardFaceType.Choice, ClozeCardFaceType.Judgement],
}

export const EnWordCardDifficulty = {
  1: [EnWordCardFaceType.PickImage],
  2: [EnWordCardFaceType.ExampleChoice],
  3: [EnWordCardFaceType.ExplainChoice, EnWordCardFaceType.WordChoice],
}
