import api from './base'

import type { CommonResponse } from './base'

enum MsgType {
  GLOBAL = 'GLOBAL',
  PERSONAL = 'PERSONAL',
}
export function readMsg(msgId: number, msgType: MsgType) {
  return api.post<unknown, CommonResponse<never>>('/v1/msgs/read', {
    msgId,
    msgType,
  })
}

export function readAllMsgs() {
  return api.post<unknown, CommonResponse<never>>('/v1/msgs/all-read')
}

export function fetchUnreadMsgCount() {
  return api.get<
    unknown,
    {
      count: number
    }
  >('/v1/msgs/un-read/count')
}

export interface PageMsgResponse {
  msgs: MsgResponse[]
  offset: number
  limit: number
  total: number
}
export interface MsgResponse {
  id: number
  content: string
  type: MsgType
  createdAt: string
  readAt?: string
}

export function fetchMsgsPage(offset: number, limit: number = 50) {
  return api.get<unknown, PageMsgResponse>('/v1/msgs/page', {
    params: {
      offset,
      limit,
    },
  })
}
