import type {
  RouteLocationNormalized,
  RouteLocationRaw,
  Router,
} from 'vue-router'
import { RouterView } from 'vue-router'

export function setupHistoryMgmt(router: Router) {
  // 栈顶始终是当前 route
  // 注意，如果用户使用了浏览器的 back/forward button 会导致 history 不正确
  // 这种情况忽略，应该引导用户始终使用 App 提供的导航
  const history: RouteLocationNormalized[] = []
  let navType: 'push' | 'replace' | 'back' = 'push'

  router.__history = history
  Object.defineProperty(router, 'canGoBack', {
    get() {
      return history.length > 1
    },
  })

  const originalPush = router.push
  router.push = function (location: RouteLocationRaw) {
    navType = 'push'

    return originalPush.call(this, location)
  }

  const originalReplace = router.replace
  router.replace = function (location: RouteLocationRaw) {
    navType = 'replace'
    return originalReplace.call(this, location)
  }

  const originalBack = router.back
  router.back = function () {
    navType = 'back'
    if (history.length <= 1) {
      return
    }

    return originalBack.call(this)
  }

  // should not use router.go method
  router.go = undefined

  // history 管理
  router.afterEach((to, _from, failure) => {
    if (!failure) {
      switch (navType) {
        case 'push': {
          history.push(to)
          // history 存储太多没有意义
          if (history.length > 50) {
            history.splice(0, 40)
          }
          break
        }
        case 'replace': {
          history[history.length - 1] = to
          break
        }
        case 'back': {
          history.pop()
          break
        }
      }
    }
  })
}

export const DEBUG_ROUTE_PREFIX = '/debug/'
export const debugRoute = {
  path: DEBUG_ROUTE_PREFIX,
  component: RouterView,
  children: [
    {
      // 测试各种三方服务的 redirect
      path: '',
      component: () => import('@/pages/debug/DebugIndex.vue'),
    },
    {
      path: 'icon-bg',
      component: () => import('@/pages/debug/DebugIconBg.vue'),
    },
    {
      path: 'raw',
      component: () => import('@/pages/debug/DebugRaw.vue'),
    },
    {
      path: 'note-editor',
      component: () =>
        import('@//pages/debug/DebugNoteEditor/DebugNoteEditor.vue'),
      name: 'debug/note-editor',
    },
    {
      path: 'button',
      component: () => import('@/pages/debug/DebugButton.vue'),
      name: 'debug/button',
    },
    {
      path: 'card-layout',
      component: () => import('@/pages/debug/DebugCardLayout.vue'),
      name: 'debug/card-layout',
    },
    {
      path: 'editor',
      component: () => import('@/pages/debug/DebugEditor.vue'),
    },
    {
      path: 'lesson-end',
      component: () => import('@/pages/debug/DebugLessonEnd.vue'),
      name: 'debug/lesson-end',
    },
    {
      path: 'check-in',
      component: () => import('@/pages/debug/DebugCheckIn.vue'),
      name: 'debug/check-in',
    },
    {
      path: 'column-layout',
      component: () => import('@/pages/debug/DebugColumnLayout.vue'),
      name: 'debug/column-layout',
    },
    {
      path: 'boss',
      component: () => import('@/pages/debug/DebugBoss.vue'),
      name: 'debug/boss',
    },
    {
      path: 'text-button',
      component: () => import('@/pages/debug/DebugTextButton.vue'),
      name: 'debug/text-button',
    },
  ],
}
