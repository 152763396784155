<template>
  <div class="content">
    <div
      v-if="display"
      class="bg"
    ></div>
    <div
      v-if="display"
      class="top-label"
    >
      {{ props.text }}
    </div>
    <div
      v-if="display"
      class="main-content"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps<{
  text: string
  index: number
}>()

const display = ref(false)
setTimeout(() => {
  display.value = true
}, 800 * props.index)
</script>

<style scoped>
.content {
  overflow: hidden;
  position: relative;
  border-radius: 16px;
  --color-swan: var(--ld-neutral-100);
  --color-snow: white;
}
.bg {
  animation: _1XPDD 0.2s ease-in-out;
  animation-fill-mode: both;
  border-radius: 16px;
  height: 100%;
  position: absolute;
  width: 100%;
  background-color: var(--color-main);
}

.top-label {
  animation: _3WCjQ 0.2s ease-in-out;
  color: var(--color-snow);
  font-size: 15px;
  position: relative;
  font-weight: 600;
  padding: 4px 0;
}

.main-content {
  position: relative;
  align-items: center;
  animation: _21BxP 0.1s ease-in-out;
  background-color: var(--color-snow);
  border: 2px solid var(--color-main);
  border-radius: 16px;
  color: var(--color-main);
  display: flex;
  flex-direction: row;
  font-size: 19px;
  font-weight: 700;
  height: 70px;
  justify-content: center;
  transition: border-color 0.2s;
}

@keyframes _1XPDD {
  0%,
  50% {
    background-color: var(--color-snow);
    transform: translateY(30px);
  }

  100% {
    transform: translateY(0);
  }
}
@keyframes _3WCjQ {
  0%,
  50% {
    transform: translateY(30px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes _21BxP {
  0% {
    border-color: var(--color-swan);
    opacity: 0;
    transform: scale(0);
  }
  50% {
    border-color: var(--color-swan);
    opacity: 1;
    transform: scale(1);
  }
  100% {
    border-color: var(--color-main);
  }
}
</style>
