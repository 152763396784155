<template>
  <div class="relative">
    <div
      v-if="props.visible"
      class="dot"
      :style="props.dotStyle"
    ></div>
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
import type { CSSProperties } from 'vue'

const props = withDefaults(
  defineProps<{
    visible?: boolean
    dotStyle?: CSSProperties
  }>(),
  {
    visible: true,
  }
)
</script>
<style scoped>
.dot {
  background-color: var(--red-500);
  width: 10px;
  height: 10px;
  border-radius: 10px;
  top: -2px;
  right: -2px;
  position: absolute;
  z-index: 1;
}
</style>
