<template>
  <Button
    v-if="!_global.isProd && db.debug.showDebugLabel"
    class="debug-button"
  >
    <template #icon>
      <Icon
        class="w-16px mr-1"
        name="debug"
      />
    </template>
  </Button>
</template>

<script lang="ts" setup>
import Button from 'primevue/button'
import db from '@/db'

const _global = window._global
</script>

<style scoped>
.debug-button {
  background-color: var(--yellow-500);
  border: 1px solid var(--yellow-500);
}
</style>
