export const fastClick = {
  mounted(el: HTMLElement) {
    let isInstantClick = false

    // 处理 touchstart 事件
    const handleTouchStart = (e: TouchEvent) => {
      isInstantClick = true
      // 立即创建并触发一个合成的 click 事件
      const syntheticEvent = new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
      e.target!.dispatchEvent(syntheticEvent)
    }

    // 处理实际的 click 事件
    const handleClick = (e: MouseEvent) => {
      // 如果是由我们的即时点击触发的，则阻止默认的 click 事件
      if (isInstantClick) {
        e.stopPropagation()
        isInstantClick = false
      }
    }

    // 处理 touchend 事件
    const handleTouchEnd = (e: TouchEvent) => {
      // 防止触发默认的 click 事件
      e.preventDefault()
    }

    // 添加事件监听器
    el.addEventListener('touchstart', handleTouchStart, { passive: false })
    el.addEventListener('click', handleClick)
    el.addEventListener('touchend', handleTouchEnd, { passive: false })

    // 在组件卸载时移除事件监听器
    ;(el as any)._instantClickCleanup = () => {
      el.removeEventListener('touchstart', handleTouchStart)
      el.removeEventListener('click', handleClick)
      el.removeEventListener('touchend', handleTouchEnd)
    }
  },
  unmounted(el: any) {
    // 清理事件监听器
    if (el._instantClickCleanup) {
      el._instantClickCleanup()
    }
  },
}
