import { createRouter, createWebHistory } from 'vue-router'

import PackageBrowser from './pages/Package/Package.vue'
import Shelf from '@/pages/Shelf.vue'
import Mine from '@/pages/Mine.vue'

import WxCallback from './pages/WxCallback.vue'
import Auth from './pages/Auth.vue'
import App from './App.vue'
import IdentityOnboarding from '@/components/IdentityOnboarding/IdentityOnboarding.vue'
import Landing from '@/pc/pages/Landing.vue'
import UnitLearn from '../pages/UnitLearn/UnitLearn.vue'
import PublicPackage from '@/pages/PublicPackage.vue'
import Atlas from '@/pages/Atlas/Atlas.vue'
import type { RouteRecordRaw } from 'vue-router'
import Message from '@/pages/Message.vue'
import Reward from '@/pc/pages/Reward.vue'
import NotFound from '@/pages/NotFound.vue'
import { cancelReport } from '@/pages/UnitLearn/report'
import { debugRoute, setupHistoryMgmt } from '@/shared'
import BetaFeature from '@/pages/BetaFeature/BetaFeature.vue'
import DebugEditor from '@/pages/debug/DebugEditor.vue'

// TODO(baifan): Debug 页面统一移动到 /debug/ 下面，组件改为 async load，注意，这里面的东西不应该有业务逻辑，是纯粹调试组件用的

// route
// - name format: camel case, use '/' to divide, e.g. 'login', 'publicPackage', 'unit/learn'
// - App 里的组件默认都是需要鉴权的
// - meta
//  - fullscreen: boolean 不需要 App Layout
const routes: RouteRecordRaw[] = [
  {
    path: '/wxcallback',
    component: WxCallback,
  },
  {
    path: '/beta-feature',
    name: 'beta-feature',
    component: BetaFeature,
    meta: {
      needAuth: true,
    },
  },
  {
    path: '/auth',
    component: Auth,
    name: 'auth',
  },
  {
    path: '/identity-onboarding',
    component: IdentityOnboarding,
    name: 'identity-onboarding',
  },
  {
    path: '/landing',
    component: Landing,
    name: 'landing',
  },
  {
    path: '/pkgs/:hashId',
    component: PublicPackage,
    name: 'publicPackage',
  },
  {
    path: '/404',
    component: NotFound,
    name: '404',
  },
  {
    path: '/',
    component: App,
    children: [
      { path: '', redirect: 'atlas' },
      {
        path: 'atlas',
        component: Atlas,
        name: 'atlas',
        meta: {
          keepAlive: true,
        },
      },

      {
        path: 'shelf',
        component: Shelf,
        name: 'shelf',
      },
      {
        path: 'mine',
        component: Mine,
        name: 'mine',
      },
      {
        path: '/message',
        component: Message,
        name: 'message',
      },
      {
        path: '/reward',
        component: Reward,
        name: 'reward',
      },
      {
        path: '/unit/learn',
        component: UnitLearn,
        name: 'unit/learn',
        meta: {
          fullscreen: true,
        },
      },
      {
        path: '/packages/:id',
        component: PackageBrowser,
        name: 'package',
        meta: {
          fullscreen: true,
        },
      },
    ],
  },
  {
    path: '/:catchAll(.*)',
    redirect: '/404',
  },
]
if (!_global.isProd) {
  routes.push(debugRoute)
}

const router = createRouter({
  history: createWebHistory(),
  routes,
})

setupHistoryMgmt(router)

router.beforeEach((to, _from) => {
  const needAuth = to.matched[0].path === '/' || to.meta.needAuth

  if (needAuth && !_store.isLoggedIn) {
    return {
      name: 'landing',
      query: {
        redirect: encodeURIComponent(to.fullPath),
      },
    }
  }
})

router.afterEach(async to => {
  if (['landing', 'unit/learn', 'unit/quiz'].includes(to.name as string)) return

  if (_store.checkStageUnit()) {
    const ok = await _confirm({
      type: 'warn',
      content: '上次学习中断了 是否要恢复进度、继续学习?',
      cancelText: '恢复进度、继续学习',
      okText: '放弃进度、不学了',
    })

    if (ok === false) {
      router.push({
        name: 'unit/learn',
      })
      return
    }

    if (ok) {
      await cancelReport()
      _store.clearStageUnit()
    }
  }
})

export default router
