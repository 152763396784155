<template>
  <Message
    v-if="parsed.error != null"
    :closable="false"
    severity="error"
  >
    卡片解析失败:{{ parsed.error }}
  </Message>

  <div
    v-else
    class="h-full flex flex-col flex-1"
  >
    <div class="content">
      <Content :content="parsed.card.content">
        <template #cloze="{ node }">
          <span
            class="underline text-primary-color font-bold underline-offset-4"
          >
            {{ node.text }}
          </span>
        </template>
      </Content>
    </div>

    <div class="analysis">
      <div class="text-sm">{{ $t('cardview.analysis') }}</div>

      <Content :content="parsed.card.analysis"></Content>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { parseClozeCard } from '@/utils/card'
import Message from 'primevue/message'
import Content from '../card-render/Content.vue'

import type { ClozeCard } from '@/types/core'

const props = defineProps<{
  card: ClozeCard
}>()

const parsed = parseClozeCard(props.card)
</script>

<style scoped>
.content {
  margin-top: 32px;
  padding: 16px;
  font-size: 19px;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: auto;
  background: var(--opacityWhite);
  border: 1px solid var(--opacityWhite);
  box-shadow: 0px 0px 2px 0px var(--slate-300);
}

.analysis {
  margin-top: 32px;
  padding: 16px;
  font-size: 19px;
  border-radius: 8px;
  max-height: 400px;
  overflow-y: auto;
  background: var(--opacityWhite);
  border: 1px solid var(--opacityWhite);
  box-shadow: 0px 0px 2px 0px var(--slate-300);
}
</style>
