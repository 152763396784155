<template>
  <Img
    class="max-w-114px flex-shrink-0"
    :name="name"
  />
</template>

<script setup lang="ts">
import { GIVE_AWAY_ROLE_COUNT, ROLE_COUNT } from '@/pages/UnitLearn/preload'
import { computed, inject } from 'vue'

const props = withDefaults(
  defineProps<{
    useGiveAwayRole?: boolean
  }>(),
  {
    useGiveAwayRole: false,
  }
)
// 如果传入 roleName 则使用传入的 roleName
const roleName = inject<string | undefined>('roleName', undefined)

const name = computed(() => {
  if (roleName) {
    return roleName
  }

  if (props.useGiveAwayRole) {
    const roleIndex = Math.floor(Math.random() * GIVE_AWAY_ROLE_COUNT + 1)

    return `give-away-role-${roleIndex}`
  }

  const roleIndex = Math.floor(Math.random() * ROLE_COUNT + 1)

  return `role-${roleIndex}`
})
</script>

<style scoped></style>
