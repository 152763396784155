import type {
  Card,
  CardType,
  ClozeCard,
  EnWordCard,
  Content,
  UserAssetId,
} from './model'

export type { Card, ClozeCard, EnWordCard }

export { CardType } from './model'
export type {
  InlineNode,
  Text,
  Cloze,
  Content,
  PronunciationLanguage,
} from './model'

export type ParsedClozeCard = {
  type: CardType.CLOZE
  content: Content
  altContents: Content[]
  analysis: Content

  // 配图 assetId
  illustration?: UserAssetId
}

export enum QuizStage {
  // 作答中
  Answering,

  // 查看提交的答案
  ViewSubmittedAnswer,

  // 查看正确答案
  ViewCorrectAnswer,
}

// 学习模式
export enum Interaction {
  // 练习
  Practice = 'PRACTICE',

  // 检验
  Check = 'CHECK',

  // 连刷
  Flash = 'Flash',
}

// 卡片内容区样式布局
export type QuestionLayout = 'horizontal' | 'vertical'

// 卡片操作区样式布局
export type OperationLayout = 'horizontal' | 'vertical' | 'grid'

export type ConcreteCardFace = ClozeCardFace | EnWordCardFace

export type ClozeCardFace = ClozeCardChoiceFace | ClozeCardJudgementFace

export type EnWordCardFace =
  | WordChoiceEnWordFace
  | ExampleChoiceEnWordFace
  | ExplainChoiceEnWordFace
  | PickImageEnWordFace

export enum ClozeCardFaceType {
  // 选择题
  Choice = 'Choice',

  // 极简选择题
  MinimalChoice = 'MinimalChoice',

  // 送分选择题
  GiveAwayChoice = 'GiveAwayChoice',

  // 判断题
  Judgement = 'Judgement',

  // 送分判断题
  GiveAwayJudgement = 'GiveAwayJudgement',
}

type CommonChoiceFaceProps = {
  cardId: number
  card: ParsedClozeCard
  interaction: Interaction
  altCards: ParsedClozeCard[]
  style: {
    hideRoleImage: boolean
    hideTip: boolean
    questionLayout: QuestionLayout
    operationLayout: OperationLayout
  }
}

// 知识点选择题
export type ChoiceFace = CommonChoiceFaceProps & {
  type: ClozeCardFaceType.Choice
}

export type MinimalChoiceFace = CommonChoiceFaceProps & {
  type: ClozeCardFaceType.MinimalChoice
}

export type GiveAwayChoiceFace = CommonChoiceFaceProps & {
  type: ClozeCardFaceType.GiveAwayChoice
}

export type ClozeCardChoiceFace =
  | ChoiceFace
  | MinimalChoiceFace
  | GiveAwayChoiceFace

// 知识点判断题

type CommonJudgementFaceProps = {
  cardId: number
  card: ParsedClozeCard
  interaction: Interaction
  altCards: ParsedClozeCard[]
  style: {
    hideRoleImage: boolean
    hideTip: boolean
    questionLayout: QuestionLayout
  }
}

export type JudgementFace = CommonJudgementFaceProps & {
  type: ClozeCardFaceType.Judgement
}

export type GivieAwayJudgementFace = CommonJudgementFaceProps & {
  type: ClozeCardFaceType.GiveAwayJudgement
}

export type ClozeCardJudgementFace = JudgementFace | GivieAwayJudgementFace

export enum EnWordCardFaceType {
  // 单词选释义
  WordChoice = 'WordChoice',

  // 例句选释义
  ExampleChoice = 'ExampleChoice',

  // 释义选单词
  ExplainChoice = 'ExplainChoice',

  // 选图
  PickImage = 'PickImage',
}

type CommonEnWordFaceProps = {
  cardId: number
  card: EnWordCard
  interaction: Interaction
  altCards: EnWordCard[]
  style: {
    hideTip: boolean
    hideRoleImage: boolean
  }
}

export type WordChoiceEnWordFace = CommonEnWordFaceProps & {
  type: EnWordCardFaceType.WordChoice
}

export type ExampleChoiceEnWordFace = CommonEnWordFaceProps & {
  type: EnWordCardFaceType.ExampleChoice
}

export type ExplainChoiceEnWordFace = CommonEnWordFaceProps & {
  type: EnWordCardFaceType.ExplainChoice
}

export type PickImageEnWordFace = CommonEnWordFaceProps & {
  type: EnWordCardFaceType.PickImage
}

export type VirtualCardBossFace = {
  type: 'boss'
  faces: ClozeCardFace[]
  name: string
}

export type VirtualCardMatchFace = {
  type: 'match'
  cards: ClozeCard[]
}

export type VirtualCardFace = VirtualCardBossFace | VirtualCardMatchFace

//
// Basic Structure
//

export enum LearnStatus {
  DEBUT = 'DEBUT',
  REVIEW = 'REVIEW',
}

// EASY 简单
// GOOD 记得
// HARD 模糊
// AGAIN 忘记
export enum CardRating {
  EASY = 'EASY',
  GOOD = 'GOOD',
  HARD = 'HARD',
  AGAIN = 'AGAIN',
}

export enum UIMode {
  K12 = 'K12',
  KaoYan = 'KaoYan',
  LocationNormal = 'LocationNormal',
  ExamNormal = 'ExamNormal',
  Language = 'Language',
}

export type IdentityTag = {
  key: string
  name: string
  aliases: string[]
  hidden: boolean
  children?: IdentityTag[]
  order?: number
  hot?: number
  uiMode?: UIMode
  code?: string
}

export interface ContentTag {
  key: string
  name: string
  aliases: string[]
  hidden: boolean
  children?: ContentTag[]
}

export const ROOT_CHAPTER_KEY = 'root'
