<template>
  <span
    :class="className"
    @click="onTextClick"
  >
    {{ props.node.text }}
  </span>
</template>

<script lang="ts" setup>
import type { Cloze } from '@/types/core'
import { computed } from 'vue'
import { reactive } from 'vue'

const props = defineProps<{
  node: Cloze
}>()

const data = reactive({
  clozeDisplay: false,
})

const className = computed(() => {
  return data.clozeDisplay ? ['cloze', 'display'] : ['cloze']
})

function onTextClick(e: MouseEvent) {
  e.stopPropagation()
  data.clozeDisplay = !data.clozeDisplay
}
</script>

<style scoped>
.cloze {
  background-color: aquamarine;
  color: transparent;
  cursor: pointer;
  white-space: pre-line;
}

.display {
  background-color: aquamarine;
  color: unset;
}
</style>
