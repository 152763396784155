<template>
  <div
    v-if="parsed"
    :class="`h-full overflow-hidden`"
  >
    <Content :content="parsed.card.content">
      <template #text="{ node }">
        <Text
          class="content"
          :node="node"
          :show-dot-style="false"
        ></Text>
      </template>

      <template #cloze="{ node }">
        <span class="content cloze">
          {{ node.text }}
        </span>
      </template>
    </Content>
  </div>
  <div v-else>
    {{ _t('卡片解析失败') }}
  </div>
</template>
<script setup lang="ts">
import { parseClozeCard } from '@/utils/card'
import Text from '../card-render/Text.vue'
import Content from '../card-render/Content.vue'

import type { ClozeCard } from '@/types/core'

const props = defineProps<{
  card: ClozeCard
}>()

const parsed = parseClozeCard(props.card)
</script>
<style scoped>
.content {
  font-size: 14px;
  font-weight: 400;
}
.cloze {
  color: var(--purple-600);
}
</style>
