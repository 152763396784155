import { startAtlasChallengeStage, startAtlasStage } from '@/api/atlas'
import { Code } from '@/api/code'
import {
  UnitStatus,
  reportUnitEvents,
  type UnitEvent,
  UnitEventType,
} from '@/api/learn'
import { Interaction } from '@/types/core'

export function debugFinishStage(packageId: number): Promise<boolean> {
  return startAtlasStage(packageId).then(async res => {
    if (res.code !== Code.Ok) {
      _message.error(res.message)
      return false
    }

    const mockLearnEvents: Record<string, UnitEvent[]> = {}

    for (const item of res.data.schedules) {
      mockLearnEvents[item.cardId] = [
        {
          event: UnitEventType.CORRECT,
          stage: Interaction.Practice,
          timestamp: Date.now(),
        },
      ]
    }

    await reportUnitEvents({
      unitId: res.data.unitId,
      status: UnitStatus.COMPLETED,
      learnEvents: mockLearnEvents,
    })
    return true
  })
}

export async function debugFinishChallengeStage(
  index: number,
  startLevel: number,
  endLevel: number,
  star: 1 | 2 | 3
): Promise<boolean> {
  return startAtlasChallengeStage({
    index: index,
    startLevel,
    endLevel,
  }).then(async res => {
    if (res.code !== Code.Ok) {
      _message.error(res.message)
      return false
    }

    const mockLearnEvents: Record<string, UnitEvent[]> = {}

    for (const item of res.data.schedules) {
      mockLearnEvents[item.cardId] = [
        {
          event: UnitEventType.CORRECT,
          stage: Interaction.Practice,
          timestamp: Date.now(),
        },
      ]
    }

    await reportUnitEvents({
      unitId: res.data.unitId,
      status: UnitStatus.COMPLETED,
      learnEvents: mockLearnEvents,
      challengeStageParam: {
        duelParam: {
          leftBlood: {
            1: 0.5,
            2: 0.7,
            3: 1,
          }[star],
        },
      },
    })
    return true
  })
}
