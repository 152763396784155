import { reactive, watch } from 'vue'
import merge from 'lodash-es/merge'

import type { ApiEnv } from './init'
import type { Locale } from './i18n'
import type { Theme } from './stores/common-store'
import type { Unit, UnitEvent } from './api/learn'
import type { CardTypeConfig } from './utils/card'
import type { ClozeCardFaceType } from './types/core'
import {
  EnWordCardDifficulty,
  MultiClozeCardDifficulty,
  QueueType,
  SingleClozeCardDifficulty,
} from './pages/UnitLearn/queue'
import type { InsertQueueState } from './pages/UnitLearn/InsertQueue'
import type { StagesQueueState } from './pages/UnitLearn/StagesQueue'
import type { FlashQueueState } from './pages/UnitLearn/FlashQueue'
import type { DuelQueueState } from './pages/UnitLearn/DuelQueue'

const LOCAL_STORAGE_KEY = '_data'

export interface StageUnit extends Unit {
  queueType: QueueType
  updatedAt: number
  completed: boolean
  cancelled: boolean
  completedCards: number[]

  challenge?: {
    index: number
    npcName: string
  }

  // 学习每张卡片所获得的分数
  cardFaceScores: Record<number, number[]>
  events: {
    [cardId in string]: UnitEvent[]
  }
}

export interface DB {
  locale: Locale
  theme: Theme
  loginToken: string
  wxOpenId: string
  packageState: Record<
    number,
    {
      expandedKeys: Record<string, boolean>
      selectedChapterId: string
      selectedCardId?: string
    }
  >
  debug: {
    apiEnv: ApiEnv
    floatingBallTop: number
    floatingBallLeft: number
    skip: boolean
    skipEnergy: boolean
    forceOrderPriceToZero: boolean
    showDebugLabel: boolean
    showDebugFloatingBall: boolean
    queueType: QueueType
    queueConfigV3: {
      [QueueType.ThoughtsMemo]: Record<ClozeCardFaceType, CardTypeConfig>
      [QueueType.Stages]: string | null
    }
    cardDifficultyConfig: {
      singleClozeCard: typeof SingleClozeCardDifficulty | null
      muliClozeCard: typeof MultiClozeCardDifficulty | null
      enWordCard: typeof EnWordCardDifficulty | null
    }
  }
  stageUnit: StageUnit | null
  tmp: {
    queueCards: Record<string, { cardId: number; review: boolean }[]>
  }

  // 游客模式相关
  guest: {
    identityTags: string[]
  }

  // 学习进度缓存
  unitProgressCache: {
    [QueueType.ThoughtsMemo]?: any
    [QueueType.Stages]?: StagesQueueState
    [QueueType.Flash]?: FlashQueueState
    [QueueType.Duel]?: DuelQueueState
    [QueueType.Insert]?: InsertQueueState
  }
}

const saved = localStorage.getItem(LOCAL_STORAGE_KEY)

const db = reactive<DB>(
  merge(
    {
      locale: 'zh',
      theme: 'light',
      loginToken: '',
      wxOpenId: '',
      packageState: {},
      debug: {
        floatingBallTop: 32,
        floatingBallLeft: 32,
        apiEnv: 'staging',
        skip: false,
        skipEnergy: false,
        forceOrderPriceToZero: false,
        showDebugLabel: false,
        showDebugFloatingBall: true,
        queueType: QueueType.Flash,
        queueConfigV3: {
          [QueueType.ThoughtsMemo]: {},
          [QueueType.Stages]: null,
        },
        cardDifficultyConfig: {
          singleClozeCard: null,
          muliClozeCard: null,
          enWordCard: null,
        },
      },
      stageUnit: null,
      tmp: {
        queueCards: {},
      },
      guest: {
        identityTags: [] as string[],
      },
      unitProgressCache: {},
    } as DB,
    saved ? (JSON.parse(saved) as DB) : {}
  ) as DB
)

watch(db, () => {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(db))
})

export default db
