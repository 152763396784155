import {
  CardType,
  Interaction,
  type ClozeCard,
  type InlineNode,
  type Cloze,
  type OperationLayout,
  type QuestionLayout,
  type EnWordCard,
  ClozeCardFaceType,
  EnWordCardFaceType,
  type ClozeCardFace,
  type EnWordCardFace,
  type ChoiceFace,
  type GiveAwayChoiceFace,
  type MinimalChoiceFace,
  type Card,
  type ParsedClozeCard,
  type Content,
} from '@/types/core'
import { Card as CardZod } from '@/types/zod'
import { ceil, cloneDeep, shuffle } from 'lodash-es'
import { randomPick } from '.'
import { warn } from '@/utils'
import type { JSONContent } from '@tiptap/core'
import type { BlockNode } from '@/types/model'

export function getContentClozes(content: Content): Cloze[] {
  const clozes: Cloze[] = []

  for (const blockNode of content) {
    if (blockNode.type === 'p') {
      clozes.push(
        ...(blockNode.content.filter(n => n.type === 'cloze') as Cloze[])
      )
    }
  }

  return clozes
}

export function flatContent(content: Content): InlineNode[] {
  const nodes: InlineNode[] = []

  for (const blockNode of content) {
    nodes.push(...blockNode.content)
  }

  return nodes
}

export function removeContentNode(content: Content, n: InlineNode): Content {
  for (const blockNode of content) {
    let result: InlineNode[] = []
    for (const inlineNode of blockNode.content) {
      if (inlineNode !== n) {
        result.push(inlineNode)
      }
    }

    blockNode.content = result
  }

  return content
}

export function parseClozeCard(card: ClozeCard): {
  card: ParsedClozeCard
  error?: string
} {
  const result: ParsedClozeCard = {
    type: CardType.CLOZE,
    content: [],
    altContents: [],
    analysis: [],
    illustration: card.illustration,
  }

  try {
    if (typeof card.content === 'string') {
      return {
        card: result,
        error: '知识点卡片内容格式错误',
      }
    } else {
      result.content = card.content
    }

    const contentClozes = getContentClozes(card.content)

    if (card.altContents) {
      for (const content of card.altContents) {
        if (typeof content === 'string') {
          return {
            card: result,
            error: '知识点卡片内容内容改述格式错误',
          }
        }
        const parsedClozes = getContentClozes(content)

        // 如果两边的挖空数量不一致，则解析失败
        if (contentClozes.length !== parsedClozes.length) {
          throw new Error('[内容改述]中的挖空数量和[内容]中的挖空数量不匹配')
        }

        // 将解析后的挖空依次替换成 content 中相同位置的挖空
        for (
          let blockNodeIdx = 0;
          blockNodeIdx < content.length;
          blockNodeIdx++
        ) {
          const blockNode = content[blockNodeIdx]
          if (blockNode.type === 'p') {
            for (let i = 0; i < blockNode.content.length; i++) {
              const parsedNode = blockNode.content[i]

              if (parsedNode.type === 'cloze') {
                const sourceBlockNode = card.content[blockNodeIdx]

                blockNode.content[i] = sourceBlockNode.content[i]
              }
            }
          }
        }

        result.altContents.push(content)
      }
    }

    if (typeof card.analysis === 'string') {
      return {
        card: result,
        error: '知识点卡片内容内容改述格式错误',
      }
    }

    return { card: result }
  } catch (_err: any) {
    warn(_err)

    return { card: result, error: _err.toString() }
  }
}

export function parseEnWordCard(card: EnWordCard): {
  card: EnWordCard
  error?: string
} {
  const result: EnWordCard = {
    type: CardType.EN_WORD,
    word: card.word,
    definition: card.definition,
    prons: card.prons.map(item => ({ ...item })),
    examples: card.examples.map(item => ({ ...item })),
    distrators: card.distrators.map(item => ({ ...item })),
    tabs: card.tabs.map(item => ({ ...item })),
    illustration: card.illustration,
  }

  return { card: result }
}

export function parseCard(card: ClozeCard | EnWordCard) {
  switch (card.type) {
    case CardType.CLOZE:
      return parseClozeCard(card)
    case CardType.EN_WORD:
      return parseEnWordCard(card)
  }
}

// 目录树卡片摘要显示
export function getCardTextDigest(content: string): string {
  const cardData = JSON.parse(content)
  let result = ''
  switch (cardData.type) {
    case CardType.CLOZE:
      const card = parseClozeCard(cardData as ClozeCard).card

      result =
        card == null
          ? _t('卡片解析失败')
          : contentToString(card.content).join('\n')
      return result.trim()
    case CardType.EN_WORD:
      result = (cardData as EnWordCard).word
      break
    default:
      return '不支持的卡片类型'
  }
  // 原数据中的换行符，渲染成空格
  return result.replace(/\n/g, ' ')
}

// content 转成 string 数组，数组中每一项代表每一个段落
export function contentToString(content: Content): string[] {
  let result: string[] = []

  for (const blockNode of content) {
    if (blockNode.type === 'p') {
      result.push(blockNode.content.map(inlineNodeToString).join(''))
    }
  }

  return result
}
// InlineNode => string
export function inlineNodeToString(node: InlineNode): string {
  if (node.type === 'cloze') {
    // 挖空部分在两端增加 []
    return `[${node.text}]`
  }
  if (node.type === 'text') {
    return node.text
  }
  return ''
}

export type CardConfig =
  | {
      cardType: CardType.CLOZE
      face: ClozeCardFaceType
    }
  | {
      cardType: CardType.EN_WORD
      face: EnWordCardFaceType
    }

export interface CardStageConfig {
  configs: CardConfig[]
  limit?: number
  order: 'random' | 'order'
}

export interface CardTypeConfig {
  practice: CardStageConfig
  check: CardStageConfig
}

export interface AllCardModeConfig {
  [CardType.CLOZE]: CardTypeConfig
  [CardType.EN_WORD]: CardTypeConfig
}

export interface CardConfigWithScore {
  cardType: CardType
  face: ClozeCardFaceType | EnWordCardFaceType
  interaction: Interaction
  style: {
    questionLayout: QuestionLayout
    operationLayout: OperationLayout
  }
  score: number
}

export function generateCardFaces(
  config: AllCardModeConfig,
  cardType: CardType,
  onlyCheck: boolean = false
): CardConfigWithScore[] {
  // 如果只有「检验」卡面，那么所有「检验」卡面的总得分就是 1

  const faces = generateCardStageFaces(
    config[cardType].check,
    Interaction.Check,
    onlyCheck ? 1 : 0.5
  )

  return onlyCheck
    ? faces
    : [
        ...generateCardStageFaces(
          config[cardType].practice,
          Interaction.Practice,
          0.5
        ),
        ...faces,
      ]
}

export function generateCardStageFaces(
  stageConfig: CardStageConfig,
  interaction: Interaction,
  totalScore: number
): CardConfigWithScore[] {
  let cardConfigs =
    stageConfig.order === 'random'
      ? shuffle(stageConfig.configs)
      : stageConfig.configs

  cardConfigs =
    stageConfig.limit != null && stageConfig.limit !== 0
      ? cardConfigs.slice(0, stageConfig.limit)
      : cardConfigs

  const score = ceil(totalScore / cardConfigs.length, 2)

  return cardConfigs.map(item => {
    return {
      cardType: item.cardType,
      face: item.face,
      score,
      interaction,
      style: {
        questionLayout: randomPick(['horizontal', 'vertical']),
        operationLayout: 'horizontal',
      },
    }
  })
}

export function isClozeTextTooLong(text: string) {
  const MAX_CHAR_LENGTH = 12
  let chineseLength = 0
  let englishLength = 0

  for (let i = 0; i < text.length; i++) {
    if (
      /[\u4e00-\u9fa5\u3040-\u309f\u30a0-\u30ff\u1100-\u11ff\u3130-\u318F\uAC00-\uD7AF]/.test(
        text[i]
      )
    ) {
      // 汉字字符（包括中文、日文、韩文）
      chineseLength++
    } else {
      // 英文字符（除了汉字之外的）
      englishLength++
    }
  }

  return chineseLength * 2 + englishLength > MAX_CHAR_LENGTH
}

function hasNoSymbol(input: string) {
  return /[\u4e00-\u9fa5\w]/gm.test(input)
}

// 解析一个填空题的内容是不是问答题的类型，同时返回「问题」部分
// eg: 中国第一部浪漫主义诗歌总集是：{{楚辞}} 属于问答题
//   问题: 中国第一部浪漫主义诗歌总集是
export function parseQAContent(content: Content): {
  isQA: boolean
  qaContent: Content
} {
  const clozes = getContentClozes(content)

  if (clozes.length !== 1) {
    return {
      isQA: false,
      qaContent: content,
    }
  }

  const clone = cloneDeep(content)
  const beforeContent: Content = []
  const afterContent: Content = []
  let cloze: Cloze | null = null

  for (const blockNode of clone) {
    let newBlockNode: BlockNode = {
      type: blockNode.type,
      content: [],
    }

    for (const inlineNode of blockNode.content) {
      if (inlineNode.type === 'cloze') {
        cloze = inlineNode as Cloze

        beforeContent.push(newBlockNode)
        newBlockNode = {
          type: blockNode.type,
          content: [],
        }
        continue
      }

      newBlockNode.content.push(inlineNode)
    }

    if (cloze == null) {
      beforeContent.push(newBlockNode)
    } else {
      afterContent.push(newBlockNode)
    }
  }

  const beforeContentString = stringifyContent(beforeContent)
  const afterContentString = stringifyContent(afterContent)

  return {
    isQA: hasNoSymbol(beforeContentString) && !hasNoSymbol(afterContentString),
    qaContent: removeTailSymbols(beforeContent),
  }
}

function removeTailSymbols(content: Content): Content {
  const reversedContent = [...content].reverse()
  let replaced = false

  for (const blockNode of reversedContent) {
    const reversedNodes = [...blockNode.content].reverse()

    for (const inlineNode of reversedNodes) {
      if (inlineNode.type === 'text') {
        inlineNode.text = inlineNode.text.replace(/[^\u4e00-\u9fa5\w]*$/gm, '')

        if (hasNoSymbol(inlineNode.text)) {
          replaced = true
          break
        }
      }
    }

    if (replaced) {
      break
    }
  }

  return content
}

export function processClozeFace(face: ClozeCardFace) {
  const clozes = getContentClozes(face.card.content)
  const distrators = clozes.reduce<string[]>(
    (acc, cur) => acc.concat(cur.distrators || []),
    []
  )

  if (face.style.hideRoleImage || face.interaction === Interaction.Flash) {
    face.style.questionLayout = 'vertical'
  }

  if (
    [
      ClozeCardFaceType.Choice,
      ClozeCardFaceType.GiveAwayChoice,
      ClozeCardFaceType.MinimalChoice,
    ].includes(face.type)
  ) {
    face = face as ChoiceFace | GiveAwayChoiceFace | MinimalChoiceFace

    if (clozes.length > 1) {
      face.style.operationLayout = 'horizontal'
    } else if (clozes.length === 1) {
      const cloze = clozes[0]
      const options = [cloze.text].concat(
        (face.type === ClozeCardFaceType.GiveAwayChoice
          ? cloze.giveAwayDistrators
          : cloze.distrators) ?? []
      )

      const hasTooLongOption = options.some(item => isClozeTextTooLong(item))
      if (hasTooLongOption) {
        face.style.operationLayout = 'vertical'
      } else {
        face.style.operationLayout = 'grid'
      }
    }
  }

  const allClozeDistrators = face.altCards.reduce((acc, cur) => {
    const clozes = getContentClozes(cur.content)

    for (const cloze of clozes) {
      acc = acc.concat(cloze.distrators || [])
    }

    return acc
  }, distrators)

  // fallback 逻辑: https://qianmo.atlassian.net/browse/LD-670
  // 如果是送分选择题卡面，当挖空数不为 1 或者是不存在送分干扰项时，fallback 到 MinimalChoice
  if (face.type === ClozeCardFaceType.GiveAwayChoice) {
    if (
      clozes.length !== 1 ||
      (clozes[0].giveAwayDistrators ?? []).length === 0
    ) {
      face = {
        ...face,
        type: ClozeCardFaceType.MinimalChoice,
      }
    }
  }

  if (
    face.type === ClozeCardFaceType.MinimalChoice ||
    face.type === ClozeCardFaceType.Choice
  ) {
    if (clozes.length > 1) {
      face.altCards.length = 0
      return face
    }

    const validAltCards = face.altCards.filter(card => {
      return getContentClozes(card.content).length > 0
    })

    // 当卡片仅有一个挖空且本身没有干扰项，且 altCards 中没有可以当作干扰项的卡片时 fallback
    if (
      distrators.length === 0 &&
      validAltCards.length === 0 &&
      clozes.length === 1
    ) {
      face = {
        ...face,
        type: ClozeCardFaceType.Judgement,
      }
    }
  } else if (face.type === ClozeCardFaceType.Judgement) {
    const validAltCards = face.altCards.filter(card => {
      return getContentClozes(card.content).length > 0
    })

    if (allClozeDistrators.length === 0 && validAltCards.length === 0) {
      face = {
        ...face,
        type: ClozeCardFaceType.GiveAwayJudgement,
      }
    }
  }

  return face
}
export function processEnWordFace(face: EnWordCardFace) {
  if (face.type === EnWordCardFaceType.PickImage) {
    const validAltCards = face.altCards.filter(card => {
      return card.illustration != null && card.illustration.trim() !== ''
    })

    if (
      face.card.illustration == null ||
      face.card.illustration.trim() === '' ||
      validAltCards.length < 3
    ) {
      face = {
        ...face,
        type: EnWordCardFaceType.WordChoice,
      }
    } else {
      face.altCards = validAltCards
    }
  }

  if (face.type === EnWordCardFaceType.ExampleChoice) {
    if (face.card.examples.length === 0) {
      face = {
        ...face,
        type: EnWordCardFaceType.WordChoice,
      }
    }
  }

  return face
}

export function stringifyContent(content: Content): string {
  const texts: string[] = []

  for (const blockNode of content) {
    if (blockNode.type === 'p') {
      for (const n of blockNode.content) {
        if (n.type === 'text') {
          texts.push(n.text)
        } else if (n.type === 'cloze') {
          const options = [n.text, ...(n.distrators ?? [])]
          if (n.group) {
            texts.push(`{{${n.group}::${options.join('|')}}}`)
          } else {
            texts.push(`{{${n.text}${options.join('|')}}}`)
          }
        }
      }
    }
  }

  return texts.join('')
}

export function newClozeCard(): ClozeCard {
  return {
    type: CardType.CLOZE,
    content: [],
    altContents: [],
    analysis: [],
  }
}

export function newWordCard(): EnWordCard {
  return {
    type: CardType.EN_WORD,
    word: '',
    definition: '',
    examples: [],
    distrators: [],
    tabs: [
      {
        title: '近义词',
        content: [],
      },
      {
        title: '易混淆',
        content: [],
      },
      {
        title: '词组',
        content: [],
      },
    ],
    prons: [
      {
        label: '',
        language: 'en-US',
      },
      {
        label: '',
        language: 'en-GB',
      },
    ],
  }
}

export function validateCard(card: Card): string {
  // safety check，编辑器应该保证不出现这个问题
  if (!CardZod.safeParse(card).success) {
    return _t('卡片结构错误')
  }

  switch (card.type) {
    case CardType.CLOZE: {
      const isContentEmpty = stringifyContent(card.content) === ''
      const hasCloze = getContentClozes(card.content).length > 0

      if (isContentEmpty) {
        return _t('请填写「内容」')
      }

      if (!hasCloze) {
        return _t('请标记一处需要挖空的部分')
      }
      break
    }

    case CardType.EN_WORD: {
      if (card.word.trim() === '') {
        return _t('请填写「单词」')
      }
      if (card.definition.trim() === '') {
        return _t('请填写「释义」')
      }
    }
  }

  return ''
}

export function content2Doc(content: Content) {
  const paragraphs = content.map(blockNode => {
    const pContent = blockNode.content.map(n => {
      switch (n.type) {
        case 'text': {
          const marks: {
            type: string
            attrs?: Record<string, any>
            [key: string]: any
          }[] = []

          if (n.style?.dot) {
            marks.push({
              type: 'textStyle',
              attrs: {
                dot: true,
              },
            })
          }

          return {
            type: 'text',
            text: n.text,
            marks,
          }
        }

        case 'cloze':
          return {
            type: 'text',
            text: n.text,
            marks: [
              {
                type: 'cloze',
                attrs: {
                  group: n.group,
                  distrators: n.distrators,
                  giveAwayDistrators: n.giveAwayDistrators,
                },
              },
            ],
          }
      }
    })
    return {
      type: 'p',
      content: pContent,
    }
  })

  return {
    type: 'doc',
    content: paragraphs,
  } as JSONContent
}

// 编辑器给出来的干扰项可能是个空字符串，这里需要用该方法过滤
function ensureDistratorsType(distrators: string[]) {
  return Array.isArray(distrators) ? distrators : undefined
}

export function doc2Content(doc: JSONContent): Content {
  if (doc.content == null) return []

  return doc.content.map(p => {
    return {
      type: 'p',
      content:
        p.content?.map(n => {
          if (n.type === 'text') {
            const marks = n.marks ?? []
            const clozeMark = marks.find(item => item.type === 'cloze')

            if (clozeMark != null) {
              return {
                type: 'cloze',
                text: n.text!,
                group: clozeMark.attrs?.group,
                distrators: ensureDistratorsType(clozeMark.attrs?.distrators),
                giveAwayDistrators: ensureDistratorsType(
                  clozeMark.attrs?.giveAwayDistrators
                ),
              }
            } else {
              const styleMark = marks.find(item => item.type === 'textStyle')

              return {
                type: 'text',
                text: n.text!,
                style: {
                  dot: styleMark?.attrs?.dot,
                },
              }
            }
          }
        }) ?? [],
    } as BlockNode
  })
}
