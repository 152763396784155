import type { UnitEventType, CardSchedule } from '@/api/learn'
import {
  Queue,
  type Frame,
  type ConcreteFrame,
  getAltCards,
  orderFrames,
  SingleClozeCardDifficulty,
  EnWordCardDifficulty,
  MultiClozeCardDifficulty,
} from '../queue'
import {
  type Card,
  Interaction,
  type ConcreteCardFace,
  type ClozeCardFace,
  LearnStatus,
  CardType,
  ClozeCardFaceType,
  EnWordCardFaceType,
} from '@/types/core'
import { getContentClozes, parseCard } from '@/utils/card'
import { randomPick, randomPickBoss } from '@/utils'

// 队列学习阶段
export enum QueueStage {
  // 练习
  Practice = 'Practice',

  // boss
  Boss = 'Boss',

  // 完成
  Done = 'Done',
}

export interface InsertQueueState {
  stage: QueueStage
  frames: Frame[]
  totalFrames: number
  cardDifficulty: Record<number, number>
  cardPracticed: Record<number, number>
  activeCardEvents: {
    cardId: number
    event: UnitEventType
  }[]
}

function getDifficultyByR(r: number) {
  if (r >= 95) {
    return 3
  } else if (r >= 90) {
    return 2
  } else {
    return 1
  }
}

type InsertQueueConfig = {
  practiceCardTimes: number
  practiceCorrectInsert: number
  practiceWrongInsert: [number, number]
  singleClozeCardDifficulty: typeof SingleClozeCardDifficulty
  multiClozeCardDifficulty: typeof MultiClozeCardDifficulty
  enWordCardDifficulty: typeof EnWordCardDifficulty
}

export class InsertQueue extends Queue<InsertQueueConfig, InsertQueueState> {
  constructor(
    cards: CardSchedule[],
    config: InsertQueueConfig = {
      practiceCardTimes: 2,
      practiceCorrectInsert: 3,
      practiceWrongInsert: [1, 3] as [number, number],
      singleClozeCardDifficulty: SingleClozeCardDifficulty,
      multiClozeCardDifficulty: MultiClozeCardDifficulty,
      enWordCardDifficulty: EnWordCardDifficulty,
    }
  ) {
    super(cards, config)

    this.frames = this._genPracticeFrames()
    this.totalFrames = cards.length * this._config.practiceCardTimes
  }

  stage: QueueStage = QueueStage.Practice
  totalFrames = 0

  // 存储每个卡片当前的难度
  cardDifficulty: Record<number, number> = {}

  // 存储每个卡片练习阶段已经学了多少次
  cardPracticed: Record<number, number> = {}

  getState(): InsertQueueState {
    return {
      stage: this.stage,
      frames: this.frames,
      totalFrames: this.totalFrames,
      activeCardEvents: this._activeCardEvents,
      cardDifficulty: this.cardDifficulty,
      cardPracticed: this.cardPracticed,
    }
  }

  setState(state: InsertQueueState) {
    this.stage = state.stage
    this.frames = state.frames
    this.totalFrames = state.totalFrames
    this._activeCardEvents = state.activeCardEvents
    this.cardDifficulty = state.cardDifficulty
    this.cardPracticed = state.cardPracticed
  }

  private _genPracticeFrames(): ConcreteFrame[] {
    const frames: ConcreteFrame[] = []
    const orderedCards = this._orderCards()
    this._cards = orderedCards

    for (const item of orderedCards) {
      const difficulity =
        item.cardStatus === LearnStatus.DEBUT
          ? 1
          : getDifficultyByR(item.r * 100)

      this.cardDifficulty[item.cardId] = difficulity

      frames.push(this._genCardFrame(item, difficulity))
    }

    return orderFrames(
      frames,
      orderedCards.map(item => item.cardId)
    )
  }

  _genCardFrame(
    item: CardSchedule,
    difficulity: 1 | 2 | 3,
    hideRoleImage = false
  ): ConcreteFrame {
    const card = JSON.parse(item.content) as Card
    const parseResult = parseCard(card)
    const altCards = getAltCards(this._cards, item.cardId, card)

    let faceType: ClozeCardFaceType | EnWordCardFaceType

    if (card.type === CardType.CLOZE) {
      const isSingleCloze = getContentClozes(card.content).length === 1
      const config = isSingleCloze
        ? this._config.singleClozeCardDifficulty
        : this._config.multiClozeCardDifficulty
      faceType = randomPick(config[difficulity])
    } else {
      faceType = randomPick(this._config.enWordCardDifficulty[difficulity])
    }

    return {
      type: 'concrete',
      status: item.cardStatus,
      cardId: item.cardId,
      face: {
        type: faceType,
        cardId: item.cardId,
        card: parseResult.card,
        interaction: Interaction.Flash,
        altCards,
        style: {
          hideRoleImage,
        },
      },
    } as ConcreteFrame
  }

  private _genBossFrame(): Frame | null {
    const faces: ConcreteCardFace[] = []

    for (const item of this._cards) {
      const difficulity = this.increaseCardDifficulty(item.cardId)

      faces.push(this._genCardFrame(item, difficulity, true).face)
    }

    return {
      type: 'virtual',
      face: {
        type: 'boss',
        faces: faces as ClozeCardFace[],
        name: randomPickBoss(),
      },
    }
  }

  onEvent(cardId: number, event: UnitEventType) {
    this._activeCardEvents.unshift({
      cardId,
      event,
    })
  }

  learnCard(cardId: number) {
    const practicedCount = this.cardPracticed[cardId] ?? 0
    this.cardPracticed[cardId] = practicedCount + 1
  }

  increaseCardDifficulty(cardId: number) {
    const difficulity = this.cardDifficulty[cardId]

    if (difficulity >= 3) {
      this.cardDifficulty[cardId] = 3
    } else {
      this.cardDifficulty[cardId] = difficulity + 1
    }

    return this.cardDifficulty[cardId] as 1 | 2 | 3
  }

  decreaseCardDifficulty(cardId: number) {
    const difficulity = this.cardDifficulty[cardId]

    if (difficulity <= 1) {
      this.cardDifficulty[cardId] = 1
    } else {
      this.cardDifficulty[cardId] = difficulity - 1
    }

    return this.cardDifficulty[cardId] as 1 | 2 | 3
  }

  tick() {
    switch (this.stage) {
      case QueueStage.Done:
        return

      case QueueStage.Practice: {
        const frame = this.frames.shift()! as ConcreteFrame
        const isCorrect = this._activeCardEvents.every(
          item => item.event === 'CORRECT'
        )
        const cardInfo = this._getCardInfo(frame.cardId)!

        this.learnCard(frame.cardId)

        // 如果学习次数超过设定次数，弃牌
        if (
          this.cardPracticed[frame.cardId] >= this._config.practiceCardTimes
        ) {
          break
        }

        if (isCorrect) {
          const difficulity = this.increaseCardDifficulty(frame.cardId)
          const nextIndex =
            this._cards.length -
            (randomPick([1, this._config.practiceCorrectInsert]) - 1)
          this.frames.splice(
            nextIndex,
            0,
            this._genCardFrame(cardInfo, difficulity)
          )
        } else {
          const difficulity = this.decreaseCardDifficulty(frame.cardId)
          const nextIndex = randomPick(this._config.practiceWrongInsert) - 1
          this.frames.splice(
            nextIndex,
            0,
            this._genCardFrame(cardInfo, difficulity)
          )
        }

        break
      }
      case QueueStage.Boss: {
        break
      }
    }

    this._activeCardEvents = []
  }

  next() {
    if (this.stage === QueueStage.Practice) {
      if (this.frames.length > 0) {
        return
      }

      this.nextToBoss()
      return
    }

    if (this.stage === QueueStage.Boss) {
      this.stage = QueueStage.Done
    }
  }

  nextToBoss() {
    this.stage = QueueStage.Boss
    const frame = this._genBossFrame()

    if (frame != null) {
      this.frames.push(frame)
      this.totalFrames = 1
    } else {
      this.stage = QueueStage.Done
    }
  }
}
