<template>
  <div
    :style="{
      '--streak-digit-digit-height': '100px',

      '--streak-digit-animation-duration': '1s',
    }"
    class="_3ppeJ"
  >
    <div
      class="_1LY7K _3udxi"
      :style="{
        '--streak-digit-animation-delay': '0ms',
      }"
    >
      <div class="flex justify-center items-center">
        <div
          v-for="(n, index) in numbers"
          :key="index"
        >
          <Icon
            :name="'digits-active-' + n"
            class="h-100px w-75px scale-120"
          ></Icon>
        </div>
      </div>
      <div class="flex justify-center items-center">
        <div
          v-for="(n, index) in lastNumbers"
          :key="index"
        >
          <Icon
            :name="'digits-inactive-' + n"
            class="h-100px w-75px scale-120"
          ></Icon>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Icon from './Icon.vue'

const props = defineProps<{
  number: number
}>()

const numbers = props.number.toString().split('')
const lastNumbers = (props.number == 0 ? 0 : props.number - 1)
  .toString()
  .split('')
</script>

<style scoped>
._3ppeJ {
  height: var(--streak-digit-digit-height);
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
}
._3udxi {
  animation-delay: var(--streak-digit-animation-delay);
  animation-duration: var(--streak-digit-animation-duration);
  animation-fill-mode: both;
}
._1LY7K {
  animation-name: _1BpuV;
  animation-timing-function: cubic-bezier(0.75, 0, 0.78, 0);
  display: inline-block;
  height: var(--streak-digit-digit-height);
  margin: 0 calc(var(--streak-digit-digit-height) * -0.08);
  position: relative;
  transform: translateY(calc(var(--streak-digit-digit-height) * -1));
}
@keyframes _1BpuV {
  0% {
    transform: translateY(calc(var(--streak-digit-digit-height) * -1));
  }
  68.75% {
    transform: translateY(calc(var(--streak-digit-digit-height) * -0.992));
  }
  76.6% {
    transform: translateY(calc(var(--streak-digit-digit-height) * -1.016));
  }
  84.4% {
    transform: translateY(calc(var(--streak-digit-digit-height) * -0.968));
  }
  92.2% {
    transform: translateY(calc(var(--streak-digit-digit-height) * -1.064));
  }
  100% {
    transform: translateY(0);
  }
}
</style>
