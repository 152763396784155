<template>
  <div class="flex items-center mb-4">
    <span>
      {{ _t('已选择') }} {{ selectedCards.length }} {{ _t('张卡片') }}
    </span>
    <span>
      ({{ reivewCards.length }} {{ _t('复习') }} {{ debutCards.length }}
      {{ _t('新学') }})
    </span>

    <Button
      class="ml-auto"
      :label="_t('确认卡片')"
      @click="onConfirm"
    ></Button>
  </div>

  <DataTable
    class="w-[60vw]"
    :value="table"
  >
    <Column
      field="id"
      header="卡片 id"
      style="width: 15%"
    ></Column>

    <Column
      field="abstract"
      header="卡片内容"
      style="width: 25%"
    >
      <template #body="slotProps">
        <CardDigest :card="slotProps.data.content" />
      </template>
    </Column>
    <Column
      field="status"
      header="是否加入队列"
    >
      <template #body="slotProps">
        <InputSwitch
          :model-value="isSelected(slotProps.data.id)"
          @update:model-value="onSelectedChange(slotProps.data.id, $event)"
        />
      </template>
    </Column>
    <Column
      field="status"
      header="是否复习"
    >
      <template #body="slotProps">
        <InputSwitch
          :model-value="isReview(slotProps.data.id)"
          @update:model-value="onReviewChange(slotProps.data.id, $event)"
        />
      </template>
    </Column>
  </DataTable>
</template>
<script setup lang="ts">
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputSwitch from 'primevue/inputswitch'
import { computed, ref } from 'vue'
import { tryJSONParse } from '@/utils'
import CardDigest from '@/components/CardDigest/CardDigest.vue'

import type { CardResponse } from '@/api/package-source'
import db from '@/db'
import type { CardSchedule } from '@/api/learn'
import { LearnStatus, type Card } from '@/types/core'

const props = defineProps<{
  packageId: number
  chapterId: string
  cards: CardResponse[]
}>()

const emit = defineEmits<{
  done: [CardSchedule[]]
}>()

const cardContentMap = Object.fromEntries(
  props.cards.map(item => [item.id, item.content])
) as Record<number, string>

const key = computed(() => `${props.packageId}-${props.chapterId}`)

const selectedCards = ref<{ cardId: number; review: boolean }[]>(
  db.tmp.queueCards[key.value] ?? []
)

const reivewCards = selectedCards.value.filter(item => item.review)

const debutCards = selectedCards.value.filter(item => !item.review)

const table = computed(() => {
  return props.cards.map(card => {
    const content = tryJSONParse(card.content) as Card

    return {
      id: card.id,
      content,
    }
  })
})

function isSelected(id: number) {
  return selectedCards.value.some(item => item.cardId === id)
}

function isReview(id: number) {
  return selectedCards.value.some(item => item.cardId === id && item.review)
}

function onSelectedChange(id: number, selected: boolean) {
  if (selected && !isSelected(id)) {
    selectedCards.value.push({
      cardId: id,
      review: false,
    })
  } else if (!selected) {
    const index = selectedCards.value.findIndex(item => item.cardId === id)
    selectedCards.value.splice(index, 1)
  }

  db.tmp.queueCards[key.value] = selectedCards.value
}

function onReviewChange(id: number, review: boolean) {
  if (!isSelected(id)) {
    selectedCards.value.push({
      cardId: id,
      review: false,
    })
  }

  const index = selectedCards.value.findIndex(item => item.cardId === id)

  selectedCards.value.splice(index, 1, { cardId: id, review })
  db.tmp.queueCards[key.value] = selectedCards.value
}

function onConfirm() {
  if (selectedCards.value.length === 0) {
    _message.info('至少需要选择一张卡片')
    return
  }

  const schedules: CardSchedule[] = selectedCards.value.map(item => {
    return {
      cardId: item.cardId,
      content: cardContentMap[item.cardId],
      authorId: '',
      cardStatus: item.review ? LearnStatus.REVIEW : LearnStatus.DEBUT,
    }
  })

  emit('done', schedules)
}
</script>
<style scoped></style>
