<template>
  <div
    ref="el"
    class="flex flex-col gap-2 py-2"
  >
    <slot name="header"></slot>

    <PkgCardList
      :packages="data.recommendPackages"
      @pkgClick="pkg => emit('pkgClick', pkg)"
    />

    <Loading v-if="data.loading" />
    <div
      v-if="data.error"
      class="flex gap-2 items-center justify-center text-ld-label-secondary text-19px py-4"
    >
      <div>{{ _t('加载失败') }}</div>
      <div
        class="cursor-pointer flex text-ld-brand-500"
        @click="fetchData"
      >
        <Icon
          name="refresh"
          class="w-20px"
          @click="fetchData"
        />
        <div>{{ _t('立即刷新') }}</div>
      </div>
    </div>
    <div
      v-if="data.noMoreData"
      class="flex flex-col text-19px text-center text-ld-label-secondary py-4"
    >
      <div>{{ _t('心仪卡包找不到？不如自己来创造') }}</div>
      <div>
        <span>
          {{ _t('去试试') }}
        </span>
        <TextButton
          :label="_t('创建自己的卡包')"
          class="create-pkg-btn mx-2px"
          @click="onPackageCreate"
        ></TextButton>
        <span>{{ _t('吧') }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  fetchRecommendPackages,
  type PackageBasic,
  type SearchedPackage,
} from '@/api/package-source'

import { reactive, ref } from 'vue'

import { useInfiniteScroll } from '@vueuse/core'
import PkgCardList from './SearchPanel/PkgCardList.vue'
import PkgForm from '@/components/PkgList/PkgForm.vue'
import { useCommonStore } from '@/stores'
import { useRouter } from 'vue-router'

const store = useCommonStore()
const router = useRouter()

const emit = defineEmits<{
  pkgClick: [SearchedPackage]
}>()

const el = ref<HTMLElement | null>(null)
useInfiniteScroll(el, () => fetchData(), {
  distance: 0,
  interval: 300,
  canLoadMore: () => !data.noMoreData && !data.error,
})

const data = reactive({
  searchLogs: [] as string[],
  recommendPackages: [] as SearchedPackage[],
  recommendOffset: 0,
  // 加载中
  loading: false,
  // 加载失败
  error: false,
  // 无更多数据
  noMoreData: false,
})

async function fetchData() {
  if (data.loading) return
  data.loading = true
  data.error = false

  try {
    const res = await fetchRecommendPackages(data.recommendOffset)
    data.recommendPackages.push(...res.packages)

    if (data.recommendPackages.length >= res.total) {
      data.noMoreData = true
      data.loading = false
      return
    }
    data.recommendOffset = res.offset + 1
  } catch {
    data.error = true
  }

  data.loading = false
}

function onPackageCreate() {
  if (!store.user?.officialAccount) {
    _confirm({
      content: '卡包制作功能尚未开放，你可以申请抢先测试资格',
      type: 'confirm',
      okText: _t('去申请'),
      cancelText: _t('暂不'),
      async onConfirm() {
        router.push({
          name: 'beta-feature',
          query: {
            name: 'alphaCreatorApply',
          },
        })
        return true
      },
    })

    return
  }

  _openDialog(PkgForm, {
    title: '新建卡包',
    props: {
      onCreate(pkg: PackageBasic) {
        router.push({ name: 'package', params: { id: pkg.id } })
      },
    },
    rootClass: 'p-0',
    dialog: {
      showHeader: false,
      dismissableMask: true,
      contentClass: 'px-4 py-3',
    },
  })
}
</script>

<style scoped>
.create-pkg-btn :deep(button) {
  font-size: 19px;
}
</style>
