<template>
  <Icon />
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
})

const Icon = defineAsyncComponent(async () => {
  return import(`../assets/icons/${props.name}.svg`).catch(() => {
    // 目前发版以后不会保留上次的资源，可能会触发 404
    // 此时直接刷新
    location.reload()
  })
})
</script>
