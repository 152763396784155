import { createI18n } from 'vue-i18n'

import db from '../db'

export const SUPPORT_LOCALES = [
  {
    name: '简体中文',
    value: 'zh',
  },
  {
    name: 'English',
    value: 'en',
  },
] as const

export type Locale = (typeof SUPPORT_LOCALES)[number]['value']

const i18n = createI18n({
  locale: db.locale,
  fallbackLocale: 'zh',
  lobalInjection: true,
})

export async function loadLocaleMessages(locale: Locale) {
  const messages = await import(`./locales/${locale}.json`)
  i18n.global.setLocaleMessage(locale, messages.default)
}

export const t = i18n.global.t

export default i18n
