// this file is the very first file to run
// we use this file to init _global and store something
// that can be used by all other files
import db from './db'

export type Env = 'local' | 'staging' | 'prod'
export type ApiEnv = 'staging' | 'prod'

const API_URL_PROD = ''
const API_URL_STAGING = ''

export type ApiURL = typeof API_URL_STAGING | typeof API_URL_PROD

const OSS_URL_STAGING = 'https://cdn.lingduck.top'
const OSS_URL_PROD = 'https://cdn.lingduck.cn'
export type OssURL = typeof OSS_URL_STAGING | typeof OSS_URL_PROD

const HELP_LINK_STAGING = 'https://help.lingduck.top/'
const HELP_LINK_PROD = 'https://help.lingduck.top/'

export type HelpLink = typeof HELP_LINK_PROD | typeof HELP_LINK_STAGING

const env = parseEnv(location.hostname)

let apiEnv = db.debug.apiEnv || 'staging'

if (env === 'prod') {
  apiEnv = 'prod'
}

const apiURL = apiEnv === 'prod' ? API_URL_PROD : API_URL_STAGING
const ossURL = apiEnv === 'prod' ? OSS_URL_PROD : OSS_URL_STAGING
const helpLink = apiEnv === 'prod' ? HELP_LINK_PROD : HELP_LINK_STAGING

const aliyunCaptchaSceneId = 'n9j3ojpv'
const aliyunCaptchaPrefix = '1ynvpx'

window._global = {
  env,

  get isLocal() {
    return this.env === 'local'
  },
  get isStaging() {
    return this.env === 'staging'
  },
  get isProd() {
    return this.env === 'prod'
  },

  aliyunCaptchaSceneId,
  aliyunCaptchaPrefix,

  apiEnv,
  apiURL,
  ossURL,

  helpLink,

  gitCommit: __GIT_COMMIT__,

  appMode: __APP_MODE__,
  get isPcMode() {
    return this.appMode === 'pc'
  },
  get isMobileMode() {
    return this.appMode === 'mobile'
  },

  now(): Date {
    if (_store.debugTs) {
      return new Date(_store.debugTs)
    }

    return new Date()
  },

  get isInsideWechat() {
    return /MicroMessenger/i.test(navigator.userAgent)
  },

  // 微信开放平台，网站应用
  wxAppId: 'wxe2e0a1b8eb6b4c08',

  assetUrl(assetId, imgStyle?: string) {
    const url = `${this.ossURL}/${assetId}`

    if (imgStyle) {
      return url + `?x-oss-process=style/${imgStyle}`
    }

    return url
  },
}

function parseEnv(host: string): Env {
  switch (host) {
    case 'localhost':
      return 'local'

    case 'lingduck.top':
    case 'm.lingduck.top':
      return 'staging'

    case 'lingduck.cn':
    case 'm.lingduck.cn':
      return 'prod'

    default:
      if (location.port !== '') return 'local'
      return 'prod'
  }
}
