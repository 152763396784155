<template>
  <div
    class="card border-solid px-2 py-3 border-3px"
    :style="style"
  >
    <PkgRoundIcon
      :pkg="props.package"
      :iconSize="60"
      :size="86"
      class="text-66px relative"
    />

    <div class="flex items-center h-36px mt-3">
      <div class="line-clamp-2 break-all leading-[1.2] text-15px">
        {{ props.package.name }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { PackageBasic } from '@/api/package-source'
import { computed } from 'vue'
import PkgRoundIcon from '@/components/PkgRoundIcon.vue'

const props = defineProps<{
  package: PackageBasic
  selected: boolean
}>()

const color = computed(() => props.package.style.themeColor)

const style = computed(() => {
  const styleList = [
    `background-color: var(--${color.value}-300)`,
    `border-color: var(--${color.value}-${props.selected ? '600' : '400'})`,
    `color: var(--${color.value}-900)`,
  ]

  return styleList
})
</script>
<style scoped>
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  transition: all 0.4s;
}
</style>
