import { Extension } from '@tiptap/core'

const ClearMarksOnEnter = Extension.create({
  name: 'clear_mark_on_enter',

  addKeyboardShortcuts() {
    return {
      Enter: ({ editor }) => {
        editor.commands.unsetCloze?.()
        editor.commands.unsetBold?.()
        editor.commands.unsetItalic?.()
        editor.commands.unsetUnderline?.()
        editor.commands.unsetStrike?.()
        editor.commands.unsetTextStyle?.()
        return false
      },
    }
  },
})

export default ClearMarksOnEnter
