<template>
  <div
    class="text-center items-center h-44px sticky top-0 z-1 bg-ld-background flex px-4 shrink-0"
  >
    <div class="min-w-44px flex-1 flex items-center overflow-hidden">
      <div
        class="cursor-pointer"
        @click="onBack"
      >
        <slot name="back">
          <i class="pi pi-chevron-left back-icon"></i>
        </slot>
      </div>
    </div>

    <div class="text-base font-semibold mx-4 line-clamp-1">
      {{ props.title }}
    </div>

    <div
      class="min-w-44px flex-1 flex justify-end items-center overflow-hidden"
    >
      <slot name="actions" />
    </div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  title?: string
}>()

const emit = defineEmits<{
  back: []
}>()

function onBack() {
  emit('back')
}
</script>
<style scoped></style>
