<template>
  <div class="flex items-center">
    <component
      v-for="n in numberImages"
      :is="n"
      :style="{
        width: props.fontWidth,
      }"
    />
  </div>
</template>

<script lang="ts" setup>
import { defineAsyncComponent } from 'vue'
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    value: number
    color: 'blue' | 'brand' | 'green' | 'orange' | 'red'
    dark?: boolean
    fontWidth?: number
  }>(),
  {
    fontWidth: 12,
  }
)

const numberImages = computed(() => {
  const numberChars = [...props.value.toString()]
  return numberChars.map(n => {
    return defineAsyncComponent(async () => {
      return import(
        `../assets/fonts/${props.color}_${
          props.dark ? 'dark' : 'light'
        }_${n}.svg`
      )
    })
  })
})
</script>
