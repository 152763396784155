<template>
  <OverlayPanel
    v-bind="props"
    :pt="pt"
    ref="op"
  >
    <slot></slot>
  </OverlayPanel>
</template>
<script setup lang="ts">
import zindexMgmt from '@/utils/zindexMgmt'
import OverlayPanel, {
  type OverlayPanelPassThroughAttributes,
  type OverlayPanelPassThroughOptions,
  type OverlayPanelProps,
} from 'primevue/overlaypanel'
import { ref, computed } from 'vue'

const props = withDefaults(defineProps<OverlayPanelProps>(), {
  dismissable: true,
})

const op = ref()

const pt = computed(() => {
  let pt = (props.pt ?? {}) as OverlayPanelPassThroughOptions

  pt.root = (pt.root ?? {}) as OverlayPanelPassThroughAttributes

  if (pt.root.style == null) {
    pt.root.style = `z-index: ${zindexMgmt.nextZIndex()}`
  } else {
    pt.root.style += `;z-index: ${zindexMgmt.nextZIndex()}`
  }

  return pt
})

function toggle(event: MouseEvent) {
  op.value.toggle(event)
}

function hide() {
  op.value.hide()
}

function show() {
  op.value.show()
}

defineExpose({
  toggle,
  hide,
  show,
})
</script>
<style scoped></style>
