<template>
  <div class="flex flex-col items-center justify-center">
    <template v-if="props.icon">
      <Img
        v-if="props.icon.type === 'img'"
        :name="props.icon.name"
        class="w-85px"
      />
      <Icon
        v-else
        class="w-80px h-80px"
        :name="props.icon.name"
      />
    </template>

    <Img
      v-else
      name="ld-greet"
      class="w-85px"
    />

    <div class="h-12px"></div>

    <template v-if="props.title">
      <div
        class="text-19px font-semibold text-[var(--text-color-primary)] text-center"
      >
        {{ props.title }}
      </div>

      <div class="h-8px"></div>

      <div
        class="text-17px font-semibold text-ld-label-secondary text-center whitespace-pre-line"
      >
        {{ props.content }}
      </div>
    </template>

    <div
      v-else
      class="text-19px font-semibold text-[var(--text-color-primary)] text-center whitespace-pre-line"
    >
      {{ props.content }}
    </div>

    <div class="h-20px"></div>

    <template v-if="props.type === 'wechat'">
      <Button
        class="whitespace-nowrap w-full text-14px"
        scene="wechat"
        @click="onConfirmButtonClick()"
      >
        <div class="flex justify-center items-center w-full text-lg">
          <Icon
            name="wechat-pure"
            class="h-28px"
          />
          <span class="ml-1">{{ props.okText }}</span>
        </div>
      </Button>
    </template>

    <template v-else-if="props.type === 'warn'">
      <Button
        class="whitespace-nowrap w-full text-14px"
        :label="props.cancelText"
        size="large"
        @click="onCancelButtonClick()"
      ></Button>

      <div class="h-8px"></div>

      <Button
        class="whitespace-nowrap w-full"
        :label="props.okText"
        scene="danger"
        size="large"
        @click="onConfirmButtonClick()"
      ></Button>
    </template>

    <template v-else-if="props.type === 'confirm'">
      <Button
        class="whitespace-nowrap w-full text-14px"
        :label="props.okText"
        size="large"
        @click="onConfirmButtonClick()"
      ></Button>

      <div class="h-8px"></div>

      <Button
        class="whitespace-nowrap w-full"
        :label="props.cancelText"
        scene="secondary"
        size="large"
        @click="onCancelButtonClick()"
      ></Button>
    </template>

    <template v-else-if="props.type === 'notice'">
      <Button
        class="whitespace-nowrap w-full text-14px"
        :label="props.okText"
        size="large"
        @click="onConfirmButtonClick()"
      ></Button>
    </template>
    <div></div>
  </div>
</template>

<script lang="ts" setup>
import Icon from './Icon.vue'

import type { ConfirmOptions } from '@/types/global'

const props = withDefaults(
  defineProps<{
    type?: ConfirmOptions['type']
    icon?: {
      name: string
      type: 'svg' | 'img'
    }
    title?: string
    content: string
    okText?: string
    cancelText?: string
  }>(),
  {
    type: 'confirm',
    icon: undefined,
    title: undefined,
    okText: '确认',
    cancelText: '取消',
  }
)

const emit = defineEmits<{
  confirm: []
  cancel: []
}>()

function onConfirmButtonClick() {
  emit('confirm')
}

function onCancelButtonClick() {
  emit('cancel')
}
</script>

<style scoped></style>
