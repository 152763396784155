<template>
  <RatioSpacedContainer
    v-if="data.emptyFrames"
    class="text-red-500 text-center h-full"
  >
    {{ _t('没有可用的卡片') }}
  </RatioSpacedContainer>

  <div
    v-if="data.activeFrame != null && data.activeFrame.face.type !== 'boss'"
    class="flex items-center mt-4 px-4 relative g-header-width"
  >
    <Icon
      name="close"
      class="cursor-pointer w-20px"
      @click="emit('close')"
    ></Icon>

    <ProgressBar
      :percent="data.progress"
      class="ml-4"
    />

    <transition
      name="monster-hint"
      mode="out-in"
    >
      <span
        v-if="data.monsterHint"
        class="monster-hint"
        :style="monsterHintStyle"
      >
        {{ data.monsterHint }}
      </span>
    </transition>
  </div>

  <template v-if="data.activeFrame != null">
    <transition
      name="card-slide"
      mode="out-in"
    >
      <ConcreteCard
        v-if="data.activeFrame.type === 'concrete'"
        :key="data.cardLearnRound"
        :face="data.activeFrame.face"
        class="flex-1"
        @event="onEventCollect"
        @star="onStar"
        @next="onNext"
        @done="onVirtualCardDone"
        @close="emit('close')"
      ></ConcreteCard>

      <VirtualCard
        v-else
        :face="data.activeFrame.face"
        class="flex-1"
        @event="onEventCollect"
        @star="onStar"
        @next="onNext"
        @done="onVirtualCardDone"
        @close="emit('close')"
      ></VirtualCard>
    </transition>
  </template>
</template>

<script lang="ts" setup>
import { UnitEventType } from '@/api/learn'
import { reactive } from 'vue'
import ConcreteCard from '@/components/ConcreteCard/ConcreteCard.vue'
import VirtualCard from '@/components/VirtualCard/VirtualCard.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import { useCommonStore } from '@/stores'
import { feedbackStar } from '@/components/ConcreteCard/common/feedback'
import { QueueStage, InsertQueue } from './index'
import {
  QueueType,
  type Frame,
  SingleClozeCardDifficulty,
  MultiClozeCardDifficulty,
  EnWordCardDifficulty,
} from '../queue'
import { completeReport } from '../report'

import { Interaction, type ConcreteCardFace } from '@/types/core'
import db from '@/db'
import { computed } from 'vue'

const emit = defineEmits<{
  close: []
  star: [feedbackStar]
}>()

const store = useCommonStore()

let queue: InsertQueue

const data = reactive({
  cardLearnRound: 0,
  stage: QueueStage.Practice,
  activeFrame: null as Frame | null,
  progress: 0,
  combo: 0,
  monsterKey: 0,
  monsterHint: '',
  emptyFrames: false,
})

function loadUnit() {
  if (!store.checkStageUnit()) return

  if (store.stageUnit != null) {
    queue = new InsertQueue(store.stageUnit.schedules, {
      practiceCardTimes: 2,
      practiceCorrectInsert: 3,
      practiceWrongInsert: [1, 3] as [number, number],

      singleClozeCardDifficulty:
        db.debug.cardDifficultyConfig.singleClozeCard ??
        SingleClozeCardDifficulty,
      multiClozeCardDifficulty:
        db.debug.cardDifficultyConfig.muliClozeCard ?? MultiClozeCardDifficulty,
      enWordCardDifficulty:
        db.debug.cardDifficultyConfig.enWordCard ?? EnWordCardDifficulty,
    })
    const progressCache = db.unitProgressCache[QueueType.Insert]

    if (progressCache) {
      queue.setState(progressCache)
      updateProgress()
    }

    data.emptyFrames = queue.frames.length === 0
    data.activeFrame = queue.frames[0]
  }
}

const monsterHintStyle = computed(() => {
  const left = Math.min(Math.max(data.progress - 10, 15), 85)

  return `left: ${left}%;`
})

loadUnit()

function showMonsterHint() {
  if (data.combo > 1) {
    data.monsterHint = `Combo x ${data.combo}`
  } else {
    data.monsterHint = `Hit + 1`
  }

  setTimeout(() => {
    data.monsterHint = ''
  }, 500)
}

function onEventCollect(cardId: number | undefined, event: UnitEventType) {
  if (!data.activeFrame || cardId == null) return

  if (data.activeFrame.face.type !== 'boss') {
    if (event === UnitEventType.CORRECT) {
      data.combo++
      showMonsterHint()
    } else {
      data.combo = 0
    }
  }

  const cardInteraction = (data.activeFrame.face as ConcreteCardFace)
    .interaction

  queue.onEvent(cardId, event)
  store.insertLearningEvent(cardId, {
    event,
    // 需要后端增加类型，否则会报错
    stage: [Interaction.Practice, Interaction.Check].includes(cardInteraction)
      ? cardInteraction
      : Interaction.Practice,
    timestamp: Date.now(),
  })
}

function onNext() {
  data.monsterKey++

  queue.next()

  if (queue.stage !== data.stage) {
    onStageChange(queue.stage)
  }

  data.activeFrame = queue.frames[0]
  data.cardLearnRound++
}

function updateProgress() {
  if (queue.stage === QueueStage.Practice) {
    const completedFrames = Object.values(queue.cardPracticed).reduce(
      (acc, cur) => acc + cur,
      0
    )
    data.progress = (completedFrames / queue.totalFrames) * 100
  } else {
    const completedFrames = queue.totalFrames - queue.frames.length
    data.progress = (completedFrames / queue.totalFrames) * 100
  }
}

function onStar(star: feedbackStar) {
  emit('star', star)

  queue.tick()
  updateProgress()
  const state = queue.getState()
  db.unitProgressCache[QueueType.Insert] = state
}

function onVirtualCardDone() {
  queue.tick()
  updateProgress()

  onNext()
}

function onStageChange(stage: QueueStage) {
  data.stage = queue.stage
  data.progress = 0

  if (store.stageUnit == null) return

  if (stage === QueueStage.Done) {
    const cardIds = (store.stageUnit?.schedules ?? []).map(item => item.cardId)

    for (const id of cardIds) {
      store.completeCard(id)
    }
    completeReport()

    delete db.unitProgressCache[QueueType.Flash]
  }
}
</script>

<style scoped>
.card-slide-enter-active,
.card-slide-leave-active {
  transition: transform 300ms;
}
.card-slide-enter-from {
  transform: translateX(100%);
}
.card-slide-enter-to {
  transform: translateX(0%);
}
.card-slide-leave-from {
  transform: translateX(0%);
}
.card-slide-leave-to {
  transform: translateX(-100%);
}

.monster-hint-enter-active,
.monster-hint-leave-active {
  transition: opacity 500ms;
}

.monster-hint-enter {
  opacity: 1; /* 初始位置 */
}
.monster-hint-leave-to {
  opacity: 0; /* 最终位置 */
}

.monster-hint {
  position: absolute;
  top: 20px;
  color: var(--red-400);
  font-family: Comic Sans MS;
  font-size: 15px;
  font-weight: 400;
  transform: rotate(-5.89deg);
  z-index: 1;
  white-space: nowrap;
}
</style>
