<template>
  <label
    data-required
    class="block text-900 text-sm font-medium"
  >
    验证码
  </label>

  <div class="flex items-center h-40px mt-2">
    <InputText
      :modelValue="props.modelValue"
      class="flex-1 min-w-100px"
      autocomplete="one-time-code"
      @update:model-value="emit('update:modelValue', $event)"
    />

    <Button
      size="small"
      class="w-108px ml-4 h-full whitespace-nowrap shrink-0"
      scene="secondary"
      :disabled="data.freezeSeconds > 0"
      :loading="loading"
      @click="onSendVcode"
    >
      <div class="flex justify-center w-full">
        <span v-if="data.freezeSeconds > 0">{{ data.freezeSeconds }}s</span>
        <span v-else>{{ $t('auth.get_code') }}</span>
      </div>
    </Button>

    <button
      :id="buttonId"
      ref="captchaButton"
      class="hidden"
    ></button>
  </div>

  <div :id="elementId"></div>
</template>

<script lang="ts" setup>
import { reactive, ref } from 'vue'
import { sendVcode } from '@/api/auth'
import { loadAliyunCaptcha, isEmail, randomString } from '@/utils'
import { t } from '@/i18n'

import type { VCODE_SCENE } from '@/api/auth'
import { onMounted } from 'vue'

const props = defineProps<{
  modelValue: string
  email: string
  scene: VCODE_SCENE
}>()

const emit = defineEmits<{
  'update:modelValue': [string]
}>()

const buttonId = `captcha-button-${randomString(4)}`
const elementId = `captcha-element-${randomString(4)}`
const captchaButton = ref<HTMLButtonElement>()

const data = reactive({
  captchaToken: '',
  freezeSeconds: 0,
})

const loading = ref(false)
let captchaVerifyResolve: () => void

onMounted(() => {
  if (captchaButton.value) {
    loadAliyunCaptcha(captchaButton.value, elementId, onCaptchaVerify)
  }
})

function onCaptchaVerify(captchaVerifyParam: string) {
  data.captchaToken = captchaVerifyParam
  captchaVerifyResolve?.()

  return {
    captchaResult: true,
    bizResult: true,
  }
}

async function startCaptchaVerify() {
  captchaButton.value?.click()

  return new Promise(resolve => {
    captchaVerifyResolve = () => resolve(undefined)
  })
}

async function onSendVcode() {
  if (captchaButton.value == null) return

  if (!isEmail(props.email)) {
    _message.info(t('auth.code_message_1'))
    return
  }

  if (_db.debug.skip) {
    data.captchaToken = '__captcha_token__'
  } else {
    await startCaptchaVerify()
  }

  try {
    loading.value = true
    const res = await sendVcode(props.email, props.scene, data.captchaToken)

    if (res.code !== 0) {
      _message.info(res.message)
    }

    if (res.data) {
      data.freezeSeconds = res.data.freezeSeconds
      startFreezeTimer()
    }
  } finally {
    loading.value = false

    loadAliyunCaptcha(captchaButton.value, elementId, onCaptchaVerify)
  }
}

let freezeTimer: number
function startFreezeTimer() {
  clearInterval(freezeTimer)

  setInterval(function () {
    data.freezeSeconds -= 1

    if (data.freezeSeconds <= 0) {
      clearInterval(freezeTimer)
    }
  }, 1000)
}
</script>

<style scoped></style>
