<template>
  <template v-if="!started">
    <div class="flex h-44px px-4 g-header-width">
      <Icon
        name="close"
        class="cursor-pointer w-20px"
        @click="emit('close')"
      ></Icon>
    </div>
    <RatioSpacedContainer class="flex-1">
      <Img
        :name="props.boss.name"
        class="w-128px"
      />

      <span class="p-2 text-22px text-ld-brand-900">
        哎呦不错哦，我们玩点刺激的吧
      </span>
    </RatioSpacedContainer>
    <Button
      class="bottom-button"
      :label="_t('继续')"
      @click="onStart"
    ></Button>
  </template>
  <BossBattle
    v-else
    :face="current"
    :index="index"
    :npc="{ hp: bossHp, avatar: props.boss.name + '_half' }"
    @event="onEvent"
    @next="onNext"
    @star="onStar"
    @close="emit('close')"
  >
    <template #end>
      <div class="flex-1 flex flex-col">
        <RatioSpacedContainer class="flex-1">
          <span class="text-88px">👍</span>
          <span class="p-2 text-22px">你真厉害，败给你了</span>
        </RatioSpacedContainer>
        <Button
          class="bottom-button"
          :label="_t('继续')"
          @click="onDone"
        ></Button>
      </div>
    </template>
  </BossBattle>
</template>

<script setup lang="ts">
import { ClozeCardFaceType, type VirtualCardBossFace } from '@/types/core'
import { UnitEventType } from '@/api/learn'
import { computed, provide, ref } from 'vue'
import { useHotKey } from '@/hooks'
import type { ConcreteCardFace } from '@/types/core'
import BossBattle from '../BossBattle.vue'
import { debounce } from 'lodash-es'
import type { feedbackStar } from '../ConcreteCard/common/feedback'

useHotKey('enter,space', () => {
  if (!started.value) {
    onStart()
  } else if (current.value == null) {
    onDone()
  }
})

const props = defineProps<{
  boss: VirtualCardBossFace
}>()
const cards = computed(() => {
  // boss 卡片 隐藏角色形象
  return props.boss.faces.map(card => {
    const res = {
      ...card,
      style: {
        ...card.style,
        hideRoleImage: true,
      },
    }
    if (
      card.type === ClozeCardFaceType.Choice ||
      card.type === ClozeCardFaceType.Judgement
    ) {
      return {
        ...res,
        style: {
          ...res.style,
          questionLayout: 'vertical',
        },
      }
    }
    return res
  })
})

const index = ref(0)

// 怪物的血量
const health = computed(() => props.boss.faces.length - index.value)

// 要显示的卡片
const current = computed(() => cards.value[index.value] as ConcreteCardFace)

// 打boss阶段
const started = ref(false)

// boss的血量
const bossHp = computed(() => {
  return (health.value / props.boss.faces.length) * 100
})

// 使用boss形象替换角色形象
provide('roleName', props.boss.name)

const emit = defineEmits<{
  event: [cardId: number, UnitEventType]
  done: []
  close: []
  star: [feedbackStar]
}>()

function onStart() {
  started.value = true
}

function onDone() {
  emit('done')
}
function onStar(star: feedbackStar) {
  emit('star', star)
}

const onNext = debounce(() => {
  index.value += 1
}, 500)

function onEvent(_: number | undefined, event: UnitEventType) {
  emit('event', current.value.cardId, event)
}
</script>

<style scoped>
.bottom-button {
  width: calc(100% - 32px);
  max-width: 700px;
  height: 44px;
  margin-bottom: 16px;
  white-space: nowrap;
  margin-left: auto;
  margin-right: auto;
}
</style>
