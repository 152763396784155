<template>
  <EnWordCardLayout
    :has-footer="data.checked && props.face.interaction !== Interaction.Flash"
    :hide-role-image="props.face.style.hideRoleImage"
  >
    <template #content>
      <div class="text-21px leading-normal font-normal tracking-0.5px">
        <span
          v-for="(span, i) of questionSpans"
          :key="i"
          :class="{
            'text-primary font-semibold': span.highlight,
          }"
        >
          {{ span.text }}
        </span>
      </div>
    </template>
    <template #options>
      <div class="flex flex-col gap-3">
        <Button
          v-for="(item, i) of options"
          :key="item.word"
          class="option"
          :class="{ wrong: optionsWrongMap[i] }"
          :scene="getOptionScene(item, i)"
          @click="onOptionClick(item, i)"
        >
          <SparkleText
            class="flex flex-col justify-center items-start w-full text-start"
            :tag="item.isAnswer && data.checked"
          >
            <template v-if="optionsWrongMap[i]">
              <div class="text-lg font-semibold leading-26px">
                {{ item.word }}
              </div>
              <div
                class="text-[var(--text-color-secondary)] text-sm leading-22px"
              >
                {{ item.definition }}
              </div>
            </template>
            <div v-else>
              {{ item.definition }}
            </div>
          </SparkleText>
        </Button>
      </div>
    </template>
    <template #footer>
      <BottomFeedback
        v-if="data.checked"
        v-show="props.face.interaction !== Interaction.Flash"
        :star="data.star"
        @next="onNext?.()"
      ></BottomFeedback>
    </template>
  </EnWordCardLayout>
</template>
<script setup lang="ts">
import type { ExampleChoiceEnWordFace } from '@/types/core'
import { Interaction } from '@/types/core'
import { getHighlightSpans, randomPick } from '@/utils'
import { reactive, computed, onMounted, inject } from 'vue'
import { playPron, genOptions, type Option } from '../common'
import { UnitEventType } from '@/api/learn'
import { useHotKey } from '@/hooks'
import Tip from '../../common/Tip.vue'
import { feedbackStar } from '../../common/feedback'
import BottomFeedback from '../../common/BottomFeedback.vue'
import SparkleText from '@/components/SparkleText.vue'
import EnWordCardLayout from '../../layout/EnWordCardLayout.vue'

useHotKey('enter,space', () => {
  if (data.checked) {
    onNext?.()
    return
  }
})

useHotKey('1,2,3,4,5,6,7,8,9,0', (evt: KeyboardEvent) => {
  const key = evt.key
  const index = (key === '0' ? 10 : Number(key)) - 1

  if (index >= options.value.length) return

  const op = options.value[index]
  onOptionClick(op, index)
})

const onNext = inject<VoidFunction>('onNext')
const onEvent = inject<(event: UnitEventType) => void>('onEvent')
const onStar = inject<(star: feedbackStar) => void>('onStar')

const props = defineProps<{
  face: ExampleChoiceEnWordFace
}>()

const data = reactive({
  checked: false,
  tipTimes: 0,
  star: feedbackStar.One,
  selectedWrongOptions: new Set<number>(),
})

const options = computed(() => genOptions(props.face.card, props.face.altCards))

const optionsWrongMap = computed(() => {
  const map: Record<number, boolean> = {}

  for (let i = 0; i < options.value.length; i++) {
    map[i] = data.selectedWrongOptions.has(i)
  }

  return map
})

const questionExample = computed(() => {
  return randomPick(props.face.card.examples)
})

const questionSpans = computed(() => {
  if (!questionExample.value) return []

  return getHighlightSpans(questionExample.value.source, props.face.card.word)
})

onMounted(async () => {
  await playPron(props.face.card.word)
  playPron(questionExample.value.source)
})

function getOptionScene(
  op: Option,
  index: number
):
  | 'choice'
  | 'choiceSelected'
  | 'choiceCorrect'
  | 'choiceWrong'
  | 'choiceUsed' {
  let wrong = optionsWrongMap.value[index]
  let correct = op.isAnswer && data.checked

  if (correct) {
    return 'choiceCorrect'
  }
  if (wrong) {
    return 'choiceWrong'
  }

  return 'choice'
}

async function onOptionClick(option: Option, index: number) {
  if (data.checked) return

  if (option.isAnswer) {
    data.checked = true
    onEvent?.(UnitEventType.CORRECT)
  } else {
    data.tipTimes++
    data.selectedWrongOptions.add(index)
    onEvent?.(UnitEventType.WRONG)
  }

  data.star = data.tipTimes > 0 ? feedbackStar.Two : feedbackStar.Three

  if (data.checked) {
    onStar?.(data.star)
  }

  // TODO(buding): 这里不光是要等待音频播放完成，还需要等待按钮动画（同 Cloze 卡面）
  // 其他英语卡面都需要处理
  await playPron(option.word)
  if (props.face.interaction === Interaction.Flash && data.checked) {
    onNext?.()
  }
}
</script>

<style scoped>
.option {
  min-height: 66px;
  font-size: 19px;
  line-height: 28.5px;
  position: relative;
}
.option.wrong {
  animation: shake 0.2s linear 0.2s;
}
</style>
