<template>
  <div ref="textRef">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { getTextLineCount } from '@/utils'
import { onMounted, onUnmounted, ref } from 'vue'

const props = defineProps<{
  fontSizeList: number[]
}>()

const textRef = ref(null)
// 根据文字行数使用不同的字体大小 index+1 对应行数
function adjustFontSizeForLine(textElement: any) {
  if (!textElement) return

  const fontSizeList = props.fontSizeList
  for (let i = 0; i < fontSizeList.length; i++) {
    textElement.style.fontSize = `${fontSizeList[i]}px`
    const lines = getTextLineCount(textElement)
    if (lines <= i + 1) {
      break
    }
  }
}

function autoLayout() {
  adjustFontSizeForLine(textRef.value)
}

onMounted(() => {
  autoLayout()
  // 监听窗口大小变化
  window.addEventListener('resize', autoLayout)
})
onUnmounted(() => {
  window.removeEventListener('resize', autoLayout)
})
</script>

<style scoped></style>
