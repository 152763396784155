import { mergeAttributes, Node } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    h1: {
      setH1: () => ReturnType
      toggleH1: () => ReturnType
    }
    h2: {
      setH2: () => ReturnType
      toggleH2: () => ReturnType
    }
    h3: {
      setH3: () => ReturnType
      toggleH3: () => ReturnType
    }
  }
}

function genHeading(level: 1 | 2 | 3) {
  return Node.create({
    name: 'h' + level,

    content: 'inline*',

    group: 'block',

    defining: true,

    parseHTML() {
      return [
        {
          tag: 'h' + level,
        },
      ]
    },

    renderHTML({ HTMLAttributes }) {
      return [
        `h${level}`,
        mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
        0,
      ]
    },

    addCommands() {
      return {
        ['setH' + level]:
          () =>
          ({ commands }) => {
            return commands.setNode(this.name)
          },

        ['toggleH' + level]:
          () =>
          ({ commands }) => {
            return commands.toggleNode(this.name, 'p')
          },
      }
    },

    addKeyboardShortcuts() {
      return {
        [`Mod-Alt-${level}`]: () => this.editor.commands['toggleH' + level](),
      }
    },
  })
}

export const H1 = genHeading(1)

export const H2 = genHeading(2)

export const H3 = genHeading(3)
