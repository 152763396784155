<template>
  <EnergyBg>
    {{ props.count }}
  </EnergyBg>
</template>
<script setup lang="ts">
import EnergyBg from './EnergyBg.vue'

const props = defineProps<{
  count: number
}>()
</script>
<style scoped></style>
