import type { AxiosRequestConfig } from 'axios'
import api from './base'

import type { CommonResponse } from './base'

export type VCODE_SCENE = 'REGISTER' | 'RESET_PWD' | 'BIND_EMAIL'

export function sendVcode(
  email: string,
  scene: VCODE_SCENE,
  captchaToken: string
) {
  return api.post<unknown, CommonResponse<{ freezeSeconds: number }>>(
    '/v1/auth/vcode/email',
    {
      email,
      scene,
      captchaToken,
    }
  )
}

export function resetPwd(payload: RegisterPayload) {
  return api.post<unknown, CommonResponse<never>>(
    '/v1/auth/reset-pwd/email',
    payload
  )
}

type RegisterPayload = {
  email: string
  code: string
  password: string
}
export type UserInfo = {
  id: string
  nickname: string
  avatar?: string
  email?: string
  phone?: string
  phoneCountry?: string
  createdAt: string
  admin: boolean
  hasBoundWechat: boolean
  wechatInfo?: {
    nickname: string
  }
  officialAccount: boolean
  claimedGift?: boolean
}
export function register(payload: RegisterPayload) {
  return api.post<unknown, CommonResponse<{ token: string; user: UserInfo }>>(
    '/v1/auth/register/email',
    payload
  )
}

export function login(payload: { email: string; password: string }) {
  return api.post<unknown, CommonResponse<{ token: string; user: UserInfo }>>(
    '/v1/auth/login',
    payload
  )
}

// token 无效时后端会返回 401
// 自动登录时需要忽略 401，不希望走 global handler
export function getCurrentUser(ignoreError = false) {
  const config: AxiosRequestConfig = {}
  if (ignoreError) {
    config.validateStatus = null
  }
  return api.get<unknown, { user: UserInfo }>('/v1/users/current', config)
}

export function wxLogin(payload: {
  code: string
  wechatType?: 'WEB' | 'APP' | 'MP' // default to "WEB" in backend
}) {
  return api.post<
    unknown,
    CommonResponse<{
      loginResponse: { token: string; user: UserInfo }
      wxToken: string
      wxOpenId: string
    }>
  >('/v1/auth/wechat/login', payload)
}

export function wxRegister(payload: { wxToken: string }) {
  return api.post<unknown, CommonResponse<{ token: string; user: UserInfo }>>(
    '/v1/auth/wechat/register',
    payload
  )
}

export function wxBind(payload: {
  email: string
  password: string
  wxToken: string
}) {
  return api.post<unknown, CommonResponse<{ token: string; user: UserInfo }>>(
    '/v1/auth/email/bind_wechat',
    payload
  )
}
