<template>
  <div v-if="hasError">卡片解析失败</div>

  <div
    v-else
    class="flex flex-col relative h-full p-4"
  >
    <ClozeCardPreview
      v-if="props.card.type === CardType.CLOZE"
      :card="props.card"
    />
    <WordCardPreview
      v-if="props.card.type === CardType.EN_WORD"
      :card="props.card"
    />
  </div>
</template>

<script lang="ts" setup>
import { CardType } from '@/types/core'
import ClozeCardPreview from './ClozeCard.vue'
import WordCardPreview from './WordCard.vue'
import { onErrorCaptured, ref } from 'vue'

import type { Card } from '@/types/core'

const props = defineProps<{
  card: Card
}>()

const hasError = ref(false)

onErrorCaptured(err => {
  hasError.value = true
  console.error(err)

  return false
})
</script>

<style scoped></style>
