<template>
  <div class="h-full w-full flex flex-col flex-1 overflow-hidden items-center">
    <div
      class="flex-1 min-h-0 g-body-width overflow-y-auto card-container pt-16px"
      :class="[
        hasFooter ? 'pb-16px' : 'pb-72px',
        {
          'justify-between': viewSize.width <= 700,
        },
      ]"
    >
      <div class="p-16px pt-40px">
        <RoleImage
          v-if="!hideRoleImage"
          class="mx-auto"
        />
        <div
          class="flex flex-col items-center justify-center question-text-background"
        >
          <slot name="content" />
        </div>
      </div>
      <div class="p-4">
        <slot name="options" />
      </div>
    </div>
    <div class="w-full">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
import RoleImage from '../common/RoleImage.vue'

defineProps<{
  hasFooter: boolean
  hideRoleImage: boolean
}>()

const viewSize = window._viewSize
</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 16px;
}

.question-text-background {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 2px 0px var(--slate-300);
  padding: 16px;
}
</style>
