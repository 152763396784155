<template>
  <div
    class="flex items-center bg-ld-brand-200 p-3 justify-between cursor-pointer"
    @click="onPackageUnlock"
  >
    <div class="text-ld-brand-600 text-15px">
      {{ _t('卡包试学中，仅限浏览') }}
    </div>

    <Button
      v-if="isPcMode"
      label="解锁完整版"
      class="h-50px"
    ></Button>

    <Button
      v-else
      class="h-32px"
    >
      <div class="text-13px text-center flex-1">{{ _t('解锁完整版') }}</div>
    </Button>
  </div>
</template>
<script setup lang="ts">
import type { PackageAccess, PackageBasic } from '@/api/package-source'
import PackageUnlock from './PackageUnlock.vue'

const isPcMode = _global.isPcMode

const props = defineProps<{
  package: PackageBasic
}>()

const emit = defineEmits<{
  unlock: [PackageAccess]
}>()

function onPackageUnlock() {
  _presentContent(PackageUnlock, {
    rootClass: 'max-h-600px min-w-400px max-w-600px',
    props: {
      package: props.package,
      onUnlock: (access: PackageAccess) => emit('unlock', access),
    },
  })
}
</script>
<style scoped></style>
