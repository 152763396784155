import { getMarkAttributes, Mark, mergeAttributes } from '@tiptap/core'
import { Extension } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    textStyle: {
      removeEmptyTextStyle: () => ReturnType
      unsetTextStyle: () => ReturnType
    }
  }
}

const TextStyle = Mark.create({
  name: 'textStyle',

  addOptions() {
    return {
      HTMLAttributes: {},
    }
  },

  parseHTML() {
    return [
      {
        tag: 'span',
        getAttrs: element => {
          const hasStyles = (element as HTMLElement).hasAttribute('style')

          if (!hasStyles) {
            return false
          }

          return {}
        },
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ]
  },

  addCommands() {
    return {
      removeEmptyTextStyle:
        () =>
        ({ state, commands }) => {
          const attributes = getMarkAttributes(state, this.type)
          const hasStyles = Object.entries(attributes).some(
            ([, value]) => !!value
          )

          if (hasStyles) {
            return true
          }

          return commands.unsetMark(this.name)
        },
      unsetTextStyle:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name)
        },
    }
  },
})

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    color: {
      setColor: (color: string) => ReturnType
      toggleColor: (color: string) => ReturnType
      unsetColor: () => ReturnType
    }
  }
}

export const Color = Extension.create({
  name: 'color',

  addGlobalAttributes() {
    return [
      {
        types: ['textStyle'],
        attributes: {
          color: {
            default: undefined,
            parseHTML: element => {
              const color = element.style.color?.replace(/['"]+/g, '')
              return color === '' ? undefined : color
            },
            renderHTML: attributes => {
              if (!attributes.color) {
                return {}
              }

              return {
                style: `color: ${attributes.color}`,
              }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setColor:
        color =>
        ({ chain }) => {
          return chain().setMark('textStyle', { color }).run()
        },
      toggleColor:
        color =>
        ({ editor, commands }) => {
          if (editor.isActive('textStyle', { color })) {
            return commands.unsetColor()
          } else {
            return commands.setColor(color)
          }
        },
      unsetColor:
        () =>
        ({ chain }) => {
          return chain()
            .setMark('textStyle', { color: null })
            .removeEmptyTextStyle()
            .run()
        },
    }
  },
})

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    bgColor: {
      setBgColor: (bgColor: string) => ReturnType
      toggleBgColor: (bgColor: string) => ReturnType
      unsetBgColor: () => ReturnType
    }
  }
}
export const BgColor = Extension.create({
  name: 'bgColor',

  addGlobalAttributes() {
    return [
      {
        types: ['textStyle'],
        attributes: {
          bgColor: {
            default: undefined,
            parseHTML: element => {
              const bgColor = element.style.backgroundColor?.replace(
                /['"]+/g,
                ''
              )
              return bgColor === '' ? undefined : bgColor
            },
            renderHTML: attributes => {
              if (!attributes.bgColor) {
                return {}
              }

              return {
                style: `background-color: ${attributes.bgColor}`,
              }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setBgColor:
        bgColor =>
        ({ chain }) => {
          return chain().setMark('textStyle', { bgColor }).run()
        },
      toggleBgColor:
        bgColor =>
        ({ editor, commands }) => {
          if (editor.isActive('textStyle', { bgColor })) {
            return commands.unsetBgColor()
          } else {
            return commands.setBgColor(bgColor)
          }
        },
      unsetBgColor:
        () =>
        ({ chain }) => {
          return chain()
            .setMark('textStyle', { bgColor: null })
            .removeEmptyTextStyle()
            .run()
        },
    }
  },
})

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    dot: {
      setDot: () => ReturnType
      toggleDot: () => ReturnType
      unsetDot: () => ReturnType
    }
  }
}

export const DotText = Extension.create({
  name: 'dot',

  addGlobalAttributes() {
    return [
      {
        types: ['textStyle'],
        attributes: {
          dot: {
            default: false,
            parseHTML: element => {
              return element.classList.contains('dot')
            },
            renderHTML: attributes => {
              return {
                class: attributes.dot ? 'dot' : '',
              }
            },
          },
        },
      },
    ]
  },

  addCommands() {
    return {
      setDot:
        () =>
        ({ chain }) => {
          return chain().setMark('textStyle', { dot: true }).run()
        },
      toggleDot:
        () =>
        ({ editor, commands }) => {
          if (editor.isActive('textStyle', { dot: true })) {
            return commands.unsetDot()
          } else {
            return commands.setDot()
          }
        },
      unsetDot:
        () =>
        ({ chain }) => {
          return chain()
            .setMark('textStyle', { dot: undefined })
            .removeEmptyTextStyle()
            .run()
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-e': () => this.editor.commands.toggleDot(),
      'Mod-E': () => this.editor.commands.toggleDot(),
    }
  },
})

export default TextStyle
