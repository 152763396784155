<template>
  <JsonViewer
    :value="value"
    expanded
    :expand-depth="10"
  ></JsonViewer>
</template>

<script lang="ts" setup>
import type { CardResponse } from '@/api/package-source'
import { JsonViewer } from 'vue3-json-viewer'

const props = defineProps<{ card: CardResponse }>()

const value = { ...props.card }
value.content = JSON.parse(value.content)
</script>

<style scoped></style>
