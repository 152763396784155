<template>
  <span>
    <span
      v-for="text in texts"
      :class="text.className"
    >
      {{ text.text }}
    </span>
  </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

import type { Text } from '@/types/core'
import type { TextStyle } from '@/types/model'

const props = withDefaults(
  defineProps<{
    node: Text
    showDotStyle?: boolean
  }>(),
  {
    showDotStyle: true,
  }
)

const texts = computed(() => {
  if (props.node.style?.dot && props.showDotStyle) {
    const chars = [...props.node.text]
    return chars.map(char => {
      return {
        text: char,
        className: getClassName(props.node.style),
      }
    })
  }
  return [
    {
      text: props.node.text,
      className: getClassName(props.node.style),
    },
  ]
})

function getClassName(style?: TextStyle) {
  const classLists: string[] = ['text']

  if (style?.dot) {
    classLists.push('dot')
  }

  return classLists.join(' ')
}
</script>

<style scoped>
.text {
  white-space: pre-line;
  position: relative;
}

.dot:after {
  content: '';
  display: block;
  position: absolute;
  width: 4px;
  height: 4px;
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  background-color: black;
}
</style>
