<template>
  <div>
    <LoginForm
      v-if="scene === 'login'"
      @resetPwd="onLoginResetPwd"
      @done="emit('done')"
    />

    <ResetPwdForm
      v-if="scene === 'resetPwd'"
      @login="scene = 'login'"
      @done="onResetPwdDone"
    />
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue'
import ResetPwdForm from './ResetPwdForm.vue'
import LoginForm from './LoginForm.vue'

export type Scene = 'login' | 'resetPwd'

const emit = defineEmits<{
  done: []
}>()

const scene = ref<Scene>('login')

// 当为 true 时从「重置密码」返回时进入「账号绑定」
const backToWxBind = ref(false)

function onLoginResetPwd() {
  scene.value = 'resetPwd'
  backToWxBind.value = false
}

function onResetPwdDone() {
  scene.value = 'login'
}
</script>

<style scoped></style>
