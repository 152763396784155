import api, { type CommonResponse } from './base'

export function fetchVipProducts() {
  return api.get<
    never,
    {
      productList: VipSku[]
    }
  >('/v1/products/vip')
}

export interface VipSku {
  id: number
  name: string
  price: number
  originPrice?: number
  pricePerMonth: number
  period: number
  periodType: 'DAY' | 'MONTH'
  currency: 'CNY'
  campaignName?: string
  defaultSelected: boolean
  shortName: string
}

export interface DiamondCardSku {
  id: number
  immediateDiamonds: number
  diamondsPerDay: number
  price: number
  currency: 'CNY'
}

export interface DiamondSku {
  id: number
  amount: number
  price: number
  currency: 'CNY'
}

export type StoreConfig = {
  vipPrivilegeResponse: {
    addEnergyLimit: number
    freeEnergyPerDay: number
  }
  vipList: VipSku[]
  diamondList: DiamondSku[]
  diamondCard: DiamondCardSku
  alphaGift?: {
    diamond: number
  }
}

export async function fetchStoreProducs(): Promise<StoreConfig> {
  return api.get<never, StoreConfig>('/v1/products/store')
}

export type DiamondCardResponse = {
  receivedToday: boolean
  diamondPerDay: number
  immediateDiamonds: number

  // date
  nextRefreshAt: string
}

export async function fetchDiamondCard() {
  return api.get<never, { status: DiamondCardResponse }>(
    '/v1/diamonds/diamond-card'
  )
}

export async function receiveDiamondCardDailyDiamonds() {
  return api.post<never, CommonResponse<void>>(
    '/v1/diamonds/diamond-card/claim'
  )
}
