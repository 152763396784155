<template>
  <div
    class="inline-flex whitespace-pre-line items-stretch relative"
    :class="{
      'button-content': !disabled && !loading,
    }"
    @click="onDisabledClick"
  >
    <Button
      class="flex-1"
      :class="{
        'p-button-option': option,
        'p-button-used': used,
        'p-button-primary': computedSeverity === 'primary',
        'p-button-basic': !outlined,
        'p-button-custom': customColor,
        'p-button-vip': vip,
        'g-premium-bg': vip,
      }"
      :label="props.label"
      :loading="props.loading"
      :severity="computedSeverity"
      :outlined="outlined"
      :size="props.size === 'normal' ? undefined : props.size"
      :disabled="props.disabled"
      :icon="props.icon"
      :style="{
        '--custom-color': customColor,
      }"
      @click="onClick"
    >
      <slot></slot>
    </Button>
    <template v-if="vip">
      <LottieBox
        name="shine"
        class="absolute left-0 aspect-square h-full pointer-events-none"
        autoplay
        loop
      />
      <LottieBox
        name="shine"
        class="absolute right-0 aspect-square h-full pointer-events-none"
        autoplay
        loop
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import Button from 'primevue/button'
import { computed } from 'vue'
import LottieBox from './LottieBox.vue'

export type ButtonScene =
  | 'primary'
  | 'secondary'
  | 'correct'
  | 'wrong'
  | 'danger'
  | 'choice'
  | 'choiceSelected'
  | 'choiceCorrect'
  | 'choiceWrong'
  | 'choiceUsed'
  | 'wechat'
  | 'invite'
  | 'vip'

const props = withDefaults(
  defineProps<{
    // slot 优先，slot 没有的情况下使用 label
    label?: string

    loading?: boolean

    disabled?: boolean

    // 按钮 disabled 时仍然可以点击，toast 提示如下消息
    disabledMsg?: string

    scene?: ButtonScene

    size?: 'small' | 'large' | 'normal'

    // primevue Icon name
    icon?: string
  }>(),
  {
    label: '',
    loading: false,
    disabled: false,
    scene: 'primary',
    size: 'normal',
    icon: '',
    disabledMsg: '',
  }
)

const computedButtonStyle = computed(() => {
  switch (props.scene) {
    case 'primary':
    case 'correct':
    case 'wrong':
    case 'invite':
    case 'wechat':
    case 'vip':
      return 'basic'
    case 'secondary':
    case 'danger':
      return 'outlined'
    case 'choice':
    case 'choiceSelected':
    case 'choiceCorrect':
    case 'choiceWrong':
    case 'choiceUsed':
      return 'option'
    default:
      return 'basic'
  }
})
const computedSeverity = computed(() => {
  switch (props.scene) {
    case 'primary':
      return 'primary'
    case 'correct':
      return 'success'
    case 'wrong':
      return 'danger'
    case 'secondary':
      return 'secondary'
    case 'danger':
      return 'danger'
    case 'choice':
      return 'primary'
    case 'choiceSelected':
      return 'secondary'
    case 'choiceCorrect':
      return 'success'
    case 'choiceWrong':
      return 'danger'
    case 'choiceUsed':
      return 'primary'
    case 'invite':
      return 'primary'
    case 'wechat':
      return 'primary'
    case 'vip':
      return 'primary'
    default:
      return 'primary'
  }
})
const customColor = computed(() => {
  if (props.scene === 'invite') {
    return 'var(--cyan-600)'
  }
  if (props.scene === 'wechat') {
    return 'var(--ld-wechat)'
  }
  return ''
})

const outlined = computed(() => computedButtonStyle.value === 'outlined')
const option = computed(() => computedButtonStyle.value === 'option')
const used = computed(() => props.scene === 'choiceUsed')
const vip = computed(() => props.scene === 'vip')

const emit = defineEmits<{
  click: [any]
}>()

function onClick(evt: any) {
  emit('click', evt)
}

function onDisabledClick() {
  if (props.disabled && props.disabledMsg) {
    _message.info(props.disabledMsg)
  }
}
</script>

<style scoped>
.button-content:active {
  padding-top: 3px;
}

/* 默认 */
.p-button.p-button-basic,
.p-button.p-button-outlined,
.p-button.p-button-option {
  border-radius: 8px;
}

.p-button.p-button-basic {
  background-color: var(--ld-brand-500);
  border: 1px solid var(--ld-brand-500);
  border-bottom: 4px solid rgb(from var(--ld-neutral-950) r g b / 28%);
}
.p-button.p-button-basic:active {
  border-bottom: 1px solid rgb(from var(--ld-neutral-950) r g b / 28%);
}

.p-button.p-button-outlined {
  color: var(--ld-brand-500);
  background-color: white;
  border: 1px solid var(--ld-neutral-200);
  border-bottom: 4px solid var(--ld-neutral-200);
}
.p-button.p-button-outlined:active {
  border-bottom: 1px solid var(--ld-neutral-200);
}

.p-button.p-button-option {
  color: var(--ld-neutral-600);
  background-color: white;
  border: 1px solid var(--ld-neutral-200);
  border-bottom: 4px solid var(--ld-neutral-200);
}
.p-button.p-button-option:active {
  border-bottom: 1px solid var(--ld-neutral-200);
}

/* secondary */
.p-button.p-button-secondary.p-button-basic {
  background-color: white;
  border-top-color: var(--ld-neutral-200);
  border-left-color: var(--ld-neutral-200);
  border-right-color: var(--ld-neutral-200);
}

.p-button.p-button-secondary:not(.p-button-link) {
  color: var(--text-color-secondary);
}

.p-button.p-button-secondary.p-button-option {
  background-color: var(--ld-brand-50);
  color: var(--ld-neutral-600);
  border: 1px solid var(--ld-brand-400);
  border-bottom: 4px solid var(--ld-brand-400);
}
.p-button.p-button-secondary.p-button-option:active {
  border-bottom: 1px solid var(--ld-brand-400);
}

/* warning */
.p-button.p-button-warning.p-button-basic {
  background-color: var(--yellow-500);
  border-top-color: var(--yellow-500);
  border-left-color: var(--yellow-500);
  border-right-color: var(--yellow-500);
}

.p-button.p-button-warning.p-button-outlined {
  color: var(--yellow-400);
}

.p-button.p-button-warning.p-button-option {
  background-color: var(--yellow-50);
  border: 1px solid var(--yellow-400);
  border-bottom: 4px solid var(--yellow-400);
}
.p-button.p-button-warning.p-button-option:active {
  border-bottom: 1px solid var(--yellow-400);
}
/* success */
.p-button.p-button-success.p-button-basic {
  background-color: var(--green-500);
  border-top-color: var(--green-500);
  border-left-color: var(--green-500);
  border-right-color: var(--green-500);
}

.p-button.p-button-success.p-button-outlined {
  color: var(--green-400);
}

.p-button.p-button-success.p-button-option {
  background-color: var(--green-50);
  border: 1px solid var(--green-400);
  border-bottom: 4px solid var(--green-400);
}
.p-button.p-button-success.p-button-option:active {
  border-bottom: 1px solid var(--green-400);
}
/* danger */
.p-button.p-button-danger.p-button-basic {
  background-color: var(--red-500);
  border-top-color: var(--red-500);
  border-left-color: var(--red-500);
  border-right-color: var(--red-500);
}

.p-button.p-button-danger.p-button-outlined {
  color: var(--red-400);
}

.p-button.p-button-danger.p-button-option {
  background-color: var(--red-50);
  border: 1px solid var(--red-400);
  border-bottom: 4px solid var(--red-400);
}
.p-button.p-button-danger.p-button-option:active {
  border-bottom: 1px solid var(--red-400);
}

/* used */
.p-button.p-button-used.p-button-option {
  color: transparent;
  background-color: var(--ld-neutral-200);
  border: 1px solid var(--ld-neutral-200);
  border-bottom: 4px solid var(--ld-neutral-200);
}
.p-button.p-button-used.p-button-option:active {
  border-bottom: 1px solid var(--ld-neutral-200);
}
/* custom */
.p-button.p-button-custom.p-button-basic {
  background-color: var(--custom-color);
  border-top-color: var(--custom-color);
  border-left-color: var(--custom-color);
  border-right-color: var(--custom-color);
}
.p-button.p-button-custom.p-button-outlined {
  color: var(--custom-color);
}

/* vip */
.p-button.p-button-vip.p-button-basic {
  color: var(--ld-premium-800);
  font-size: 17px;
  font-weight: 600;
  background-repeat: no-repeat;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
  border: 1px solid var(--ld-premium-400);
  border-bottom-width: 4px;
  border-bottom-color: var(--yellow-600);
}
/* large */
.p-button.p-button-lg {
  font-size: 21px;
}
</style>
