<template>
  <div class="flex my-6 items-center h-39px overflow-y-hidden">
    <a
      href="/"
      class="hstack-1"
    >
      <Icon
        name="logo"
        class="w-36px h-36px"
      />
      <span class="text-32px font-bold ml-2">
        {{ $t('brand') }}
      </span>
    </a>

    <span class="ml-auto text-28px font-extrabold">
      {{ props.title }}
    </span>
  </div>
</template>

<script lang="ts" setup>
const props = defineProps<{
  title: string
}>()
</script>

<style scoped></style>
