<template>
  <Loading v-if="loading" />

  <template v-else-if="!snapshotPackage">snapshot package not found</template>

  <template v-else-if="!latestPackage">package not found</template>

  <template v-else>
    <div v-if="nameChanged">
      卡包名称变更：{{ snapshotPackage.name }} {{ '->' }}
      {{ latestPackage.name }}
    </div>

    <div v-if="contentTagChanged">
      标签变更：{{ snapshotTagName }} {{ '->' }}
      {{ latestPackageTag.name }}
    </div>

    <div v-if="descriptionChanged">
      简介：{{ snapshotPackage.description }} {{ '->' }}
      {{ props.description }}
    </div>

    <div
      v-if="styleChanged"
      class="flex items-center my-2"
    >
      图标变更：

      <div
        class="w-66px h-66px rounded-4 text-60px flex justify-center items-center"
        :style="{
          backgroundColor: `var(--${snapshotPackage.style.themeColor}-200)`,
        }"
      >
        <PkgIcon
          :style="snapshotPackage.style"
          class="w-44px h-44px"
        />
      </div>

      <span class="mx-4">{{ '->' }}</span>

      <div
        class="w-66px h-66px rounded-4 text-60px flex justify-center items-center"
        :style="{
          backgroundColor: `var(--${latestPackage.style.themeColor}-200)`,
        }"
      >
        <PkgIcon
          :style="latestPackage.style"
          class="w-44px h-44px"
        />
      </div>
    </div>

    <DataTable
      :value="diff"
      tableStyle="min-width: 50rem"
    >
      <Column
        field="cardAdd"
        header="新增卡片"
      ></Column>
      <Column
        field="cardUpdate"
        header="修改卡片"
      ></Column>
      <Column
        field="cardDelete"
        header="删除卡片"
      ></Column>
      <Column
        field="chapterAdd"
        header="新增章节"
      ></Column>
      <Column
        field="chapterUpdate"
        header="修改章节"
      ></Column>
      <Column
        field="chapterDelete"
        header="删除章节"
      ></Column>
    </DataTable>

    <Button
      class="w-[240px] mt-4 ml-auto"
      label="发布更新"
      :loading="publishLoading"
      @click="publish"
    ></Button>
  </template>
</template>
<script setup lang="ts">
import {
  fetchPackageById,
  fetchPackageSnapshot,
  releasePackage,
} from '@/api/package-source'
import { computed, ref } from 'vue'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import { diffPackage } from '@/utils/package'

import type { Package, PackageBasic } from '@/api/package-source'
import PkgIcon from '@/pages/Atlas/PkgIcon.vue'

const props = defineProps<{
  contentTags: {
    key: string
    name: string
    rootKey: string
  }[]
  description: string
  packageId: number
}>()

const emit = defineEmits<{
  release: [PackageBasic]
  done: []
}>()

const loading = ref(false)
const publishLoading = ref(false)

const latestPackage = ref<Package>()
const snapshotPackage = ref<Package>()

const latestPackageTag = computed(() => props.contentTags[0])
const snapshotPackageTag = computed(() => snapshotPackage.value?.contentTags[0])

const snapshotTagName = computed(() => {
  if (snapshotPackageTag.value == null) return ''

  const { tag, parentTag } = snapshotPackageTag.value

  const showParentName = parentTag.key !== 'main'

  return showParentName ? `${parentTag.name}/${tag.name}` : tag.name
})

const nameChanged = computed(
  () => latestPackage.value?.name !== snapshotPackage.value?.name
)

const contentTagChanged = computed(
  () => latestPackageTag.value?.key !== snapshotPackageTag.value?.tag.key
)

const styleChanged = computed(() => {
  const isSameIcon =
    latestPackage.value?.style.icon.type ===
      snapshotPackage.value?.style.icon.type &&
    latestPackage.value?.style.icon.content ===
      snapshotPackage.value?.style.icon.content
  const isSameColor =
    latestPackage.value?.style.themeColor ===
    snapshotPackage.value?.style.themeColor
  return !isSameIcon || !isSameColor
})

const descriptionChanged = computed(() => {
  return props.description !== snapshotPackage.value?.description
})

const diff = computed(() => {
  if (!snapshotPackage.value || !latestPackage.value) return []

  return [diffPackage(latestPackage.value, snapshotPackage.value)]
})

async function fetchPackages() {
  loading.value = true

  try {
    const pkgRes = await fetchPackageById(props.packageId)
    const snapshotPkgRes = await fetchPackageSnapshot(props.packageId)

    latestPackage.value = pkgRes.data
    snapshotPackage.value = snapshotPkgRes.pkg
  } finally {
    loading.value = false
  }
}

fetchPackages()

function publish() {
  publishLoading.value = true

  const contentTags = props.contentTags.map(item => {
    return {
      key: item.key,
      rootKey: item.rootKey,
    }
  })

  releasePackage(props.packageId, {
    tags: contentTags,
    description: props.description,
  })
    .then(res => {
      if (res.code === 0) {
        _message.success('发布成功')

        emit('release', res.data)
        emit('done')
      } else {
        _message.info(res.message)
      }
    })
    .finally(() => {
      publishLoading.value = false
    })
}
</script>
<style scoped></style>
