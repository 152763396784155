function preloadImage(src: string): Promise<void> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.src = src
    img.onload = () => {
      resolve()
    }
    img.onerror = err => {
      reject(err)
    }
  })
}

export function preloadAssetImage(assetId: string): Promise<void> {
  return preloadImage(_global.assetUrl(assetId))
}

const IMGS = import.meta.glob('../assets/images/*', { eager: true })
export function preloadLocalImage(name: string): Promise<void> {
  for (const key in IMGS) {
    if (key.startsWith(`../assets/images/${name}.`)) {
      const src = (IMGS[key] as any).default
      return preloadImage(src)
    }
  }
  return Promise.resolve()
}
