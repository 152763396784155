import type { CardSchedule, UnitEventType } from '@/api/learn'
import {
  Queue,
  type Frame,
  type ConcreteFrame,
  getCardConfigs,
  getAltCards,
  orderFrames,
  type FrameConfig,
} from '../queue'
import {
  type QueueStagesConfig,
  QueueStage as StagesQueueStage,
} from '../StagesQueue'
import {
  type Card,
  Interaction,
  type ConcreteCardFace,
  type ClozeCardFace,
} from '@/types/core'
import { parseCard } from '@/utils/card'
import { randomPickBoss } from '@/utils'

// 队列学习阶段
export enum QueueStage {
  // 基础
  Base = 'Base',

  // 错题
  WrongQuestions = 'WrongQuestions',

  // 完成
  Done = 'Done',
}

export interface FlashQueueState {
  stage: QueueStage
  frames: Frame[]
  totalFrames: number
  activeCardEvents: {
    cardId: number
    event: UnitEventType
  }[]

  correctCardIds: number[]
  wrongCardIds: number[]
}

// 「连刷队列」使用的是和「分阶段队列」一样的配置
export class FlashQueue extends Queue<QueueStagesConfig, FlashQueueState> {
  constructor(cards: CardSchedule[], config: QueueStagesConfig) {
    super(cards, config)

    this.frames = this._genBaseFrames()
    this.totalFrames = this.frames.length
  }

  stage: QueueStage = QueueStage.Base
  totalFrames = 0

  wrongCardIds: Set<number> = new Set()
  correctCardIds: Set<number> = new Set()

  getState(): FlashQueueState {
    return {
      stage: this.stage,
      frames: this.frames,
      totalFrames: this.totalFrames,
      activeCardEvents: this._activeCardEvents,

      wrongCardIds: [...this.wrongCardIds],
      correctCardIds: [...this.correctCardIds],
    }
  }

  setState(state: FlashQueueState) {
    this.stage = state.stage
    this.frames = state.frames
    this.totalFrames = state.totalFrames
    this._activeCardEvents = state.activeCardEvents
    this.correctCardIds = new Set(state.correctCardIds)
    this.wrongCardIds = new Set(state.wrongCardIds)
  }

  private _genBaseFrames(): ConcreteFrame[] {
    const frames: ConcreteFrame[] = []
    const orderedCards = this._orderCards()

    for (const item of orderedCards) {
      const card = JSON.parse(item.content) as Card
      const parseResult = parseCard(card)
      const altCards = getAltCards(this._cards, item.cardId, card)

      if (parseResult.error != null) return frames

      const configs = getCardConfigs(this._config[QueueStage.Base], {
        cardType: card.type,
        status: item.cardStatus,
        random: false,
      })

      for (const config of configs) {
        frames.push({
          type: 'concrete',
          status: item.cardStatus,
          cardId: item.cardId,
          face: {
            type: config.cardFace,
            cardId: item.cardId,
            card: parseResult.card,
            interaction: Interaction.Flash,
            altCards,
            style: {
              ...config.style,
            },
          },
        } as ConcreteFrame)
      }
    }

    return orderFrames(
      frames,
      orderedCards.map(item => item.cardId)
    )
  }

  private _genCardFaces(
    cardIds: number[],
    config: FrameConfig[],
    interaction?: Interaction
  ): ConcreteCardFace[] {
    const faces: ConcreteCardFace[] = []

    for (const id of cardIds) {
      const cardInfo = this._getCardInfo(id)!

      const card = JSON.parse(cardInfo.content) as Card
      const parsedResult = parseCard(card)

      if (parsedResult.error != null) return faces

      const configs = getCardConfigs(config, {
        cardType: card.type,
        status: cardInfo.cardStatus,
        random: false,
      })

      const altCards = getAltCards(this._cards, cardInfo.cardId, card)

      for (const config of configs) {
        faces.push({
          type: config.cardFace,
          cardId: cardInfo.cardId,
          card: parsedResult.card,
          interaction: interaction ?? config.interaction,
          altCards,
          style: {
            ...config.style,
          },
        } as ConcreteCardFace)
      }
    }

    return faces
  }

  private _genBossFrame(): Frame | null {
    const faces: ConcreteCardFace[] = []

    // 这里同时使用了「分阶段队列」中的挑战和错题配置信息
    const challengeConfig = this._config[StagesQueueStage.Challenge]
    const wrongQuestionsConfig = this._config[QueueStage.WrongQuestions]

    faces.push(
      ...this._genCardFaces(
        [...this.correctCardIds],
        challengeConfig,
        Interaction.Flash
      )
    )
    faces.push(
      ...this._genCardFaces(
        [...this.wrongCardIds],
        wrongQuestionsConfig,
        Interaction.Flash
      )
    )

    return {
      type: 'virtual',
      face: {
        type: 'boss',
        faces: faces as ClozeCardFace[],
        name: randomPickBoss(),
      },
    }
  }

  onEvent(cardId: number, event: UnitEventType) {
    this._activeCardEvents.unshift({
      cardId,
      event,
    })
  }

  // 刷完一个怪（frame）
  tick() {
    switch (this.stage) {
      case QueueStage.Done:
        return
      case QueueStage.Base: {
        const frame = this.frames.shift()!

        if (frame.type === 'virtual') return

        // 全对，没有做错过的卡片进入「正确」挑战队列
        if (
          !this._activeCardEvents.some(item => item.event === 'WRONG') &&
          !this.wrongCardIds.has(frame.cardId)
        ) {
          this.correctCardIds.add(frame.cardId)
        }

        // 做错过，则进入「错题」挑战队列
        if (this._activeCardEvents.some(item => item.event === 'WRONG')) {
          this.wrongCardIds.add(frame.cardId)
        }
        break
      }
      case QueueStage.WrongQuestions: {
        this.frames.shift()
        break
      }
    }

    this._activeCardEvents = []
  }

  next() {
    if (this.frames.length > 0) {
      return
    }

    if (this.stage === QueueStage.Base) {
      this.nextToBoss()
      return
    }

    if (this.stage === QueueStage.WrongQuestions) {
      this.stage = QueueStage.Done
    }
  }

  nextToBoss() {
    this.stage = QueueStage.WrongQuestions
    const frame = this._genBossFrame()

    if (frame != null) {
      this.frames.push(frame)
      this.totalFrames = 1
    } else {
      this.stage = QueueStage.Done
    }
  }
}
