<template>
  <div class="digest">
    <ClozeCardDigest
      v-if="props.card.type === CardType.CLOZE"
      :card="props.card"
    />

    <WordCardDigest
      v-if="props.card.type === CardType.EN_WORD"
      :card="props.card"
    />
  </div>
</template>

<script lang="ts" setup>
import { type Card, CardType } from '@/types/core'
import ClozeCardDigest from './ClozeCard.vue'
import WordCardDigest from './WordCard.vue'

const props = defineProps<{
  card: Card
}>()
</script>

<style scoped></style>
