<template>
  <div class="h-full w-full flex flex-col flex-1 overflow-hidden items-center">
    <div
      class="flex-1 min-h-0 g-body-width overflow-y-auto card-container pt-16px"
      :class="[
        hasFooter ? 'pb-16px' : 'pb-72px',
        {
          'justify-between': viewSize.width <= 700,
        },
      ]"
    >
      <div class="p-4"><slot name="content" /></div>
      <div class="p-4">
        <slot name="options" />
      </div>
    </div>
    <div class="g-body-width">
      <slot name="footer" />
    </div>
  </div>
</template>

<script setup lang="ts">
defineProps<{
  hasFooter: boolean
}>()

const viewSize = window._viewSize
</script>

<style scoped>
.card-container {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  gap: 16px;
}
</style>
