import hotkeys from 'hotkeys-js'
import { onMounted, onUnmounted } from 'vue'

export function useHotKey(
  key: string,
  callback: (_e: KeyboardEvent) => void,
  options?: {
    keyup: boolean
  }
) {
  const handler = (e: KeyboardEvent) => {
    e.preventDefault()

    callback(e)
  }

  onMounted(() => {
    options == null ? hotkeys(key, handler) : hotkeys(key, options, handler)
  })

  onUnmounted(() => {
    hotkeys.unbind(key, handler)
  })
}
