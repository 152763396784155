<template>
  <div class="grid grid-cols-7 container p-x-1">
    <div
      v-for="(e, i) in checkInTable()"
      :key="i"
      class="flex flex-col justify-center items-center space-y-2 m-t-2 m-b-4"
    >
      <div
        :class="[
          'week',
          e.checked
            ? 'text-[var(--orange-200)]'
            : 'text-[var(--text-color-secondary)]',
        ]"
      >
        {{ e.week }}
      </div>
      <div class="flex items-center justify-center">
        <div
          class="flex items-end justify-center h-34px w-34px rounded-full bg-gray-200"
        >
          <LottieBox
            v-if="e.checked"
            class="w-45px m-x--5px"
            :autoplay="true"
            :loop="false"
            name="flame1"
            :start="e.today ? 0 : 'end'"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { CheckInStatsItem } from '@/api/user'
import LottieBox from './LottieBox.vue'

defineEmits<{
  done: []
}>()
// 签到数据
const props = defineProps<{
  checkInDates: CheckInStatsItem[]
}>()

const weeks = ['一', '二', '三', '四', '五', '六', '日']

// 本周7日签到表 从周一开始
function checkInTable() {
  const now = new Date()
  const week = now.getDay()

  const start = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - (week == 0 ? 7 : week) + 1
  )

  return Array.from({ length: 7 }, (_, i) => {
    const date = new Date(start)
    date.setDate(start.getDate() + i)
    return {
      date,
      week: weeks[i],
      checked: props.checkInDates[i].type ? true : false,
      today: now.toDateString() === date.toDateString(),
    }
  })
}
</script>
<style scoped>
.week {
  font-size: 12px;
  font-weight: 700;
}
.container {
  border: 2px solid var(--ld-border);
  border-radius: 8px;
}
</style>
