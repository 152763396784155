import { isMacOS, Mark, mergeAttributes } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    strike: {
      setStrike: () => ReturnType
      toggleStrike: () => ReturnType
      unsetStrike: () => ReturnType
    }
  }
}

const Strike = Mark.create({
  name: 'strike',

  parseHTML() {
    return [
      {
        tag: 's',
      },
      {
        tag: 'del',
      },
      {
        tag: 'strike',
      },
      {
        style: 'text-decoration',
        consuming: false,
        getAttrs: style =>
          (style as string).includes('line-through') ? {} : false,
      },
    ]
  },

  renderHTML({ HTMLAttributes }) {
    return [
      's',
      mergeAttributes(this.options.HTMLAttributes, HTMLAttributes),
      0,
    ]
  },

  addCommands() {
    return {
      setStrike:
        () =>
        ({ commands }) => {
          return commands.setMark(this.name)
        },
      toggleStrike:
        () =>
        ({ commands }) => {
          return commands.toggleMark(this.name)
        },
      unsetStrike:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name)
        },
    }
  },

  addKeyboardShortcuts() {
    const shortcuts: Record<string, () => boolean> = {}

    if (isMacOS()) {
      shortcuts['Mod-Shift-s'] = () => this.editor.commands.toggleStrike()
    } else {
      shortcuts['Ctrl-Shift-s'] = () => this.editor.commands.toggleStrike()
    }

    return shortcuts
  },
})

export default Strike
