<template>
  <div class="pad ld-shadow">
    <div
      v-if="studyCount"
      class="text-15px text-[var(--text-color-secondary)] mb-2"
    >
      {{ `已学习 ${studyCount} 次` }}
    </div>
    <div
      v-if="card.type === CardType.CLOZE"
      class="flex items-start gap-2"
      :class="{ 'flex-col': !isPcMode }"
    >
      <div>
        <Content :content="card.content">
          <template #cloze="{ node }">
            <span class="text-primary-color font-bold underline-offset-4">
              {{ node.text }}
            </span>
          </template>
        </Content>
      </div>

      <Image
        v-if="card.illustration"
        preview
        class="illustration shrink-0"
      >
        <template #image>
          <img
            :src="_global.assetUrl(card.illustration)"
            class="h-78px w-78px rounded-4px object-cover"
            @click.stop
          />
        </template>
        <template #preview="slotProps">
          <img
            :src="_global.assetUrl(card.illustration)"
            class="object-cover"
            :style="slotProps.style"
            @click="slotProps.onClick"
          />
        </template>
      </Image>
    </div>
    <div
      v-else-if="card.type === CardType.EN_WORD"
      class="flex items-start gap-2"
    >
      <div
        v-if="isPcMode"
        class="flex flex-1 items-start"
      >
        <div class="w-174px text-21px font-bold">
          {{ card.word }}
        </div>
        <Divider
          v-if="card.definition"
          layout="vertical"
        />
        <span class="flex-1 text-19px">{{ card.definition }}</span>
      </div>
      <div
        v-else
        class="flex flex-col flex-1"
      >
        <div class="text-21px font-bold">
          {{ card.word }}
        </div>
        <span class="flex-1">{{ card.definition }}</span>
      </div>

      <Image
        v-if="card.illustration"
        preview
        class="illustration ml-6 shrink-0"
      >
        <template #image>
          <img
            :src="_global.assetUrl(card.illustration)"
            class="h-78px w-78px rounded-4px object-cover"
            @click.stop
          />
        </template>
        <template #preview="slotProps">
          <img
            :src="_global.assetUrl(card.illustration)"
            class="object-cover"
            :style="slotProps.style"
            @click="slotProps.onClick"
          />
        </template>
      </Image>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { CardResponse } from '@/api/package-source'

import { CardType, type Card } from '@/types/core'
import Image from 'primevue/image'
import { computed } from 'vue'
import Content from '@/components/card-render/Content.vue'

const props = defineProps<{
  cardResponse?: CardResponse
  card?: string
}>()

const card = computed(() => {
  if (props.cardResponse) {
    return JSON.parse(props.cardResponse.content) as Card
  }
  if (props.card) {
    return JSON.parse(props.card) as Card
  }

  return {} as Card
})

// 学习次数
const studyCount = computed<number | null>(() => {
  // TODO: 获取学习次数
  return null
})

const isPcMode = _global.isPcMode
</script>
<style scoped>
.pad {
  border-radius: 8px;
  width: 100%;
  min-height: 44px;
  padding: 12px 16px;
  border: 1px solid var(--ld-border);
  transition: all 0.3s;
  background-color: white;
}

.illustration :deep(.p-image-preview-indicator) {
  opacity: 0;
}
</style>
