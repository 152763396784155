<template>
  <div class="relative">
    <Icon
      name="energy"
      class="w-36px"
    />
    <div
      class="absolute top-0 left-0 w-full h-full flex items-center justify-center"
    >
      <div class="energy-count"><slot /></div>
    </div>
  </div>
</template>
<script setup lang="ts"></script>
<style scoped>
.energy-count {
  flex-wrap: nowrap;
  font-size: 15px;
  color: var(--yellow-900);
  font-weight: 700;
  font-family: DIN;
  line-height: 20px;
  transform: translateX(-3px);
}
</style>
