<template>
  <div class="flex items-center">
    <Icon
      name="diamond"
      class="w-24px"
    />

    <ColorNumber
      :value="diamondAmount"
      color="brand"
      class="ml-1px"
      :font-width="10"
    />
  </div>
</template>
<script setup lang="ts">
import { useCommonStore } from '@/stores/common-store'
import { computed } from 'vue'
import ColorNumber from './ColorNumber.vue'

const store = useCommonStore()

const diamondAmount = computed(() => store.userAssets?.diamond ?? 0)
</script>
<style scoped></style>
