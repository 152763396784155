<template>
  <div class="flex items-center flex-col p-4 gap-3">
    <slot name="icon">
      <Icon
        name="empty"
        class="w-40px"
      />
    </slot>
    <p
      v-if="text"
      class="text-center text-21px leading-none"
    >
      {{ text }}
    </p>
    <p
      v-if="desc"
      class="text-center text-17px leading-none text-[var(--text-color-secondary)]"
    >
      {{ desc }}
    </p>

    <slot></slot>
  </div>
</template>

<script lang="ts">
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    desc: {
      type: String,
      default: '',
    },
  },
}
</script>

<style scoped></style>
