<template>
  <template v-if="data.searchLogs.length > 0">
    <div class="my-4">
      {{ _t('最近搜索') }}
    </div>

    <div class="flex items-center">
      <div class="flex gap-2 flex-1 flex-wrap min-w-0">
        <div
          v-for="keyword of data.searchLogs"
          :key="keyword"
          class="keyword-log shrink-0"
          @click="emit('search', keyword)"
        >
          {{ keyword }}
        </div>
      </div>

      <i
        class="pi pi-trash cursor-pointer p-2"
        @click="onSearchLogsClear"
      ></i>
    </div>
  </template>

  <div class="my-4">
    {{ _t('为你推荐') }}
  </div>

  <RecommendPkgList
    class="gap-2 overflow-auto flex-1"
    @pkgClick="pkg => emit('pkgClick', pkg)"
  />
</template>
<script setup lang="ts">
import {
  clearSearchKeywordLogs,
  fetchSearchKeywordLogs,
} from '@/api/package-source'
import { reactive } from 'vue'

import type { SearchedPackage } from '@/api/package-source'

import RecommendPkgList from '../RecommendPkgList.vue'

const emit = defineEmits<{
  pkgClick: [SearchedPackage]
  search: [string]
}>()

const data = reactive({
  searchLogs: [] as string[],
})

fetchSearchKeywordLogs().then(({ logs }) => {
  data.searchLogs = logs.map(({ keyword }) => keyword)
})

function onSearchLogsClear() {
  data.searchLogs = []

  clearSearchKeywordLogs().then(() => {
    _message.success('搜索历史已清除')
  })
}
</script>
<style scoped>
.keyword-log {
  cursor: pointer;
  color: var(--text-color-secondary);
  background-color: var(--surface-200);
  border-radius: 4px;
  font-size: 13px;
  padding: 4px 8px;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  word-break: break-all;
}
</style>
