<template>
  <div class="flex flex-col">
    <AppBar @back="emit('back')">
      <template
        v-if="!props.showBackBtn"
        #back
      >
        <Icon
          name="logo"
          class="w-20px h-20px"
        />
        <span class="text-ld-brand-500 ml-1">小灵鸭</span>
      </template>

      <template #actions>
        <template v-if="isLoggedIn && packageInfo && !isAuthor">
          <span
            v-if="isDiamondCost"
            class="text-15px text-ld-brand-500 font-semibold leading-none"
          >
            已解锁完整版
          </span>
          <span
            v-else-if="isVipValid"
            class="text-15px text-yellow-800 font-semibold leading-none cursor-pointer"
            @click="onUnlock"
          >
            畅学卡生效中
          </span>

          <div
            v-else
            class="text-15px text-yellow-800 font-semibold leading-none unlock-button cursor-pointer"
            @click="onUnlock"
          >
            解锁完整版
          </div>
        </template>

        <slot name="actions"></slot>
      </template>
    </AppBar>

    <Loading
      v-if="packageLoading"
      class="h-full"
    />
    <div
      v-else-if="packageInfo"
      class="flex-1 overflow-auto w-full px-4 py-2"
    >
      <div class="flex gap-4">
        <PkgIcon
          class="w-66px h-66px rounded-4 text-54px bg-yellow-100"
          :style="packageInfo.style"
        />
        <div class="flex-1 flex flex-col justify-between gap-1">
          <AutoFontSizeByLine
            class="leading-none font-semibold line-clamp-2"
            :font-size-list="[21, 15]"
          >
            {{ packageInfo.title }}
          </AutoFontSizeByLine>
          <div class="flex items-center">
            <Avatar
              :avatar="packageInfo.author.avatar"
              class="shrink-0 w-18px h-18px"
              imgStyle="xs"
            />
            <span
              class="line-clamp-1 ml-1 text-15px text-[var(--surface-600)] font-semibold"
            >
              {{ packageInfo.author.nickname }}
            </span>
            <Divider
              layout="vertical"
              class="mx-3 p-0"
            />
            <span class="text-17px shrink-0 text-[var(--surface-600)]">
              {{ packageInfo.cardCount }} 张卡片
            </span>
          </div>
        </div>
      </div>

      <DebugLabel>
        <div class="flex gap-2 mt-4 items-center">
          <div>
            <span>卡包 ID: {{ packageInfo.id }}</span>
          </div>
          <div>
            <span>作者 ID: {{ packageInfo.author.id }}</span>
          </div>
          <DebugButton
            label="版本记录"
            @click="onSnapshotHistoryShow"
          ></DebugButton>
        </div>
      </DebugLabel>

      <TextClamp
        v-if="packageInfo.description"
        class="text-17px mt-4 text-[var(--surface-600)]"
        auto-resize
        :max-lines="3"
        :text="packageInfo.description"
      >
        <template #after="{ toggle, clamped }">
          <button
            v-if="clamped"
            class="text-blue-500"
            @click="toggle"
          >
            展开
          </button>
        </template>
      </TextClamp>
      <div class="flex flex-col gap-3 mt-6">
        <Loading v-if="previewLoading" />
        <template v-else>
          <CardBrowserPad
            v-for="card in preivewCards"
            :key="card"
            class="overflow-hidden"
            :card="card"
          ></CardBrowserPad>
          <div
            v-if="preivewCards.length < packageInfo.cardCount"
            class="text-ld-brand-500 text-center"
          >
            添加到我的卡包中，可查看全部卡片
          </div>
        </template>
      </div>
    </div>
    <div v-else>404 Package Not found</div>
    <div
      v-if="packageInfo"
      class="p-3 bg-white shadow-[0px_-1px_1px_0px_var(--slate-300)]"
    >
      <Button
        v-if="!isLoggedIn"
        class="w-full"
        label="添加到我的卡包"
        @click="onBookToShelf"
      ></Button>
      <div
        v-else-if="packageLoading"
        class="min-h-50px"
      ></div>
      <div
        v-else-if="isAuthor"
        class="w-full text-center text-17px leading-none text-[var(--text-color-secondary)] min-h-50px flex items-center justify-center"
      >
        这是你自己的卡包
      </div>
      <div
        v-else-if="isAddedShelf"
        class="w-full flex items-center gap-4 justify-center"
      >
        <div
          class="text-ld-brand-500 flex flex-col items-center gap-2 justify-center text-15px leading-none cursor-pointer"
          @click="onClickAdded"
        >
          <Icon
            name="clipboard-check"
            class="w-24px h-24px"
          />
          <span>已添加</span>
        </div>

        <Button
          class="flex-1"
          label="去闯关"
          @click="onPkgChallenge"
        ></Button>
      </div>
      <Button
        v-else
        class="w-full"
        label="添加到我的卡包"
        @click="onBookToShelf"
      ></Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  PackageAccess,
  fetchPreivewCards,
  fetchPublicPackage,
} from '@/api/package-source'
import Divider from 'primevue/divider'
import { computed, ref } from 'vue'
import { useCommonStore } from '@/stores'
import { putToBookShelf, removeShelfPackageById } from '@/api/user'
import type { PublicPackage } from '@/api/package-source'
import AppBar from '@/mobile/components/AppBar.vue'
import CardBrowserPad from '@/components/Package/CardBrowserPad.vue'
import TextClamp from 'vue3-text-clamp'
import AutoFontSizeByLine from '@/components/AutoFontSizeByLine.vue'
import PackageDialog from '@/components/Package/PackageDialog.vue'
import PkgIcon from '@/pages/Atlas/PkgIcon.vue'
import DebugLabel from '@/components/DebugLabel.vue'
import SnapshotHistory from '@/components/Package/SnapshotHistory.vue'
import PackageUnlock from '@/components/PackageUnlock.vue'

const props = defineProps<{
  hashId: string
  showBackBtn: boolean
}>()

const emit = defineEmits<{
  back: []
  challenge: [pkgId: number]
  view: [pkgId: number]
  addToShelf: []
  removeFromShelf: []
  needAuth: []
}>()

const packageInfo = ref<PublicPackage>()
const preivewCards = ref<string[]>([])
const packageLoading = ref(true)
const previewLoading = ref(true)
const shelfAddLoading = ref(false)

const store = useCommonStore()

// 是否是登录状态
const isLoggedIn = computed(() => store.isLoggedIn)

// 是否是作者本人
const isAuthor = computed(() => {
  if (packageInfo.value == null) return false
  return store.user?.id === packageInfo.value.author.id
})
// 是否加入卡包
const isAddedShelf = computed(() => {
  if (packageInfo.value == null) return false
  return packageInfo.value.addedShelf
})

const isDiamondCost = computed(() => {
  return packageInfo.value?.packageAccess === PackageAccess.Unlock
})

const isVipValid = computed(() => {
  return packageInfo.value?.packageAccess === PackageAccess.Vip
})

async function onBookToShelf() {
  if (!packageInfo.value) return

  if (!store.isLoggedIn) {
    emit('needAuth')
    return
  }

  shelfAddLoading.value = true

  putToBookShelf(packageInfo.value.id)
    .then(res => {
      if (res.code === 0) {
        packageInfo.value!.addedShelf = true
        emit('addToShelf')

        _message.success(_t('已加入我的卡包'))
      } else {
        _message.info(res.message)
      }
    })
    .finally(() => {
      shelfAddLoading.value = false
    })
}

function onPkgChallenge() {
  emit('challenge', packageInfo.value!.id)
}

function onUnlock() {
  _presentContent(PackageUnlock, {
    rootClass: 'max-h-600px min-w-400px max-w-600px',
    props: {
      package: {
        ...packageInfo.value,
        name: packageInfo.value?.title,
      },
      onUnlock(access: PackageAccess) {
        packageInfo.value!.packageAccess = access
      },
    },
  })
}

function onRemove() {
  shelfAddLoading.value = true

  removeShelfPackageById(packageInfo.value!.id)
    .then(() => {
      packageInfo.value!.addedShelf = false
      emit('addToShelf')

      _message.info('已从我的卡包中移除')
    })
    .finally(() => {
      shelfAddLoading.value = false
    })
}

// 已添加
function onClickAdded() {
  // 大屏
  const isLargeScreen = _viewSize.value.width > 700
  _openDialog(PackageDialog, {
    rootClass: isLargeScreen ? 'w-full m-x-24px' : 'w-full m-0 rounded-b-0px',
    dialog: {
      showHeader: false,
      position: isLargeScreen ? 'center' : 'bottom',
      pt: {
        content: { class: 'p-4' },
      },
      style: { 'max-width': isLargeScreen ? '500px' : '100%' },
    },
    props: {
      onView: () => emit('view', packageInfo.value!.id),
      onRemove: onRemove,
    },
  })
}

fetchPublicPackage(props.hashId)
  .then(({ pkg }) => {
    packageInfo.value = pkg
  })
  .finally(() => {
    packageLoading.value = false
  })

fetchPreivewCards(props.hashId)
  .then(({ cardResponses: cardResponses }) => {
    preivewCards.value = cardResponses.map(item => item.content)
  })
  .finally(() => {
    previewLoading.value = false
  })

async function onSnapshotHistoryShow() {
  await _openDialog(SnapshotHistory, {
    title: '更新记录',
    props: {
      packageHashId: props.hashId,
    },
    rootClass: 'w-[85vw] h-[80vh] m-4',
    dialog: {
      contentClass: 'p-4',
      closable: true,
    },
  })
}
</script>

<style>
.unlock-button {
  background: linear-gradient(
    -45deg,
    var(--yellow-500) 0,
    var(--yellow-500) 25%,
    var(--yellow-400) 25%,
    var(--yellow-400) 50%,
    var(--yellow-500) 50%,
    var(--yellow-500) 75%,
    var(--yellow-400) 75%,
    var(--yellow-400) 100%
  );
  background-size: 50px 50px;
  border-radius: 16px;
  padding: 8px;
}
</style>
