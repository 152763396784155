<template>
  <div
    ref="cardDiv"
    class="flex flex-col min-h-0 w-full"
  >
    <Boss
      v-if="props.face.type === 'boss'"
      :boss="props.face"
      @event="onEvent"
      @done="emit('done')"
      @close="emit('close')"
      @star="emit('star', $event)"
    />

    <Match
      v-else-if="props.face.type === 'match'"
      :match="props.face"
      @event="onEvent"
    />
  </div>
</template>

<script lang="ts" setup>
import { QuizStage } from '@/types/core'
import { provide, ref } from 'vue'
import { UnitEventType } from '@/api/learn'
import { computed } from 'vue'
import Boss from './Boss.vue'
import Match from './Match.vue'

import type { feedbackStar } from '@/components/ConcreteCard/common/feedback'
import type { VirtualCardFace } from '@/types/core'

const emit = defineEmits<{
  star: [feedbackStar]
  answerChange: [answered: boolean]
  resultChange: [correct: boolean]
  done: []
  close: []
  next: []
  event: [cardId: number | undefined, UnitEventType]
}>()

const props = withDefaults(
  defineProps<{
    face: VirtualCardFace
    quizStage?: QuizStage
  }>(),
  {
    quizStage: undefined,
  }
)

function onEvent(cardId: number | undefined, event: UnitEventType) {
  emit('event', cardId, event)
}

const cardDiv = ref<HTMLDivElement | null>(null)

const isLargeScreen = computed(() => {
  if (cardDiv.value != null) {
    return cardDiv.value.clientWidth > 500
  }

  return false
})

provide('isLargeScreen', isLargeScreen)
provide('onStar', (star: feedbackStar) => emit('star', star))
provide('onNext', () => emit('next'))

// quiz
provide('answerChange', (answered: boolean) => emit('answerChange', answered))
provide('resultChange', (correct: boolean) => emit('resultChange', correct))
</script>

<style scoped></style>
