<template>
  <RatioSpacedContainer
    v-if="packages.length === 0"
    class="h-full"
  >
    <div class="flex flex-col items-center justify-center">
      <Icon name="no-search-result" />
      <div class="text-21px">{{ _t('找不到相关卡包') }}</div>
      <div class="text-17px text-ld-label-secondary">
        {{ _t('请试试调整关键词、重新搜索') }}
      </div>
    </div>
  </RatioSpacedContainer>

  <PkgCardList
    v-else
    :packages="packages"
    class="overflow-auto"
    highlight
    @pkgClick="pkg => emit('pkgClick', pkg)"
  />
</template>
<script setup lang="ts">
import { reactive, computed, watch } from 'vue'

import type { SearchedPackage } from '@/api/package-source'
import RatioSpacedContainer from '../RatioSpacedContainer.vue'

import PkgCardList from './PkgCardList.vue'

const props = defineProps<{
  result: {
    packages: SearchedPackage[]
  }
}>()

const emit = defineEmits<{
  pkgClick: [SearchedPackage]
}>()

const data = reactive({
  activeIndex: 0,
})

const packages = computed(() => props.result.packages)

const showAllPanel = computed(() => {
  return packages.value.length > 0
})

watch(
  () => props.result,
  (_, oldVal) => {
    const oldShowAllPanel = oldVal.packages.length > 0

    if (!showAllPanel.value) {
      data.activeIndex = 0
    } else if (!oldShowAllPanel) {
      data.activeIndex += 1
    }
  }
)
</script>
<style scoped>
.card-list {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 12px;
}
</style>
