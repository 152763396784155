<template>
  <AppBar
    title="商店"
    class="bg-white"
    @back="emit('done')"
  >
    <template #back>
      <Icon
        name="close"
        class="w-20px"
      ></Icon>
    </template>

    <template #actions>
      <DiamondCount />
    </template>
  </AppBar>

  <div class="px-4 pb-4 mt-4">
    <div class="vip-box">
      <Loading v-if="loading" />

      <template v-else-if="defaultVip">
        <div class="relative py-3">
          <Icon
            name="vip-medal"
            class="w-122px absolute top-0 right-0"
          />

          <div
            class="text-25px leading-[1em] font-semibold mb-15px text-ld-premium-800"
          >
            {{ _t('畅学卡') }}
          </div>

          <div
            class="text-17px leading-[1.4] font-semibold text-ld-premium-800 flex flex-col gap-1"
          >
            <div class="flex items-center gap-1">
              <Icon
                name="vip-check"
                class="w-20px"
              />
              {{ _t('所有卡包免解锁') }}
            </div>
            <div class="flex items-center gap-1">
              <Icon
                name="vip-check"
                class="w-20px"
              />
              {{
                _t(`面包上限 +${config?.vipPrivilegeResponse.addEnergyLimit}`)
              }}
            </div>
            <div class="flex items-center gap-1">
              <Icon
                name="vip-check"
                class="w-20px"
              />
              {{
                _t(
                  `每天 ${config?.vipPrivilegeResponse.freeEnergyPerDay} 次免费购买面包`
                )
              }}
            </div>
            <div class="flex items-center gap-1">
              <Icon
                name="vip-check"
                class="w-20px"
              />
              {{ _t('免除广告') }}
            </div>
          </div>
        </div>

        <div
          v-if="store.isVipValid"
          class="flex items-center"
        >
          <div
            class="flex flex-col text-17px leading-[1.4] text-ld-label-secondary font-semibold"
          >
            <span>{{ _t('已开通畅学卡') }}</span>
            <span class="nowrap">
              {{ _t('到期时间') }}
              {{ $f.date(store.vip!.expireAt, 'YYYY-MM-DD') }}
            </span>
          </div>

          <Button
            class="h-50px ml-4 flex-1 rounded-12px overflow-hidden"
            scene="vip"
            :label="_t(`立即续费`)"
            @click="onVipBuy"
          ></Button>
        </div>

        <Button
          v-else
          class="h-50px w-full rounded-12px overflow-hidden"
          scene="vip"
          :label="
            _t(
              `立即开通 · 限时特惠 ${$f.currency(
                defaultVip.currency
              )}${fenToYuan(defaultVip.pricePerMonth)}/月`
            )
          "
          @click="onVipBuy"
        ></Button>
      </template>
    </div>

    <div class="text-21px leading-[1em] font-semibold mt-6 mb-4">
      {{ _t('钻石获取') }}
    </div>

    <div
      v-if="showAlphaGift"
      class="diamond-card-box relative mb-3"
    >
      <div
        class="text-25px leading-[1em] font-semibold text-ld-brand-600 mb-15px"
      >
        {{ _t('内测限时福利') }}
      </div>

      <div
        class="text-17px leading-[1.4] font-semibold text-ld-brand-500 flex flex-col gap-1"
      >
        <div class="absolute top-2 right-4">
          <Img
            name="ld-look"
            class="w-97px"
          />
        </div>

        <div class="flex items-center gap-1">
          <Icon
            name="diamond"
            class="w-20px"
          />

          {{ _t('立即获得') }} {{ alphaGiftDiamond }} {{ _t('钻石') }}
        </div>
        <div class="flex items-center gap-1">
          <Icon
            name="diamond"
            class="w-20px"
          />
          {{ _t('每人限领 1 次') }}
        </div>

        <DotBadge class="mt-15px">
          <Button
            class="h-50px w-full"
            scene="secondary"
            @click="onClaimAlphaGift"
          >
            <div
              class="text-center flex-1 flex text-17px text-ld-brand-600 justify-center items-center font-600"
            >
              {{ _t('免费领取：') }}
              <Icon
                name="diamond"
                class="w-20px"
              />
              x{{ alphaGiftDiamond }}
            </div>
          </Button>
        </DotBadge>
      </div>
    </div>

    <div class="diamond-card-box relative">
      <Icon
        name="diamonds"
        class="w-97px absolute top-2 right-4"
      />

      <div
        class="text-25px leading-[1em] font-semibold text-ld-brand-600 mb-15px"
      >
        {{ _t('永恒钻石') }}
      </div>

      <div
        class="text-17px leading-[1.4] font-semibold text-ld-brand-500 flex flex-col gap-1"
      >
        <div class="flex items-center gap-1">
          <Icon
            name="diamond"
            class="w-20px"
          />
          {{ _t('立即获得 1980 钻石') }}
        </div>
        <div class="flex items-center gap-1">
          <Icon
            name="diamond"
            class="w-20px"
          />
          {{ _t('每天可领取 66 钻 (永久有效)') }}
        </div>
      </div>

      <Button
        v-if="store.diamondCard == null"
        class="h-50px w-full mt-15px"
        :loading="diamondCardBuyLoading"
        @click="onDiamondCardBuy"
      >
        <div class="text-center flex-1 text-17px font-semibold">
          {{
            _t(`${$f.currency(diamondCardCurrency)}${diamondCardPrice} 购买`)
          }}
        </div>
      </Button>

      <Button
        v-else-if="store.diamondCard.receivedToday"
        class="h-50px w-full mt-15px"
        scene="secondary"
        :disabled="true"
      >
        <div class="text-center flex-1 text-17px text-ld-brand-500">
          {{ _t('今日已领取（凌晨 4 点刷新）') }}
        </div>
      </Button>

      <DotBadge
        v-else
        class="mt-15px"
      >
        <Button
          class="h-50px w-full"
          scene="secondary"
          @click="onDiamondCardReceiveDaily"
        >
          <div
            class="flex items-center justify-center flex-1 text-17px font-semibold text-ld-brand-500"
          >
            {{ _t('领取今日钻石：') }}
            <Icon
              name="clean-diamond"
              class="w-20px"
            />
            <span>x{{ store.diamondCard.diamondPerDay }}</span>
          </div>
        </Button>
      </DotBadge>
    </div>

    <div
      v-if="config"
      class="grid grid-cols-3 gap-3 text-ld-brand-500 mt-3"
    >
      <div
        v-for="item in config.diamondList"
        :key="item.id"
        class="diamond-sku"
        :loading="diamondBuyLoading[item.id]"
        @click="onDiamondBuy(item)"
      >
        <Icon
          name="clean-diamond"
          class="w-52px"
        />

        <div
          class="text-17px leading-[1em] font-semibold mt-1 mb-3 whitespace-nowrap"
        >
          {{ item.amount }} {{ _t('钻石') }}
        </div>

        <Button class="h-40px w-full">
          <div class="text-center flex-1 text-15px font-semibold">
            {{ _t(`${$f.currency(item.currency)} ${fenToYuan(item.price)}`) }}
          </div>
        </Button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import AppBar from '@/mobile/components/AppBar.vue'
import VipSelector from './VipSelector.vue'
import { useCommonStore } from '@/stores'
import { computed, onUnmounted, ref } from 'vue'
import {
  fetchStoreProducs,
  type StoreConfig,
  type DiamondSku,
  receiveDiamondCardDailyDiamonds,
} from '@/api/product'
import { fenToYuan, warn } from '@/utils'
import { RewardType } from '@/api/task'
import DotBadge from '@/components/DotBadge.vue'
import {
  OrderStatus,
  createDiamondCardOrder,
  createDiamondOrder,
} from '@/api/order'
import { payByEnv } from '@/shared/pay'
import DiamondCount from '@/components/DiamondCount.vue'
import { claimAlphaGift } from '@/api/user'

const props = withDefaults(
  defineProps<{
    exitAfterVipBought?: boolean
  }>(),
  {
    exitAfterVipBought: false,
  }
)

const emit = defineEmits<{
  done: []
  vipBought: []
}>()

const store = useCommonStore()

const config = ref<StoreConfig>()
const loading = ref(true)
const diamondCardBuyLoading = ref(false)

const defaultVip = computed(() => {
  if (config.value == null) return null
  const vip = config.value.vipList.find(item => item.defaultSelected)

  return vip ?? config.value.vipList[0]
})

const diamondCardPrice = computed(() => {
  if (config.value == null) return 0
  return fenToYuan(config.value.diamondCard.price)
})

const diamondCardCurrency = computed(() => {
  if (config.value == null) return 'CNY'

  return config.value.diamondCard.currency
})

// 是否显示内测福利
const showAlphaGift = computed(() => {
  return (
    store.user?.claimedGift === false &&
    alphaGiftDiamond.value != null &&
    alphaGiftDiamond.value > 0
  )
})

// 内测福利钻石数量
const alphaGiftDiamond = computed(() => {
  return config.value?.alphaGift?.diamond
})

fetchStoreProducs().then(res => {
  config.value = res
  loading.value = false
})

function onVipBuy() {
  if (config.value == null) return

  _presentContent(VipSelector, {
    contentClass: 'w-360px',
    props: {
      initialSkuId: defaultVip.value!.id,
      skuList: config.value.vipList,
      onBought() {
        emit('vipBought')
        if (props.exitAfterVipBought) {
          emit('done')
        }
      },
    },
  })
}

function onDiamondCardReceiveDaily() {
  receiveDiamondCardDailyDiamonds().then(() => {
    store.receiveDiamondCardToday()
    _showRewards([
      {
        type: RewardType.DIAMOND,
        value: store.diamondCard!.diamondPerDay,
      },
    ])
  })
}

let cancelPoll: VoidFunction

onUnmounted(() => {
  cancelPoll?.()
})

async function onDiamondCardBuy() {
  if (config.value == null) return

  try {
    diamondCardBuyLoading.value = true
    const res = await createDiamondCardOrder(config.value.diamondCard.id)

    if (res.code !== 0) {
      diamondCardBuyLoading.value = false
      _message.info(res.message)
      return
    }

    // 0 元订单，下单时就完成了
    if (res.data.status === OrderStatus.DELIVERED) {
      diamondCardBuyLoading.value = false
      _message.success('订单支付成功（0元订单）')
      onDiamondCardBuySuccess()
      return
    }

    const payData = payByEnv(res.data.orderId, onDiamondCardBuySuccess)
    cancelPoll = payData.cancelPoll
    payData.payPromise
      .catch(e => {
        _message.info(e.message)
      })
      .finally(() => {
        diamondCardBuyLoading.value = false
      })
  } catch (_err) {
    warn(_err)
    diamondCardBuyLoading.value = false
  }
}

function onDiamondCardBuySuccess() {
  cancelPoll?.()
  _showRewards([
    {
      type: RewardType.DIAMOND,
      value: config.value!.diamondCard.immediateDiamonds,
    },
  ])
  store.fetchUserDiamondCard()
}

const diamondBuyLoading = ref<Record<number, boolean>>({})
async function onDiamondBuy(sku: DiamondSku) {
  try {
    diamondBuyLoading.value[sku.id] = true
    const res = await createDiamondOrder(sku.id)

    if (res.code !== 0) {
      diamondBuyLoading.value[sku.id] = false
      _message.info(res.message)
      return
    }

    // 0 元订单，下单时就完成了
    if (res.data.status === OrderStatus.DELIVERED) {
      diamondBuyLoading.value[sku.id] = false
      _message.success('订单支付成功（0元订单）')
      onDiamondBuySuccess(sku)
      return
    }

    const payData = payByEnv(res.data.orderId, () => onDiamondBuySuccess(sku))
    cancelPoll = payData.cancelPoll
    payData.payPromise
      .catch(e => {
        _message.info(e.message)
      })
      .finally(() => {
        diamondBuyLoading.value[sku.id] = false
      })
  } catch (_err) {
    warn(_err)
    diamondBuyLoading.value[sku.id] = false
  }
}

function onDiamondBuySuccess(sku: DiamondSku) {
  cancelPoll?.()
  _showRewards([
    {
      type: RewardType.DIAMOND,
      value: sku.amount,
    },
  ])
  store.fetchUserAssets()
}

async function onClaimAlphaGift() {
  const res = await claimAlphaGift()

  if (res.code !== 0) {
    _message.info(res.message)
    return
  }

  _showRewards([
    {
      type: RewardType.DIAMOND,
      value: alphaGiftDiamond.value!,
    },
  ])
  if (store.user) store.user.claimedGift = true
  store.fetchUserAssets()
}
</script>

<style scoped>
.vip-box {
  padding: 16px;
  border-radius: 12px;
  background: linear-gradient(
    114.77deg,
    var(--ld-premium-100) 0%,
    var(--ld-premium-200) 100%
  );
  border: 1px solid var(--ld-premium-200);
}

.diamond-card-box {
  padding: 16px;
  border-radius: 12px;
  background: linear-gradient(
    114.77deg,
    var(--ld-brand-100) 0%,
    var(--ld-brand-200) 100%
  );

  border: 1px solid var(--ld-brand-200);
}

.diamond-sku {
  border: 1px solid var(--ld-brand-200);
  background-color: var(--ld-brand-100);
  padding: 9px 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 12px;
}
</style>
