export enum BusEvent {
  StageUnitClear = 'StageUnitClear',
  StageUnitComplete = 'StageUnitComplete',
  NoteEditorFocus = 'NoteEditorFocus',
  Login = 'Login',
  OverlayPanelShow = 'OverlayPanelShow',
}

type BusHandler<K extends BusEvent> = (...args: BusEventProps[K]) => void

type BusEventProps = {
  [BusEvent.StageUnitClear]: []
  [BusEvent.StageUnitComplete]: []
  [BusEvent.NoteEditorFocus]: [id: number]
  [BusEvent.Login]: []
  [BusEvent.OverlayPanelShow]: [any]
}

class Bus {
  handlerMap = new Map<BusEvent, Set<(...args: any) => void>>()

  emit<K extends BusEvent>(event: K, ...args: BusEventProps[K]) {
    const handlerSet = this.handlerMap.get(event)

    if (handlerSet == null) {
      return
    }

    for (const handler of handlerSet) {
      handler(...args)
    }
  }

  on<K extends BusEvent>(event: K, handler: BusHandler<K>) {
    if (!this.handlerMap.has(event)) {
      this.handlerMap.set(event, new Set<BusHandler<K>>())
    }

    const handlerSet = this.handlerMap.get(event)
    handlerSet?.add(handler)
  }

  off<K extends BusEvent>(event: K, handler?: BusHandler<K>) {
    if (handler == null) {
      this.handlerMap.delete(event)
      return
    }

    const handlerSet = this.handlerMap.get(event)
    if (handlerSet != null) {
      handlerSet.delete(handler)
    }
  }
}

export default new Bus()
