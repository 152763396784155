<template>
  <span class="relative break-all text-left">
    <slot></slot>
    <span class="lottie-container">
      <span
        ref="lottieBoxLeft"
        class="lottie-box lottie-box-left"
      ></span>
      <span
        ref="lottieBoxRight"
        class="lottie-box lottie-box-right"
      ></span>
    </span>
  </span>
</template>

<script setup lang="ts">
import { onUnmounted, watch } from 'vue'
import { onMounted, ref } from 'vue'
import animData0 from '@/assets/lottie/button-sparkle0.json'
import animData1 from '@/assets/lottie/button-sparkle1.json'
import lottie, { type AnimationItem } from 'lottie-web'

const lottieBoxLeft = ref(null)
const lottieBoxRight = ref(null)
let animLeft: AnimationItem | null = null
let animRight: AnimationItem | null = null
const animate = () => {
  emit('animationStart')

  animLeft?.stop()
  animRight?.stop()
  animLeft?.play()
  animRight?.play()
}

const props = defineProps<{
  tag: boolean
}>()

const emit = defineEmits<{
  animationStart: []
  animationEnd: []
}>()

onMounted(() => {
  if (lottieBoxLeft.value) {
    animLeft = lottie.loadAnimation({
      container: lottieBoxLeft.value,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: animData0,
    })
    animLeft?.goToAndStop(animLeft.totalFrames, true)
    animLeft?.addEventListener('complete', () => {
      emit('animationEnd')
    })
  }

  if (lottieBoxRight.value) {
    animRight = lottie.loadAnimation({
      container: lottieBoxRight.value,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: animData1,
    })
    animRight?.goToAndStop(animRight.totalFrames, true)
  }

  // 监听 tag 变化 从false => true 触发动画
  watch(
    () => props.tag,
    tag => {
      if (tag) {
        animate()
      }
    }
  )
})
onUnmounted(() => {
  animLeft?.destroy()
  animRight?.destroy()
})
</script>

<style scoped>
.lottie-container {
  position: absolute;
  bottom: -6px;
  left: 0;
  right: 0;
  top: -6px;
  transform: scale(1, -1);
}
.lottie-box {
  display: inline-block;
  position: absolute;
}
.lottie-box-left {
  left: 0;
  top: 0;
  width: 40px;
}
.lottie-box-right {
  bottom: 0;
  right: 0;
  width: 30px;
}
</style>
