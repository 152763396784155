<template>
  <div class="h-full flex flex-col flex-1">
    <Choice
      v-if="
        finalFace.type === ClozeCardFaceType.Choice ||
        finalFace.type === ClozeCardFaceType.MinimalChoice ||
        finalFace.type === ClozeCardFaceType.GiveAwayChoice
      "
      :face="finalFace"
      :quiz-stage="props.quizStage"
    />

    <Judgement
      v-else-if="
        finalFace.type === ClozeCardFaceType.Judgement ||
        finalFace.type === ClozeCardFaceType.GiveAwayJudgement
      "
      :face="finalFace"
      :quiz-stage="props.quizStage"
    />
  </div>
</template>

<script lang="ts" setup>
import Choice from './faces/Choice/Choice.vue'
import Judgement from './faces/Judgement.vue'
import { processClozeFace } from '@/utils/card'

import { ClozeCardFaceType } from '@/types/core'
import type { ClozeCardFace, QuizStage } from '@/types/core'

const props = defineProps<{
  face: ClozeCardFace
  quizStage?: QuizStage
}>()

const finalFace = processClozeFace(props.face)
</script>

<style scoped></style>
