<template>
  <div class="flex flex-col gap-2">
    <Button
      :label="_t('查看卡包')"
      scene="secondary"
      @click="onView"
    />
    <Button
      :label="_t('移除卡包')"
      scene="danger"
      @click="onRemove"
    />

    <div
      class="w-full text-center py-2 text-[var(--text-color-secondary)] font-600"
      @click="onDone"
    >
      {{ _t('取消') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import Button from '../Button.vue'

const emit = defineEmits<{
  view: []
  remove: []
  done: []
}>()

function onView() {
  emit('view')
  emit('done')
}
function onRemove() {
  emit('remove')
  emit('done')
}
function onDone() {
  emit('done')
}
</script>

<style scoped></style>
