<template>
  <div>
    <div class="flex flex-col items-center cursor-pointer">
      <RewardBoxIcon
        :animation="isFullStar"
        class="mb--1px w-30px"
      />

      <div
        class="text-10px leading-[1.2] flex items-center py-3px px-4px rounded-4px bg-neutral-900 bg-opacity-50 text-white z-1 font-[DIN]"
      >
        <Icon
          name="light-star"
          class="mr-2px w-14px"
        />
        <span>{{ props.star }} / {{ props.maxStar }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import Icon from './Icon.vue'
import RewardBoxIcon from './RewardBoxIcon.vue'

const props = defineProps<{
  star: number
  maxStar: number
}>()

// 满星
const isFullStar = computed(() => props.star === props.maxStar)
</script>

<style scoped></style>
