<template>
  <div class="flex items-center mx-4 pt-4">
    <Icon
      name="close"
      class="cursor-pointer w-20px"
      @click="emit('close')"
    ></Icon>

    <ProgressBar
      :percent="progressBarPercent"
      class="ml-4"
    />
  </div>

  <DebugLabel v-if="activeCardConfig && data.activeSchedule">
    <div class="flex items-center mt-2 mx-4">
      <Tag
        class="w-fit"
        :value="activeCardConfig.interaction"
      />

      <Tag
        class="w-fit ml-2"
        severity="info"
        :value="`卡面 ${activeCardConfig.cardType}`"
      />

      <Tag
        class="w-fit ml-2"
        severity="info"
        :value="
          data.activeSchedule.cardStatus === LearnStatus.DEBUT ? '新学' : '复习'
        "
      />

      <div class="ml-auto flex items-center gap-2">
        <div>T：{{ T }}</div>
        <div>得分: {{ scores }}</div>
      </div>
    </div>
  </DebugLabel>

  <ConcreteCard
    v-if="activeCardFace != null && activeCardConfig"
    :key="data.cardLearnRound"
    class="flex-1"
    :face="activeCardFace"
    @event="onLearnEvent"
    @star="emit('star', $event)"
    @next="onNext"
  ></ConcreteCard>
</template>

<script lang="ts" setup>
import {
  LearnStatus,
  CardType,
  ClozeCardFaceType,
  EnWordCardFaceType,
} from '@/types/core'
import { UnitEventType } from '@/api/learn'
import { computed, reactive, onMounted } from 'vue'
import Tag from 'primevue/tag'
import { tryJSONParse } from '@/utils'
import { useCommonStore } from '@/stores'
import { generateCardFaces, parseCard } from '@/utils/card'
import db from '@/db'
import { sum } from 'lodash-es'
import { reportCardEvents, completeReport } from '../report'
import ProgressBar from '@/components/ProgressBar.vue'
import ConcreteCard from '@/components/ConcreteCard/ConcreteCard.vue'
import { feedbackStar } from '@/components/ConcreteCard/common/feedback'

import type { CardSchedule } from '@/api/learn'
import type { Card, ConcreteCardFace } from '@/types/core'
import type { AllCardModeConfig, CardConfigWithScore } from '@/utils/card'

const emit = defineEmits<{
  close: []
  star: [feedbackStar]
}>()

const cardModeConfig: AllCardModeConfig = {
  [CardType.CLOZE]: {
    practice: {
      configs: [
        {
          cardType: CardType.CLOZE,
          face: ClozeCardFaceType.MinimalChoice,
        },
        {
          cardType: CardType.CLOZE,
          face: ClozeCardFaceType.Choice,
        },
      ],
      order: 'random',
      limit: 1,
    },
    check: {
      configs: [],
      order: 'order',
    },
  },
  [CardType.EN_WORD]: {
    practice: {
      configs: [
        {
          cardType: CardType.EN_WORD,
          face: EnWordCardFaceType.ExampleChoice,
        },
      ],
      limit: 1,
      order: 'random',
    },
    check: {
      configs: [
        {
          cardType: CardType.EN_WORD,
          face: EnWordCardFaceType.WordChoice,
        },
      ],
      order: 'order',
    },
  },
}

const T0 = 4
const K = 2
let T = T0

// https://qianmojiaoyu.feishu.cn/wiki/XPzNwtvA9iQuMtkwQr0cSy73nb6#WUuOdfnHuoV0wnxuS0Qcukaznhg
// 一张卡划走后，如果还没有弃牌，则在下T张卡时（T最小为1，即下一张卡）再次出现
// 初始T=T0
// 当通过一个卡面，依次进行下列操作
//     T = T * K
//     如果T<T0，则重置为T=T0
//       防止完成的过快
//       先倍增，再重置，会让出错过卡会比其他卡快一倍的完成，免得等会又忘了（根据间隔重复原理，遇到难啃的骨头先放一放，第二天再复习巩固可能会更好），减少挫败感
//   当一个卡面错误，依次进行下列操作
//     如果T>T0，则重置T=T0
//     T = T / K
//       让出错的卡片更频繁的出现，防止背了忘忘了背的情况
// 如果队列没有那么多卡，直接放到队尾
// 如果放在队尾，且队伍中还有至少三张卡，有30%几率与前一张卡交换位置
// 拍脑袋T0=4，K=2，可能需要实测来确定更好的值
function updateT(correct: boolean) {
  if (correct) {
    T = T * K

    if (T < T0) {
      T = T0
    }

    return
  }

  if (T > T0) {
    T = T0
  }

  T = Math.floor(T / K)
}

const store = useCommonStore()

onMounted(() => {
  T = T0
})

// 记录当前卡片的事件
const activeCardEvents: UnitEventType[] = []

const data = reactive({
  cardLearnRound: 0,
  activeSchedule: null as CardSchedule | null,
  cardSchedules: [] as CardSchedule[],
  cardFaceListMap: {} as Record<number, CardConfigWithScore[]>,
})

const progressBarPercent = computed(
  () => (scores.value / totalScores.value) * 100
)

const activeCard = computed<Card | null>(() => {
  if (!data.activeSchedule) return null

  return tryJSONParse(data.activeSchedule.content)
})

const activeCardFace = computed<ConcreteCardFace | null>(() => {
  if (
    activeCard.value == null ||
    activeCardConfig.value == null ||
    data.activeSchedule == null
  )
    return null

  const parsedResult = parseCard(activeCard.value)

  if (parsedResult.error != null) return null

  const altCards = data.cardSchedules
    .filter(item => item.cardId !== data.activeSchedule!.cardId)
    .map(item => JSON.parse(item.content) as Card)
    .filter(item => item.type === activeCard.value!.type)
    .map(item => parseCard(item))
    .filter(item => item.error != null)
    .map(item => item.card)

  return {
    type: activeCardConfig.value.face,
    cardId: data.activeSchedule!.cardId,
    card: parsedResult.card,
    interaction: activeCardConfig.value.interaction,
    altCards,
    style: activeCardConfig.value.style,
  } as ConcreteCardFace
})

const activeCardConfig = computed<CardConfigWithScore | null>(() => {
  if (!data.activeSchedule) return null

  return data.cardFaceListMap[data.activeSchedule.cardId][0]
})

const totalScores = computed(() => store.stageUnit?.schedules.length ?? 0)
const scores = computed(() => {
  const cardScores = store.stageUnit?.cardFaceScores ?? {}

  let sumScore = 0
  for (const cardId in cardScores) {
    const scores = Array.isArray(cardScores[cardId]) ? cardScores[cardId] : []

    sumScore += sum(scores)
  }

  return sumScore
})

function loadUnit() {
  if (!store.checkStageUnit()) return

  if (store.stageUnit != null) {
    store.stageUnit.schedules.forEach(item => {
      if (!store.stageUnit!.completedCards?.includes(item.cardId)) {
        data.cardSchedules.push(item)
      }
    })
    data.activeSchedule = data.cardSchedules[0]

    for (const item of data.cardSchedules) {
      const card = tryJSONParse(item.content) as Card

      // 复习卡片在初始时只需要展示「检验」卡面
      data.cardFaceListMap[item.cardId] = generateCardFaces(
        cardModeConfig,
        card.type,
        item.cardStatus === LearnStatus.REVIEW
      )
    }
  }
}

loadUnit()

function onLearnEvent(_: number | undefined, event: UnitEventType) {
  onEventCollect(event)

  if (event === UnitEventType.CORRECT) {
    onCorrect()
  }

  if (event === UnitEventType.LAPSE) {
    onLapse()
  }
}

function onEventCollect(event: UnitEventType) {
  if (!activeCardConfig.value) return

  activeCardEvents.unshift(event)
  store.insertLearningEvent(data.activeSchedule!.cardId, {
    event,
    stage: activeCardConfig.value.interaction,
    timestamp: Date.now(),
  })
}

function onCorrect() {
  const correct =
    activeCardEvents[0] === UnitEventType.CORRECT &&
    !activeCardEvents.some(item => item === 'WRONG')

  if (correct) {
    rewardScore()
  }
}

function onLapse() {
  resetScore()
}

async function onNext() {
  data.cardLearnRound++

  if (!store.stageUnit || !data.activeSchedule) return
  const cardId = data.activeSchedule.cardId

  const currentCardModes = data.cardFaceListMap[cardId]
  const correct =
    activeCardEvents[0] === UnitEventType.CORRECT &&
    !activeCardEvents.some(item =>
      [UnitEventType.WRONG, UnitEventType.LAPSE].includes(item)
    )

  // 如果是最后一个卡面且回答正确，此时应该弃牌
  if (currentCardModes.length === 1 && correct) {
    data.cardSchedules.shift()
    store.completeCard(cardId)

    const unitCompleted = data.cardSchedules.length === 0

    unitCompleted ? completeReport() : reportCardEvents(cardId)

    updateT(true)

    if (db.debug.showDebugLabel) {
      _message.info('弃牌')
    }
  } else if (correct) {
    currentCardModes.shift()
    nextCard()
    updateT(true)
  }

  // 如果做错了，不管什么状态下都要重新生成所有卡面继续学习
  if (
    activeCardEvents.includes(UnitEventType.WRONG) ||
    activeCardEvents.includes(UnitEventType.LAPSE)
  ) {
    data.cardFaceListMap[cardId] = generateCardFaces(
      cardModeConfig,
      activeCard.value!.type
    )

    nextCard()
    updateT(false)
  }

  data.activeSchedule = data.cardSchedules[0]
}

function rewardScore() {
  if (!activeCard.value || !activeCardConfig.value) return

  const cardId = data.activeSchedule!.cardId
  const faces = generateCardFaces(cardModeConfig, activeCard.value.type)

  // 已通过的卡面数量
  const passedFacesLength = faces.length - data.cardFaceListMap[cardId].length
  const currentCardScoreList = store.stageUnit?.cardFaceScores[cardId] ?? []

  // 这里是判断之前已经得分过的卡面已经无需再次得分了
  // 通过判断已经得分的长度是否超过了当前已经通过的卡面数量来判断
  if (
    passedFacesLength >= currentCardScoreList.length &&
    sum(currentCardScoreList) < 1
  ) {
    store.pushCardScore(cardId, activeCardConfig.value.score)
  }
}

// 在单词卡片点击「记错了」时需要回收分数
function resetScore() {
  if (data.activeSchedule == null) return

  const cardId = data.activeSchedule.cardId

  store.popCardScore(cardId)
}

function nextCard() {
  activeCardEvents.length = 0
  const learningCard = data.cardSchedules.shift()

  if (learningCard == null) return

  if (T > data.cardSchedules.length) {
    data.cardSchedules.push(learningCard)
  } else {
    data.cardSchedules.splice(T, 0, learningCard)
  }
}
</script>

<style scoped></style>
