import { defineStore } from 'pinia'
import db from '@/db'
import i18n, { SUPPORT_LOCALES, loadLocaleMessages } from '@/i18n'

import type { DB } from '@/db'
import type { Locale } from '@/i18n'
import type { UserInfo } from '@/api/auth'
import type { CardResponse } from '@/api/package-source'
import type { CardSchedule, Unit, UnitEvent } from '@/api/learn'
import bus, { BusEvent } from '@/bus/bus'
import { fetchUnreadMsgCount } from '@/api/msg'
import { watch } from 'vue'
import { QueueType } from '@/pages/UnitLearn/queue'
import {
  checkInCt,
  userAssets,
  type CheckInCtRes,
  type UserAssets,
  type EnergyResponse,
  fetchEnergy,
  type UserVipInfo,
  fetchUserVip,
  claimAlphaGift,
} from '@/api/user'
import { fetchUnreceivedTaskCount } from '@/api/task'
import dayjs from 'dayjs'
import { fetchStatsOverview, type OverviewStats } from '@/api/stats'
import { fetchDiamondCard, type DiamondCardResponse } from '@/api/product'

export type Theme = 'light' | 'dark'

export enum PackageMode {
  Challenge,
  Browser,
}

// 单元学习缓存只保留一小时
const UNIT_TIMEOUT = 3600
let energyTimer: number

export const useCommonStore = defineStore('common', {
  state: () => ({
    theme: db.theme,
    token: db.loginToken,
    user: null as UserInfo | null,
    cardEditTab: 0,
    packageState: db.packageState,

    // 用来做卡片更新的通知，每次更新卡片会修改该字段
    cardEditEvent: null as [number, CardResponse] | null,

    // 当前正在学习的关卡单元信息
    stageUnit: db.stageUnit,

    guest: db.guest,

    // 未读消息数量
    unreadMsgCount: 0,

    // 可领取但未领取的任务数量
    unreceivedTaskCount: 0,

    cardResponseCache: {} as Record<number, CardResponse | CardSchedule>,
    // 连续签到数据
    checkInCtRes: null as CheckInCtRes | null,

    // 用户资产信息
    userAssets: null as UserAssets | null,

    // 个人页面 统计信息
    statsOverview: null as OverviewStats | null,

    energy: null as EnergyResponse | null,
    energyCountDown: null as number | null,

    debugTs: null as string | null,

    vip: null as UserVipInfo | null,

    diamondCard: null as DiamondCardResponse | null,
  }),

  getters: {
    locale() {
      return i18n.global.locale as Locale
    },

    localeName() {
      const locale = this.locale as string
      return SUPPORT_LOCALES.find(v => v.value === locale)!.name
    },

    isLightTheme(): boolean {
      return this.theme === 'light'
    },

    isDarkTheme(): boolean {
      return this.theme === 'dark'
    },

    isLoggedIn(): boolean {
      return this.user != null
    },
    isEnergyFull(): boolean {
      if (this.energy == null) return false

      return this.energy.currentEnergy >= this.energy.energyLimit
    },

    isVipValid(): boolean {
      if (this.vip == null) {
        return false
      }

      const { expireAt } = this.vip

      const isExpired = dayjs(expireAt).isBefore(Date.now())

      return !isExpired
    },
  },

  actions: {
    async setLocale(locale: Locale) {
      if (locale === this.locale) return

      if (i18n.global.availableLocales.includes(locale)) {
        i18n.global.locale = locale
        db.locale = locale
        return
      }

      await loadLocaleMessages(locale)
      i18n.global.locale = locale
      db.locale = locale
    },

    updatePackageState(
      packageId: number,
      {
        expandedKeys,
        selectedChapterId,
        selectedCardId: selectedCardId,
      }: Partial<DB['packageState'][number]>
    ) {
      this.packageState[packageId] = this.packageState[packageId] ?? {}

      if (expandedKeys) {
        this.packageState[packageId].expandedKeys = expandedKeys
      }

      if (selectedChapterId) {
        this.packageState[packageId].selectedChapterId = selectedChapterId
      }
      this.packageState[packageId].selectedCardId = selectedCardId
    },

    setCardEditTab(tab: number) {
      this.cardEditTab = tab
    },

    updateCard(id: number, card: CardResponse) {
      this.cardEditEvent = [id, card]
    },

    checkStageUnit(): boolean {
      if (this.stageUnit) {
        const seconds = Math.floor(
          (Date.now() - this.stageUnit.updatedAt) / 1000
        )

        if (seconds > UNIT_TIMEOUT) {
          return false
        }

        if (this.stageUnit?.completed || this.stageUnit?.cancelled) {
          return false
        }

        return true
      }

      return false
    },

    setStageUnit(unit: Unit, queueType: QueueType) {
      bus.emit(BusEvent.StageUnitClear)

      this.stageUnit = {
        ...unit,
        queueType,
        updatedAt: Date.now(),
        cardFaceScores: {},
        completedCards: [],
        completed: false,
        cancelled: false,
        events: {},
      }
    },

    setChallengeStageUnit(
      unit: Unit,
      challenge: {
        index: number
        npcName: string
      }
    ) {
      bus.emit(BusEvent.StageUnitClear)

      this.stageUnit = {
        ...unit,
        queueType: QueueType.Duel,
        challenge,
        updatedAt: Date.now(),
        cardFaceScores: {},
        completedCards: [],
        completed: false,
        cancelled: false,
        events: {},
      }
    },

    clearStageUnit() {
      if (this.stageUnit) {
        delete db.unitProgressCache[this.stageUnit.queueType]
      }
      delete db.unitProgressCache[QueueType.Duel]

      this.stageUnit = null
    },

    completeStageUnit() {
      if (!this.stageUnit) return

      bus.emit(BusEvent.StageUnitComplete)
      this.stageUnit.completed = true
    },

    cancelStageUnit() {
      if (!this.stageUnit) return

      this.stageUnit.cancelled = true
    },

    insertLearningEvent(cardId: number, event: UnitEvent) {
      if (!this.stageUnit) return

      const events = this.stageUnit.events[cardId] ?? []
      events.push(event)
      this.stageUnit.events[cardId] = events
      this.stageUnit.updatedAt = event.timestamp
    },

    pushCardScore(carId: number, score: number) {
      if (!this.stageUnit) return

      this.stageUnit.cardFaceScores = this.stageUnit.cardFaceScores || {}
      this.stageUnit.cardFaceScores[carId] =
        this.stageUnit.cardFaceScores[carId] ?? []
      this.stageUnit.cardFaceScores[carId].push(score)
    },

    popCardScore(cardId: number) {
      if (!this.stageUnit) return

      if (this.stageUnit.cardFaceScores[cardId]) {
        this.stageUnit.cardFaceScores[cardId].pop()
      }
    },

    completeCard(cardId: number) {
      if (!this.stageUnit) return

      this.stageUnit.completedCards = this.stageUnit.completedCards ?? []
      this.stageUnit.completedCards.push(cardId)
    },

    clearCardEvents(cardId: number) {
      if (!this.stageUnit) return

      this.stageUnit.events[cardId] = []
    },

    login(token: string, user: UserInfo) {
      this.token = token
      this.user = user

      // 拉取登录需要的信息
      this.fetchUnreceivedTaskCount()
      this.fetchUnreadMsgCount()
      this.fetchEnergyStatus()
      this.fetchUserAssets()
      this.fetchCheckInCtRes()
      this.fetchStatsOverview()
      this.fetchUserVip()
      this.fetchUserDiamondCard()

      bus.emit(BusEvent.Login)
    },

    patchUser(payload: Partial<UserInfo>) {
      if (this.user == null) return

      for (const key in payload) {
        if (key) {
          this.user[key] = payload[key as keyof UserInfo]
        }
      }
    },

    logout() {
      this.token = ''
      this.user = null
      this.checkInCtRes = null
      this.userAssets = null
    },

    // 获取未读消息数量
    async fetchUnreadMsgCount() {
      const res = await fetchUnreadMsgCount()
      this.unreadMsgCount = res.count
    },

    async fetchUnreceivedTaskCount() {
      const res = await fetchUnreceivedTaskCount()
      this.unreceivedTaskCount = res.count
    },

    async fetchUserVip() {
      return fetchUserVip().then(res => {
        this.vip = res.vip
      })
    },

    async fetchUserDiamondCard() {
      return fetchDiamondCard().then(res => {
        this.diamondCard = res.status
      })
    },

    async receiveDiamondCardToday() {
      if (this.diamondCard == null) return

      this.diamondCard.receivedToday = true
    },

    setUnreceivedTaskCount(count: number) {
      this.unreceivedTaskCount = count
    },

    // 清空未读消息数量
    clearUnreadMsgCount() {
      this.unreadMsgCount = 0
    },

    // 未读消息数量-1
    decreaseUnreadMsgCount() {
      this.unreadMsgCount -= 1
    },

    setCardResponseCache(cardId: number, res: CardResponse | CardSchedule) {
      this.cardResponseCache[cardId] = res
    },

    // 连续签到数据
    async fetchCheckInCtRes() {
      const res = await checkInCt()
      this.checkInCtRes = res
      if (this.statsOverview) {
        this.statsOverview.accumulatedCheckInDays = res.accumulatedDays
        this.statsOverview.maxCheckInCtDays = res.maxContinuousDays
      }
      return res
    },

    //  用户资产信息
    async fetchUserAssets() {
      const res = await userAssets()
      this.userAssets = res
      if (this.statsOverview) {
        this.statsOverview.exp = res.exp
      }
      return res
    },

    async fetchEnergyStatus() {
      const res = await fetchEnergy()
      this.energy = res
      this.setEnergyCountDown()
    },
    setEnergyStatus(energy: EnergyResponse) {
      this.energy = energy
      this.setEnergyCountDown()
    },
    // 个人页面 统计信息
    async fetchStatsOverview() {
      const res = await fetchStatsOverview()
      this.statsOverview = res

      if (this.userAssets) {
        this.userAssets.exp = res.exp
      }
      if (this.checkInCtRes) {
        this.checkInCtRes.accumulatedDays = res.accumulatedCheckInDays
        this.checkInCtRes.maxContinuousDays = res.maxCheckInCtDays
      }
      return res
    },

    increaseEnergy(delta: number = 1) {
      if (this.energy == null) return
      const result = this.energy.currentEnergy + delta

      if (result > this.energy.energyLimit) {
        this.energy.currentEnergy = this.energy.energyLimit
        this.energy.nextTicketAt = undefined
      } else if (result < 0) {
        this.energy.currentEnergy += 0
      } else {
        this.energy.currentEnergy = result
        this.energy.nextTicketAt = dayjs()
          .add(this.energy.recoverInterval, 'minutes')
          .toString()
        this.setEnergyCountDown()
      }
    },

    setEnergyCountDown() {
      clearInterval(energyTimer)
      this.energyCountDown = null

      if (this.energy == null) return

      if (this.energyCountDown != null) return

      if (
        this.energy.nextTicketAt &&
        this.energy.currentEnergy < this.energy.energyLimit
      ) {
        clearInterval(energyTimer)

        const ms = new Date(this.energy.nextTicketAt).getTime() - Date.now()
        let seconds = Math.floor(ms / 1000)

        if (seconds > 0) {
          this.energyCountDown = seconds
          energyTimer = setInterval(() => {
            if (seconds <= 0) {
              clearInterval(energyTimer)

              this.increaseEnergy()
              this.energyCountDown = null
              this.setEnergyCountDown()
              return
            }

            seconds--
            this.energyCountDown = seconds
          }, 1000)
        }
      }
    },
  },
})

export function subscribeStore(store: CommonStore) {
  watch(
    () => [store.theme, store.token],
    function () {
      db.theme = store.theme
      db.loginToken = store.token

      if (store.theme === 'light') {
        document.documentElement.removeAttribute('theme-dark')
      } else {
        document.documentElement.setAttribute('theme-dark', 'true')
      }
    },
    { immediate: true }
  )

  watch(
    () => store.stageUnit,
    function () {
      db.stageUnit = store.stageUnit

      db.stageUnit?.schedules.forEach(c => {
        store.setCardResponseCache(c.cardId, c)
      })
    },
    { immediate: true }
  )
}

export type CommonStore = ReturnType<typeof useCommonStore>
