<template>
  <Loading
    v-if="data.packageLoading"
    class="h-[var(--ld-viewport-height)]"
  />

  <PackageBrowser
    v-else-if="data.package"
    :package="data.package"
    @chapterCountChange="onChapterCountChange"
  ></PackageBrowser>
</template>

<script setup lang="ts">
import PackageBrowser from './PackageBrowser.vue'
import { useRoute, useRouter } from 'vue-router'
import { reactive } from 'vue'
import { fetchPackageById } from '@/api/package-source'
import { Code } from '@/api/code'

import type { Package } from '@/api/package-source'

const router = useRouter()
const route = useRoute()

const packageId = Number(route.params.id)

const data = reactive({
  package: undefined as Package | undefined,
  packageLoading: true,
})

async function fetchPackage() {
  data.packageLoading = true

  try {
    const res = await fetchPackageById(packageId)

    if (res.code === Code.PackageNotFound) {
      router.replace({
        name: '404',
      })
      return
    }

    data.package = res.data
  } finally {
    data.packageLoading = false
  }
}

function onChapterCountChange(chapterId: string, diff: number) {
  if (data.package == null) return

  const chapterCount = data.package.chapterCardsCount[chapterId] ?? 0
  data.package.chapterCardsCount[chapterId] = chapterCount + diff
}

fetchPackage()
</script>

<style scoped></style>
