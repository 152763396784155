<template>
  <RatioSpacedContainer
    v-if="data.emptyFrames"
    class="text-red-500 text-center h-full"
  >
    {{ _t('没有可用的卡片') }}
  </RatioSpacedContainer>

  <div
    v-if="data.activeFrame != null && data.activeFrame?.face.type !== 'boss'"
    class="flex items-center mt-4 px-4 g-header-width"
  >
    <Icon
      name="close"
      class="cursor-pointer w-20px"
      @click="emit('close')"
    ></Icon>

    <ProgressBar
      :percent="data.progress"
      :color="progressBarColor"
      class="ml-4"
    />
  </div>

  <div
    v-if="data.showComboTip || data.showWrongQuestionsTip"
    class="flex flex-col flex-1 w-full items-stretch"
  >
    <RatioSpacedContainer class="flex-1">
      <RoleImage />

      <span class="my-4 text-center">
        <template v-if="data.showComboTip">
          {{ data.combo }} {{ _t('连击，太厉害了') }}
        </template>

        <template v-else>
          {{ _t('来巩固一下之前不太熟练的部分吧') }}
        </template>
      </span>
    </RatioSpacedContainer>

    <Button
      class="mx-4 mb-4"
      label="继续"
      @click="onContinue"
    ></Button>
  </div>

  <template v-else-if="data.activeFrame != null">
    <ConcreteCard
      v-if="data.activeFrame.type === 'concrete'"
      class="flex-1"
      :key="data.cardLearnRound"
      :face="data.activeFrame.face"
      @event="onEventCollect"
      @star="onStar"
      @next="onNext"
      @done="onVirtualCardDone"
      @close="emit('close')"
    ></ConcreteCard>

    <VirtualCard
      v-else
      class="flex-1"
      :face="data.activeFrame.face"
      @event="onEventCollect"
      @star="onStar"
      @next="onNext"
      @done="onVirtualCardDone"
      @close="emit('close')"
    ></VirtualCard>
  </template>
</template>

<script lang="ts" setup>
import { UnitEventType } from '@/api/learn'
import { reactive, computed } from 'vue'
import ConcreteCard from '@/components/ConcreteCard/ConcreteCard.vue'
import VirtualCard from '@/components/VirtualCard/VirtualCard.vue'
import ProgressBar from '@/components/ProgressBar.vue'
import { useCommonStore } from '@/stores'
import { feedbackStar } from '@/components/ConcreteCard/common/feedback'
import RoleImage from '@/components/ConcreteCard/common/RoleImage.vue'
import { StagesQueue, defaultConfig, QueueStage } from './index'
import { completeReport } from '../report'
import { QueueType, type Frame } from '../queue'
import { useHotKey } from '@/hooks'

import db from '@/db'
import { Interaction } from '@/types/core'

useHotKey('enter,space', () => {
  onContinue()
})

const emit = defineEmits<{
  close: []
  star: [feedbackStar]
}>()

const store = useCommonStore()

let queue: StagesQueue

const data = reactive({
  cardLearnRound: 0,
  stage: QueueStage.Base,
  activeFrame: null as Frame | null,
  progress: 0,
  combo: 0,
  showComboTip: false,
  showWrongQuestionsTip: false,
  emptyFrames: false,
})

const progressBarColor = computed(() => {
  if (
    data.stage === QueueStage.WrongQuestions ||
    data.stage === QueueStage.Done
  ) {
    return 'linear-gradient(269.98deg, var(--amber-500) 0.01%, var(--amber-300) 99.98%)'
  }
  return undefined
})

function loadUnit() {
  if (!store.checkStageUnit()) return

  if (store.stageUnit != null) {
    const debugConfigJson = db.debug.queueConfigV3[QueueType.Stages]

    if (debugConfigJson) {
      queue = new StagesQueue(
        store.stageUnit.schedules,
        JSON.parse(debugConfigJson)
      )
    } else {
      queue = new StagesQueue(store.stageUnit.schedules, defaultConfig)
    }

    const progressCache = db.unitProgressCache[QueueType.Stages]

    if (progressCache) {
      queue.setState(progressCache)
      data.stage = queue.stage
      updateProgress()
    }

    data.emptyFrames = queue.frames.length === 0
    data.activeFrame = queue.activeFrame
  }
}

loadUnit()

function onEventCollect(cardId: number | undefined, event: UnitEventType) {
  if (!data.activeFrame || cardId == null) return

  if (data.activeFrame.face.type !== 'boss') {
    if (event === UnitEventType.CORRECT) {
      data.combo++
    } else {
      data.combo = 0
    }
  }

  queue.onEvent(cardId, event)
  store.insertLearningEvent(cardId, {
    event,
    stage: Interaction.Practice,
    timestamp: Date.now(),
  })
}

function onNext() {
  queue.next()

  if (queue.stage !== data.stage) {
    onStageChange(queue.stage)
  }

  setTimeout(() => {
    data.showComboTip = data.combo > 0 && data.combo % 5 === 0
  })

  data.activeFrame = queue.activeFrame
  data.cardLearnRound++
}

function onContinue() {
  data.showComboTip = false
  data.showWrongQuestionsTip = false
}

function updateProgress() {
  const completedFrames = queue.totalFrames - queue.frames.length
  data.progress = (completedFrames / queue.totalFrames) * 100
}

function onStar(star: feedbackStar) {
  emit('star', star)
  queue.tick()
  updateProgress()

  const state = queue.getState()
  db.unitProgressCache[QueueType.Stages] = state
}

function onVirtualCardDone() {
  queue.tick()
  updateProgress()

  onNext()
}

function onStageChange(stage: QueueStage) {
  data.stage = queue.stage
  data.progress = 0

  if (store.stageUnit == null) return

  if (stage === QueueStage.WrongQuestions) {
    data.showWrongQuestionsTip = true
    data.combo = 0
  }

  if (stage === QueueStage.Done) {
    const cardIds = (store.stageUnit?.schedules ?? []).map(item => item.cardId)

    for (const id of cardIds) {
      store.completeCard(id)
    }
    completeReport()

    delete db.unitProgressCache[QueueType.Stages]
  }
}
</script>

<style scoped></style>
