import { mergeAttributes, Node } from '@tiptap/core'

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    caption: {
      setCaption: () => ReturnType
      toggleCaption: () => ReturnType
    }
  }
}

const Paragraph = Node.create({
  name: 'caption',

  priority: 1000,

  group: 'block',

  marks: '',

  content: 'text*',

  parseHTML() {
    return [{ tag: 'p[data-caption]' }]
  },

  renderHTML({ HTMLAttributes }) {
    return ['p', mergeAttributes({ 'data-caption': true }, HTMLAttributes), 0]
  },

  addCommands() {
    return {
      setCaption:
        () =>
        ({ commands }) => {
          return commands.setNode(this.name)
        },
      toggleCaption:
        () =>
        ({ commands }) => {
          return commands.toggleNode(this.name, 'p')
        },
    }
  },

  addKeyboardShortcuts() {
    return {
      'Mod-Alt-k': () => this.editor.commands.setCaption(),
    }
  },
})

export default Paragraph
