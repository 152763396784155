<template>
  <RatioSpacedContainer
    class="h-[var(--ld-viewport-height)] leading-[1.8] px-6 bg-ld-background"
  >
    <div class="g-body-width flex flex-col items-center">
      <Loading v-if="initLoading" />

      <template v-else-if="status == null && showForm">
        <div class="flex greet items-center">
          <Img
            name="ld-greet"
            class="w-77px"
          />
          <div class="chat">{{ _t('你想制作卡包的目的是？') }}</div>
        </div>

        <div class="flex flex-col w-full gap-4 mt-11 mb-4">
          <Button
            v-for="item of PURPOSES"
            :scene="selectedKey === item.key ? 'choiceSelected' : 'choice'"
            class="w-full"
            @click="selectedKey = item.key"
          >
            <div>
              {{ _t(item.title) }}
            </div>
          </Button>
        </div>

        <Textarea
          v-show="selectedKey === 'other'"
          v-model="customReason"
          class="w-full mb-2 max-h-304px"
          rows="4"
          autoResize
          placeholder="请输入"
        />

        <Button
          :label="_t('提交申请')"
          :loading="submitLoading"
          class="w-full"
          @click="onSubmit"
        ></Button>
      </template>

      <template v-else>
        <Img
          name="ld-look"
          class="w-107px"
        />

        <div class="text-33px mt-4">
          {{ _t('内测申请') }}
        </div>

        <div class="text-24px text-[var(--text-color-secondary)] mb-12">
          {{ _t('提示说明巴拉巴拉巴拉巴拉') }}
        </div>

        <div
          v-if="status === 'closed' || status === 'rejected'"
          class="text-21px text-ld-label-secondary"
        >
          {{ closedOrRejectedReason }}
        </div>

        <div
          v-else-if="status === 'applied'"
          class="text-15px text-ld-label-secondary text-center w-full"
        >
          <Button
            :label="_t('已申请')"
            disabled
            class="w-full mb-2"
          ></Button>

          <div>
            {{ _t('你已加入 waitinglist ') }}
          </div>
          <div>{{ _t('申请成功后将通过『小灵鸭公众号』发送通知') }}</div>
        </div>

        <div
          v-else-if="status === 'approved'"
          class="flex flex-col items-stretch w-full"
        >
          <div
            class="flex items-center justify-center bg-bluegray-200 rounded-12px h-65px px-2"
          >
            <div class="w-40px h-40px relative items-center">
              <LottieBox
                name="check-mark"
                :loop="false"
                :autoplay="true"
                class="absolute absolute-center w-80px h-80px"
              />
            </div>
            <span
              class="text-21px ml-2 font-semibold text-ld-brand-500 leading-[1.4]"
            >
              {{ _t('你已通过申请，快去体验吧') }}
            </span>
          </div>

          <Button
            :label="_t('去体验')"
            :loading="submitLoading"
            class="mt-8 mx-2"
            @click="onToPackage"
          ></Button>
        </div>

        <Button
          v-else
          :label="_t('立即申请')"
          :loading="submitLoading"
          class="w-full"
          @click="onApply"
        ></Button>
      </template>
    </div>
  </RatioSpacedContainer>
</template>
<script setup lang="ts">
import {
  applyAlphaCreator,
  fetchAlphaCreatorStatus,
  type ApplyStatus,
} from '@/api/user'
import RatioSpacedContainer from '@/components/RatioSpacedContainer.vue'
import { ref } from 'vue'
import Textarea from 'primevue/textarea'
import LottieBox from '@/components/LottieBox.vue'
import { useRouter } from 'vue-router'
import { useClipboard } from '@vueuse/core'
import { openWechatApp } from '@/utils/wechat'

const PURPOSES = [
  {
    title: '✍️ 自己学习',
    key: 'own',
  },
  {
    title: '🌐 分享给他人使用',
    key: 'share',
  },
  {
    title: '🤔 其他',
    key: 'other',
  },
]

const router = useRouter()

const initLoading = ref(true)
const submitLoading = ref(false)
const status = ref<ApplyStatus>()
const closedOrRejectedReason = ref<string>()
const showForm = ref(false)
const selectedKey = ref<string>()
const customReason = ref('')

const { copy } = useClipboard({ source: '' })

function onApply() {
  if (!_global.isInsideWechat) {
    _confirm({
      type: 'wechat',
      icon: {
        name: 'ld-avatar',
        type: 'svg',
      },
      okText: _t('复制名称、去微信'),
      content: _t('目前仅支持通过微信公众号申请\n请前往微信搜索：小灵鸭公众号'),
      async onConfirm() {
        copy('小灵鸭公众号')
        openWechatApp()

        return false
      },
    })
    return
  }
  showForm.value = true
}

function onSubmit() {
  if (selectedKey.value == null) {
    _message.info(_t('请选择你申请内测的目的'))
    return
  }
  if (selectedKey.value === 'other' && customReason.value.trim() === '') {
    _message.info(_t('请填写内容'))
    return
  }

  const purpose = PURPOSES.find(item => item.key === selectedKey.value)!

  submitLoading.value = true
  applyAlphaCreator({
    purpose: purpose.title,
    customReason: customReason.value,
  })
    .then(() => {
      status.value = 'applied'
      showForm.value = false
    })
    .finally(() => {
      submitLoading.value = false
    })
}

function fetchApplyStatus() {
  fetchAlphaCreatorStatus()
    .then(res => {
      status.value = res.status
      closedOrRejectedReason.value = res.reason
    })
    .finally(() => {
      initLoading.value = false
    })
}

function onToPackage() {
  router.push({
    name: 'shelf',
  })
}

fetchApplyStatus()
</script>
<style scoped>
.greet .chat {
  background-color: white;
  border-radius: 18px;
  padding: 12px 16px;
  font-size: 17px;
  line-height: 22px;
  height: fit-content;
  border: 1px solid var(--ld-border);
  box-shadow: 0px 0px 2px 0px var(--slate-300);
}
</style>
