<template>
  <div
    ref="textRef"
    :style="{
      fontSize: `${fontSize}px`,
    }"
  >
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'

const textRef = ref(null)

const fontSize = computed(() => {
  const textElement = textRef.value as HTMLElement | null
  if (!textElement) return 21
  const text = textElement.innerText
  return getFontSizeForText(text)
})

function getFontSizeForText(text: string): number {
  const textLength = text.length
  const chineseCharCount = text.match(/[\u4e00-\u9fa5]/g)?.length || 0
  const charCount = textLength + chineseCharCount
  // 从45px开始，每增加一个字符减小2px 最小21px
  return Math.max(21, 45 - charCount * 2)
}
</script>

<style scoped></style>
