<template>
  <RatioSpacedContainer v-show="showLoading">
    <div class="loading-widget">
      <div class="oval oval-1"></div>
      <div class="oval oval-2"></div>
      <div class="oval oval-3"></div>
    </div>
  </RatioSpacedContainer>
</template>

<script lang="ts" setup>
import { ref, onBeforeMount } from 'vue'

// loading 延迟一会后再出现
const SHOW_DELAY = 200

const showLoading = ref(false)

onBeforeMount(() => {
  setTimeout(() => {
    showLoading.value = true
  }, SHOW_DELAY)
})
</script>

<style scoped>
.loading-widget {
  width: 60px;
  height: 72px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.oval {
  width: 10px;
  height: 10px;
  background-color: var(--primary-color);
  border-radius: 50%;
  position: relative;
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.oval-1 {
  animation-name: bounce-1;
}

.oval-2 {
  animation-name: bounce-2;
}

.oval-3 {
  animation-name: bounce-3;
}
/* [0, -11, 15, -11, -14] */
@keyframes bounce-1 {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-11px);
  }
  40% {
    transform: translateY(15px);
  }
  60% {
    transform: translateY(-11px);
  }
  80% {
    transform: translateY(-14px);
  }
  100% {
    transform: translateY(0);
  }
}
/* [0, 1, -27, 19, -17] */
@keyframes bounce-2 {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(1px);
  }
  40% {
    transform: translateY(-27px);
  }
  60% {
    transform: translateY(19px);
  }
  80% {
    transform: translateY(-17px);
  }
  100% {
    transform: translateY(0);
  }
}
/* [-5, 1, -4, -31, 6] */
@keyframes bounce-3 {
  0% {
    transform: translateY(0);
  }
  20% {
    transform: translateY(-5px);
  }
  40% {
    transform: translateY(1px);
  }
  60% {
    transform: translateY(-4px);
  }
  80% {
    transform: translateY(-31px);
  }
  100% {
    transform: translateY(6px);
  }
}
</style>
