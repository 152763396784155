<template>
  <div class="pt-2 overflow-hidden flex flex-col h-full bg-ld-background">
    <PublicPackage
      v-if="currentPkg"
      class="flex-1 overflow-hidden"
      :pkg="currentPkg"
      :hashId="currentPkg.hashId"
      showBackBtn
      @back="currentPkg = null"
      @challenge="emit('packageChallenge', $event)"
    />

    <div
      v-show="!currentPkg"
      class="flex-1 flex flex-col overflow-hidden"
    >
      <div class="flex items-enter mt-2">
        <span class="text-19px font-600 mx-auto">
          {{ _t('卡包推荐') }}
        </span>

        <Icon
          name="close-circle"
          class="cursor-pointer absolute right-4 z-1 w-25px"
          @click="emit('done')"
        ></Icon>
      </div>
      <RecommendPkgList
        class="overflow-auto flex-1 px-2"
        @pkgClick="onPkgClick"
      >
        <template #header>
          <div class="text-ld-brand-500 text-21px font-600 text-center mt-6">
            {{ _t('挑一挑感兴趣的卡包，开始学习吧') }}
          </div>
        </template>
      </RecommendPkgList>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { SearchedPackage } from '@/api/package-source'
import PublicPackage from '@/components/PublicPackage.vue'
import RecommendPkgList from '@/components/RecommendPkgList.vue'
import { ref } from 'vue'

const currentPkg = ref<SearchedPackage | null>(null)

const emit = defineEmits<{
  packageChallenge: [pkgId: number]
  done: []
}>()

function onPkgClick(pkg: SearchedPackage) {
  currentPkg.value = pkg
}
</script>

<style scoped></style>
