<template>
  <div
    class="text-ld-brand-500 font-600 inline"
    :class="{
      'cursor-pointer': !props.loading,
      'opacity-60': props.loading,
    }"
    @click="onClick"
  >
    <slot>
      {{ props.label }}
    </slot>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{
  label?: string
  loading?: boolean
}>()

const emit = defineEmits<{
  click: [any]
}>()

function onClick(evt: any) {
  if (props.loading) {
    return
  }
  emit('click', evt)
}
</script>

<style scoped></style>
