export function wechatLogin() {
  // prod wechat login link: https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8c050b3c2831f61c&redirect_uri=https%3A%2F%2Fm.lingduck.cn%2Fwxcallback&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect
  const prefix = _global.isLocal
    ? '/__local__'
    : _global.isStaging
      ? '/__staging__'
      : ''
  const redirectURL = location.protocol + `//m.lingduck.cn${prefix}/wxcallback`
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8c050b3c2831f61c&redirect_uri=${encodeURIComponent(
    redirectURL
  )}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
  location.replace(url)
}

// 回跳到当前 URL
// 该函数只负责获取 openId，所以在回跳到 wxcallback 时通过携带 login=false
// 避免自动登录微信账号
export function wechatRedirect() {
  const prefix = _global.isLocal
    ? '/__local__'
    : _global.isStaging
      ? '/__staging__'
      : ''
  const redirectURL =
    location.protocol +
    `//m.lingduck.cn${prefix}/wxcallback?redirect=${encodeURI(
      location.pathname + location.search
    )}&login=false`
  const url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx8c050b3c2831f61c&redirect_uri=${encodeURIComponent(
    redirectURL
  )}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`
  location.replace(url)
}

export function openWechatApp() {
  window.location.href = 'weixin://'
}
