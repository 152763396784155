<template>
  <div class="md-lg">
    <WxQRCode @code="onWxCode" />
  </div>
</template>

<script lang="ts" setup>
import WxQRCode from '@/components/WxQRCode.vue'

const emit = defineEmits<{
  // string: wx code
  done: [string]
}>()

function onWxCode(code: string) {
  emit('done', code)
}
</script>

<style scoped></style>
