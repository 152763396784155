<template>
  <div
    v-show="displayCount"
    class="badge"
  >
    {{ displayCount }}
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

const props = defineProps<{
  count: number
}>()

const displayCount = computed(() => {
  if (props.count <= 0) {
    return null
  }
  if (props.count > 99) {
    return '99'
  }
  return props.count.toString()
})
</script>

<style scoped>
.badge {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: DIN;
  color: white;
  position: absolute;
  min-width: 16px;
  height: 16px;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  background-color: var(--red-500);
  outline: 3px solid white;
  border-radius: 5px;
  transform: rotate(-6deg);
  font-weight: 700;
  padding: 2px;
}
</style>
