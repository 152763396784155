<template>
  <div
    ref="containerRef"
    class="w-full progress-bar-container h-16px"
    :style="{
      borderRadius: props.borderRadius,
    }"
  >
    <div
      class="w-full relative h-full overflow-hidden rounded-12px flex items-center justify-center"
    >
      <span class="relative text-gray-400">{{ props.text }}</span>

      <div
        :class="['progress-bar-thumb overflow-hidden', `${props.align}-0`]"
        :style="thumbStyle"
      >
        <span
          class="text-gray-50 absolute top-50% whitespace-nowrap"
          :style="thumbTextStyle"
        >
          {{ props.text }}
        </span>
      </div>
    </div>

    <div
      v-if="props.lottie"
      class="top-1/2 absolute transition-left duration-400"
      :style="`left: ${props.percent}%`"
    >
      <span>
        <span
          ref="lottieBox"
          class="w-15 h-15 translate-x--1/2 translate-y--1/2 inline-block absolute pointer-events-none"
        ></span>
      </span>
    </div>

    <div
      v-if="props.highlight"
      class="progress-bar-hightlight"
      :style="hightlightStyle"
    ></div>
  </div>
</template>
<script setup lang="ts">
import { computed, onUnmounted, watch } from 'vue'
import { onMounted, ref } from 'vue'
import animationData from '@/assets/lottie/sparkle.json'
import lottie, { type AnimationItem } from 'lottie-web'
import { onActivated } from 'vue'

const lottieBox = ref(null)
const containerRef = ref<HTMLDivElement>()
const containerWidth = ref(0)

const props = withDefaults(
  defineProps<{
    percent: number
    color?: string
    align?: 'left' | 'right'
    text?: string
    lottie?: boolean
    highlight?: boolean
    borderRadius?: number
  }>(),
  {
    align: 'left',
    lottie: true,
    highlight: true,
    borderRadius: 8,
  }
)

const thumbStyle = computed(() => {
  const sytles = [`width: ${props.percent}%;`]

  if (props.color) {
    sytles.push(`background: ${props.color};`)
  }

  if (props.align === 'left') {
    sytles.push(
      `border-radius: 0px ${props.borderRadius}px ${props.borderRadius}px 0px;`
    )
  }

  if (props.align === 'right') {
    sytles.push(
      `border-radius: ${props.borderRadius}px 0px 0px ${props.borderRadius}px;`
    )
  }

  return sytles.join('')
})

const thumbTextStyle = computed(() => {
  return `left: ${
    containerWidth.value / 2
  }px; transform: translate(-50%, -50%);`
})

const hightlightStyle = computed(() => {
  return `width: calc(${props.percent}% - 16px);${props.align}: 8px;`
})

function onWindowResize() {
  containerWidth.value = containerRef.value?.clientWidth ?? 0
}

let animation: AnimationItem | null = null
onMounted(() => {
  containerWidth.value = containerRef.value?.clientWidth ?? 0

  window.addEventListener('resize', onWindowResize)

  if (lottieBox.value) {
    animation = lottie.loadAnimation({
      container: lottieBox.value,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      animationData: animationData,
    })
    animation?.goToAndStop(animation.totalFrames, true)
  }
  // 监听进度条变化
  watch(
    () => props.percent,
    () => {
      animation?.stop()
      animation?.play()
    }
  )
})

onActivated(() => {
  onWindowResize()
})

onUnmounted(() => {
  window.removeEventListener('resize', onWindowResize)
  animation?.destroy()
})
</script>
<style scoped>
.progress-bar-container {
  position: relative;
  background: var(--gray-200);
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: white;
  line-height: 16px;
  border-radius: 12px;
}

.progress-bar-thumb {
  height: 100%;
  transition: width 0.4s;
  position: absolute;
  top: 0;
  background: linear-gradient(
    269.98deg,
    var(--ld-brand-600) 0.01%,
    var(--ld-brand-400) 99.98%
  );
  z-index: 0;
}
.progress-bar-hightlight {
  height: 30%;
  transition: width 0.4s;
  position: absolute;
  border-radius: 8px;
  top: 25%;
  background: white;
  z-index: 1;
  opacity: 0.2;
}
:deep(.progress-bar-particle-lottie) {
  fill: var(--ld-brand-600);
  stroke: var(--ld-brand-600);
}
</style>
