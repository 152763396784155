<template>
  <AlphaCreatorApply
    v-if="feature === FeatureType.AlphaCreatorApply"
  ></AlphaCreatorApply>
</template>
<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import AlphaCreatorApply from './AlphaCreatorApply.vue'

enum FeatureType {
  AlphaCreatorApply = 'alphaCreatorApply',
}

const route = useRoute()
const router = useRouter()
const feature = route.query.name as FeatureType | undefined

if (feature == null || !Object.values(FeatureType).includes(feature)) {
  router.replace({
    name: '404',
  })
}
</script>
<style scoped></style>
