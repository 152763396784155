<template>
  <div
    class="rounded-full bg-whiteMask flex items-center justify-center overflow-hidden"
    :style="{
      width: `${props.size}px`,
      height: `${props.size}px`,
    }"
  >
    <Img
      v-if="pkg.style.icon.type === 'img'"
      :name="pkg.style.icon.content"
      :key="pkg.style.icon.content"
      :style="{
        width: `${props.iconSize}px`,
      }"
    />

    <span v-else>
      {{ pkg.style.icon.content }}
    </span>

    <div
      v-if="pkg.packageAccess == null"
      class="absolute bottom-0 left-0 w-full h-28px text-center pt-5px"
    >
      <div class="absolute bg-black opacity-30 w-full h-full"></div>
      <div
        class="relative text-12px font-semibold text-white h-full flex items-center justify-center"
      >
        {{ _t('试学中') }}
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { PackageBasic } from '@/api/package-source'

// size 为组件整体大小
// iconSize 为中间图标的大小
// NOTE: 需要注意的是 emoji 图标需要通过上层传递 text size 来控制
const props = defineProps<{
  pkg: PackageBasic
  size: number
  iconSize: number
}>()
</script>
<style scoped></style>
