<template>
  <Loading
    v-if="pageLoading"
    class="h-full"
  ></Loading>

  <RatioSpacedContainer
    v-else-if="endLoading"
    class="items-center text-22px leading-30px g-body-width h-[var(--ld-viewport-height)]"
  >
    <Loading
      class="mb-8"
      :full="false"
    ></Loading>
    <div>{{ _t('正在收拾行囊') }}</div>
    <div>{{ _t('准备进入灵鸭世界') }}</div>
  </RatioSpacedContainer>

  <div
    v-else-if="identityTagRoot != null"
    class="h-[var(--ld-viewport-height)] flex flex-col bg-ld-background"
  >
    <AppBar @back="clickBack" />

    <div class="flex flex-col flex-1 h-0 p-8 pt-6 g-body-width">
      <div class="flex greet items-center">
        <Img name="ld-greet" />
        <div class="chat">{{ _t('请选择你的学习阶段') }}</div>
      </div>
      <div class="overflow-y-auto flex-1 basis-0 flex flex-col gap-4 mt-44px">
        <Button
          v-for="e in identityTagRoot.children"
          :key="e.key"
          :scene="selectedFirstTag?.key === e.key ? 'choiceSelected' : 'choice'"
          @click="selectedFirstTagKey = e.key"
        >
          <div class="py-1">{{ e.name }}</div>
        </Button>
      </div>

      <Button
        class="mt-4 w-full"
        label="继续"
        :disabled="!selectedFirstTagKey"
        @click="onEnd"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue'
import { fetchIdentityTags } from '@/api/package-source'

import { tree2Map } from '@/utils/tag'
import { useRoute, useRouter } from 'vue-router'
import { updateIdentityTags } from '@/api/user'
import AppBar from '@/mobile/components/AppBar.vue'

import type { IdentityTag } from '@/types/core'

let tagCacheMap = new Map<string, IdentityTag>()

const route = useRoute()
const router = useRouter()

const identityTagRoot = ref<IdentityTag | null>(null)
const selectedFirstTagKey = ref<string | null>(null)

const pageLoading = ref(false)
const endLoading = ref(false)

const selectedFirstTag = computed(() => {
  if (selectedFirstTagKey.value == null || identityTagRoot.value == null) {
    return null
  }

  return tagCacheMap.get(selectedFirstTagKey.value)
})

pageLoading.value = true
fetchIdentityTags('main')
  .then(res => {
    identityTagRoot.value = res.tree
    tagCacheMap = tree2Map(res.tree)
  })
  .finally(() => {
    pageLoading.value = false
  })

function clickBack() {
  router.push({
    name: 'landing',
  })
}

async function onPageLeave() {
  if (route.query.redirect) {
    const redirect = decodeURIComponent(route.query.redirect as string)
    router.push(redirect)
  } else {
    router.push({
      path: '/',
      query: {
        to: 'recommend-list',
      },
    })
  }
}

async function onEnd() {
  if (!selectedFirstTagKey.value) {
    return
  }

  await updateIdentityTags([selectedFirstTagKey.value])

  onPageLeave()
}
</script>

<style scoped>
.greet :deep(img) {
  width: 77px;
}

.greet .chat {
  background-color: white;
  border-radius: 18px;
  padding: 12px 16px;
  font-size: 17px;
  line-height: 22px;
  height: fit-content;
}
</style>
