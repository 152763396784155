<template>
  <div class="min-h-40px flex items-center px-4">
    <div
      v-if="props.visible"
      class="tip-box w-full line-clamp-3"
    >
      <slot>
        {{ tipText }}
      </slot>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { shuffle } from 'lodash-es'

const props = withDefaults(
  defineProps<{
    visible?: boolean
    tipTimes: number
  }>(),
  {
    visible: false,
  }
)

// 选错提示文字集合 '再想想','再看看','再考虑下','换个思路','是吗','真的吗','别着急','慢慢来',
const tipTexts = shuffle(['再想想', '换个思路', '别着急', '慢慢来'])

// 随机选取一个提示文字 并且跟 tipTimes 相关
const tipText = computed(() => {
  return tipTexts[props.tipTimes % tipTexts.length]
})
</script>
<style scoped>
.tip-box {
  color: var(--yellow-800);
  font-size: 17px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.5px;
}
</style>
