<template>
  <div
    class="pkg-card grow-0 h-70px w-70px relative rounded-12px overflow-hidden flex flex-col relative cursor-pointer"
    :style="{
      backgroundColor: `var(--${color}-400)`,
      borderColor: `rgb(from var(--ld-neutral-950) r g b / 30%)`,
    }"
    @click="toggle"
  >
    <div
      class="pkg-part-5 absolute top-0 left-0 w-full h-full"
      :style="{
        backgroundColor: `var(--${color}-200)`,
      }"
    ></div>

    <div
      class="flex relative mb--1px"
      :style="{
        color: `var(--${color}-300)`,
      }"
    >
      <Icon
        name="package-part-2"
        class="w-33px z-1"
      />
      <Icon
        name="package-part-1"
        class="w-30px pkg-part-1 ml--1"
      />
    </div>

    <div
      class="pkg-part-3 w-full flex-1 relative"
      :style="{
        background: `linear-gradient(180deg,  var(--${color}-300) 0%, var(--${color}-300)100%)`,
      }"
    ></div>

    <div
      class="z-1 rounded-full absolute w-48px h-48px text-32px bg-whiteMask flex items-center justify-center absolute-center"
    >
      <Img
        v-if="props.icon.type === 'img'"
        :name="props.icon.content"
        :key="props.icon.content"
        class="w-32px"
      />

      <span v-else>
        {{ props.icon.content }}
      </span>
    </div>
  </div>

  <OverlayPanel
    ref="op"
    :pt="{ root: { class: 'w-[385px]' } }"
  >
    <div
      v-if="!enabledUseIcon"
      class="max-h-[300px] overflow-auto"
    >
      <div
        v-for="category in emojiTable"
        :key="category.label"
      >
        <div>{{ category.label }}</div>
        <div class="grid grid-cols-9 gap-2">
          <span
            v-for="emoji in category.emojis"
            :key="emoji"
            class="text-2xl emoji"
            @click="onEmojiSelect(emoji)"
          >
            {{ emoji }}
          </span>
        </div>
      </div>
    </div>
    <TabView
      v-else
      v-model:activeIndex="tabIndex"
      :pt="{ panelContainer: { class: 'p-0' } }"
    >
      <TabPanel
        :header="_t('表情符号')"
        :pt="{ root: { class: 'max-h-[300px] overflow-auto' } }"
      >
        <div
          v-for="category in emojiTable"
          :key="category.label"
        >
          <div>{{ category.label }}</div>
          <div class="grid grid-cols-9 gap-2">
            <span
              v-for="emoji in category.emojis"
              :key="emoji"
              class="text-2xl emoji"
              @click="onEmojiSelect(emoji)"
            >
              {{ emoji }}
            </span>
          </div>
        </div>
      </TabPanel>
      <TabPanel :header="_t('图标')">
        <div class="flex gap-3 flex-wrap py-4">
          <div
            v-for="icon in PKG_ICONS"
            :key="icon"
            :class="['cursor-pointer']"
            @click="onIconSelect(icon)"
          >
            <Img
              :name="icon"
              class="w-44px"
            />
          </div>
        </div>
      </TabPanel>
    </TabView>
  </OverlayPanel>
</template>

<script setup lang="ts">
import OverlayPanel from '@/components/OverlayPanel.vue'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import emojiTable from './emoji.json'
import { ref } from 'vue'
import type { PackageIconEmoji, PackageIconImage } from '@/api/package-source'
import { PKG_ICONS } from '@/utils/package'

const props = defineProps<{
  color: string
  icon: PackageIconEmoji | PackageIconImage

  // 可以修改自定义卡包的图标
  enabledUseIcon: boolean
}>()

const emit = defineEmits<{
  select: [PackageIconEmoji | PackageIconImage]
}>()

const tabIndex = ref(props.icon.type === 'img' ? 1 : 0)

const op = ref()

const toggle = (event: MouseEvent) => {
  op.value.toggle(event)
}

function onEmojiSelect(emoji: string) {
  emit('select', {
    type: 'emoji',
    content: emoji,
  })
  op.value.hide()
}

function onIconSelect(icon: string) {
  emit('select', {
    type: 'img',
    content: icon,
  })
  op.value.hide()
}
</script>

<style scoped>
.emoji {
  cursor: pointer;
  border: 1px solid transparent;
  border-radius: 4px;
}

.emoji:hover {
  border: 1px solid var(--primary-color);
}

.pkg-card {
  box-shadow: 0px 0px 2px rgb(from var(--ld-neutral-950) r g b / 30%);
}

.pkg-part-1 {
  filter: drop-shadow(0px -1px 1px var(--slate-300));
}
</style>
