<template>
  <div class="flex flex-col g-header-width overflow-x-hidden relative">
    <Icon
      v-if="player"
      name="arch"
      class="absolute top-0 w-full h-80px text-ld-brand-100"
    ></Icon>
    <div
      v-else
      class="circle-bg"
    ></div>

    <div class="h-44px flex px-4 z-1">
      <Icon
        name="close"
        class="cursor-pointer w-20px"
        @click="emit('close')"
      ></Icon>
    </div>

    <div
      v-if="player"
      class="relative flex mx-16px h-44px"
    >
      <div class="flex-1 flex">
        <Avatar
          :avatar="player.avatar"
          class="user-avatar"
          imgStyle="s"
        />
        <div
          class="flex-1 flex flex-col items-start justify-between py-4px ml--4"
        >
          <ProgressBar
            :percent="player.hp"
            :color="playerProgressColor"
            align="left"
            :lottie="false"
            :highlight="false"
          />
          <div class="user-name ml-6 line-clamp-1">{{ player.name }}</div>
        </div>
      </div>
      <Icon
        name="sword"
        class="h-44px"
      />
      <div class="flex-1 flex">
        <div
          class="flex-1 flex flex-col items-end justify-between py-4px relative mr--4"
        >
          <ProgressBar
            v-if="npc"
            :percent="npc?.hp"
            :color="npcProgressColor"
            align="right"
            :highlight="false"
            :lottie="false"
          />
          <div
            v-if="npc?.name"
            class="npc-name mr-6 line-clamp-1"
          >
            {{ npc?.name }}
          </div>
          <transition
            name="monster-hint"
            mode="out-in"
          >
            <span
              v-if="monsterHint"
              class="monster-hint top--20px"
            >
              {{ monsterHint }}
            </span>
          </transition>
        </div>
        <Img
          v-if="npc?.avatar"
          class="npc-avatar npc-shake"
          :name="npc?.avatar"
        />
      </div>
      <div></div>
    </div>
    <div
      v-else-if="npc"
      class="flex flex-col items-center justify-center mt--22px relative overflow-hidden mx-36px"
    >
      <Img
        v-if="npc?.avatar"
        :name="npc?.avatar"
        class="w-128px npc-shake"
        :class="{ 'npc-defeated': !face }"
      />
      <ProgressBar
        :percent="npc?.hp"
        :color="npcProgressColor"
        class="mt--16px"
        :highlight="false"
        :lottie="false"
      />
      <transition
        name="monster-hint"
        mode="out-in"
      >
        <span
          v-if="monsterHint"
          class="monster-hint bottom-20px"
        >
          {{ monsterHint }}
        </span>
      </transition>
    </div>
  </div>

  <transition
    name="slide"
    mode="out-in"
  >
    <ConcreteCard
      v-if="face"
      :key="index"
      :face="face"
      class="flex-1"
      @event="onEvent"
      @star="onStar"
      @next="onNext"
      @close="emit('close')"
    ></ConcreteCard>
    <slot
      v-else
      name="end"
    ></slot>
  </transition>
</template>

<script setup lang="ts">
import type { ConcreteCardFace } from '@/types/core'
import ConcreteCard from './ConcreteCard/ConcreteCard.vue'
import type { feedbackStar } from './ConcreteCard/common/feedback'
import ProgressBar from './ProgressBar.vue'
import { UnitEventType } from '@/api/learn'
import { ref } from 'vue'
defineProps<{
  face?: ConcreteCardFace
  index: number

  npc?: {
    hp: number
    avatar?: string
    name?: string
  }

  player?: {
    hp: number
    avatar?: string
    name?: string
  }
}>()
const emit = defineEmits<{
  close: []
  event: [cardId: number | undefined, UnitEventType]
  star: [star: feedbackStar]
  next: []
}>()

const combo = ref(0)

const monsterHint = ref('')

function onEvent(cardId: number | undefined, event: UnitEventType) {
  emit('event', cardId, event)
  if (event === UnitEventType.CORRECT) {
    combo.value++
    showComboHint()
    animateCSS('.npc-shake', 'shake', '')
  } else {
    combo.value = 0
  }
}
function onStar(star: feedbackStar) {
  emit('star', star)
}
function onNext() {
  emit('next')
}

function showComboHint() {
  if (combo.value > 1) {
    monsterHint.value = `Combo x ${combo.value}`
  } else {
    monsterHint.value = `Hit + 1`
  }
  setTimeout(() => {
    monsterHint.value = ''
  }, 500)
}

const animateCSS = (element: string, animation: string, prefix = 'animate__') =>
  // We create a Promise and return it
  new Promise(resolve => {
    const animationName = `${prefix}${animation}`
    const node = document.querySelector(element)

    node?.classList.add(`${prefix}animated`, animationName)

    // When the animation ends, we clean the classes and resolve the Promise
    function handleAnimationEnd(event: any) {
      event.stopPropagation()
      node?.classList.remove(`${prefix}animated`, animationName)
      resolve('Animation ended')
    }

    node?.addEventListener('animationend', handleAnimationEnd, { once: true })
  })

const npcProgressColor =
  'linear-gradient(270deg, var(--red-500) 0.01%, rgb(from var(--red-500) r g b / 50%) 99.98%)'

const playerProgressColor =
  'linear-gradient(270deg, rgb(from var(--green-500) r g b / 50%) 0.01%, var(--green-500) 99.98%)'
</script>

<style scoped>
.npc-avatar,
.user-avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;
  background-color: white;
  flex-shrink: 0;
  z-index: 1;
}

.npc-avatar {
  border: 2px solid var(--red-500);
}

.user-avatar {
  border: 2px solid var(--green-500);
}

.npc-name,
.user-name {
  font-size: 15px;
  font-weight: 600;
  line-height: 1;
}

.monster-hint {
  position: absolute;
  left: 0;
  color: var(--red-400);
  font-family: Comic Sans MS;
  font-size: 15px;
  font-weight: 400;
  transform: rotate(-5.89deg);
  z-index: 1;
  white-space: nowrap;
}

/* npc-half 被击败状态 */

.npc-defeated {
  filter: grayscale(100%);
  transform: rotate(8deg);
  transition:
    filter 0.3s,
    transform 0.3s;
  transition-delay: 0.3s;
}

.circle-bg {
  position: absolute;
  left: -50%;
  width: 200%;
  height: 100px;
  background-color: var(--ld-brand-100);
  border-bottom-left-radius: 100%;
  border-bottom-right-radius: 100%;
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-8px);
  }
  50% {
    transform: translateX(8px);
  }
  75% {
    transform: translateX(-8px);
  }
  100% {
    transform: translateX(0);
  }
}

.shake {
  animation: shake 0.2s ease-in-out;
}

.monster-hint-enter-active,
.monster-hint-leave-active {
  transition: opacity 500ms;
}

.monster-hint-enter {
  opacity: 1; /* 初始位置 */
}
.monster-hint-leave-to {
  opacity: 0; /* 最终位置 */
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 300ms; /* 过渡效果的时间 */
}
.slide-enter-from {
  transform: translateX(100%);
}
.slide-enter-to {
  transform: translateX(0%);
}
.slide-leave-from {
  transform: translateX(0%);
}
.slide-leave-to {
  transform: translateX(-100%); /* 最终位置 */
}
</style>
