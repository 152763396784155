<template>
  <div class="h-[var(--ld-viewport-height)]">
    <Loading
      v-if="loading"
      class="h-full"
    />

    <UnitReview
      v-else
      :cards="reviewCards"
      @back="emit('done')"
    />
  </div>
</template>
<script setup lang="ts">
import { ref } from 'vue'
import UnitReview from '@/mobile/components/UnitReview.vue'
import { fetchAtlasStageCards, type AtlasStageCard } from '@/api/atlas'

const props = defineProps<{
  stageId: number
}>()

const emit = defineEmits<{
  done: []
}>()

const loading = ref(true)

const reviewCards = ref<AtlasStageCard[]>([])

fetchAtlasStageCards(props.stageId)
  .then(res => {
    reviewCards.value = res.cards
  })
  .finally(() => {
    loading.value = false
  })
</script>
<style scoped></style>
