<template>
  <PublicPackage
    :hash-id="hashId"
    :show-back-btn="router.canGoBack"
    class="h-[var(--ld-viewport-height)] bg-ld-background"
    @back="onBack"
    @challenge="onPkgChallenge"
    @view="onView"
    @need-auth="onNeedAuth"
  />
</template>
<script setup lang="ts">
import PublicPackage from '@/components/PublicPackage.vue'
import { useRoute, useRouter } from 'vue-router'

const route = useRoute()
const router = useRouter()
const hashId = route.params.hashId as string

function onBack() {
  if (router.canGoBack) {
    router.back()
  } else {
    router.replace({
      name: 'atlas',
    })
  }
}

function onPkgChallenge(id: number) {
  router.push({
    name: 'atlas',
    query: {
      pkgId: id,
    },
  })
}

function onView(id: number) {
  router.push({
    name: 'package',
    params: {
      id: id,
    },
  })
}

function onNeedAuth() {
  router.push({
    name: 'auth',
    query: {
      from: encodeURIComponent(route.fullPath),
    },
  })
}
</script>

<style></style>
