// 管理 Dialog/Toast 的 z-index
const BASE_Z_INDEX = 2000

const Z_INDEX_STEP = 1

export class ZIndexMgmt {
  zIndex: number

  constructor() {
    this.zIndex = BASE_Z_INDEX
  }

  nextZIndex() {
    this.zIndex += Z_INDEX_STEP
    return this.zIndex
  }
}

export default new ZIndexMgmt()
