<template>
  <div class="flex flex-col items-center space-y-2">
    <img
      ref="imgRef"
      :src="props.src"
      alt="image"
      class="w-256px h-256px"
    />
    <TextButton
      :label="_t('保存')"
      @click="crop"
    ></TextButton>
  </div>
</template>

<script setup lang="ts">
import Cropper from 'cropperjs'
import 'cropperjs/dist/cropper.css'
import { onBeforeUnmount, onMounted, ref } from 'vue'

const props = defineProps<{
  src: string
}>()

const emit = defineEmits<{
  done: [HTMLCanvasElement | undefined]
}>()

const imgRef = ref()

let cropper: Cropper | null = null

onMounted(() => {
  cropper = new Cropper(imgRef.value, {
    initialAspectRatio: 1,
    aspectRatio: 1,
    viewMode: 1,
    autoCropArea: 1,
  })
})

onBeforeUnmount(() => {
  cropper?.destroy()
})

function crop() {
  const canvas = cropper?.getCroppedCanvas({
    maxWidth: 1024,
    maxHeight: 1024,
  })

  emit('done', canvas)
}
</script>

<style scoped></style>
