<template>
  <TrailHeader
    v-if="props.package.packageAccess == null"
    :package="props.package"
    @unlock="onUnlock"
  />

  <div class="vstack items-stretch gap-0 h-100vh bg-ld-background">
    <div class="header flex items-center">
      <button @click="onBack">
        <i class="pi pi-arrow-left"></i>
      </button>

      <Breadcrumb
        class="justify-self-center mx-auto bg-[transparent]"
        :model="breadCrumbItems"
      >
        <template #item="{ item, label }">
          <button @click="onBreadCrumbClick(item)">
            {{ label }}
          </button>
        </template>
      </Breadcrumb>

      <div class="ml-auto flex items-center">
        <template v-if="selectedCard">
          <DebugButton
            v-if="data.cardResList.length > 0"
            class="ml-4"
            :label="_t('配对卡')"
            @click="onDisplayMatchCard"
          />

          <DebugButton
            v-if="data.cardResList.length > 0"
            class="ml-4"
            :label="_t('Boss卡')"
            @click="onDisplayBossCard"
          />
        </template>
      </div>
    </div>

    <Divider class="my-0" />

    <div class="flex flex-1 overflow-hidden">
      <div class="basis-1/5 overflow-y-scroll overflow-x-hidden min-w-200px">
        <ChapterTree
          :chapterCardsMap="cardListCache"
          :package="props.package"
          :selectedCardId="data.selectedCardId"
          :selectedChapterId="data.selectedChapterId"
          @chapterSelect="onChapterSelect"
          @cardSelect="onCardSelect"
          @createChapter="onChapterCreated"
          @renameChapter="onChapterRename"
          @deleteChapter="onChapterDelete"
          @editCard="onCardEdit"
          @createCardBefore="onCardCreateBefore"
          @createCardAfter="onCardCreateAfter"
          @cardCopy="onCardCopy"
          @deleteCard="onCardDeleteInTree"
          @moveChapter="onChapterMove"
          @chapterCardsFetch="onChapterCardsFetch"
        />
      </div>

      <Divider
        layout="vertical"
        class="mx-0"
      />

      <SlickList
        :list="data.cardResList"
        axis="y"
        class="p-4 flex-1 basis-3/5 overflow-auto max-w-816px min-w-376px mx-auto flex flex-col"
        useDragHandle
        @sortEnd="onNotesSortEnd"
      >
        <div class="mb-4 text-right text-gray-300 flex items-center gap-4">
          <div class="text-black text-lg">{{ selectedChapter.title }}</div>

          <DebugLabel>
            pkgId:{{ props.package.id }}, chapterId:{{ data.selectedChapterId }}
          </DebugLabel>

          <div class="ml-auto flex gap-2">
            <template v-if="isOwner">
              <Button
                :label="_t('新建章节')"
                @click="onChapterAdd"
              />

              <Button
                :label="_t('新建卡片')"
                @click="onCreateCard"
              />
            </template>

            <DebugButton
              v-if="card"
              label="源码"
              @click="showCardInfo = !showCardInfo"
            />

            <DebugButton
              :label="_t('队列调试')"
              @click="onDebugQueue"
            />
          </div>
        </div>

        <Loading
          v-if="data.cardsLoading"
          class="flex-1"
        />

        <template v-else-if="card">
          <CardPreview
            v-if="!showCardInfo"
            class="bg-transparent"
            :key="card.id + card.content"
            :card="JSON.parse(card.content)"
          />

          <CardSource
            v-else
            :key="card.content"
            class="overflow-y-auto"
            :card="card"
          />
          <Button
            :label="_t('编辑卡片')"
            @click="onCardEdit(data.selectedChapterId, card)"
          />
        </template>

        <CardList
          v-else
          class="flex-1"
          :package="props.package"
          :chapterId="data.selectedChapterId"
          :cards="data.cardResList"
          @onChapterClick="
            (chapter: ChapterItem) => onChildChapterClick(String(chapter.id))
          "
          @onCreateCard="onNoteCreate(data.cardResList.length)"
        >
          <template #card="{ cardRes, noteIndex }">
            <SlickItem
              :key="cardRes.id"
              :index="noteIndex"
            >
              <div
                v-if="noteIndex > 0"
                class="mid-create-btn"
              >
                <Icon
                  v-if="isOwner"
                  name="mid-card-create"
                  class="w-32px"
                  @click="onNoteCreate(noteIndex)"
                />
              </div>

              <CardPad
                v-if="isOwner"
                :packageId="props.package.id"
                :context="cardRes"
                :cardRes="cardRes"
                :cardResList="data.cardResList"
                :focus="data.noteFocus === noteIndex"
                @note-create-after="onNoteCreate(noteIndex + 1)"
                @note-create-before="onNoteCreate(noteIndex)"
                @note-copy="onNoteCopy(cardRes, noteIndex)"
                @note-delete="onNoteDelete(cardRes, noteIndex)"
                @note-focus="onNoteFocus(noteIndex + $event)"
                @note-update="onNoteUpdate(cardRes, noteIndex, $event)"
                @note-prev="onPrevNote"
                @note-next="onNextNote"
                @add-illustration="
                  onIllustrationAdd(cardRes, noteIndex, $event)
                "
                @remove-illustration="onIllustrationRemove(cardRes)"
                @change-card-type="defaultCardType = $event"
              />

              <CardBrowserPad
                v-else
                :cardResponse="cardRes"
                @click="onCardClick(cardRes)"
              />
            </SlickItem>
          </template>
        </CardList>
      </SlickList>

      <div class="basis-1/5"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Loading from '@/components/Loading.vue'
import Breadcrumb from 'primevue/breadcrumb'
import { onMounted, onUnmounted, reactive, ref, computed } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import {
  fetchCards,
  createChapter,
  deleteCard,
  createCard,
  CardCreatedType,
  CardTypeName,
  updateCard,
  moveCard,
  CardUpdatedType,
  PackageAccess,
} from '@/api/package-source'
import { type CardSchedule } from '@/api/learn'

import ChapterTree from '@/components/Package/ChapterTree.vue'
import TrailHeader from '@/components/TrailHeader.vue'
import CardForm from './CardForm.vue'
import {
  getChapterAncestors,
  getChapterChildren,
  isChapterExists,
} from '@/utils/package'
import { useCommonStore } from '@/stores'
import DebugLabel from '@/components/DebugLabel.vue'
import {
  Interaction,
  ROOT_CHAPTER_KEY,
  CardType,
  ClozeCardFaceType,
} from '@/types/core'
import {
  getCardTextDigest,
  newClozeCard,
  newWordCard,
  parseCard,
  validateCard,
} from '@/utils/card'
import VirtualCard from '@/components/VirtualCard/VirtualCard.vue'
import { debounce, shuffle } from 'lodash-es'
import CardPad from './CardPad.vue'
import CardBrowserPad from '@/components/Package/CardBrowserPad.vue'
import { SlickList, SlickItem } from 'vue-slicksort'

import type { MenuItem } from 'primevue/menuitem'
import type { ClozeCard } from '@/types/core'
import type {
  Package,
  CardResponse as CardResponse,
  ChapterItem,
  MoveChapterRequest,
} from '@/api/package-source'
import CardQueueSelect from '@/components/Debug/CardQueueSelect.vue'
import bus, { BusEvent } from '@/bus/bus'
import type { DragPosition } from '@/components/Tree/Tree.vue'
import type { Card } from '@/types/core'
import db from '@/db'
import dayjs from 'dayjs'
import { nextTick } from 'vue'
import CardList from '@/components/Package/CardList.vue'
import CardPreview from '@/components/CardPreview/CardPreview.vue'
import CardSource from './CardSource.vue'
import CardEdit from './CardEdit.vue'

const router = useRouter()
const route = useRoute()
const store = useCommonStore()

const props = defineProps<{
  package: Package
}>()

const emit = defineEmits<{
  chapterCountChange: [string, number]
}>()

const defaultCardType = ref(
  {
    [CardTypeName.CLOZE]: CardType.CLOZE,
    [CardTypeName.WORD]: CardType.EN_WORD,
  }[props.package.owned?.defaultCardType ?? CardTypeName.CLOZE]
)

const packageState = store.packageState[props.package.id] ?? {}

function getQueryChapterId() {
  return route.query.chapterId as string | undefined
}

function getQueryCardId() {
  return route.query.cardId as string | undefined
}

const cardListCache = ref<Record<string, CardResponse[]>>({})

// 记录 note 是否正在创建中
const noteCardCreateMap = new Map<CardResponse, boolean>()
const noteCardCreateDeboucingMap = new Map<CardResponse, boolean>()

const data = reactive({
  // 选中章节时展示的笔记列表
  cardResList: [] as CardResponse[],
  selectedChapterId:
    getQueryChapterId() ?? packageState.selectedChapterId ?? ROOT_CHAPTER_KEY,
  selectedCardId: getQueryCardId() ?? packageState.selectedCardId,

  noteFocus: -1,
  cardsLoading: true,
})

const selectedCardIdQuery = computed(() => {
  if (data.selectedCardId != null) {
    return parseInt(data.selectedCardId) > 0 ? data.selectedCardId : undefined
  }

  return undefined
})

const isOwner = computed(() => props.package?.owned != null)

const selectedChapter = computed(() => {
  if (data.selectedChapterId === ROOT_CHAPTER_KEY) {
    return {
      title: props.package?.name,
    }
  }

  const chapter = props.package?.chapters[data.selectedChapterId]

  return {
    title: chapter?.title,
  }
})

const selectedCard = computed(() => {
  if (data.selectedCardId == null) return null

  const cards = cardListCache.value[data.selectedChapterId] ?? []

  return cards.find(item => String(item.id) === data.selectedCardId)
})

const breadCrumbItems = computed<MenuItem[]>(() => {
  if (!props.package) return []

  const items: MenuItem[] = []
  const ancestors = getChapterAncestors(
    props.package.chapters,
    data.selectedChapterId
  )

  for (const ancestorId of ancestors) {
    const chapter =
      ancestorId === ROOT_CHAPTER_KEY
        ? {
            title: props.package.name,
            id: ROOT_CHAPTER_KEY,
          }
        : props.package.chapters[ancestorId]

    if (chapter == null) {
      break
    }

    items.push({
      label: chapter.title,
      key: String(chapter.id),
    })
  }
  return items
})

function onRouteChange() {
  data.selectedCardId = getQueryCardId()
  const newChapterId = getQueryChapterId() ?? ROOT_CHAPTER_KEY

  if (data.selectedCardId !== newChapterId && newChapterId != null) {
    fetchCardList(newChapterId)
  }
}

onMounted(() => {
  window.addEventListener('popstate', onRouteChange)
})

onUnmounted(() => {
  window.removeEventListener('popstate', onRouteChange)
})

if (!isChapterExists(props.package, data.selectedChapterId)) {
  onChapterSelect(ROOT_CHAPTER_KEY)
}

fetchCardList(data.selectedChapterId)

function onChildChapterClick(id: string) {
  onChapterSelect(id)
}

async function onChapterSelect(chapterId: string) {
  if (data.selectedChapterId !== chapterId) {
    fetchCardList(chapterId)
  }

  data.selectedChapterId = chapterId

  router.push({
    query: {
      ...route.query,
      chapterId: data.selectedChapterId,
      cardId: selectedCardIdQuery.value,
    },
  })
}

const card = computed(() =>
  data.cardResList.find(card => String(card.id) === data.selectedCardId)
)

const showCardInfo = ref(false)

function onCardClick(card: CardResponse) {
  if (!isOwner.value) return

  onCardSelect(card.id)
}

function onCardSelect(cardId: number | undefined) {
  if (!isOwner.value) return

  data.selectedCardId = cardId == null ? undefined : cardId.toString()

  const index = data.cardResList.findIndex(item => item.id === cardId)

  data.noteFocus = index

  if (index > -1) {
    bus.emit(BusEvent.NoteEditorFocus, cardId!)
  }

  router.push({
    query: {
      ...route.query,
      chapterId: data.selectedChapterId,
      cardId: selectedCardIdQuery.value,
    },
  })
}

function onCardUpdate(newCard: CardResponse) {
  const cards = cardListCache.value[data.selectedChapterId] ?? []
  const index = cards.findIndex(item => item.id === newCard.id)

  if (index > -1) {
    cards.splice(index, 1, newCard)
  }
}

let newCardId = 1
function onNoteCreate(targetIndex: number) {
  let card: Card

  switch (defaultCardType.value) {
    case CardType.CLOZE:
      card = newClozeCard()
      break
    case CardType.EN_WORD:
      card = newWordCard()
      break
  }

  const newCardRes = {
    id: -newCardId,
    content: JSON.stringify(card),
    contentHash: 0,
    createdType: CardCreatedType.NORMAL,
    updatedType: CardUpdatedType.NORMAL,
    createdAt: dayjs().format('YYYY-DD-MM'),
    updatedAt: dayjs().format('YYYY-DD-MM'),
    authorId: '',
  } as CardResponse

  newCardId++
  data.cardResList.splice(targetIndex, 0, newCardRes)
  onNoteFocus(targetIndex)
}

function onIllustrationAdd(
  cardRes: CardResponse,
  cardIndex: number,
  assetId: string
) {
  if (cardRes.id < 0) {
    let afterCardId: number | undefined = undefined
    let beforeCardId: number | undefined = undefined

    for (let index = cardIndex - 1; index >= 0; index--) {
      if (data.cardResList[index].id > 0) {
        afterCardId = data.cardResList[index].id
        break
      }
    }

    for (let index = cardIndex + 1; index < data.cardResList.length; index++) {
      if (data.cardResList[index].id > 0) {
        beforeCardId = data.cardResList[index].id
        break
      }
    }

    noteCardCreateDeboucingMap.set(cardRes, true)
    const card = {
      type: CardType.CLOZE,
      content: [],
      analysis: [],
      altContents: [],
      illustration: assetId,
    } as Card
    createNoteCard(cardRes, card, afterCardId, beforeCardId)
    return
  }

  const cardContent = JSON.parse(cardRes.content) as Card

  updateNoteCard(cardRes.id, {
    ...cardContent,
    illustration: assetId,
  })
}

function onIllustrationRemove(cardRes: CardResponse) {
  if (cardRes.id < 0) return

  const cardContent = JSON.parse(cardRes.content) as Card

  const newContent = {
    ...cardContent,
  }
  delete newContent.illustration
  updateNoteCard(cardRes.id, newContent)
}

// 卡片文本两端加「」文本字符超过 20 字时截断+...
function getCardTitleForDialog(content: string): string {
  const title = getCardTextDigest(content)
  return `「${title.length > 20 ? title.slice(0, 20) + '...' : title}」`
}

let isDeleteConfirmOpen = false
async function onNoteDelete(cardRes: CardResponse, noteIndex: number) {
  if (isDeleteConfirmOpen) return

  const needConfirm = cardRes.id >= 0

  isDeleteConfirmOpen = true
  const ok = needConfirm
    ? await _confirm({
        title: `删除${getCardTitleForDialog(cardRes.content)}？`,
        type: 'warn',
        content: '删除后无法恢复，请确认',
        okText: '删除',
        cancelText: '暂不',
      })
    : true

  isDeleteConfirmOpen = false
  if (ok) {
    if (data.noteFocus > data.cardResList.length - 1) {
      onPrevNote()
    }

    if (cardRes.id > 0) {
      onCardDelete(data.selectedChapterId, cardRes.id, false)
    } else {
      data.cardResList.splice(noteIndex, 1)
    }
  }
}

async function onNoteCopy(cardRes: CardResponse, noteIndex: number) {
  if (cardRes.id < 0) {
    _message.info(_t('创建卡片后再尝试'))
    return
  }

  const copied = await onCardCopy(data.selectedChapterId, cardRes.id, cardRes)

  if (copied) {
    data.cardResList.splice(noteIndex + 1, 0, copied)
    onNoteFocus(noteIndex + 1)
  }
}

const updateNoteCard = debounce((cardId: number, content: Card) => {
  if (validateCard(content)) return

  updateCard(cardId, content).then(res => {
    if (res.code !== 0) {
      _message.info(res.message)
      return
    }

    onCardUpdate(res.data)
  })
}, 500)

const createNoteCard = debounce(
  (
    cardRes: CardResponse,
    card: Card,
    afterCardId?: number,
    beforeCardId?: number
  ) => {
    if (validateCard(card)) return

    noteCardCreateDeboucingMap.set(cardRes, false)

    if (noteCardCreateMap.get(cardRes)) {
      return
    }

    noteCardCreateMap.set(cardRes, true)
    createCard({
      packageId: props.package.id,
      chapterId: data.selectedChapterId,
      content: card,
      afterCardId: afterCardId,
      beforeCardId: beforeCardId,
      createdType: CardCreatedType.NORMAL,
    }).then(res => {
      noteCardCreateMap.set(cardRes, false)

      if (res.code !== 0) {
        _message.info(res.message)
        return
      }

      if (data.selectedCardId === cardRes.id.toString()) {
        data.selectedCardId = res.data.id.toString()
      }

      cardRes.id = res.data.id
      cardRes.content = res.data.content
      emit('chapterCountChange', data.selectedChapterId, 1)
    })
  },
  500
)

function onNoteUpdate(cardRes: CardResponse, noteIndex: number, newCard: Card) {
  cardRes.content = JSON.stringify(newCard)

  if (cardRes.id < 0) {
    let afterCardId: number | undefined = undefined
    let beforeCardId: number | undefined = undefined

    for (let index = noteIndex - 1; index >= 0; index--) {
      if (data.cardResList[index].id > 0) {
        afterCardId = data.cardResList[index].id
        break
      }
    }

    for (let index = noteIndex + 1; index < data.cardResList.length; index++) {
      if (data.cardResList[index].id > 0) {
        beforeCardId = data.cardResList[index].id
        break
      }
    }

    noteCardCreateDeboucingMap.set(cardRes, true)
    createNoteCard(cardRes, newCard, afterCardId, beforeCardId)
  } else {
    updateNoteCard(cardRes.id, newCard)
  }
}

function onNoteFocus(noteIndex: number) {
  data.noteFocus = noteIndex
  const note = data.cardResList[noteIndex]

  nextTick(() => {
    bus.emit(BusEvent.NoteEditorFocus, note.id)
  })
}

function onPrevNote() {
  if (data.noteFocus > 0) {
    data.noteFocus--
    onNoteFocus(data.noteFocus)
  }
}

function onNextNote() {
  if (data.noteFocus < data.cardResList.length - 1) {
    data.noteFocus++
    onNoteFocus(data.noteFocus)
  } else {
    onNoteCreate(data.cardResList.length)
  }
}

function onBack() {
  router.push({
    name: 'shelf',
  })
}

function onBreadCrumbClick(item: MenuItem) {
  if (item.key) {
    onChapterSelect(item.key)
    onCardSelect(undefined)
  }
}

async function onCreateCard() {
  await _openDialog(CardForm, {
    title: '新建卡片',
    rootClass: 'min-w-480px',
    props: {
      package: props.package,
      chapterId: data.selectedChapterId,
      onUpdateDefaultCard: (type: CardTypeName) => {
        props.package.owned!.defaultCardType = type
      },
      onCreate: (card: CardResponse) => {
        const afterCardId = data.cardResList[data.cardResList.length - 1]?.id

        insertNote(card, { afterCardId })

        emit('chapterCountChange', data.selectedChapterId, 1)

        _message.success('创建成功')
      },
    },
  })
}

function onChapterCreated(chapter: ChapterItem) {
  // TODO: 这里需要更好的方案，应该避免修改 props
  props.package.chapters[chapter.id] = chapter
  onChapterSelect(String(chapter.id))
}

function onChapterRename(chapter: ChapterItem) {
  // TODO: 这里需要更好的方案，应该避免修改 props
  props.package.chapters[chapter.id] = chapter
}

function onChapterDelete(chapterId: string) {
  const chapterItem = props.package.chapters[chapterId]
  // TODO: 这里需要更好的方案，应该避免修改 props
  delete props.package.chapters[chapterId]

  props.package.chapterCardsCount[chapterId] = 0
  cardListCache.value[chapterId] = []
  onChapterSelect(
    chapterItem.parentId != null
      ? String(chapterItem.parentId)
      : ROOT_CHAPTER_KEY
  )
}

function onCardEdit(_chapterId: string, card: CardResponse) {
  const cardRes = data.cardResList.find(item => item.id === card.id)

  if (cardRes?.id == null) {
    _message.info(_t('创建卡片后再尝试'))
    return
  }

  _openDialog(CardEdit, {
    title: _t('编辑'),
    props: {
      card: cardRes,
      cards: data.cardResList,
      onUpdate(newCard: CardResponse) {
        const index = data.cardResList.findIndex(item => item.id === card.id)

        if (index > -1) {
          onNoteUpdate(cardRes, index, JSON.parse(newCard.content))
        }
      },
    },
    rootClass: 'w-1000px',
  })
}

function onCardCreateBefore(chapterId: string, beforeCardId: number) {
  if (beforeCardId < 0) {
    const index = data.cardResList.findIndex(item => item.id === beforeCardId)
    onNoteCreate(index)
    return
  }

  _openDialog(CardForm, {
    title: '新建卡片',
    rootClass: 'min-w-480px',
    props: {
      package: props.package,
      chapterId,
      beforeCardId,
      closeAfterCreate: true,
      onUpdateDefaultCard: (type: CardTypeName) => {
        props.package.owned!.defaultCardType = type
      },
      onCreate: (card: CardResponse) => {
        const cards = cardListCache.value[chapterId] ?? []
        const index = cards.findIndex(item => item.id === beforeCardId)

        if (index > -1) {
          insertNote(card, { beforeCardId })
          emit('chapterCountChange', chapterId, 1)
        }
      },
    },
  })
}

function onCardCreateAfter(chapterId: string, afterCardId: number) {
  if (afterCardId < 0) {
    const index = data.cardResList.findIndex(item => item.id === afterCardId)
    onNoteCreate(index + 1)
    return
  }

  _openDialog(CardForm, {
    title: '新建卡片',
    rootClass: 'min-w-480px',
    props: {
      package: props.package,
      chapterId,
      afterCardId,
      closeAfterCreate: true,
      onUpdateDefaultCard: (type: CardTypeName) => {
        props.package.owned!.defaultCardType = type
      },
      onCreate: (card: CardResponse) => {
        const cards = cardListCache.value[chapterId] ?? []
        const index = cards.findIndex(item => item.id === afterCardId)

        if (index > -1) {
          insertNote(card, {
            afterCardId: afterCardId,
          })
          emit('chapterCountChange', chapterId, 1)
        }
      },
    },
  })
}

async function onCardCopy(
  chapterId: string,
  cardId: number,
  card: CardResponse
): Promise<CardResponse | undefined> {
  if (cardId < 0) {
    _message.info('卡片还未保存，稍后尝试')
    return
  }

  const content = JSON.parse(card.content)

  return createCard({
    packageId: props.package.id,
    chapterId,
    content,
    afterCardId: cardId,
    createdType: CardCreatedType.DUPLICATE,
  }).then(res => {
    if (res.code === 0) {
      const cards = data.cardResList
      const index = cards.findIndex(item => item.id === cardId)

      if (index > -1) {
        cards.splice(index + 1, 0, res.data)
      }

      return res.data
    } else {
      _message.info(res.message)
    }
  })
}

function onChapterMove({
  chapterId,
  parentChapterId,
  beforeChapterId,
}: MoveChapterRequest) {
  if (!props.package) return

  const chapterItem = props.package.chapters[chapterId]

  if (!chapterItem) return

  const parentChildren = getChapterChildren(
    props.package.chapters,
    parentChapterId
  )
  const existIndex = parentChildren.findIndex(
    item => String(item.id) === chapterId
  )

  if (existIndex > -1) {
    parentChildren.splice(existIndex, 1)
  }

  const index = beforeChapterId
    ? parentChildren.findIndex(item => String(item.id) === beforeChapterId)
    : parentChildren.length

  if (index > -1) {
    chapterItem.parentId =
      parentChapterId === ROOT_CHAPTER_KEY ? null : parseInt(parentChapterId)
    parentChildren.splice(index, 0, chapterItem)
  }

  for (let i = 0; i < parentChildren.length; i++) {
    parentChildren[i].orderKey = i
  }
}

async function onCardDeleteInTree(chapterId: string, cardId: number) {
  if (cardId < 0) {
    const index = data.cardResList.findIndex(item => item.id === cardId)
    if (index > -1) {
      onNoteDelete(data.cardResList[index], index)
    }
  }

  const result = await onCardDelete(chapterId, cardId)

  if (result) {
    const index = data.cardResList.findIndex(item => item.id === cardId)

    if (index > -1) {
      data.cardResList.splice(index, 1)
    }
  }
}

async function onCardDelete(
  chapterId: string,
  cardId: number,
  needConfirm = true
) {
  const cards = cardListCache.value[chapterId]
  const card = cards?.find(item => item.id === cardId)

  if (card == null || props.package == null) return

  const ok = needConfirm
    ? await _confirm({
        title: `删除${getCardTitleForDialog(card.content)}？`,
        type: 'warn',
        content: '删除后无法恢复，请确认',
        okText: '删除',
        cancelText: '暂不',
      })
    : true

  if (ok) {
    const res = await deleteCard(props.package.id, chapterId, Number(cardId))

    if (res.code !== 0) {
      _message.info(res.message)
      return
    }

    const index = cards.findIndex(item => item.id === cardId)

    if (index > -1) {
      cards.splice(index, 1)

      onCardSelect(undefined)

      emit('chapterCountChange', chapterId, -1)
      _message.info('删除成功')
      return true
    }
  }
}

function onNotesSortEnd({
  newIndex,
  oldIndex,
}: {
  newIndex: number
  oldIndex: number
}) {
  const sourceNote = data.cardResList[oldIndex]
  let beforeCardId: number | undefined

  const offset = newIndex > oldIndex ? 1 : 0

  for (
    let index = newIndex + offset;
    index < data.cardResList.length;
    index++
  ) {
    if (data.cardResList[index].id > 0) {
      beforeCardId = data.cardResList[index].id
      break
    }
  }

  if (sourceNote.id < 0) {
    return
  }

  if (beforeCardId != null) {
    moveNoteItem(sourceNote.id, beforeCardId, 'before')
  } else {
    moveNoteItem(sourceNote.id, beforeCardId, 'bottom')
  }

  moveNoteResList(sourceNote.id, beforeCardId)

  data.cardResList.splice(oldIndex, 1)
  data.cardResList.splice(newIndex, 0, sourceNote)

  if (data.noteFocus === oldIndex) {
    data.noteFocus = newIndex
  } else if (data.noteFocus >= newIndex) {
    data.noteFocus++
  } else if (oldIndex < data.noteFocus) {
    data.noteFocus--
  }
}

async function moveNoteResList(sourceCardId: number, targetCardId?: number) {
  const oldIndex = data.cardResList.findIndex(item => item.id === sourceCardId)
  const newIndex =
    data.cardResList.findIndex(item => item.id === targetCardId) ??
    data.cardResList.length
  const sourceNoteRes = data.cardResList[oldIndex]
  data.cardResList.splice(oldIndex, 1)

  if (newIndex > oldIndex) {
    data.cardResList.splice(newIndex - 1, 0, sourceNoteRes)
  } else {
    data.cardResList.splice(newIndex, 0, sourceNoteRes)
  }
}

async function moveNoteItem(
  sourceCardId: number,
  targetCardId?: number,
  position?: DragPosition
) {
  await moveCard({
    sourcePkgId: props.package.id,
    cardId: sourceCardId,
    beforeCardId: position === 'bottom' ? undefined : targetCardId,
    sourceChapterId: data.selectedChapterId,
    targetChapterId: data.selectedChapterId,
  })
}

async function onChapterAdd() {
  await _openInputDialog({
    title: _t('新建章节'),
    text: '',
    placeholder: _t('请输入章节名称'),
    okText: _t('保存'),
    onSubmit: async title => {
      const res = await createChapter({
        packageId: props.package.id,
        title: title.trim(),
        parentId: data.selectedChapterId,
      })

      if (res.code !== 0) {
        _message.info(res.message)
        return false
      }
      // TODO: 这里需要更好的方案，应该避免修改 props
      props.package.chapters[res.data.id] = res.data
      onChapterSelect(String(res.data.id))

      return true
    },
  })
}

async function onDebugQueue() {
  if (!props.package) return

  const cards = await _openDialog<CardSchedule[]>(CardQueueSelect, {
    title: _t('选择卡片'),
    props: {
      packageId: props.package.id,
      chapterId: data.selectedChapterId,
      cards: data.cardResList,
    },
  })

  if (cards) {
    store.setStageUnit(
      {
        unitId: 0,
        local: true,
        schedules: cards,
      },
      db.debug.queueType
    )
    router.push({
      name: 'unit/learn',
    })
  }
}

function onDisplayMatchCard() {
  const cards = shuffle(data.cardResList)
    .slice(0, 5)
    .map(item => JSON.parse(item.content))
    .filter(card => card.type === CardType.CLOZE) as ClozeCard[]

  if (cards.length === 0) {
    _message.info(_t('该章节不存在知识点卡片'))
    return
  }

  _openDialog(VirtualCard, {
    title: '配对卡',
    rootClass: 'phone-container',
    props: {
      face: {
        type: 'match',
        cards,
      },
    },
    dialog: {
      pt: {
        content: {
          class: 'p-0',
        },
      },
    },
  })
}

function onDisplayBossCard() {
  const cardsFaces = shuffle(data.cardResList)
    .filter(item => {
      const note = JSON.parse(item.content) as Card
      return note.type === CardType.CLOZE
    })
    .slice(0, 5)
    .map(item => {
      const note = JSON.parse(item.content) as Card
      const parsedResult = parseCard(note)

      const altCards = data.cardResList
        .filter(oNote => {
          const note = JSON.parse(oNote.content) as Card
          return note.type === CardType.CLOZE && oNote.id !== item.id
        })
        .map(item => parseCard(JSON.parse(item.content) as Card))
        .filter(result => result.error == null)
        .map(result => result.card)

      if (parsedResult.error != null) return null

      return {
        type: ClozeCardFaceType.Choice,
        cardId: item.id,
        card: parsedResult.card,
        interaction: Interaction.Practice,
        altCards,
        style: {
          hideRoleImage: true,
          questionLayout: 'horizontal',
          operationLayout: 'horizontal',
        },
      }
    })

  if (cardsFaces.length === 0) {
    _message.info(_t('该章节不存在知识点卡片'))
    return
  }

  const boss = {
    type: 'boss',
    faces: cardsFaces,
  }

  _openDialog(VirtualCard, {
    title: 'boss卡',
    rootClass: 'phone-container',
    props: {
      face: boss,
    },
    dialog: {
      pt: {
        content: {
          class: 'p-0',
        },
      },
    },
  })
}

function insertNote(
  cardRes: CardResponse,
  { beforeCardId, afterCardId }: { beforeCardId?: number; afterCardId?: number }
) {
  if (beforeCardId) {
    const targetNoteIndex = data.cardResList.findIndex(
      item => item.id === beforeCardId
    )

    if (targetNoteIndex > -1) {
      data.cardResList.splice(targetNoteIndex, 0, cardRes)
    }
  } else if (afterCardId) {
    const targetNoteIndex = data.cardResList.findIndex(
      item => item.id === afterCardId
    )

    if (targetNoteIndex > -1) {
      data.cardResList.splice(targetNoteIndex + 1, 0, cardRes)
    }
  } else {
    data.cardResList.push(cardRes)
  }
}

async function fetchCardList(chapterId: string) {
  if (chapterId == null) return []

  try {
    if (cardListCache.value[chapterId] != null) {
      data.cardResList = cardListCache.value[chapterId]
    } else {
      data.cardsLoading = true
      data.cardResList = (
        await fetchCards(props.package.id, chapterId)
      ).data.cards
      cardListCache.value[chapterId] = data.cardResList
      data.cardResList.forEach(c => {
        store.setCardResponseCache(c.id, c)
      })
    }

    if (data.selectedCardId) {
      const index = data.cardResList.findIndex(
        item => item.id > 0 && item.id.toString() === data.selectedCardId
      )
      data.noteFocus = index
    }
  } finally {
    data.cardsLoading = false
  }
}

function onChapterCardsFetch(chapterId: string, cards: CardResponse[]) {
  if (data.selectedChapterId !== chapterId) {
    cardListCache.value[chapterId] = cards
  }
}

// TODO(buding): 外面一层已经不需要了，需要去掉
function onUnlock(access: PackageAccess) {
  props.package!.packageAccess = access
}
</script>

<style scoped>
.header {
  height: 56px;
  padding: 0px 16px;
}

.mid-create-btn {
  height: 12px;
  display: flex;
  justify-content: center;
}

.mid-create-btn svg {
  opacity: 0;
  scale: 0;
  transition: scale 0.15s;
  transition-delay: 0.01s;
  cursor: pointer;
}

.mid-create-btn:hover svg {
  opacity: 1;
  scale: 1.5;
}
</style>
