<template>
  <div class="question">
    <div class="min-h-48px flex justify-center items-center">
      <div :style="imageStyle">
        <slot name="image"></slot>
      </div>
    </div>

    <div
      class="w-full flex items-center"
      :class="{
        'question-text-background': props.isRoleImage,
        'justify-center': lines === 1,
      }"
    >
      <div ref="textRef">
        <slot name="text"></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { getTextLineCount } from '@/utils'
import { computed, onMounted, onUnmounted, ref } from 'vue'
const props = defineProps<{
  // 是否是角色图片
  isRoleImage?: boolean
}>()

const textRef = ref(null)

// 文字行数
const lines = ref(0)

// 图片样式
const imageStyle = computed(() => {
  if (props.isRoleImage) {
    return roleImageStyle.value
  } else {
    return normalImageStyle.value
  }
})

// 角色图片样式
const roleImageStyle = computed(() => {
  // early out
  if (lines.value >= 6) {
    return { display: 'none' }
  }

  const defaultWidth = 114
  let imageWidth = defaultWidth
  if (lines.value <= 2) {
    imageWidth = defaultWidth
  } else if (lines.value == 3) {
    imageWidth = defaultWidth * (8 / 9)
  } else if (lines.value == 4) {
    imageWidth = defaultWidth * (7 / 9)
  } else if (lines.value >= 5) {
    imageWidth = defaultWidth * (6 / 9)
  }

  return {
    width: `${imageWidth}px`,
    display: 'block',
  }
})

const normalImageStyle = computed(() => {
  // 根据行数调整图片大小
  // 图片的宽度最大为 432
  const defaultPct = 100
  let widthPct = defaultPct
  if (lines.value == 3) {
    widthPct = defaultPct * (7 / 8)
  } else if (lines.value == 4) {
    widthPct = defaultPct * (6 / 8)
  } else if (lines.value >= 5) {
    widthPct = defaultPct * (5 / 8)
  }

  return {
    marginBottom: '12px',
    marginLeft: '16px',
    marginRight: '16px',
    marginTop: '16px',
    maxWidth: '432px',
    width: `${widthPct}%`,
  }
})

function adjustForQuestion() {
  const textElement = textRef.value as HTMLElement | null
  if (!textElement) return
  if (props.isRoleImage) {
    let count = getTextLineCount(textElement)
    lines.value = count
  } else {
    lines.value = getTextLineCount(textElement)
  }
}

function autoLayout() {
  adjustForQuestion()
}

onMounted(() => {
  autoLayout()
  // 监听窗口大小变化
  window.addEventListener('resize', autoLayout)
})
onUnmounted(() => {
  window.removeEventListener('resize', autoLayout)
})
</script>

<style scoped>
.question {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  line-height: 1.5;
}

.question-text-background {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 2px 0px var(--slate-300);
  padding: 16px;
}
</style>
