<template>
  <div>Loading...</div>
</template>

<script lang="ts" setup>
import { IframeMessage } from '@/types/iframe'
import { useRoute } from 'vue-router'

const route = useRoute()
const message = JSON.stringify({
  type: IframeMessage.WX_GET_CODE,
  data: {
    code: route.query.code,
  },
})
parent.postMessage(message, '*')
</script>

<style scoped></style>
