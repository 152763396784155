<template>
  <div class="header">
    <div
      v-if="store.energy"
      class="flex items-center cursor-pointer"
      @click="onEnergyClick"
    >
      <EnergyBg class="z-1">
        <Roller
          :key="rollerKey"
          :value="store.energy.currentEnergy.toString()"
        />
      </EnergyBg>

      <div class="energy-text relative left--14px">
        <div
          v-if="store.isEnergyFull"
          class="text-15px w-64px ml-auto text-center"
        >
          {{ _t('已满') }}
        </div>
        <div
          v-else
          class="text-16px w-62px ml-auto text-center"
        >
          {{ energyTimerLabel }}
        </div>
      </div>
    </div>

    <div
      class="ml-auto flex items-center cursor-pointer"
      @click="onDiamondClick"
    >
      <DotBadge
        :visible="store.diamondCard != null && !store.diamondCard.receivedToday"
        class="flex items-center"
        :dotStyle="{
          top: 0,
          right: 0,
        }"
      >
        <Icon
          name="diamond"
          class="w-30px"
        />
      </DotBadge>

      <ColorNumber
        :value="diamondAmount"
        color="brand"
        class="ml-1"
      />
    </div>

    <div
      class="flex cursor-pointer"
      @click="onHotDaysClick"
    >
      <Icon
        name="streak"
        class="ml-4 w-25px"
      />
      <ColorNumber
        :value="continuousDays"
        color="orange"
        class="ml-1"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import ColorNumber from '@/components/ColorNumber.vue'

import { Roller } from 'vue-roller'
import 'vue-roller/dist/style.css'
import { useCommonStore } from '@/stores'
import CheckIn from '../CheckIn.vue'
import Store from '@/components/Store/Store.vue'
import DotBadge from '@/components/DotBadge.vue'
import { ref, onActivated, computed } from 'vue'
import EnergyBg from '@/components/EnergyBg.vue'

const store = useCommonStore()

const rollerKey = ref(0)
const continuousDays = computed(() => store.checkInCtRes?.continuousDays ?? 0)

const diamondAmount = computed(() => store.userAssets?.diamond ?? 0)

const energyTimerLabel = computed(() => {
  if (store.energyCountDown == null) return ''

  const minsLabel = Math.floor(store.energyCountDown / 60).toString()
  const secondsLabel = (store.energyCountDown % 60).toString()
  return `${minsLabel.padStart(2, '0')}:${secondsLabel.padStart(2, '0')}`
})

// TODO(buding): roller 这个组件有个 bug，学习完成后返回时，roller item 宽度会变成 16
// 这里是为了重新让 roller 渲染
onActivated(() => {
  rollerKey.value++
})

function onEnergyClick() {
  _openEnergyBuy()
}

function onHotDaysClick() {
  _openDialog(CheckIn, {
    rootClass: 'p-dialog-checkin-pc',
    fullscreenInMobile: true,
    dialog: {
      showHeader: false,
      pt: {
        content: { class: 'p-0' },
      },
    },
  })
}

function onDiamondClick() {
  _openDialog(Store, {
    rootClass: 'max-h-600px',
    fullscreenInMobile: true,
    dialog: {
      showHeader: false,
      dismissableMask: true,
      pt: {
        content: { class: 'p-0' },
      },
    },
  })
}
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 0px 16px;
  height: 36px;
}

.energy-text {
  position: relative;
  width: 72px;
  height: 24px;
  background: var(--ld-energyBg);
  border-radius: 8px;
  border: 2px solid white;
  color: white;
  font-family: DIN;
  font-weight: 700;
  font-size: 15px;
  line-height: 1;
  display: flex;
  align-items: center;
}
</style>
