<template>
  <div class="flex">
    <template
      v-for="(item, index) in items"
      :key="index"
    >
      <Roller
        v-if="typeof item === 'number'"
        class="item"
        default-value="1"
        :value="item.toString()"
      />
      <div
        v-else
        class="item"
      >
        {{ item }}
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { Roller } from 'vue-roller'

const props = defineProps<{
  value: number | string
}>()

const items = props.value
  .toString()
  .split('')
  .map(item => {
    return isNaN(Number(item)) ? item : Number(item)
  })
</script>

<style scoped>
.item {
  font-size: 20px;
  line-height: 1;
}
</style>
