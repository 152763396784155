import api from './base'

// GET /api/v1/stats/overview 获取个人页概览 统计数据
export function fetchStatsOverview() {
  return api.get<unknown, OverviewStats>('/v1/stats/overview')
}

export type OverviewStats = {
  // 总闯关数
  stageCount: number
  // 总经验
  exp: number
  // 累计天数
  accumulatedCheckInDays: number
  // 最长连续天数
  maxCheckInCtDays: number
}
