<template>
  <RatioSpacedContainer v-if="isPackageEmpty">
    <Empty
      :text="_t('这个卡包还是空的')"
      :desc="isOwner ? _t('点击右上角按钮，可新建章节或卡片') : ''"
    >
      <template #icon>
        <Icon
          name="empty-package"
          class="h-120px"
        />
      </template>
    </Empty>
  </RatioSpacedContainer>

  <RatioSpacedContainer v-else-if="isChapterEmpty">
    <Empty
      :text="$t('package.no_card')"
      :desc="isOwner ? _t('点击右上角按钮，新建子章节或卡片') : ''"
    >
      <template #icon>
        <Icon
          name="empty-package"
          class="h-120px"
        />
      </template>
    </Empty>
  </RatioSpacedContainer>

  <div
    v-else
    class="p-4"
  >
    <div
      v-if="showTitle"
      class="my-2 text-13px text-[var(--text-color-secondary)]"
    >
      章节
    </div>

    <div class="grid-chapter">
      <div
        v-for="chapter in chapters"
        :key="chapter.id"
        class="p-4 rounded-lg space-y-1 bg-slate-100 chapter"
        @click="onChapterClick(chapter)"
      >
        <div class="text-17px line-clamp-1">{{ chapter.title }}</div>
        <div class="text-15px text-gray">
          {{ allChapterCountMap[chapter.id] + ' ' + _t('张卡片') }}
        </div>
      </div>
    </div>

    <div
      v-if="showTitle"
      class="my-2 text-13px text-[var(--text-color-secondary)]"
    >
      卡片
    </div>

    <div class="flex flex-col">
      <template
        v-for="(item, index) in cards"
        :key="item.id"
      >
        <slot
          name="card"
          :cardRes="item"
          :noteIndex="index"
        >
          <CardBrowserPad
            :cardResponse="item"
            class="overflow-hidden"
            :class="{ 'mt-3': index !== 0 }"
            @click="onCardClick(item)"
          ></CardBrowserPad>
        </slot>
      </template>
    </div>
    <div
      v-if="isOwner"
      class="mt-3 w-full min-h-50px flex justify-center items-center p-4 rounded-lg bg-white text-ld-brand-500 border-ld-border border-1px cursor-pointer gap-10px ld-shadow"
      @click="onCreateCard"
    >
      <Icon name="add_card" />
      {{ _t('新建卡片') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  type Package,
  type CardResponse,
  type ChapterItem,
} from '@/api/package-source'

import Empty from '@/components/Empty.vue'
import Icon from '@/components/Icon.vue'
import CardBrowserPad from '@/components/Package/CardBrowserPad.vue'

import {
  getAllChapterTotalCardCount,
  getChapterChildren,
} from '@/utils/package'

import { computed } from 'vue'

const props = defineProps<{
  chapterId: string
  package: Package
  cards: CardResponse[]
}>()

const emit = defineEmits<{
  onChapterClick: [ChapterItem]
  onCardClick: [CardResponse]
  onCreateCard: []
}>()

const chapters = computed(() => {
  if (props.package)
    return getChapterChildren(props.package.chapters, props.chapterId)
  return []
})
const allChapterCountMap = computed(() => {
  if (props.package) return getAllChapterTotalCardCount(props.package)
  return {}
})

const isOwner = computed(() => props.package?.owned != null)

// package 是空的
const isPackageEmpty = computed(() => {
  return (
    Object.values(props.package.chapters).length === 0 &&
    props.cards.length === 0
  )
})

// 当前章节是空的 (没有卡片也没有子章节)
const isChapterEmpty = computed(() => {
  return chapters.value.length === 0 && props.cards.length === 0
})

// 显示章节title
const showTitle = computed(() => {
  if (chapters.value.length === 0) {
    return false
  }
  if (props.cards.length === 0 && !isOwner.value) {
    return false
  }
  return true
})

function onChapterClick(chapter: ChapterItem) {
  emit('onChapterClick', chapter)
}

function onCardClick(card: CardResponse) {
  emit('onCardClick', card)
}

function onCreateCard() {
  emit('onCreateCard')
}
</script>

<style scoped>
.grid-chapter {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
}
.chapter {
  box-shadow: 0px 0px 4px 0px var(--slate-300) inset;
}
</style>
