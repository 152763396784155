<template>
  <Loading v-if="data.registerLoading" />

  <WxQRCode
    v-else
    @code="onWxCallback"
  />
</template>

<script lang="ts" setup>
import { wxLogin, wxRegister } from '@/api/auth'
import { useCommonStore } from '@/stores/common-store'
import { reactive } from 'vue'
import WxQRCode from '../WxQRCode.vue'

const store = useCommonStore()

const data = reactive({
  registerLoading: false,
})

const emit = defineEmits<{
  done: []
}>()

function onWxCallback(code: string) {
  wxLogin({ code }).then(res => {
    if (res.code !== 0) {
      _message.info(res.message)
      return
    }

    if (res.data.loginResponse) {
      store.login(res.data.loginResponse.token, res.data.loginResponse.user)
      emit('done')
    } else {
      onRegister(res.data.wxToken)
    }
  })
}

function onRegister(wxToken: string) {
  data.registerLoading = true

  wxRegister({ wxToken })
    .then(res => {
      if (res.code !== 0) {
        _message.info(res.message)
        return
      }

      store.login(res.data.token, res.data.user)
      emit('done')
    })
    .finally(() => {
      data.registerLoading = false
    })
}
</script>

<style scoped></style>
