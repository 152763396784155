import { CardType } from './model'
import { z } from 'zod'

const PronunciationLanguage = z.enum(['en-US', 'en-GB'])

const NonEmtpyString = z.string().trim().min(1)
const UserAssetId = NonEmtpyString

const TextStyle = z.object({
  dot: z.boolean().optional(),
})

const Text = z.object({
  type: z.literal('text'),
  text: z.string(),
  style: TextStyle.optional(),
})

const Cloze = z.object({
  type: z.literal('cloze'),
  text: z.string(),
  group: NonEmtpyString.optional(),
  distrators: z.array(NonEmtpyString).optional(),
  giveAwayDistrators: z.array(NonEmtpyString).optional(),
})

const InlineNode = z.discriminatedUnion('type', [Text, Cloze])

const P = z.object({
  type: z.literal('p'),
  content: z.array(InlineNode),
})

const BlockNode = P

const Content = z.array(BlockNode)

const ClozeCard = z.object({
  type: z.literal(CardType.CLOZE),
  content: Content,
  altContents: z.array(Content).optional(),
  analysis: Content.optional(),
  illustration: UserAssetId.optional(),
})

const EnWordCard = z.object({
  type: z.literal(CardType.EN_WORD),
  word: NonEmtpyString,
  definition: NonEmtpyString,
  illustration: UserAssetId.optional(),
  prons: z.array(
    z.object({
      label: NonEmtpyString,
      language: PronunciationLanguage,
    })
  ),
  examples: z.array(
    z.object({
      source: NonEmtpyString,
      translation: NonEmtpyString,
    })
  ),
  tabs: z.array(
    z.object({
      title: NonEmtpyString,
      content: Content,
    })
  ),
  distrators: z.array(
    z.object({
      word: NonEmtpyString,
      definition: NonEmtpyString,
    })
  ),
})

export const Card = z.discriminatedUnion('type', [ClozeCard, EnWordCard])
