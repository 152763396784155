<template>
  <div class="mt-4">
    <Empty
      v-if="props.packages.length === 0"
      :text="props.emptyHint"
    >
      <div class="flex">
        <TextButton
          class="p-2 text-sm"
          :label="_t('去搜索')"
          @click="onSearch"
        ></TextButton>
        <TextButton
          class="p-2 text-sm"
          :label="_t('自己创建')"
          @click="onPackageCreate"
        ></TextButton>
      </div>
    </Empty>

    <div
      v-else
      ref="gridList"
      :class="['grid gap-x-3 gap-y-4', gridListClass]"
    >
      <PkgCard
        v-for="pkg in props.packages"
        :key="pkg.id"
        :pkg="pkg"
        class="cursor-pointer"
        @edit="emit('edit', pkg.id)"
        @share-setting="emit('shareSetting', pkg.id)"
        @delete="emit('delete', pkg.id)"
        @remove-shelf="emit('removeShelf', pkg.id)"
        @click="onClick(pkg)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import Empty from '@/components/Empty.vue'
import PkgCard from './PkgCard.vue'
import { onMounted, onUnmounted, ref } from 'vue'

import type { PackageBasic } from '@/api/package-source'
import { computed } from 'vue'

const props = defineProps<{
  packages: PackageBasic[]
  emptyHint: string
}>()

const emit = defineEmits<{
  edit: [number]
  shareSetting: [number]
  delete: [number]
  removeShelf: [number]
  search: []
  packageCreate: []
  click: [number]
}>()

const gridList = ref<HTMLDivElement>()

function onClick(pkg: PackageBasic) {
  emit('click', pkg.id)
}

const rows = ref(2)

const gridListClass = computed(() => {
  return (
    {
      2: 'grid-cols-2',
      3: 'grid-cols-3',
      4: 'grid-cols-4',
      5: 'grid-cols-5',
    }[rows.value] ?? ''
  )
})

function getGridRows() {
  const gridListWidth = gridList.value?.clientWidth ?? 0
  if (gridListWidth < 530) {
    rows.value = 2
  } else if (gridListWidth < 740) {
    rows.value = 3
  } else if (gridListWidth < 1200) {
    rows.value = 4
  } else {
    rows.value = 5
  }
}

onMounted(() => {
  getGridRows()
  window.addEventListener('resize', getGridRows)
})

onUnmounted(() => {
  window.removeEventListener('resize', getGridRows)
})

function onSearch() {
  emit('search')
}

function onPackageCreate() {
  emit('packageCreate')
}
</script>

<style scoped></style>
