<template>
  <div
    v-if="showDebugFloatingBall"
    ref="floatingBallRef"
    class="fixed rounded-full vstack gap-0 justify-center leading-[1em] cursor-pointer z-99"
    :style="style"
  >
    <div class="text-18px">
      {{ '🐞' }}
    </div>
    <div class="text-12px text-yellow font-semibold">{{ store.user?.id }}</div>
  </div>
</template>

<script setup lang="ts">
import { useCommonStore } from '@/stores'
import { clamp } from '@/utils'
import Moveable from 'moveable'
import db from '@/db'
import { onUnmounted, computed, onMounted, ref } from 'vue'

const store = useCommonStore()
const floatingBallRef = ref<HTMLDivElement>()

const showDebugFloatingBall = computed(
  () => db.debug.showDebugFloatingBall && !_global.isProd
)

const BALL_SIZE = 44
const style = computed(() => ({
  top:
    clamp(_db.debug.floatingBallTop, 0, window.innerHeight - BALL_SIZE) + 'px',
  left:
    clamp(_db.debug.floatingBallLeft, 0, window.innerWidth - BALL_SIZE) + 'px',
  width: BALL_SIZE + 'px',
  height: BALL_SIZE + 'px',
  background:
    _db.debug.apiEnv === 'prod' ? 'var(--red-500)' : 'var(--green-500)',
}))

onMounted(() => {
  setMoveable()
})

onUnmounted(() => {
  removeMoveable()
})

let moveable: Moveable
function setMoveable() {
  if (floatingBallRef.value == null) return

  moveable = new Moveable(document.body, {
    target: floatingBallRef.value,
    container: document.body,
    draggable: true,
  })
  moveable.on('drag', ({ target, left: _left, top: _top }) => {
    const W = window.innerWidth
    const H = window.innerHeight
    const margin = BALL_SIZE

    const left = clamp(_left, 0, W - margin)
    const top = clamp(_top, 0, H - margin)

    _db.debug.floatingBallTop = top
    _db.debug.floatingBallLeft = left
    target!.style.top = `${top}px`
    target!.style.left = `${left}px`
  })

  moveable.on('click', () => {
    window._openDebugPanel()
  })
}

function removeMoveable() {
  moveable?.off('drag')
  moveable?.off('click')
  moveable?.destroy()
}
</script>

<style>
.moveable-line {
  display: none;
}

.moveable-control-box,
.moveable-control {
  z-index: -1;
  display: none;
}
</style>
