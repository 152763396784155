<template>
  <Icon
    name="reward-box"
    :class="['reward-box', { animation: props.animation }]"
  />
</template>
<script setup lang="ts">
import Icon from './Icon.vue'

const props = defineProps<{
  animation: boolean
}>()
</script>
<style scoped>
.reward-box.animation {
  cursor: pointer;
  box-shadow: 0 4px 8px var(--slate-300);
  animation:
    reward-glow 1.5s infinite,
    reward-shake 5s infinite;
}

.reward-box:hover {
  animation-play-state: paused;
}

@keyframes reward-shake {
  0%,
  25%,
  50%,
  75%,
  100% {
    transform: translate(0, 0) rotate(0);
  }

  10%,
  35%,
  60%,
  85% {
    transform: translate(-3px, 0) rotate(-4deg);
  }

  15%,
  40%,
  65%,
  90% {
    transform: translate(3px, 0) rotate(4deg);
  }
}

@keyframes reward-glow {
  0%,
  100% {
    box-shadow: 0 0 10px var(--rewardGlowStart);
  }

  50% {
    box-shadow: 0 0 20px var(--rewardGlowEnd);
  }
}
</style>
