<template>
  <div
    class="grid gap-3"
    :style="style"
  >
    <div
      v-for="(g, idx) in groups"
      :key="idx"
      class="space-y-3"
    >
      <slot
        v-for="d in g"
        :item="d"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed } from 'vue'

const props = withDefaults(
  defineProps<{
    columns?: number
    data: any[]
  }>(),
  {
    columns: 2,
  }
)

const style = computed(() => ({
  gridTemplateColumns: `repeat(${props.columns}, minmax(0, 1fr))`,
}))

const groups = computed(() => {
  const N = props.columns
  const result: any[][] = new Array(N).fill(0).map(() => [])

  for (let i = 0; i < props.data.length; i++) {
    const r = i % N
    result[r].push(props.data[i])
  }

  return result
})
</script>

<style scoped></style>
