<template>
  <div>
    <EditorContent
      :editor="editor"
      class="min-h-44px note-editor"
      @click="editor?.commands.focus()"
      @keydown="onKeydown"
    />

    <div
      v-if="wordOverflow > 0"
      class="text-red-500 text-17px mt-10px"
    >
      超出字数限制，请再删减 {{ wordOverflow }} 个字
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useEditor, EditorContent } from '@tiptap/vue-3'
// block nodes
import Document from '@/components/ClozeEditor/extensions/doc'
import Paragraph from '@/components/ClozeEditor/extensions/p'
// inline nodes
import Text from '@/components/ClozeEditor/extensions/text'
// marks
import Cloze from '@/components/ClozeEditor/extensions/cloze'
import PlainText from '@/components/ClozeEditor/extensions/plain-text'
import Placeholder from '@tiptap/extension-placeholder'
import History from '@tiptap/extension-history'
import CustomPaste from '@/components/ClozeEditor/extensions/custom-paste'
import ClearMarksOnEnter from '@/components/ClozeEditor/extensions/clear-mark-on-enter'
import type { Content } from '@/types/core'
import { computed, onMounted, onUnmounted } from 'vue'
import bus, { BusEvent } from '@/bus/bus'
import { content2Doc, doc2Content } from '@/utils/card'
import TextStyle, {
  DotText,
} from '@/components/ClozeEditor/extensions/text-style'

const props = withDefaults(
  defineProps<{
    content: Content
    id?: number
    editable?: boolean
    limit?: number
    autofocus?: boolean
    placeholder?: string
  }>(),
  {
    editable: true,
    autofocus: false,
    placeholder: '在此输入卡片内容',
  }
)

const emit = defineEmits<{
  update: [Content]
  focus: []
  blur: []
  prev: []
  next: []
  createNext: []
}>()

const editor = useEditor({
  editable: props.editable,
  content: content2Doc(props.content),
  extensions: [
    Document,
    // Block
    Paragraph,
    // Inline
    Text,
    // marks
    Cloze,
    PlainText,
    TextStyle,
    DotText,
    Placeholder.configure({
      placeholder: props.placeholder,
    }),
    History,
    CustomPaste,
    ClearMarksOnEnter,
  ],
  editorProps: {
    attributes: {
      class: 'focus:outline-none',
    },
  },
  onUpdate({ editor }) {
    const doc = editor.getJSON()

    setTimeout(() => {
      emit('update', doc2Content(doc))
    })
  },
  onFocus() {
    emit('focus')
  },
  onBlur() {
    emit('blur')
    if (editor.value != null) {
      // 当前字数
      const currentWordCount = editor.value.getText().length
      if (props.limit != null && currentWordCount > props.limit) {
        editor.value.commands.deleteRange({
          from: props.limit + 1,
          to: currentWordCount + 1,
        })
      }
    }
  },
})

// 超出多少字
const wordOverflow = computed(() => {
  if (editor.value != null) {
    // 当前字数
    const currentWordCount = editor.value.getText().length
    if (props.limit != null && currentWordCount > props.limit) {
      return currentWordCount - props.limit
    }
  }
  return 0
})

function onKeydown(e: KeyboardEvent) {
  const key = e.key.toLowerCase()

  if (key === 'enter' && (e.metaKey || e.ctrlKey)) {
    emit('createNext')
  }

  if (key === 'tab') {
    e.preventDefault()

    if (e.shiftKey) {
      emit('prev')
    } else {
      emit('next')
    }
  }
}

function onFocusCall(id: number) {
  console.log('focus call', id)
  if (props.id === id && id != null) {
    editor.value?.commands.focus('end')
  }
}

onMounted(() => {
  bus.on(BusEvent.NoteEditorFocus, onFocusCall)
})

onUnmounted(() => {
  bus.off(BusEvent.NoteEditorFocus, onFocusCall)
})
</script>
<style lang="stylus">
.tiptap
  outline: none

  p.is-editor-empty:first-child::before {
    color: var(--gray-500);
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  }

  p {
    white-space: pre-line;
    margin-bottom: 0px;
  }

  span.dot {
    margin: 0px 4px;
  }

  span.dot:before,span.dot:after {
    content: '·';
    display: inline;
  }
  span.dot:before {
    margin-right: 2px;
  }
  span.dot:after {
    margin-left: 2px;
  }

  span[data-cloze]
    padding: 0 6px
    &::before
      content: "{{ "
      font-weight: bold
      color: blue
    &::after
      content: " }}"
      color: blue
      font-weight: bold
</style>
