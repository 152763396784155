<template>
  <div class="flex mb-4 gap-4">
    <div
      :class="[
        'cardtype gap-4',
        {
          selected: type === CardType.CLOZE,
        },
      ]"
      @click="type = CardType.CLOZE"
    >
      <Icon
        name="cardtype-cloze"
        class="w-48px"
      />
      <span>{{ _t('知识点') }}</span>
    </div>

    <div
      :class="[
        'cardtype gap-4',
        {
          selected: type === CardType.EN_WORD,
        },
      ]"
      @click="type = CardType.EN_WORD"
    >
      <Icon
        name="cardtype-enword"
        class="w-48px"
      />
      <span>{{ _t('英语单词') }}</span>
    </div>
  </div>
  <Button
    :disabled="props.card.type === type"
    :label="_t('切换卡片类型')"
    @click="onCardTypeSwitch"
  ></Button>
</template>
<script setup lang="ts">
import { type Card, CardType } from '@/types/core'
import { stringifyContent } from '@/utils/card'
import { ref } from 'vue'

const props = defineProps<{
  card: Card
  packageId: number
}>()

const emit = defineEmits<{
  done: []
  typeSwitch: [CardType]
}>()

const type = ref(props.card.type)

function isEmptyCard(card: Card): boolean {
  switch (card.type) {
    case CardType.CLOZE:
      const contentText = stringifyContent(card.content)
      const analysisText = stringifyContent(card.analysis ?? [])

      return contentText.trim() === '' && analysisText.trim() === ''
    case CardType.EN_WORD:
      return card.word === '' && card.definition === ''
  }
}

async function onCardTypeSwitch() {
  if (!isEmptyCard(props.card)) {
    const ok = await _confirm({
      type: 'warn',
      icon: {
        name: 'ld-cry',
        type: 'img',
      },
      content: '当前卡片已有内容了\n切换笔记类型将会清空全部数据哦',
      okText: '清除笔记数据、切换笔记类型',
      cancelText: '保留数据、暂不切换',
    })

    if (ok) {
      emit('typeSwitch', type.value)
      emit('done')
    }
  } else {
    emit('typeSwitch', type.value)
    emit('done')
  }
}
</script>
<style scoped>
.cardtype {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 164px;
  background-color: white;
  border-radius: 8px;
  border: 1px solid var(--ld-border);
  cursor: pointer;
}

.cardtype.selected {
  border: 1px solid var(--primary-color);
  background-color: var(--primary-100);
}
</style>
