<template>
  <div>
    <div class="header text-center mb-4">
      <span class="text-19px leading-24px font-semibold">{{ _t('共享') }}</span>

      <Icon
        name="close-circle"
        class="cursor-pointer absolute right-4 top-3 w-22px"
        @click="emit('done')"
      ></Icon>
    </div>

    <div class="text-19x leading-none">
      {{ _t('开启共享后，其他用户可学习此卡包') }}
    </div>

    <div
      v-if="
        isShareOpen &&
        props.package.owned?.shareStatus === ShareStatus.COMMUNITY
      "
      class="w-full mt-4 flex h-38px text-ld-label-secondary"
    >
      <div
        class="flex-1 px-2 border-1 border-r-none rounded-l-lg flex items-center text-14px line-clamp-1"
      >
        <Icon
          name="globe"
          class="w-16px h-16px mr-2 shrink-0"
        ></Icon>
        {{ shareLink }}
      </div>
      <div
        class="px-4 cursor-pointer text-14px bg-ld-background border-1 rounded-r-lg flex items-center"
        @click="onLinkCopy"
      >
        {{ _t('复制链接') }}
      </div>
    </div>

    <label
      data-required
      class="block font-semibold text-15px mt-4 text-ld-label-secondary leading-none"
    >
      {{ _t('学科分类') }}
    </label>

    <div class="flex items-center mt-2 w-full">
      <AutoComplete
        class="mt-2 w-full"
        :model-value="tagValue"
        :suggestions="filteredContentTags"
        dropdown
        optionLabel="name"
        optionGroupLabel="name"
        optionGroupChildren="children"
        placeholder="请选择内容标签"
        emptySearchMessage="未找到对应的标签"
        :pt="{ input: { value: inputDisplay } }"
        @complete="searchTag"
        @update:model-value="onTagChange"
        @blur="onTagSelecterBlur"
      ></AutoComplete>
    </div>
    <label
      class="block font-semibold text-15px mt-4 text-ld-label-secondary leading-none"
    >
      卡包简介
    </label>
    <Textarea
      v-model="description"
      class="w-full mt-2"
      rows="4"
      autoResize
      maxlength="1000"
    />

    <div
      v-if="isShareOpen"
      :class="[
        'flex mt-4',
        'gap-4',
        {
          'flex-wrap': !isPcMode,
          'justify-between': !isPcMode,
        },
      ]"
    >
      <Button
        :label="_t('取消共享')"
        :loading="loading"
        scene="danger"
        @click="onShareClose()"
      />
      <Button
        :label="_t('发布更新')"
        scene="secondary"
        @click="onPublish()"
      />
      <Button
        class="flex-1"
        :label="_t('查看链接')"
        @click="onShareLinkShow()"
      />
    </div>

    <Button
      v-else
      class="w-full mt-4"
      :label="_t('发布共享')"
      :loading="loading"
      @click="onSharePublish()"
    />
  </div>
</template>

<script lang="ts" setup>
import {
  fetchContentTags,
  fetchFrequentlyContentTags,
  ShareStatus,
  updatePackageShare,
} from '@/api/package-source'
import { computed } from 'vue'
import { ref } from 'vue'
import { useClipboard } from '@vueuse/core'
import PublishInfo from './PublishInfo.vue'

import type { PackageBasic } from '@/api/package-source'
import { useRouter } from 'vue-router'
import Textarea from 'primevue/textarea'
import AutoComplete from 'primevue/autocomplete'
import { findParentTag, tree2Map } from '@/utils/tag'
import type { ContentTag } from '@/types/core'
import type { AutoCompleteCompleteEvent } from 'primevue/autocomplete'

interface ContentTagOption extends ContentTag {
  parent: ContentTag
  children?: ContentTagOption[]
}
const isPcMode = _global.isPcMode

const router = useRouter()

const props = defineProps<{
  package: PackageBasic
}>()

const emit = defineEmits<{
  done: []
  update: [PackageBasic]
}>()

const pkgPublicPath = computed(() => `/pkgs/${props.package.hashId}`)

const shareLink = computed(
  () => `${location.protocol}//${location.host}${pkgPublicPath.value}`
)

const { copy } = useClipboard({ source: shareLink.value })

const isShareOpen = computed(
  () => props.package.owned?.shareStatus === ShareStatus.COMMUNITY
)

const loading = ref(false)

// 简介
const description = ref(props.package.description)

let cacheMap = new Map<string, ContentTagOption>()
const selectedTagKey = ref<string | undefined>(
  props.package?.contentTags[0]?.tag.key
)

const tagValue = ref<string | ContentTagOption | undefined>(
  selectedTagKey.value
)
const contentTags = ref<ContentTagOption[]>([])
const filteredContentTags = ref<ContentTagOption[]>([])

const selectedTag = computed(() => {
  if (selectedTagKey.value == null) return undefined

  return cacheMap.get(selectedTagKey.value)
})

const inputDisplay = computed(() => {
  if (typeof tagValue.value === 'string') {
    return tagValue.value
  } else if (selectedTag.value) {
    const parent = selectedTag.value.parent
    const showParentName = !['frequently', 'main'].includes(parent.key)

    return showParentName
      ? `${parent.name}/${selectedTag.value.name}`
      : selectedTag.value.name
  }

  return null
})

Promise.all([
  fetchContentTags('main'),
  fetchFrequentlyContentTags('main'),
]).then(([{ tree }, { contentTags: frequentlyContentTags }]) => {
  const allLeafs = getContentTagLeafs(tree, [])
  const parents: Record<string, ContentTagOption> = {}

  for (const leaf of allLeafs) {
    const parent = leaf.parent

    if (parents[parent.key]) {
      parents[parent.key].children?.push(leaf)
    } else {
      parents[parent.key] = {
        ...parent,
        parent,
        children: [leaf],
      }
    }
  }

  const frequentlyGroup: ContentTagOption = {
    key: 'frequently',
    name: _t('最近使用'),
    hidden: false,
    parent: tree,
    aliases: [],
    children: [],
  }

  frequentlyGroup.children = frequentlyContentTags.map(item => {
    const parent = findParentTag([tree], item.key)
    return {
      ...item,
      name: parent ? `${parent.name}/${item.name}` : item.name,
      parent: frequentlyGroup,
      children: [],
    }
  })

  const options = [frequentlyGroup, ...Object.values(parents)]

  contentTags.value = options
  filteredContentTags.value = options
  cacheMap = tree2Map(options)

  tagValue.value = selectedTag.value
})

function getContentTagLeafs(tag: ContentTag, leafs: ContentTagOption[]) {
  if (!tag.children) return leafs

  for (const item of tag.children) {
    if (!item.children) {
      leafs.push({
        ...item,
        parent: tag,
        children: [],
      })
    } else {
      getContentTagLeafs(item, leafs)
    }
  }

  return leafs
}

function onTagChange(opt: ContentTagOption) {
  tagValue.value = opt

  // 仅当选择选项时，设置 selectedTagKey
  if (opt.key) {
    selectedTagKey.value = opt.key
  }
}

function onTagSelecterBlur() {
  tagValue.value = selectedTag.value
}

function searchTag(evt: AutoCompleteCompleteEvent) {
  const filteredOptions: ContentTagOption[] = []
  const query = evt.query.trim()

  if (!query) {
    filteredContentTags.value = [...contentTags.value]
    return
  }

  for (const parent of contentTags.value) {
    const filteredChildren = parent.children!.filter(item => {
      return item.name.includes(query)
    })
    const matched = parent.name.includes(query)

    // 当父节点匹配的时候，无需再筛选子节点
    if (matched) {
      filteredOptions.push(parent)
    } else if (filteredChildren.length > 0) {
      filteredOptions.push({
        ...parent,
        children: filteredChildren,
      })
    }
  }

  filteredContentTags.value = filteredOptions
}
async function onSharePublish() {
  // 检查是否选择了标签
  if (!selectedTagKey.value) {
    _message.info('请填写分类')
    return
  }

  loading.value = true
  try {
    const res = await updatePackageShare(
      props.package.id,
      ShareStatus.COMMUNITY,
      [
        {
          key: selectedTagKey.value!,
          rootKey: 'main',
        },
      ],
      description.value
    )

    if (res.code !== 0) {
      _message.info(res.message)
      return
    } else {
      _message.info('已开启共享')

      emit('update', res.data)
    }
  } finally {
    loading.value = false
  }
}

function onLinkCopy() {
  copy(shareLink.value)
  _message.info('链接已复制')
}

async function onPublish() {
  if (!selectedTagKey.value) {
    _message.info('请填写分类')
    return
  }

  _openDialog(PublishInfo, {
    title: _t('发布更新'),
    props: {
      contentTags: [
        {
          key: selectedTag.value!.key,
          name: selectedTag.value!.name,
          rootKey: 'main',
        },
      ],
      description: description.value,
      packageId: props.package.id,
      onRelease(newPkg: PackageBasic) {
        emit('update', newPkg)
      },
    },
  })
}

async function onShareClose() {
  loading.value = true
  try {
    const res = await updatePackageShare(props.package.id, ShareStatus.OFF)

    if (res.code !== 0) {
      _message.info(res.message)
    } else {
      _message.info('已关闭共享')

      emit('update', res.data)
      emit('done')
    }
  } finally {
    loading.value = false
  }
}

function onShareLinkShow() {
  emit('done')

  if (isPcMode) {
    window.open(shareLink.value)
  } else {
    router.push(pkgPublicPath.value)
  }
}
</script>

<style scoped></style>
