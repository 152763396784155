<template>
  <label
    data-required
    class="block text-900 text-xl font-medium"
  >
    卡片类型：
  </label>

  <Dropdown
    :modelValue="data.card?.type"
    optionLabel="label"
    optionValue="value"
    :options="cardOptions"
    class="mb-4"
    :placeholder="_t('请选择卡片类型')"
    @update:modelValue="onCardTypeChange"
  />

  <template v-if="data.card">
    <ClozeCardForm
      v-if="data.card.type === CardType.CLOZE"
      class="w-md"
      :card="data.card"
      @change="onClozeCardChange"
    ></ClozeCardForm>

    <WordCardForm
      v-if="data.card.type === CardType.EN_WORD"
      class="w-md"
      :card="data.card"
      @change="onWordCardChange"
    ></WordCardForm>

    <div
      v-if="cardInvalidMsg"
      class="text-red mt-4"
    >
      {{ cardInvalidMsg }}
    </div>

    <div class="mt-4 flex items-center">
      <Button
        class="w-full"
        :label="okText"
        :disabled="!isValid"
        :loading="data.loading"
        @click="onSubmit"
      />
    </div>
  </template>

  <Empty
    v-else
    :text="_t('请先选择卡片类型')"
  ></Empty>
</template>

<script lang="ts" setup>
import { CardTypeName, createCard, updateCard } from '@/api/package-source'
import ClozeCardForm from './ClozeCardForm.vue'
import WordCardForm from './WordCardForm.vue'
import { computed, reactive } from 'vue'
import { CardType } from '@/types/core'
import Dropdown from 'primevue/dropdown'
import Empty from '@/components/Empty.vue'
import { updatePackage } from '@/api/package-source'

import type { CardResponse, Package } from '@/api/package-source'
import type { ClozeCard, Card } from '@/types/core'
import { newClozeCard, newWordCard, validateCard } from '@/utils/card'

const cardOptions = [
  {
    label: _t('填空题'),
    value: CardType.CLOZE,
  },
  {
    label: _t('英语单词'),
    value: CardType.EN_WORD,
  },
]

const props = defineProps<{
  card?: CardResponse
  package: Package
  chapterId: string
  beforeCardId?: number
  afterCardId?: number
  closeAfterCreate?: boolean
}>()

const emit = defineEmits<{
  create: [CardResponse]
  update: [CardResponse]
  updateDefaultCard: [CardTypeName]
  done: []
}>()

const data = reactive({
  loading: false,
  card: createDefaultCard() as Card | null,
})

const okText = computed(() =>
  props.card == null ? _t('保存 并 新建') : _t('保存')
)

const cardInvalidMsg = computed(() => {
  if (data.card == null) return ''

  return validateCard(data.card)
})

const isValid = computed(() => cardInvalidMsg.value === '')

const cardType = computed<CardTypeName | undefined>(() => {
  if (data.card == null) return undefined

  return {
    [CardType.CLOZE]: CardTypeName.CLOZE,
    [CardType.EN_WORD]: CardTypeName.WORD,
  }[data.card.type]
})

function createDefaultCard(): Card | null {
  if (props.card != null) {
    return JSON.parse(props.card.content)
  }

  if (props.package.owned?.defaultCardType != null) {
    return {
      [CardTypeName.CLOZE]: newClozeCard(),
      [CardTypeName.WORD]: newWordCard(),
    }[props.package.owned.defaultCardType]
  }

  return null
}

function onCardTypeChange(type: Card['type']) {
  if (type === CardType.CLOZE) {
    data.card = newClozeCard()
  }

  if (type === CardType.EN_WORD) {
    data.card = newWordCard()
  }
}

function onClozeCardChange(clozeCard: ClozeCard) {
  data.card = clozeCard
}
function onWordCardChange(wordCard: Card) {
  data.card = wordCard
}

function onCreate() {
  if (!isValid.value) return Promise.reject()

  return createCard({
    packageId: props.package.id,
    chapterId: props.chapterId,
    content: data.card!,
    beforeCardId: props.beforeCardId,
    afterCardId: props.afterCardId,
  }).then(res => {
    if (res.code === 0) {
      emit('create', res.data)
      if (props.closeAfterCreate) {
        emit('done')
      }
      onCardTypeChange(data.card!.type)
    } else {
      _message.info(res.message)
    }
  })
}

function onUpdate() {
  if (!isValid.value) return Promise.reject()

  return updateCard(props.card!.id, data.card!).then(res => {
    if (res.code === 0) {
      emit('update', res.data)
    } else {
      _message.info(res.message)
    }
  })
}

function onSubmit() {
  if (!isValid.value) return

  data.loading = true

  if (props.card == null) {
    onCreate()
      .then(() => {
        if (
          cardType.value == null ||
          cardType.value === props.package.owned?.defaultCardType
        )
          return

        return updatePackage(props.package.id, {
          defaultCardType: cardType.value,
        }).then(() => {
          emit('updateDefaultCard', cardType.value!)
        })
      })
      .finally(() => {
        data.loading = false
      })
  } else {
    onUpdate().finally(() => {
      data.loading = false
    })
  }
}
</script>

<style scoped></style>
