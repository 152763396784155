import { random } from 'lodash-es'

export enum feedbackStar {
  One = 1,
  Two = 2,
  Three = 3,
}

const FEEDBACK_WORDS = {
  [feedbackStar.One]: [
    '🏃‍♂️ 再接再厉',
    '🏋️‍♀️ 继续努力',
    '🎈 下次会更好',
    '🧡 不要灰心',
    '🌱 每次尝试都是成长',
    '💪🏻 加油加油',
    '🔥 别气馁',
    '🧩 没关系慢慢来',
    '🌼 失败是成功之母',
  ],
  [feedbackStar.Two]: [
    '🌼 做得很好',
    '🎈 哎呦，不错哦',
    '🍭 棒棒哒',
    '💐 可圈可点',
    '🙌 值得嘉许',
    '🌟 近乎完美',
    '😎 酷',
    '🌈 优秀',
    '👍🏻 继续保持',
  ],
  [feedbackStar.Three]: [
    '🏆 真厉害！',
    '🥇 你是最棒的！',
    '🎉 太棒了！',
    '🌟 完美！',
    '👍🏻 大大的赞！',
    '💎 无可挑剔！',
    '😎 太酷啦！',
    '💯 满分！',
    '🏆 无与伦比！',
  ],
}

export function genWord(star: feedbackStar) {
  const words = FEEDBACK_WORDS[star]

  return words[random(words.length - 1)]
}
