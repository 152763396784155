import { Extension } from '@tiptap/core'
import type { Editor } from '@tiptap/core'

function parseTiptapHtml(html: string): string | null {
  const el = document.createElement('span')
  el.innerHTML = html

  const p = el.querySelector('p[data-pm-slice]')

  if (p != null) {
    p.replaceWith(...p.childNodes)
    return el.outerHTML
  }

  return null
}

async function onPaste(editor: Editor) {
  const clipboardContentArray = await navigator.clipboard.read()

  if (clipboardContentArray.length == 0) {
    return
  }

  const clipboardContent = clipboardContentArray[0]

  if (clipboardContent.types.includes('text/html')) {
    let html = await (await clipboardContent.getType('text/html')).text()
    const parsedHtml = parseTiptapHtml(html)

    // 对于纯文本这里需要特殊处理下，只需要粘贴对应的文本就可以，如果直接 insert html 会打断当前的 marks
    if (parsedHtml) {
      editor.commands.insertContent(parsedHtml)
    } else {
      editor.commands.insertContent(html)
    }
  } else if (clipboardContent.types.includes('text/plain')) {
    let text = await (await clipboardContent.getType('text/plain')).text()

    editor.commands.insertContent(text)
  }
}

const CustomPaste = Extension.create({
  name: 'custom_paste',

  addKeyboardShortcuts() {
    return {
      'Mod-v': ({ editor }) => {
        onPaste(editor)
        return true
      },
      'Mod-V': ({ editor }) => {
        onPaste(editor)
        return true
      },
    }
  },
})

export default CustomPaste
