<template>
  <div
    v-if="store.energy"
    class="flex flex-col items-center bg-ld-brand-100 w-full pt-20px px-4 rounded-12px relative"
  >
    <Icon
      name="close-circle"
      class="cursor-pointer absolute right-2 top-2 z-1 w-25px"
      @click="emit('done')"
    ></Icon>

    <div
      class="rounded-full w-81px h-81px relative overflow-hidden flex items-center justify-center"
    >
      <div
        class="absolute w-full h-full top-0 left-0 bg-orange-100"
      ></div>

      <Icon
        name="energy"
        class="w-56px heartbeat relative top-1"
      />
    </div>

    <ProgressBar
      :percent="energyPercent"
      color="linear-gradient(269.98deg, var(--yellow-600) 0.01%, var(--yellow-500) 99.98%)"
      :highlight="false"
      :lottie="false"
      :text="`${store.energy.currentEnergy}/${store.energy.energyLimit}`"
      :border-radius="50"
      class="progress-container"
    />

    <span class="text-19px leading-[1.4] text-ld-brand-600 font-semibold">
      <template v-if="energyTimerLabel">
        {{ _t(`${energyTimerLabel} 后面包+1`) }}
      </template>
      <template v-else>{{ _t('面包已满，快去闯关吧') }}</template>
    </span>

    <div class="w-full energy-buy mt-6 mb-4">
      <template v-if="config != null">
        <div class="flex flex-col items-center">
          <Icon
            name="energy-add"
            class="w-50px"
          />
          <span class="text-17px font-semibold text-yellow-900 leading-[1.4]">
            {{ _t(`面包+${config.energy}`) }}
          </span>
        </div>

        <div class="ml-auto flex gap-6px">
          <template v-if="!store.isVipValid">
            <Button
              class="h-50px"
              :loading="diamondExchangeLoading"
              @click="onDiamondExchange(config)"
            >
              <Icon
                name="diamond"
                class="w-24px mr-2"
              />
              <span class="text-17px font-semibold">{{ config.diamond }}</span>
            </Button>

            <Button
              class="h-50px"
              scene="vip"
              @click="onVipBuy"
            >
              {{ _t('免费畅学') }}
            </Button>
          </template>

          <template v-else-if="vipFreeTimes > 0">
            <div class="flex flex-col items-center">
              <div
                class="text-[var(--text-color-secondary)] text-15px leading-[1em] font-semibold mb-2"
              >
                {{ _t(`今日剩余 ${vipFreeTimes} 次`) }}
              </div>
              <Button
                class="h-44px"
                scene="vip"
                :loading="vipFreeExchangeLoading"
                @click="onVipFreeExchange"
              >
                {{ _t('免费补充') }}
              </Button>
            </div>
          </template>
          <template v-else>
            <div class="flex flex-col items-center">
              <div
                class="text-[var(--text-color-secondary)] text-15px leading-[1em] font-semibold mb-2"
              >
                {{ _t(`今日免费次数已用完`) }}
              </div>

              <Button
                class="h-44px"
                :loading="diamondExchangeLoading"
                @click="onDiamondExchange(config)"
              >
                <Icon
                  name="diamond"
                  class="w-24px mr-2"
                />
                <span class="text-17px font-semibold">
                  {{ config.diamond }}
                </span>
              </Button>
            </div>
          </template>
        </div>
      </template>
    </div>
  </div>

  <div class="grid grid-cols-2 w-full p-4 gap-3 leading-[1.4]">
    <div class="box bg-cyan-100 text-cyan-800">
      <div class="text-21px font-semibold mb-6px">{{ _t('邀请好友') }}</div>
      <div class="text-15px font-semibold mb-6px">{{ _t('增加面包上限') }}</div>
      <Icon
        name="energy-hand"
        class="w-50px"
      />

      <Button
        label="立即邀请"
        class="mt-4 w-full"
        @click="onInviteFirend"
      />
    </div>

    <div class="box bg-yellow-100 text-yellow-800">
      <template v-if="!store.isVipValid">
        <div class="text-21px font-semibold mb-6px">{{ _t('开通畅学卡') }}</div>
        <div class="text-15px font-semibold mb-6px">
          {{ _t('面包上限+30') }}
        </div>

        <Icon
          name="energy-vip"
          class="w-50px"
        />

        <Button
          class="w-full mt-4 h-44px"
          label="立即开通"
          scene="vip"
          @click="onVipBuy"
        />
      </template>

      <template v-else>
        <div class="text-19px font-semibold mb-2 text-red-400">
          {{ _t('面包上限+30') }}
        </div>

        <Icon
          name="energy-vip"
          class="w-90px"
        />

        <div class="text-yellow-800 text-21px font-semibold mt-auto">
          {{ _t('畅学卡激活中') }}
        </div>
      </template>
    </div>
  </div>
</template>
<script setup lang="ts">
import {
  fetchDiamondExchangeConfigs,
  type DiamondExchangeConfig,
  exchangeEnergy,
} from '@/api/order'
import { useCommonStore } from '@/stores'
import { computed, onMounted, onUnmounted, ref } from 'vue'
import { Code } from '@/api/code'
import { fetchVipLeftFreeTimes, vipExchangeEnergyFree } from '@/api/user'
import ProgressBar from './ProgressBar.vue'
import Store from '@/components/Store/Store.vue'

const store = useCommonStore()
const diamondConfigs = ref<DiamondExchangeConfig[]>([])
const vipFreeTimes = ref(0)
const diamondExchangeLoading = ref(false)
const vipFreeExchangeLoading = ref(false)

const energyGainSound = new Howl({
  src: ['/audio/energy_gain.mp3'],
  html5: true,
})

const emit = defineEmits<{
  done: []
}>()

const config = computed(() => diamondConfigs.value[0])

const energyTimerLabel = computed(() => {
  if (store.energyCountDown == null) return ''

  const minsLabel = Math.floor(store.energyCountDown / 60).toString()
  const secondsLabel = (store.energyCountDown % 60).toString()
  return `${minsLabel.padStart(2, '0')}:${secondsLabel.padStart(2, '0')}`
})

const energyPercent = computed(() => {
  if (store.energy == null) return 0

  return Math.min(
    (store.energy.currentEnergy / store.energy.energyLimit) * 100,
    100
  )
})

function fetchConfigs() {
  fetchDiamondExchangeConfigs().then(res => {
    diamondConfigs.value = res.items
  })
  fetchVipLeftFreeTimes().then(res => {
    vipFreeTimes.value = res.leftTimes
  })
}

function onInviteFirend() {
  _message.info(_t('该功能尚未开放，敬请期待'))
}

async function onVipBuy() {
  _openDialog(Store, {
    rootClass: 'max-h-600px',
    fullscreenInMobile: true,
    dialog: {
      showHeader: false,
      dismissableMask: true,
      pt: {
        content: { class: 'p-0' },
      },
    },
  })
}

function onVipFreeExchange() {
  if (vipFreeExchangeLoading.value) return

  vipFreeExchangeLoading.value = true
  vipExchangeEnergyFree()
    .then(res => {
      if (res.code !== Code.Ok) {
        _message.info(res.message)
        return
      }

      vipFreeTimes.value -= 1
      store.setEnergyStatus(res.data)
    })
    .finally(() => {
      vipFreeExchangeLoading.value = false
    })
}

async function onDiamondExchange(config: DiamondExchangeConfig) {
  if (diamondExchangeLoading.value) return
  const userDiamonds = store.userAssets?.diamond ?? 0

  if (userDiamonds < config.diamond) {
    _message.info('钻石不足')
    return
  }

  diamondExchangeLoading.value = true
  exchangeEnergy(config.configId)
    .then(res => {
      if (res.code !== Code.Ok) {
        _message.info(res.message)
        return
      }
      energyGainSound.play()

      store.setEnergyStatus(res.data)
      store.fetchUserAssets()
    })
    .finally(() => {
      diamondExchangeLoading.value = false
    })
}

fetchConfigs()

onMounted(() => {
  energyGainSound.load()
})

onUnmounted(() => {
  energyGainSound.unload()
})
</script>

<style scoped>
.progress-container {
  width: 142px;
  height: 33px;
  border-radius: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid white;
  top: -4px;
}

.progress-container :deep(span) {
  font-family: DIN;
  font-weight: 700;
  font-size: 19px;
}

.energy-count {
  font-size: 19px;
  font-weight: 700;
  line-height: 1;
  color: white;
  font-family: DIN;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--red-600);
}

.energy-buy {
  height: 90px;
  display: flex;
  align-items: center;
  padding: 8px 12px;
  background: var(--gray-50);
  border-radius: 12px;
  box-shadow: -1px -1px 2px 0px var(--slate-300) inset;
}

.box {
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
}

.heartbeat {
  animation: heartbeat 2s infinite;
}

@keyframes heartbeat {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
